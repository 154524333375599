/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SchibstedMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 126,
  height: 33,
  viewBox: "0 0 126 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M0.761601 15.7819L0.777004 15.8131C0.862506 15.9865 0.979867 16.2244 1.15716 16.4482C1.27966 16.6028 1.45201 16.7086 1.65992 16.7086C1.98559 16.7086 2.20795 16.4723 2.20795 16.1431C2.20795 16.0301 2.16987 15.9279 2.12131 15.8326C2.10476 15.8002 2.0791 15.7483 2.05566 15.7008L2.01793 15.6247C2.00073 15.5902 2.00142 15.5557 2.03101 15.5397C2.0658 15.52 2.09868 15.5373 2.11696 15.5676C2.14525 15.6145 2.23303 15.7546 2.23303 15.7546C2.33894 15.9198 2.51832 16.0406 2.72922 16.0406C3.06276 16.0406 3.32205 15.7643 3.32205 15.4337C3.32205 15.3388 3.29873 15.2497 3.25989 15.1698C3.25253 15.1547 3.24194 15.1323 3.23117 15.1095C3.21915 15.084 3.20682 15.058 3.19856 15.0411C3.18143 15.0063 3.18105 14.9712 3.2111 14.953C3.24215 14.9339 3.27618 14.9521 3.2946 14.9805C3.31731 15.0154 3.36342 15.0848 3.38766 15.1186C3.48753 15.2581 3.64749 15.3423 3.82681 15.3423C4.12372 15.3423 4.36925 15.1191 4.36925 14.7785C4.36925 14.6718 4.32345 14.539 4.27489 14.4313C4.03395 13.8971 3.91627 13.3765 3.91627 12.5753C3.91627 10.6862 5.39374 9.15487 7.17952 9.15487C9.54499 9.15487 10.4249 10.9748 10.5936 11.5161C10.6137 11.581 10.6829 11.617 10.7479 11.597L14.2659 10.5144C14.3314 10.4942 14.3692 10.4249 14.3489 10.3596C13.5721 7.85526 11.0946 5.22974 7.21913 5.22974C4.0078 5.22974 1.33907 7.38125 0.360233 10.285C-0.250881 12.0979 -0.0630816 14.114 0.761601 15.7819Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.29316 14.0304C7.10383 14.0304 6.95166 13.8769 6.95166 13.6892C6.95166 13.4998 7.10513 13.3477 7.29293 13.3477C7.48225 13.3477 7.63442 13.5011 7.63442 13.6889C7.63442 13.8782 7.48096 14.0304 7.29316 14.0304Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6.95166 16.615C6.95166 16.8027 7.10383 16.9562 7.29316 16.9562C7.48096 16.9562 7.63442 16.804 7.63442 16.6147C7.63442 16.427 7.48225 16.2734 7.29293 16.2734C7.10513 16.2734 6.95166 16.4257 6.95166 16.615Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.8093 17.417L13.8247 17.4482C14.6494 19.1161 14.8372 21.1323 14.2261 22.9451C13.2472 25.8488 10.5785 28.0004 7.36718 28.0004C3.49175 28.0004 1.01418 25.3748 0.237441 22.8706C0.217102 22.8053 0.254952 22.7359 0.320407 22.7158L3.83836 21.6331C3.90336 21.6131 3.97256 21.6491 3.99275 21.714C4.16143 22.2553 5.04132 24.0753 7.40679 24.0753C9.19257 24.0753 10.67 22.544 10.67 20.6548C10.67 19.8537 10.5523 19.333 10.3114 18.7988C10.2629 18.6912 10.2171 18.5584 10.2171 18.4516C10.2171 18.111 10.4626 17.8878 10.7595 17.8878C10.9388 17.8878 11.0988 17.972 11.1986 18.1116C11.2229 18.1453 11.269 18.2147 11.2917 18.2496C11.3101 18.2781 11.3442 18.2962 11.3752 18.2772C11.4053 18.2589 11.4049 18.2238 11.3877 18.189C11.3792 18.1717 11.3665 18.1447 11.3541 18.1185C11.3437 18.0965 11.3335 18.0749 11.3264 18.0603C11.2876 17.9804 11.2643 17.8913 11.2643 17.7965C11.2643 17.4658 11.5235 17.1895 11.8571 17.1895C12.068 17.1895 12.2474 17.3103 12.3533 17.4755C12.3533 17.4755 12.4411 17.6156 12.4694 17.6626C12.4876 17.6928 12.5205 17.7101 12.5553 17.6904C12.5849 17.6744 12.5856 17.6399 12.5684 17.6055L12.5317 17.5314C12.508 17.4834 12.4818 17.4304 12.465 17.3975C12.4164 17.3023 12.3784 17.2 12.3784 17.087C12.3784 16.7578 12.6007 16.5215 12.9264 16.5215C13.1343 16.5215 13.3067 16.6274 13.4292 16.7819C13.6064 17.0057 13.7238 17.2436 13.8093 17.417Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6.95166 15.152C6.95166 15.3398 7.10383 15.4933 7.29316 15.4933C7.48096 15.4933 7.63442 15.3411 7.63442 15.1518C7.63442 14.9641 7.48225 14.8105 7.29293 14.8105C7.10513 14.8105 6.95166 14.9628 6.95166 15.152Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.98334 17.1435C9.91092 17.0682 9.81243 16.9874 9.72656 16.9279C9.52584 16.789 9.34118 16.674 9.11981 16.5721L9.0825 16.5549C8.96495 16.5005 8.89539 16.4683 8.73687 16.4235C8.60695 16.3868 8.48323 16.3706 8.36593 16.4193C8.18264 16.4953 8.08133 16.6899 8.14036 16.9014C8.18555 17.0631 8.31638 17.1276 8.45639 17.1948C8.68089 17.3026 8.79231 17.36 8.98309 17.4777C9.13181 17.5694 9.25852 17.6539 9.40961 17.7618C9.58885 17.8898 9.81572 17.9107 9.97584 17.769C10.1568 17.6088 10.1597 17.3271 9.98334 17.1435Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.4651 15.7245C10.7157 15.8531 10.9752 16.0552 11.2046 16.2896C11.351 16.4392 11.4092 16.6696 11.3145 16.8564C11.2272 17.0287 11.0356 17.1292 10.8442 17.103C10.718 17.0858 10.633 17.0116 10.5439 16.9338C10.5287 16.9206 10.5134 16.9073 10.4978 16.8941C10.3326 16.7547 10.2099 16.6572 10.033 16.5288L10.0014 16.506C9.89714 16.4309 9.80795 16.3667 9.73869 16.2484C9.64769 16.0932 9.65939 15.9034 9.77386 15.7646C9.87931 15.6368 10.0483 15.5873 10.21 15.6253C10.3196 15.6509 10.3817 15.6817 10.4651 15.7245Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.06596 15.0448C8.96281 15.0006 8.87755 14.9654 8.73005 14.9277C8.62093 14.8998 8.49064 14.8838 8.37777 14.9102C8.12192 14.9703 8.00064 15.2781 8.14715 15.4967C8.20649 15.5853 8.28433 15.6239 8.39223 15.6621C8.47901 15.6929 8.54324 15.716 8.64693 15.754L8.68062 15.7665C8.74861 15.7918 8.8221 15.8191 8.88214 15.8306C9.00861 15.8548 9.14159 15.8147 9.23411 15.7254C9.45158 15.5154 9.37856 15.1786 9.06596 15.0448Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.51408 13.4514C8.58374 13.4571 8.65539 13.4726 8.71182 13.4883C8.9208 13.5465 9.05011 13.7461 8.99574 13.9559C8.94887 14.1369 8.77216 14.2587 8.58611 14.2376C8.50238 14.228 8.41055 14.1987 8.33125 14.1709C8.24439 14.1406 8.17052 14.0775 8.12663 13.9966C8.03617 13.8302 8.08916 13.6161 8.246 13.5102C8.32606 13.4562 8.42577 13.4442 8.51408 13.4514Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.5373 14.1013C10.438 14.0395 10.35 13.9928 10.2402 13.9514C10.1199 13.906 10.0036 13.8722 9.88826 13.8645C9.61642 13.8463 9.39368 14.0969 9.44223 14.3647C9.46586 14.4951 9.55211 14.6092 9.67048 14.6684L9.70078 14.6836C9.78009 14.7234 9.84645 14.7567 9.93628 14.8022C9.95686 14.8127 9.97763 14.8236 9.99859 14.8346C10.0779 14.8764 10.1599 14.9196 10.2447 14.9423C10.3753 14.9773 10.5173 14.9484 10.624 14.8656C10.8781 14.6685 10.825 14.2804 10.5373 14.1013Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.1613 15.199C12.2708 15.295 12.3729 15.3991 12.4755 15.5252C12.6087 15.6888 12.6254 15.8914 12.5391 16.0629C12.4609 16.2182 12.2952 16.3162 12.1214 16.3098C11.9757 16.3044 11.8696 16.2282 11.7723 16.129C11.6642 16.019 11.5537 15.9186 11.4381 15.8165C11.4131 15.7945 11.3894 15.7741 11.3663 15.7544C11.3041 15.7011 11.2469 15.6521 11.1858 15.587C11.072 15.4658 11.0367 15.2855 11.0969 15.1304C11.1509 14.9912 11.2762 14.8866 11.423 14.8589C11.5668 14.8318 11.7058 14.8714 11.8257 14.9456C11.9647 15.0316 12.0444 15.0966 12.1613 15.199Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.63442 19.541C7.63442 19.3532 7.48226 19.1997 7.29293 19.1997C7.10513 19.1997 6.95166 19.3519 6.95166 19.5412C6.95166 19.729 7.10383 19.8825 7.29316 19.8825C7.48088 19.8825 7.63442 19.7302 7.63442 19.541Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.29293 17.7366C7.48226 17.7366 7.63442 17.8901 7.63442 18.0778C7.63442 18.2672 7.48088 18.4194 7.29316 18.4194C7.10383 18.4194 6.95166 18.2659 6.95166 18.0781C6.95166 17.8887 7.10513 17.7366 7.29293 17.7366Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.85925 16.3019C4.77338 16.2424 4.67489 16.1616 4.60247 16.0863C4.42607 15.9026 4.42897 15.6211 4.60997 15.4608C4.77009 15.3191 4.99696 15.3401 5.1762 15.4681C5.32729 15.576 5.454 15.6604 5.60272 15.7522C5.7935 15.8698 5.90491 15.9272 6.12942 16.0351C6.26943 16.1023 6.40026 16.1667 6.44545 16.3285C6.50448 16.54 6.40317 16.7346 6.21988 16.8106C6.10258 16.8593 5.97886 16.843 5.84894 16.8063C5.69023 16.7615 5.6207 16.7293 5.5029 16.6747L5.466 16.6577C5.24463 16.5558 5.05997 16.4409 4.85925 16.3019Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3.38158 16.9404C3.61097 17.1747 3.8705 17.3767 4.12108 17.5054C4.2045 17.5482 4.26659 17.5791 4.37617 17.6047C4.53789 17.6427 4.70688 17.5931 4.81233 17.4653C4.9268 17.3266 4.93849 17.1368 4.8475 16.9815C4.77824 16.8633 4.68906 16.7991 4.5848 16.724L4.55318 16.7012C4.37632 16.5727 4.25359 16.4753 4.08835 16.3358C4.07273 16.3226 4.05746 16.3093 4.0423 16.2961C3.9532 16.2183 3.86822 16.1442 3.74196 16.1269C3.55057 16.1008 3.35902 16.2012 3.27169 16.3735C3.17703 16.5603 3.23522 16.7908 3.38158 16.9404Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.85602 18.3022C5.70852 18.2645 5.62326 18.2292 5.52011 18.1851C5.20752 18.0512 5.13449 17.7145 5.35196 17.5045C5.44449 17.4151 5.57746 17.375 5.70393 17.3993C5.76385 17.4107 5.83717 17.438 5.90504 17.4632L5.93914 17.4759C6.04283 17.5139 6.10706 17.537 6.19385 17.5678C6.30174 17.606 6.37951 17.6445 6.43892 17.7332C6.58543 17.9518 6.46408 18.2596 6.2083 18.3197C6.09544 18.3461 5.96514 18.3301 5.85602 18.3022Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.87445 19.7416C5.93088 19.7573 6.00253 19.7728 6.07226 19.7785C6.16051 19.7858 6.26029 19.7738 6.34035 19.7198C6.49711 19.6139 6.5501 19.3998 6.45972 19.2333C6.41582 19.1524 6.34196 19.0894 6.25509 19.059C6.17572 19.0313 6.08396 19.0019 6.00023 18.9924C5.81412 18.9713 5.6374 19.0931 5.59053 19.2741C5.53624 19.484 5.66547 19.6835 5.87445 19.7416Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.34633 19.2785C4.23645 19.2369 4.14851 19.1903 4.04918 19.1284C3.76152 18.9494 3.70838 18.5614 3.96255 18.3642C4.06929 18.2814 4.21129 18.2525 4.34189 18.2876C4.42661 18.3103 4.50855 18.3534 4.58783 18.3952C4.60877 18.4062 4.62964 18.4172 4.6502 18.4276C4.74337 18.4749 4.81136 18.5089 4.89468 18.5507L4.91608 18.5614C5.03444 18.6206 5.12062 18.7348 5.14425 18.8651C5.19288 19.133 4.97014 19.3836 4.69822 19.3654C4.58284 19.3576 4.46653 19.3238 4.34633 19.2785Z",
    fill: "none"
  }), _jsx("path", {
    d: "M2.111 17.7047C2.21361 17.8307 2.31569 17.9348 2.42527 18.0309C2.54211 18.1333 2.62186 18.1981 2.7608 18.2842C2.8807 18.3585 3.01964 18.398 3.16355 18.3709C3.31036 18.3432 3.43569 18.2386 3.48967 18.0994C3.54985 17.9443 3.51452 17.764 3.40074 17.6428C3.33978 17.5779 3.28275 17.529 3.22075 17.4759C3.19752 17.456 3.17358 17.4354 3.14848 17.4133C3.03287 17.3113 2.9223 17.2109 2.81425 17.1009C2.71691 17.0016 2.61085 16.9254 2.46518 16.92C2.2913 16.9136 2.12568 17.0116 2.04745 17.167C1.96112 17.3385 1.97779 17.541 2.111 17.7047Z",
    fill: "none"
  }), _jsx("path", {
    d: "M36.5816 14.597C37.5004 13.5059 39.0508 13.0465 40.4005 13.0465C43.9896 13.0465 45.655 15.5446 45.655 18.6456V27.4241C45.655 27.4919 45.6001 27.5467 45.5325 27.5467H41.9586C41.891 27.5467 41.8361 27.4919 41.8361 27.4241V19.3059C41.8361 17.7267 41.0608 16.4341 39.2232 16.4331C37.477 16.4321 36.5669 17.6719 36.5816 19.2486V27.4241C36.5816 27.4919 36.5267 27.5467 36.459 27.5467H32.8853C32.8176 27.5467 32.7627 27.4919 32.7627 27.4241V6.8808C32.7627 6.81313 32.8176 6.7583 32.8853 6.7583H36.459C36.5267 6.7583 36.5816 6.81313 36.5816 6.8808V14.597Z",
    fill: "none"
  }), _jsx("path", {
    d: "M50.8561 6.41365C49.5558 6.40355 48.4834 7.46971 48.4834 8.7967C48.4834 10.0601 49.5457 11.1226 50.8379 11.1226C52.2617 11.1226 53.352 9.88794 53.1731 8.49764C53.0234 7.33322 52.0301 6.42274 50.8561 6.41365Z",
    fill: "none"
  }), _jsx("path", {
    d: "M49.0654 13.4197H52.6392C52.7069 13.4197 52.7617 13.4745 52.7617 13.5422V27.4241C52.7617 27.4919 52.7069 27.5467 52.6392 27.5467H49.0654C48.9977 27.5467 48.9429 27.4919 48.9429 27.4241V13.5422C48.9429 13.4745 48.9977 13.4197 49.0654 13.4197Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M64.0905 13.0465C61.9945 13.0465 60.4439 13.9653 59.8121 14.9703V6.8808C59.8121 6.81313 59.7573 6.7583 59.6896 6.7583H56.1734C56.1056 6.7583 56.0508 6.81313 56.0508 6.8808V27.4242C56.0508 27.4919 56.1056 27.5467 56.1734 27.5467H59.6323C59.7 27.5467 59.7548 27.4919 59.7548 27.4242V25.824C60.5012 27.0299 61.9945 27.8914 63.947 27.8914C67.9667 27.8914 70.6084 24.704 70.6084 20.4259C70.6084 16.2337 68.2252 13.0465 64.0905 13.0465ZM63.2866 24.504C61.3627 24.504 59.7238 23.0099 59.7238 20.4545C59.7238 17.8703 61.3627 16.434 63.2866 16.434C65.2391 16.434 66.7896 17.8703 66.7896 20.4545C66.7896 23.0388 65.2391 24.504 63.2866 24.504Z",
    fill: "none"
  }), _jsx("path", {
    d: "M92.4441 24.3595C92.8175 24.3595 93.2287 24.3216 93.4775 24.2765C93.5526 24.2629 93.6214 24.3217 93.6214 24.398V27.3178C93.6214 27.3702 93.5896 27.4167 93.5406 27.4353C93.1742 27.5749 92.5063 27.7478 91.4679 27.7478C88.7114 27.7478 86.9887 26.111 86.9887 23.3831V16.8078H84.5556C84.488 16.8078 84.4331 16.7529 84.4331 16.6853V13.5421C84.4331 13.4745 84.488 13.4196 84.5556 13.4196H86.997L87.0024 9.32115C87.0024 9.25348 87.0573 9.19873 87.1248 9.19873H90.6562C90.7239 9.19873 90.7787 9.25363 90.7787 9.32123V13.4196H93.4988C93.5665 13.4196 93.6214 13.4745 93.6214 13.5421V16.6853C93.6214 16.7529 93.5665 16.8078 93.4988 16.8078H90.7787V22.7228C90.7787 23.9575 91.3531 24.3595 92.4441 24.3595Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M125.377 6.7583H121.823C121.755 6.7583 121.7 6.81313 121.7 6.8808V14.8267C121.298 14.0801 120.15 13.0752 117.709 13.0752C113.718 13.0752 110.933 16.3197 110.933 20.4545C110.933 24.7329 113.804 27.8914 117.824 27.8914C119.719 27.8914 121.155 27.0299 121.786 25.9101L121.794 27.4248C121.794 27.4923 121.849 27.5467 121.916 27.5467H125.377C125.445 27.5467 125.5 27.4919 125.5 27.4242V6.8808C125.5 6.81313 125.445 6.7583 125.377 6.7583ZM118.283 24.504C116.273 24.504 114.78 22.9813 114.78 20.4545C114.78 17.9278 116.331 16.4626 118.283 16.4626C120.236 16.4626 121.802 17.899 121.802 20.4259C121.802 22.9813 120.236 24.504 118.283 24.504Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M95.2197 20.4258C95.2197 15.9177 98.5506 12.989 102.168 12.989C106.533 12.989 109.146 15.7742 109.146 20.311C109.146 20.7248 109.113 21.1551 109.097 21.3638L109.096 21.3758C109.091 21.4396 109.038 21.4883 108.974 21.4883H98.9812C99.0674 23.3545 100.647 24.7041 102.542 24.7041C103.936 24.7041 104.837 24.158 105.383 23.3144C105.415 23.2638 105.479 23.2432 105.534 23.2673L108.397 24.5113C108.463 24.5398 108.491 24.6187 108.457 24.6817C107.442 26.5637 105.406 27.9774 102.513 27.9774C98.6366 27.9774 95.2197 25.1922 95.2197 20.4258ZM102.226 16.0902C100.245 16.0902 99.1534 17.5832 99.0674 18.8753H105.384C105.327 17.4683 104.408 16.0902 102.226 16.0902Z",
    fill: "none"
  }), _jsx("path", {
    d: "M79.3504 18.9498L77.3405 18.5192C76.5652 18.3755 76.0484 17.9852 76.0484 17.2961C76.0484 16.4921 76.8523 15.8891 77.8574 15.8891C79.235 15.8891 79.8737 16.6687 80.1007 17.4158C80.121 17.483 80.1957 17.5172 80.2611 17.4918L83.1061 16.3896C83.167 16.366 83.1998 16.2988 83.1787 16.2369C82.674 14.7645 81.1795 12.989 77.8286 12.989C74.7849 12.989 72.5453 15.0851 72.5453 17.6119C72.5453 19.5931 73.78 21.2849 76.5077 21.8878L78.3742 22.3186C79.4653 22.5484 79.896 23.01 79.896 23.6417C79.896 24.3883 79.2931 25.0487 78.0296 25.0487C76.4293 25.0487 75.5979 24.0941 75.4588 23.0261C75.4496 22.9546 75.3821 22.9091 75.3118 22.9245L72.2813 23.5891C72.2194 23.6027 72.1769 23.6611 72.1857 23.7239C72.4121 25.3442 73.9048 27.9774 78.0583 27.9774C81.7623 27.9774 83.5426 25.623 83.5426 23.3259C83.5426 21.2585 82.1356 19.5241 79.3504 18.9498Z",
    fill: "none"
  }), _jsx("path", {
    d: "M27.3849 22.1314L30.512 23.1739C30.5743 23.1946 30.6102 23.2609 30.592 23.3239C29.9209 25.6419 27.7208 27.9774 24.0194 27.9774C19.8559 27.9774 16.5825 24.8189 16.5825 20.4832C16.5825 16.1188 19.7986 12.989 23.9045 12.989C27.4582 12.989 29.5901 15.0219 30.3703 17.2101C30.3931 17.2742 30.3582 17.3446 30.2939 17.3668L27.09 18.4722C27.0291 18.4932 26.9634 18.4631 26.9376 18.4042C26.5012 17.4086 25.6165 16.5208 23.9907 16.5208C22.0382 16.5208 20.4016 17.9279 20.4016 20.4832C20.4016 23.0387 22.0669 24.4745 24.0194 24.4745C25.9002 24.4745 26.875 23.2756 27.2265 22.2123C27.2485 22.1458 27.3184 22.1092 27.3849 22.1314Z",
    fill: "none"
  })]
}), 'SchibstedMonoLogo', 'CompanyMono');
export default SchibstedMonoLogo;