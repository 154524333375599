/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ShutterstockMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 159,
  height: 32,
  viewBox: "0 0 159 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M94.565 17.2881C94.3252 17.2467 93.5209 17.1153 93.2853 17.0728C92.0148 16.8482 90.9267 16.5563 90.9267 15.2998C90.9267 14.221 92.0774 13.7177 93.5095 13.7177C95.6404 13.7177 97.5092 14.6668 97.7225 14.7715L98.8907 11.7428C98.4412 11.5136 96.5399 10.5152 93.5456 10.5152C90.8032 10.5152 87.4535 11.9306 87.4535 15.452C87.4535 18.6033 89.7447 19.6148 91.9527 19.9967C92.2575 20.0518 93.2161 20.2128 93.4902 20.2597C94.9753 20.5065 95.791 21.0501 95.791 22.0052C95.791 23.2174 94.6325 23.7858 92.8787 23.7858C90.0706 23.7858 88.4229 22.9632 87.9469 22.7708L86.6528 25.7341C87.0547 25.9376 88.9717 27 92.7124 27C96.3422 27 99.2552 25.2331 99.2552 21.9443C99.2268 18.883 97.1333 17.7216 94.565 17.2881Z",
    fill: "none"
  }), _jsx("path", {
    d: "M105.649 5.00003L102.213 6.86784V10.8232H100.405V14.0522H102.213V21.033C102.213 24.015 103.46 26.6926 107.859 26.6926H109.234V23.4608H108.719C106.557 23.4608 105.649 22.2344 105.649 20.5688V14.0522H109.594V10.8232H105.649V5.00003",
    fill: "none"
  }), _jsx("path", {
    d: "M132.487 13.7982C133.828 13.7982 135.201 14.3975 135.614 15.8476L138.731 14.6822C137.858 11.6494 134.991 10.5152 132.463 10.5152C127.932 10.5152 126.105 13.2805 126.105 15.9683V21.5458C126.105 24.2324 127.932 27 132.463 27C134.991 27 137.858 25.8643 138.731 22.8314L135.614 21.6665C135.201 23.115 133.828 23.7178 132.487 23.7178C130.153 23.7178 129.535 22.4618 129.535 21.03V16.483C129.535 15.0528 130.153 13.7982 132.487 13.7982Z",
    fill: "none"
  }), _jsx("path", {
    d: "M147.177 17.9509L153.599 10.8232H149.374L143.85 17.036V5.00069H140.412V26.6926H143.85V21.6418L144.978 20.39L149.897 26.6926H154.02L147.177 17.9509Z",
    fill: "none"
  }), _jsx("path", {
    d: "M119.204 14.2859H115.632C115.064 14.2859 114.603 14.7395 114.603 15.2956V19.1055H111.079V15.2956C111.079 12.8245 113.116 10.8231 115.632 10.8231H119.204V14.2859",
    fill: "none"
  }), _jsx("path", {
    d: "M116.296 23.2293H119.871C120.437 23.2293 120.899 22.7764 120.899 22.2185V18.4098H124.422V22.2185C124.422 24.6895 122.384 26.6926 119.871 26.6926H116.296V23.2293Z",
    fill: "none"
  }), _jsx("path", {
    d: "M58.0398 10.8232H61.9842V14.0522H58.0398V20.5688C58.0398 22.2344 58.9473 23.4608 61.1077 23.4608H61.6234V26.6926H60.2473C55.8474 26.6926 54.6042 24.015 54.6042 21.033V14.0522H48.8222V20.5688C48.8222 22.2344 49.7313 23.4608 51.8916 23.4608H52.4056V26.6926H51.0292C46.6312 26.6926 45.385 24.015 45.385 21.033V14.0522H43.6436V10.8232H45.385V6.86784L48.8222 5.00003V10.8232H54.6042V6.86784L58.0398 5.00003V10.8232",
    fill: "none"
  }), _jsx("path", {
    d: "M20.9529 10.5153C19.8661 10.5153 18.7636 10.7221 17.7714 11.1352V5.00003H14.3354V26.6926H17.7714V14.2921C18.4618 13.8657 19.4552 13.6111 20.4265 13.6111C22.5861 13.6111 23.6026 14.7912 23.6026 16.5316V26.6926H27.0354V16.0704C27.0354 12.8672 24.675 10.5153 20.9529 10.5153",
    fill: "none"
  }), _jsx("path", {
    d: "M38.3663 23.5366C37.7132 23.8829 36.8868 24.0814 36.0645 24.0814C33.5003 24.0814 32.5363 22.7976 32.5363 21.027V10.8231H29.1011V21.0892C29.1011 25.0797 31.4476 27 35.6416 27C37.6502 27 39.8743 26.5008 41.8003 25.6813V10.8231H38.3663V23.5366Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.91155 17.2881C7.67364 17.2467 6.86809 17.1153 6.6318 17.0728C5.36381 16.8482 4.27326 16.5563 4.27326 15.2998C4.27326 14.221 5.42288 13.7177 6.85658 13.7177C8.98707 13.7177 10.8559 14.6668 11.0691 14.7715L12.2366 11.7428C11.7902 11.5136 9.88697 10.5152 6.89092 10.5152C4.15041 10.5152 0.80015 11.9306 0.80015 15.452C0.80015 18.6033 3.0888 19.6148 5.2998 19.9967C5.60409 20.0518 6.5633 20.2128 6.83917 20.2597C8.32364 20.5065 9.13582 21.0501 9.13582 22.0052C9.13582 23.2174 7.98078 23.7858 6.22773 23.7858C3.4171 23.7858 1.7695 22.9632 1.29294 22.7708L0 25.7341C0.403133 25.9376 2.31949 27 6.06018 27C9.69003 27 12.603 25.2331 12.603 21.9443C12.5717 18.883 10.4787 17.7216 7.91155 17.2881Z",
    fill: "none"
  }), _jsx("path", {
    d: "M72.7534 17.5546H66.9607V16.3999C66.9607 15.112 67.6186 13.7306 69.8748 13.7306C72.0497 13.7306 72.7534 15.0759 72.7534 16.3373V17.5546ZM76.1893 15.9683C76.1893 13.0657 73.9385 10.5141 69.8911 10.5141C65.2967 10.5141 63.5327 13.334 63.5327 15.9683V21.2391C63.5327 24.8854 65.9034 27 69.8911 27C73.4089 27 75.235 25.0008 75.7772 23.8001L72.8769 22.0875C72.6293 22.7928 71.481 23.7823 69.914 23.7823C67.7897 23.7823 66.9728 22.6174 66.9728 21.1579L66.9607 20.2886H76.1893V15.9683Z",
    fill: "none"
  }), _jsx("path", {
    d: "M78.3877 15.9744V26.6926H81.8205V16.2628C81.8205 15.3519 82.3465 13.7952 84.8678 13.7952C85.2636 13.7952 86.1583 13.906 86.4444 13.9588V10.6853C86.1348 10.6117 85.4251 10.5141 84.6919 10.5141C80.3252 10.5141 78.3877 13.2061 78.3877 15.9744",
    fill: "none"
  }), _jsx("path", {
    d: "M157.06 11.7237C157.06 11.5981 156.98 11.4804 156.764 11.4804H156.548V12.0151H156.764C156.997 12.0151 157.06 11.9127 157.06 11.7475V11.7237ZM157.028 12.8571C157.012 12.8571 156.997 12.8495 156.988 12.834L156.731 12.2828H156.548V12.8257C156.548 12.8416 156.539 12.8571 156.524 12.8571H156.203C156.187 12.8571 156.171 12.8416 156.171 12.8257V11.1968H156.812C157.244 11.1968 157.436 11.4649 157.436 11.7161V11.7475C157.436 11.9682 157.316 12.1727 157.1 12.2673L157.421 12.8257C157.429 12.8416 157.421 12.8571 157.404 12.8571H157.028ZM156.764 10.7793C156.036 10.7793 155.507 11.3388 155.507 12.0547C155.507 12.7239 156.043 13.2906 156.764 13.2906C157.492 13.2906 158.014 12.7239 158.014 12.0547C158.014 11.346 157.445 10.7793 156.764 10.7793ZM156.764 13.6606C155.835 13.6606 155.083 12.9679 155.083 12.0547C155.083 11.1417 155.835 10.4092 156.764 10.4092C157.685 10.4092 158.438 11.1494 158.438 12.0547C158.438 12.9679 157.693 13.6606 156.764 13.6606Z",
    fill: "none"
  })]
}), 'ShutterstockMonoLogo', 'CompanyMono');
export default ShutterstockMonoLogo;