/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ChopraMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 148,
  height: 32,
  viewBox: "0 0 148 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M104.838 7.79593C104.28 8.17601 103.745 8.5875 103.234 9.02839V6.96451L97.0227 8.99416C97.1763 9.20296 97.3499 9.39625 97.5411 9.57126C98.3138 10.1288 98.4508 10.8771 98.4459 11.7868C98.4068 17.0149 98.3872 22.2431 98.4459 27.4713C98.4459 28.9385 98.3187 30.303 97.2623 31.4377C97.145 31.6113 97.0528 31.8007 96.9885 32.0001H104.667L103.875 30.6307C103.664 30.2688 103.288 29.9069 103.278 29.5352C103.214 27.4811 103.249 25.4221 103.249 23.2262C103.518 23.456 103.679 23.5783 103.826 23.7152C105.101 24.9153 106.798 25.5633 108.548 25.5185C110.298 25.4736 111.96 24.7394 113.172 23.4756C116.595 19.9787 117.016 13.8213 114.116 9.89894C111.744 6.68085 108.09 5.86899 104.838 7.79593ZM111.025 18.1692C110.845 19.6255 110.344 21.0237 109.558 22.2627C107.782 24.9624 105.391 24.7667 103.395 22.2627C103.271 22.0359 103.22 21.7763 103.249 21.5193C103.249 19.7195 103.249 17.9148 103.249 16.1102C103.249 14.3055 103.214 12.5595 103.249 10.7842C103.323 10.3375 103.49 9.91126 103.738 9.53214C103.858 9.35657 104.008 9.20414 104.183 9.08219C106.041 7.4438 108.095 7.71768 109.445 9.82069C111.093 12.403 111.45 15.22 111.025 18.1692Z",
    fill: "none"
  }), _jsx("path", {
    d: "M0.0543414 11.3906C0.0543414 13.0339 -0.0679267 14.6283 0.0543414 16.1982C0.983579 25.7938 11.3666 31.6969 20.0574 27.4566C26.9386 24.0918 28.7482 18.1643 28.0195 11.3906H0.0543414ZM25.4372 15.7238H17.255L17.167 15.9488L23.8868 19.8222L23.1532 21.0694L16.3551 17.1861L16.2329 17.2937C17.5289 19.519 18.82 21.7492 20.1454 24.0429L18.8885 24.7765L14.9808 18.0958H14.8488V25.828H13.3327V18.1692L13.1272 18.0958C11.8231 20.3097 10.5189 22.5301 9.21467 24.757L7.94797 24.0429L11.8605 17.2839L11.7578 17.1666L4.93528 21.0791L4.20657 19.8271L10.9558 15.939L10.9069 15.7776H2.68555V14.3446H25.4372V15.7238Z",
    fill: "none"
  }), _jsx("path", {
    d: "M75.2346 21.6414C75.2884 18.4038 75.2737 15.1612 75.2346 11.9236C75.2346 9.18478 73.3418 7.0622 70.7889 6.86168C68.9695 6.71985 67.3996 7.40455 65.9617 8.4365C65.4727 8.79841 64.9836 9.20434 64.3771 9.68852V0L58.4643 1.92694C58.3536 1.9729 58.2472 2.02855 58.1464 2.09323C58.2948 2.28532 58.4583 2.46522 58.6354 2.63121C59.4033 3.21321 59.6136 3.95659 59.5842 4.91029C59.5353 10.0896 59.5207 15.2688 59.5842 20.4432C59.5842 22.1354 59.5207 23.7151 58.2735 25.0112C58.2295 25.0601 58.2295 25.1481 58.1953 25.2801H65.8199C65.3895 24.615 64.9983 24.0575 64.6559 23.4706C64.4908 23.2087 64.3949 22.9091 64.3771 22.6C64.3771 18.7755 64.3771 14.9509 64.3771 11.1264C64.3724 10.8943 64.4371 10.6661 64.563 10.471C65.0053 9.9166 65.6011 9.50457 66.2759 9.28629C66.9508 9.06801 67.6749 9.05311 68.3582 9.24347C69.7178 9.70319 70.4416 10.8183 70.4416 12.4713C70.4416 15.1808 70.3781 17.8903 70.4416 20.5948C70.4954 22.1892 70.3438 23.6466 69.2336 24.8791C69.1452 25.0039 69.0713 25.1385 69.0136 25.2801H76.6675C76.4181 24.8889 76.2469 24.5661 76.022 24.302C75.44 23.5428 75.1595 22.5951 75.2346 21.6414Z",
    fill: "none"
  }), _jsx("path", {
    d: "M147.603 25.3635C147.442 25.0256 147.259 24.6988 147.055 24.3854C146.483 23.5524 146.223 22.5445 146.321 21.539C146.365 18.3551 146.346 15.181 146.321 11.9825C146.367 11.0306 146.112 10.0885 145.592 9.28969C145.073 8.49087 144.315 7.8759 143.426 7.53195C139.865 5.94247 134.559 7.1407 132.07 10.1289C131.803 10.4893 131.555 10.8631 131.326 11.2489C131.442 11.2801 131.559 11.303 131.678 11.3174C132.93 11.3174 134.182 11.2929 135.434 11.3418C135.536 11.3606 135.64 11.3585 135.74 11.3355C135.84 11.3125 135.935 11.2692 136.018 11.2082C136.101 11.1473 136.17 11.07 136.222 10.9811C136.274 10.8922 136.307 10.7936 136.32 10.6914C136.577 9.515 137.253 8.47222 138.222 7.75693C139.728 6.74455 141.308 7.38523 141.406 9.18013C141.538 11.6597 141.44 14.1491 141.44 16.8635C140.535 14.8778 139.415 13.3617 137.361 12.9069C136.411 12.6778 135.421 12.6612 134.464 12.8582C133.506 13.0551 132.603 13.4608 131.82 14.0464C131.092 14.5749 130.484 15.2509 130.035 16.0303C129.586 16.8096 129.306 17.6749 129.214 18.5696C129.122 19.4643 129.219 20.3684 129.499 21.223C129.78 22.0776 130.237 22.8635 130.842 23.5295C132.397 25.2559 134.422 25.7841 136.662 25.4075C138.902 25.031 140.399 23.4757 141.347 21.4167L141.528 21.5145V25.3635H147.603ZM140.638 21.2162C140.515 21.6188 140.3 21.9872 140.01 22.2921C139.72 22.597 139.363 22.83 138.967 22.9724C138.571 23.1149 138.147 23.163 137.729 23.1128C137.311 23.0626 136.91 22.9156 136.559 22.6834C134.603 21.5781 133.224 18.5018 133.703 16.2961C133.977 15.0294 134.5 13.8948 135.909 13.5378C137.317 13.1807 138.516 13.8019 139.43 14.8778C140.52 16.1903 141.09 17.858 141.029 19.5631C140.936 20.1222 140.805 20.6744 140.638 21.2162Z",
    fill: "none"
  }), _jsx("path", {
    d: "M86.8059 6.7885C84.9617 6.81165 83.1654 7.3797 81.6432 8.42118C80.1209 9.46266 78.9406 10.9311 78.2508 12.6416C77.561 14.3522 77.3925 16.2286 77.7665 18.0348C78.1404 19.8409 79.0402 21.4961 80.3525 22.7921C81.6648 24.0882 83.3311 24.9672 85.1418 25.3186C86.9524 25.67 88.8266 25.4781 90.5284 24.767C92.2303 24.0559 93.6838 22.8575 94.7062 21.3223C95.7286 19.7872 96.2742 17.984 96.2744 16.1396C96.2706 14.9027 96.0222 13.6789 95.5436 12.5384C95.0649 11.398 94.3655 10.3634 93.4855 9.4943C92.6055 8.6252 91.5623 7.93872 90.4159 7.47434C89.2696 7.00997 88.0427 6.77689 86.8059 6.7885ZM91.8874 21.9742C91.3983 24.2533 89.442 25.1629 87.4026 24.0283C85.8465 23.0885 84.5831 21.7336 83.7541 20.1157C82.1989 17.3818 81.3088 14.462 81.6218 11.3124C81.7441 9.9528 82.1109 8.64697 83.5047 8.03563C84.8986 7.42429 86.1213 8.07965 87.1776 8.94531C89.4616 10.8136 90.7576 13.347 91.5353 16.1053C91.8727 17.3231 91.995 18.5996 92.1906 19.6902C92.0781 20.5412 92.0292 21.2748 91.8874 21.9742Z",
    fill: "none"
  }), _jsx("path", {
    d: "M130.456 7.09151C126.132 6.78829 124.098 9.15051 123.384 13.0582L123.178 12.9066V7.06706L117.011 9.09182C117.156 9.28944 117.32 9.47285 117.5 9.63958C118.283 10.1922 118.42 10.9454 118.41 11.8551C118.361 14.8531 118.337 17.856 118.41 20.854C118.449 22.3897 118.322 23.7884 117.192 24.972C117.112 25.0948 117.05 25.2282 117.006 25.3681H124.719C124.289 24.7226 123.912 24.1895 123.579 23.627C123.402 23.3695 123.291 23.0721 123.257 22.7614C123.257 20.316 123.222 17.8266 123.257 15.3568C123.238 14.6837 123.447 14.024 123.85 13.4844C124.253 12.9448 124.826 12.5569 125.477 12.3833C126.451 12.1237 127.444 11.9374 128.446 11.8257C128.866 11.767 129.111 11.6741 129.233 11.2291C129.61 9.87922 130.016 8.54406 130.456 7.09151Z",
    fill: "none"
  }), _jsx("path", {
    d: "M53.7642 21.3823C53.6324 21.9214 53.4422 22.4446 53.1969 22.9424C52.478 24.3559 51.2406 24.9427 49.8174 24.3021C48.7825 23.8453 47.8533 23.1792 47.0884 22.3458C44.6871 19.6021 43.4986 16.3057 43.1758 12.7013C43.0256 11.5052 43.2603 10.2923 43.8459 9.23863C44.1204 8.69847 44.5756 8.27157 45.1322 8.03213C45.6889 7.79268 46.3118 7.7558 46.8928 7.92792C48.5556 8.34852 49.7098 9.45871 50.7027 10.7841C50.864 10.9993 51.1184 11.2732 51.3384 11.2732C53.0551 11.3123 54.7619 11.2732 56.5275 11.2732C55.0603 8.36808 50.8102 5.89338 46.257 7.04759C44.0851 7.56839 42.1719 8.85052 40.8645 10.6613C39.557 12.4721 38.9421 14.6916 39.1312 16.9171C39.542 21.3725 43.1954 25.109 47.4454 25.4318C51.7982 25.7742 56.2487 23.1723 57.0899 19.7684H53.9941C53.9454 20.3099 53.8687 20.8486 53.7642 21.3823Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.0369 8.80319C15.1901 8.8034 16.2972 8.35108 17.1203 7.54351C17.9435 6.73594 18.4168 5.6376 18.4386 4.48468C18.4445 3.90614 18.3346 3.33226 18.1152 2.79691C17.8958 2.26155 17.5714 1.77555 17.1611 1.36758C16.7509 0.959605 16.2631 0.637923 15.7265 0.421504C15.1899 0.205085 14.6154 0.0983129 14.0369 0.107486C13.4634 0.0982825 12.8938 0.204221 12.3619 0.419031C11.83 0.633842 11.3466 0.95316 10.9403 1.35809C10.534 1.76302 10.2131 2.24534 9.99649 2.7765C9.77989 3.30766 9.67203 3.87687 9.6793 4.45045C9.6793 5.02247 9.79205 5.58888 10.0111 6.1173C10.2302 6.64572 10.5512 7.12578 10.9559 7.53003C11.3606 7.93429 11.841 8.2548 12.3697 8.47326C12.8984 8.69172 13.4649 8.80383 14.0369 8.80319Z",
    fill: "none"
  })]
}), 'ChopraMonoLogo', 'CompanyMono');
export default ChopraMonoLogo;