/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StylepitMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 105,
  height: 33,
  viewBox: "0 0 105 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M38.8958 10.774C38.5415 10.774 38.3754 10.8847 38.2569 11.2169C37.783 12.5312 37.2836 13.8366 36.7898 15.1442C36.0635 17.0686 35.3349 18.9919 34.561 21.0369C34.468 20.8055 34.417 20.6881 34.3727 20.5686C33.3866 17.8913 32.4 15.217 31.4131 12.5456C31.2039 11.9776 31.1319 11.1914 30.7211 10.9124C30.2849 10.6168 29.5187 10.7917 28.8975 10.7873C27.5755 10.7773 26.2523 10.7873 24.865 10.7873V6.94189H20.7151V10.7951H17.9326V14.2275H20.7339V25.6585H24.8794V14.223C25.6544 14.223 26.3741 14.2507 27.0872 14.2131C27.5101 14.1898 27.6939 14.3482 27.8501 14.7279C29.3614 18.4216 30.8972 22.1043 32.4052 25.8002C32.503 26.065 32.5077 26.3551 32.4185 26.6229C31.7386 28.4886 31.0245 30.3421 30.3236 32.2C30.2572 32.3761 30.2129 32.5588 30.1376 32.7968C31.3356 32.7968 32.4417 32.7702 33.5445 32.809C34.0162 32.8256 34.2089 32.6518 34.375 32.2321C37.1098 25.2831 39.8532 18.339 42.605 11.3996C42.677 11.218 42.7412 11.0331 42.8353 10.7796C41.4701 10.7796 40.1824 10.7928 38.8958 10.774Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.6718 13.9764C12.8403 13.6011 11.939 13.3697 11.0555 13.125C9.7445 12.7607 8.4103 12.4772 7.10377 12.0963C6.33757 11.8749 5.70312 11.4198 5.50825 10.5684C5.21594 9.29948 5.929 8.20776 7.27982 7.82576C9.20086 7.28433 10.8008 7.88002 11.9811 9.61062L16.1421 7.79033C15.5674 7.09167 15.14 6.41294 14.5654 5.89808C11.7386 3.37139 6.13937 3.27063 3.1875 5.64785C0.370717 7.91434 0.120483 12.3576 2.68593 14.7327C3.72672 15.6982 5.01111 16.2019 6.35971 16.5529C7.8899 16.9504 9.4367 17.2948 10.9436 17.7709C12.0221 18.1097 12.4938 18.846 12.4938 19.8182C12.4938 20.8501 11.959 21.7303 10.9093 22.0326C10.0657 22.2809 9.18724 22.3905 8.30843 22.357C6.40068 22.264 5.49386 21.4712 4.93471 19.6897C4.91089 19.6277 4.87283 19.5721 4.82359 19.5275C4.77436 19.4829 4.71533 19.4505 4.65126 19.4328C3.25504 19.4162 1.85772 19.4218 0.428293 19.4218C0.419736 19.5659 0.419736 19.7105 0.428293 19.8547C0.736102 22.192 1.91198 23.9381 4.00796 25.0387C5.84706 26.0042 7.83122 26.1925 9.86409 26.0673C11.567 25.9633 13.1492 25.5016 14.5742 24.5172C17.9269 22.2064 18.3178 16.0746 13.6718 13.9764Z",
    fill: "none"
  }), _jsx("path", {
    d: "M75.1076 11.1128C74.4632 11.4593 73.9173 11.9919 73.3272 12.4348C73.2474 11.924 73.1637 11.3833 73.0758 10.8127H69.6611V32.7856H73.7734V24.3807C73.8534 24.4302 73.9303 24.4845 74.0037 24.5434C76.2547 26.7579 81.865 26.9306 84.4282 23.1682C85.8577 21.0645 86.1555 18.7316 85.7004 16.3034C84.5888 10.3776 78.7116 9.17178 75.1076 11.1128ZM81.4354 19.4767C80.9482 21.4698 79.2907 22.5249 77.1172 22.2847C75.3102 22.0854 73.9528 20.5707 73.8752 18.6651C73.8752 18.5179 73.8752 18.3706 73.8752 18.2223C73.8752 16.1063 74.9415 14.6016 76.754 14.1643C79.2763 13.5553 81.5018 15.2316 81.5904 17.8358C81.6129 18.3873 81.5608 18.9393 81.4354 19.4767Z",
    fill: "none"
  }), _jsx("path", {
    d: "M63.7228 19.6819C63.6354 19.6819 63.5014 19.8159 63.4704 19.9144C63.0098 21.3461 61.9081 22.0569 60.5628 22.4511C58.8743 22.946 57.0905 22.1189 56.5945 20.6463L66.4998 16.3159C66.3182 15.7623 66.1853 15.2862 66.0059 14.8256C64.6152 11.2537 61.391 9.51092 57.7427 10.3491C53.2883 11.3744 50.7173 15.0958 51.6396 19.3486C52.2553 22.1854 53.7367 24.4629 56.5358 25.6111C59.7844 26.9398 64.2144 25.465 66.2672 22.4312C66.8447 21.6128 67.2131 20.6655 67.3401 19.672C66.1045 19.672 64.9131 19.6664 63.7228 19.6819ZM55.474 18.0488C54.8163 16.616 55.5847 14.9906 57.2743 14.1735C59.0625 13.3076 60.7377 13.7682 61.556 15.3914L55.474 18.0488Z",
    fill: "none"
  }), _jsx("path", {
    d: "M48.9483 0.809082C47.686 1.3627 46.5013 1.89859 45.2944 2.38467C44.8604 2.5585 44.7474 2.79434 44.7485 3.24055C44.7618 10.4936 44.7652 17.7467 44.7585 24.9998V25.694C45.9942 25.694 47.1523 25.6675 48.3083 25.7062C48.8331 25.7228 48.9914 25.5789 48.9903 25.0419C48.9726 17.1813 48.9682 9.31998 48.977 1.45792C48.977 1.26526 48.9604 1.07371 48.9483 0.809082Z",
    fill: "none"
  }), _jsx("path", {
    d: "M101.841 10.774V6.94629H97.6339V10.7928H94.9102V14.2385H97.6638V25.6518H101.882V14.1898H104.628V10.774H101.841Z",
    fill: "none"
  }), _jsx("path", {
    d: "M88.3477 25.6574H92.4444V10.8271H88.3477V25.6574Z",
    fill: "none"
  }), _jsx("path", {
    d: "M90.4007 3.57032C89.072 3.57032 88.0755 4.51589 88.0611 5.77149C88.049 6.98944 89.0886 7.99148 90.3863 8.00477C91.684 8.01806 92.6882 7.05145 92.6905 5.78035C92.6938 4.47936 91.7482 3.567 90.4007 3.57032Z",
    fill: "none"
  })]
}), 'StylepitMonoLogo', 'CompanyMono');
export default StylepitMonoLogo;