/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StanleySecurityMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 106,
  height: 33,
  viewBox: "0 0 106 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M4.90438 22.9132C4.28285 22.6713 3.94209 22.5615 3.30795 22.5615C2.62498 22.5615 1.81906 22.8048 1.81906 23.6902C1.81906 25.3167 5.47845 24.9413 5.47845 27.7312C5.47845 29.5383 4.08175 30.2569 2.36799 30.2569C1.77015 30.2569 1.185 30.086 0.624911 29.9039L0.746422 28.7752C1.16541 28.9461 1.89311 29.1922 2.51464 29.1922C3.21302 29.1922 4.19066 28.8043 4.19066 27.8021C4.19066 25.9088 0.532715 26.4301 0.532715 23.7959C0.532715 22.2918 1.72687 21.4897 3.26467 21.4897C3.88202 21.4944 4.49444 21.5996 5.07761 21.8011L4.90719 22.9132",
    fill: "none"
  }), _jsx("path", {
    d: "M11.7955 29.8634C11.3556 30.0219 10.8556 30.2651 9.83174 30.2651C7.62495 30.2651 6.6123 28.9293 6.6123 26.9151C6.6123 25.0816 7.77015 23.7832 9.52728 23.7832C11.6 23.7832 12.2704 25.2887 12.2704 27.2793H7.83167C7.82658 27.5496 7.87595 27.8181 7.97688 28.0691C8.07773 28.3201 8.2281 28.5485 8.41902 28.7407C8.61002 28.933 8.83771 29.0852 9.0887 29.1886C9.33977 29.2918 9.609 29.344 9.88065 29.3421C10.6362 29.3421 11.5147 28.9543 11.7955 28.7235V29.8634ZM11.051 26.4286C11.051 25.4819 10.4924 24.7062 9.50207 24.7062C8.3847 24.7062 7.9168 25.6279 7.82605 26.4286H11.051Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.7584 24.9707C17.3652 24.8027 16.9431 24.712 16.5153 24.7038C15.2345 24.7038 14.5152 25.8436 14.5152 27.021C14.5152 28.1262 15.113 29.3397 16.5879 29.3397C17.0231 29.3363 17.449 29.2147 17.8198 28.988L17.905 30.0069C17.449 30.1922 16.9587 30.2794 16.4664 30.2627C14.5879 30.2627 13.2959 28.7934 13.2959 27.021C13.2959 25.1402 14.5641 23.7808 16.4664 23.7808C16.9423 23.784 17.4144 23.8663 17.8631 24.024L17.7654 24.9707",
    fill: "none"
  }), _jsx("path", {
    d: "M24.7224 30.1168H23.6134V29.1326H23.5883C23.2475 29.8374 22.4178 30.2627 21.5519 30.2627C19.9415 30.2627 19.2222 29.2674 19.2222 27.6035V23.9268H20.3689V27.1309C20.3689 28.5752 20.6859 29.2786 21.686 29.3397C22.9905 29.3397 23.5758 28.2958 23.5758 26.7903V23.9268H24.7224V30.1168Z",
    fill: "none"
  }), _jsx("path", {
    d: "M26.6738 23.9263H27.7479V24.8855H27.7716C28.0761 24.2418 28.7353 23.7803 29.3933 23.7803C29.648 23.7788 29.9022 23.8073 30.1503 23.8652V24.8855C29.9479 24.8084 29.7326 24.7711 29.5161 24.7756C28.491 24.7756 27.8205 25.7487 27.8205 27.3V30.1163H26.6738V23.9263Z",
    fill: "none"
  }), _jsx("path", {
    d: "M32.7841 22.5422H31.4907V21.2563H32.7841V22.5422ZM31.5648 23.9323H32.7101V30.1223H31.5648V23.9323Z",
    fill: "none"
  }), _jsx("path", {
    d: "M38.114 24.8495H36.4672V28.2343C36.4672 28.9516 36.9058 29.3464 37.4309 29.3464C37.7069 29.3503 37.9781 29.2745 38.2117 29.1282V30.1012C37.8954 30.2032 37.5663 30.2608 37.234 30.2722C36.051 30.2722 35.3191 29.7161 35.3191 28.4748V24.8606H33.9224V23.9376H35.3191V22.4961L36.4658 22.1333V23.9292H38.1125V24.8523",
    fill: "none"
  }), _jsx("path", {
    d: "M39.0891 31.7918C39.2618 31.8513 39.4427 31.8837 39.6254 31.8877C40.6506 31.8877 40.9914 30.481 40.9914 30.3586C40.9914 30.2363 40.8517 29.9097 40.7721 29.7039L38.6631 23.9268H39.9201L41.5961 29.0478H41.6214L43.3281 23.9268H44.486L42.194 30.2502C41.7666 31.4387 41.3154 32.8107 39.8042 32.8107C39.5417 32.8095 39.2801 32.7811 39.0234 32.7259L39.096 31.7918",
    fill: "none"
  }), _jsx("path", {
    d: "M0.527008 12.7474C1.95119 13.4977 3.53874 13.8887 5.15013 13.8858C6.4784 13.8858 8.01058 13.6232 8.01058 12.4263C8.01058 9.63501 0.0828838 11.2364 0.0828838 5.53712C0.0828838 1.77278 3.57466 0.810794 6.72704 0.810794C8.3565 0.80168 9.97746 1.04508 11.5317 1.53227L11.1406 4.88932C10.0359 4.43479 8.63636 3.97745 7.058 3.97745C5.99797 3.97745 4.36236 4.04694 4.36236 5.41333C4.36236 7.87932 12.332 6.60331 12.332 12.0718C12.332 16.128 8.63909 17.0539 5.15013 17.0539C3.42178 17.0487 1.70339 16.7925 0.0493164 16.2935L0.52845 12.7474H0.527008ZM26.633 1.11108H13.4285V4.28601H17.9384V16.7494H22.1286V4.28601H26.6357L26.633 1.11108ZM100.821 1.11108L97.78 7.38447L94.6951 1.11108H89.8764L95.6847 10.8569V16.7522H99.8753V10.8569L105.641 1.11108H100.821ZM44.6869 1.11108V16.7522H48.7178V8.31722L55.5547 16.7467H58.9808V14.3974L48.1339 1.11108H44.6869ZM58.9808 9.03452V1.11108H52.5657L58.9808 9.03452ZM80.7973 10.4246H87.4022V7.30389H80.7973V4.18592H88.0606V1.11108H76.5026V16.7522H88.3021V13.6329H80.7973V10.4246ZM67.0985 1.11108H62.8036V16.7522H73.9706V13.5301H67.0985V1.11108ZM36.9449 13.6218H31.0634L29.9963 16.7467H25.9095L31.6709 1.11108H36.5371L42.3837 16.7522H37.9742L36.9449 13.6218ZM36.0216 10.665L34.0663 4.38474L32.0285 10.665H36.0216Z",
    fill: "none"
  })]
}), 'StanleySecurityMonoLogo', 'CompanyMono');
export default StanleySecurityMonoLogo;