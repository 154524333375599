/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MetaMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 120,
  height: 32,
  viewBox: "0 0 120 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M43.7144 4.771H48.2282L55.9033 18.6547L63.5783 4.77101H67.9945V27.5845H64.3117V10.0997L57.5817 22.2072H54.1271L47.3971 10.0997V27.5845H43.7144V4.771Z",
    fill: "white"
  }), _jsx("path", {
    d: "M79.8735 27.9921C78.1678 27.9921 76.6686 27.6146 75.376 26.8596C74.0831 26.1046 73.0755 25.0589 72.3532 23.7227C71.6306 22.3864 71.2694 20.8547 71.2695 19.1274C71.2695 17.3785 71.6226 15.8304 72.3287 14.4832C73.0348 13.1363 74.0153 12.0825 75.2701 11.3218C76.5249 10.5616 77.967 10.1814 79.5965 10.1812C81.2151 10.1812 82.6083 10.5641 83.7763 11.3299C84.9439 12.096 85.8428 13.1688 86.4731 14.5484C87.103 15.9281 87.4181 17.5468 87.4182 19.4044V20.4146H74.9197C75.1479 21.8054 75.7101 22.8999 76.6063 23.6982C77.5026 24.4967 78.6351 24.8959 80.0038 24.8958C81.101 24.8958 82.0461 24.7329 82.8392 24.407C83.6321 24.0811 84.3763 23.5868 85.0718 22.9242L87.0272 25.3196C85.0824 27.1014 82.6978 27.9923 79.8735 27.9921ZM82.5622 14.4587C81.7908 13.6712 80.7805 13.2774 79.5313 13.2772C78.3145 13.2772 77.296 13.6792 76.4759 14.4832C75.6556 15.2871 75.1368 16.3681 74.9197 17.726H83.8822C83.7735 16.3354 83.3335 15.2463 82.5622 14.4587Z",
    fill: "white"
  }), _jsx("path", {
    d: "M91.8836 13.6031H88.4941V10.5884H91.8836V5.60205H95.436V10.5884H100.585V13.6031H95.436V21.2456C95.436 22.5165 95.6533 23.4236 96.0878 23.9668C96.5221 24.5102 97.2663 24.7818 98.3203 24.7816C98.7872 24.7816 99.1837 24.7626 99.5099 24.7245C99.8358 24.6867 100.194 24.6351 100.585 24.5697V27.5517C100.139 27.6808 99.6844 27.7789 99.2246 27.8452C98.6986 27.9226 98.1675 27.9607 97.6358 27.9592C93.8009 27.9592 91.8835 25.8626 91.8836 21.6693V13.6031Z",
    fill: "white"
  }), _jsx("path", {
    d: "M119.097 27.5846H115.61V25.2055C114.991 26.0964 114.203 26.7835 113.247 27.2668C112.291 27.7501 111.205 27.9918 109.988 27.9921C108.489 27.9921 107.161 27.6091 106.004 26.8431C104.847 26.0773 103.937 25.0236 103.274 23.6819C102.612 22.3402 102.28 20.8058 102.28 19.0785C102.28 17.3404 102.617 15.8031 103.291 14.4668C103.964 13.1307 104.896 12.0824 106.085 11.3218C107.275 10.5616 108.641 10.1814 110.183 10.1812C111.346 10.1812 112.389 10.4066 113.312 10.8575C114.223 11.2982 115.012 11.9556 115.61 12.7721V10.5886H119.097V27.5846ZM115.545 16.5364C115.164 15.5695 114.564 14.8063 113.744 14.2468C112.924 13.6874 111.976 13.4077 110.901 13.4077C109.379 13.4077 108.168 13.9183 107.267 14.9394C106.365 15.9606 105.914 17.3403 105.914 19.0785C105.914 20.8277 106.349 22.2128 107.218 23.2339C108.087 24.2551 109.265 24.7656 110.754 24.7656C111.851 24.7656 112.829 24.4831 113.687 23.9181C114.545 23.3535 115.164 22.5931 115.545 21.6368V16.5364Z",
    fill: "white"
  }), _jsx("path", {
    d: "M25.92 4C22.9586 4 20.6437 6.23046 18.5481 9.06383C15.6683 5.39717 13.26 4 10.3779 4C4.50207 4 0 11.6469 0 19.7407C0 24.8055 2.45029 28 6.55448 28C9.50842 28 11.6329 26.6074 15.4097 20.0055C15.4097 20.0055 16.984 17.2253 18.0671 15.3102C18.4466 15.923 18.8463 16.5832 19.2662 17.291L21.0372 20.2703C24.4872 26.0435 26.4094 28 29.8924 28C33.8907 28 36.1159 24.7618 36.1159 19.5917C36.1159 11.1172 31.5123 4 25.92 4ZM12.5297 18.2179C9.46759 23.0179 8.40828 24.0938 6.70345 24.0938C4.94897 24.0938 3.90621 22.5535 3.90621 19.8069C3.90621 13.931 6.83586 7.92276 10.3283 7.92276C12.2195 7.92276 13.8 9.015 16.2208 12.4807C13.9221 16.0065 12.5297 18.2179 12.5297 18.2179ZM24.0863 17.6137L21.9688 14.0821C21.3958 13.1501 20.8451 12.2923 20.3167 11.5086C22.2252 8.56294 23.7994 7.09518 25.6717 7.09518C29.5614 7.09518 32.6731 12.8221 32.6731 19.8566C32.6731 22.5379 31.7949 24.0938 29.9752 24.0938C28.2311 24.0938 27.3979 22.9419 24.0863 17.6137Z",
    fill: "white"
  })]
}), 'MetaMonoLogo', 'CompanyMono');
export default MetaMonoLogo;