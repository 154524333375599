/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TheMotleyFoolMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 118,
  height: 33,
  viewBox: "0 0 118 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M3.50889 20.8297H0.526367V18.6295H8.83832V20.8297H5.90469V29.5817H3.46V20.8297H3.50889Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.4737 18.6295H11.8206V22.6388C12.114 21.9543 12.9941 21.5143 14.0208 21.5143C15.9277 21.5143 16.9545 22.7366 16.9545 24.7901V29.5817H14.6075V25.0835C14.6075 24.1545 14.1186 23.5678 13.2385 23.5678C12.4073 23.5678 11.7717 24.2034 11.7717 25.1324V29.5817H9.4248V18.6295H9.4737Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.6041 24.7412C23.6041 24.0567 22.8707 23.4211 21.9417 23.4211C20.9638 23.4211 20.1326 24.0078 20.0837 24.7412H23.6041ZM17.7368 25.6213C17.7368 23.1277 19.5459 21.4653 21.9417 21.4653C24.3864 21.4653 25.951 23.2744 25.951 25.4746C25.951 25.4746 25.951 25.8658 25.9021 26.2569H20.0837C20.1326 27.1859 20.9149 27.8215 22.1861 27.8215C23.7019 27.8215 24.533 27.3815 25.022 27.0392V29.0928C24.2886 29.5817 23.4574 29.8262 22.0884 29.8262C19.5459 29.8262 17.7857 28.2127 17.7857 25.7191V25.6213H17.7368Z",
    fill: "none"
  }), _jsx("path", {
    d: "M30.5469 18.2384L36.3164 24.5457L42.1348 18.2384V29.5818H39.7879V24.0079L36.3164 27.8705L32.845 24.0079V29.5818H30.498V18.2384H30.5469Z",
    fill: "none"
  }), _jsx("path", {
    d: "M47.2691 27.7238C48.3937 27.7238 49.3226 26.7948 49.3226 25.6703C49.3226 24.5457 48.3937 23.6167 47.2691 23.6167C46.0956 23.6167 45.2156 24.5457 45.2156 25.6703C45.2156 26.7948 46.1445 27.7238 47.2691 27.7238ZM47.2691 21.4165C49.7138 21.4165 51.6695 23.2745 51.6695 25.6214C51.6695 27.9683 49.7138 29.8262 47.2691 29.8262C44.8244 29.8262 42.8687 27.9683 42.8687 25.6214C42.8687 23.2745 44.8244 21.4165 47.2691 21.4165Z",
    fill: "none"
  }), _jsx("path", {
    d: "M52.9892 23.7634H51.9136V21.6609H52.9892V18.6295H55.2873V21.6609H57.0474V23.7634H55.2873V29.5817H52.9892V23.7634Z",
    fill: "none"
  }), _jsx("path", {
    d: "M59.9811 18.6295H57.6343V29.5817H59.9811V18.6295Z",
    fill: "none"
  }), _jsx("path", {
    d: "M66.6309 24.7412C66.6309 24.0567 65.8975 23.4211 64.9685 23.4211C63.9907 23.4211 63.1595 24.0078 63.1106 24.7412H66.6309ZM60.7637 25.6213C60.7637 23.1277 62.5727 21.4653 64.9685 21.4653C67.4132 21.4653 68.9778 23.2744 68.9778 25.4746C68.9778 25.4746 68.9778 25.8658 68.9289 26.2569H63.1106C63.1595 27.1859 63.9418 27.8215 65.213 27.8215C66.7287 27.8215 67.5599 27.3815 68.0489 27.0392V29.0928C67.3154 29.5817 66.4843 29.8262 65.1152 29.8262C62.5727 29.8262 60.8126 28.2127 60.8126 25.7191V25.6213H60.7637Z",
    fill: "none"
  }), _jsx("path", {
    d: "M68.6353 21.6609H71.08L73.1824 26.9414L75.3337 21.6609H77.7784L73.0846 32.6131H70.6888L71.96 29.7284L68.6353 21.6609Z",
    fill: "none"
  }), _jsx("path", {
    d: "M81.6899 18.6295H88.9262V20.8297H84.1346V23.0789H88.4862V25.2302H84.1346V29.5817H81.6899V18.6295Z",
    fill: "none"
  }), _jsx("path", {
    d: "M93.669 27.7238C94.7936 27.7238 95.7225 26.7948 95.7225 25.6703C95.7225 24.5457 94.7936 23.6167 93.669 23.6167C92.4955 23.6167 91.6155 24.5457 91.6155 25.6703C91.6155 26.7948 92.4955 27.7238 93.669 27.7238ZM93.669 21.4165C96.1137 21.4165 98.0694 23.2745 98.0694 25.6214C98.0694 27.9683 96.1137 29.8262 93.669 29.8262C91.2243 29.8262 89.2686 27.9683 89.2686 25.6214C89.2686 23.2745 91.2243 21.4165 93.669 21.4165Z",
    fill: "none"
  }), _jsx("path", {
    d: "M102.959 27.7238C104.084 27.7238 105.013 26.7948 105.013 25.6703C105.013 24.5457 104.084 23.6167 102.959 23.6167C101.786 23.6167 100.905 24.5457 100.905 25.6703C100.905 26.7948 101.786 27.7238 102.959 27.7238ZM102.959 21.4165C105.404 21.4165 107.359 23.2745 107.359 25.6214C107.359 27.9683 105.404 29.8262 102.959 29.8262C100.514 29.8262 98.5586 27.9683 98.5586 25.6214C98.5586 23.2745 100.514 21.4165 102.959 21.4165Z",
    fill: "none"
  }), _jsx("path", {
    d: "M110.537 18.6295H108.19V29.5817H110.537V18.6295Z",
    fill: "none"
  }), _jsx("path", {
    d: "M117.871 15.8426C117.871 16.7226 117.187 17.4072 116.307 17.4072C115.427 17.4072 114.742 16.7226 114.742 15.8426C114.742 14.9625 115.427 14.278 116.307 14.278C117.138 14.278 117.871 14.9625 117.871 15.8426Z",
    fill: "none"
  }), _jsx("path", {
    d: "M106.773 0.636597C111.369 0.636597 115.231 5.77045 116.307 12.7623C116.307 12.9089 116.258 13.0067 116.111 13.0556C116.014 13.0556 115.916 13.0067 115.867 12.9089C114.791 10.2198 112.933 8.45961 110.782 8.45961C107.75 8.45961 105.061 12.1755 104.768 17.3094C104.377 18.1406 101.199 18.8251 98.314 18.9229V6.69943C100.123 2.9835 103.692 0.636597 106.773 0.636597Z",
    fill: "none"
  }), _jsx("path", {
    d: "M78.7563 15.8426C78.7563 16.7226 79.4409 17.4072 80.3209 17.4072C81.201 17.4072 81.8855 16.7226 81.8855 15.8426C81.8855 14.9625 81.201 14.278 80.3209 14.278C79.4897 14.278 78.7563 14.9625 78.7563 15.8426Z",
    fill: "none"
  }), _jsx("path", {
    d: "M89.8556 0.636597C85.2596 0.636597 81.397 5.77045 80.3213 12.7623C80.3213 12.9089 80.3702 13.0067 80.5169 13.0556C80.6147 13.0556 80.7124 13.0067 80.7613 12.9089C81.837 10.2198 83.695 8.45961 85.8463 8.45961C88.8777 8.45961 91.5669 12.1755 91.8602 17.3094C92.2514 18.1406 95.4295 18.8251 98.3142 18.9229V6.69943C96.5051 2.9835 92.9359 0.636597 89.8556 0.636597Z",
    fill: "none"
  }), _jsx("path", {
    d: "M112.689 29.0439C112.737 29.0439 112.835 28.995 112.835 28.8972C112.835 28.8483 112.786 28.7505 112.689 28.7505H112.542V29.0439H112.689ZM112.395 28.6039H112.689C112.835 28.6039 112.982 28.7016 112.982 28.8972C112.982 28.995 112.933 29.0928 112.835 29.1417L113.031 29.4839H112.835L112.64 29.1417H112.542V29.4839H112.395V28.6039ZM113.324 29.0439C113.324 28.6527 113.031 28.4083 112.689 28.4083C112.346 28.4083 112.053 28.6527 112.053 29.0439C112.053 29.3862 112.297 29.6795 112.689 29.6795C113.031 29.6795 113.324 29.435 113.324 29.0439ZM111.906 29.0439C111.906 28.6039 112.249 28.2616 112.689 28.2616C113.129 28.2616 113.471 28.555 113.471 29.0439C113.471 29.4839 113.129 29.8262 112.689 29.8262C112.249 29.8262 111.906 29.5328 111.906 29.0439Z",
    fill: "none"
  })]
}), 'TheMotleyFoolMonoLogo', 'CompanyMono');
export default TheMotleyFoolMonoLogo;