/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var SpotHeroMonoLogo = createCompanyLogosComponent(_jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 52,
  height: 32,
  fill: "none",
  viewBox: "0 0 52 32",
  children: _jsx("path", {
    fillRule: "evenodd",
    d: "M11.583 3.044l.484.16c1.124.321 1.926 1.119 2.093 2.072 0 .802-.162 1.6-.807 2.08-.646.643-1.447.803-2.254.803-.963 0-1.926-.476-2.733-.958l.807-1.6c.484.477.963.798 1.608.958.48 0 1.124-.32 1.124-.802 0-.56-.62-.752-1.1-.899L10.62 4.8c-1.286-.481-2.093-.963-2.093-2.241C8.527.958 9.652 0 11.26 0c.807 0 1.77.16 2.415.642l-.806 1.6c-.323-.322-.802-.642-1.286-.642-.48 0-.963.32-.963.803 0 .245.375.396.696.526.098.04.191.077.267.115zm18.819 5.12c2.571 0 4.342-1.76 4.342-4.318 0-2.24-2.093-3.84-4.342-3.84-2.249 0-4.341 1.6-4.341 4.001 0 2.397 1.77 4.157 4.34 4.157zm10.62-.16h-2.093V1.926h-1.931l.16-1.76h5.472l.162 1.76h-1.77v6.078zM19.303 5.446h1.286c1.448 0 2.733-.802 2.733-2.718 0-1.76-1.447-2.557-2.894-2.557h-3.056v7.838h1.931V5.446zm11.099-3.52c1.285 0 2.255.958 2.255 2.081 0 1.279-.964 2.402-2.255 2.402-1.29 0-2.254-.958-2.254-2.402.005-1.123.967-2.081 2.254-2.081zM3.374 20.166c1.77-.16 5.632-.483 5.632-.483l-.156-7.998h3.217v19.037c-1.125 0-2.092 0-3.217.16v-8.32l-5.632.481v8.475l-.296.043C1.896 31.708.878 31.854 0 32V11.844h3.374v8.32zm34.108-2.884c0-5.12-4.342-5.442-5.471-5.442h-5.628v18.556h3.056v-7.191h1.447l3.057 7.357c.56 0 1.124.04 1.687.08.564.04 1.128.08 1.691.08l-.807-1.761-2.734-6.238c1.77-.642 3.702-2.081 3.702-5.441zm-8.043 3.04h2.572s2.414 0 2.414-3.04c0-2.398-1.446-2.88-2.571-2.88h-2.415v5.92zm15.925 8.318c2.733.16 2.894-1.599 2.894-7.356v-.504c.008-3.302.014-6.056-2.894-6.056-3.07 0-3.064 2.767-3.057 6.079v.481c0 5.115.162 7.196 3.057 7.356zm0-16.955c-5.471 0-6.274 3.841-6.274 9.599 0 5.757.485 10.24 6.435 10.556 5.148.32 6.111-2.717 6.111-10.556-.16-5.763-.963-9.599-6.272-9.599zM20.51 22.483c-.724.04-1.447.08-2.171.08v5.119c2.577-.16 5.471-.16 5.471-.16v2.88c-3.056 0-5.956 0-8.688.16V11.685h8.688v2.718H18.34v5.12h4.342v2.88c-.724 0-1.447.04-2.171.08zm-.563-20.557h-.645v1.915h.807c.801 0 1.286 0 1.286-.957 0-.958-.64-.958-1.448-.958z",
    clipRule: "evenodd"
  })
}), 'SpotHeroMonoLogo', 'CompanyMono');
export default SpotHeroMonoLogo;