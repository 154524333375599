/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SlackMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 108,
  height: 33,
  viewBox: "0 0 108 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M34.5498 24.2601L35.8987 21.1646C37.3564 22.2394 39.2927 22.7983 41.2073 22.7983C42.6215 22.7983 43.5135 22.2609 43.5135 21.4441C43.4918 19.1654 35.0502 20.9496 34.9849 15.2315C34.9632 12.3294 37.574 10.0938 41.2726 10.0938C43.47 10.0938 45.6674 10.6312 47.2339 11.8565L45.972 15.0165C44.5361 14.1136 42.7521 13.4687 41.055 13.4687C39.9019 13.4687 39.1405 14.0062 39.1405 14.6941C39.1622 16.9297 47.6691 15.7044 47.7561 21.1646C47.7561 24.1312 45.2105 26.2163 41.5554 26.2163C38.8794 26.2163 36.4209 25.5929 34.5498 24.2601Z",
    fill: "none"
  }), _jsx("path", {
    d: "M86.3086 20.0468C85.6342 21.2076 84.3723 22.003 82.9146 22.003C80.7607 22.003 79.0202 20.2832 79.0202 18.155C79.0202 16.0269 80.7607 14.3071 82.9146 14.3071C84.3723 14.3071 85.6342 15.1025 86.3086 16.2633L90.029 14.2211C88.6366 11.7705 85.9605 10.0938 82.9146 10.0938C78.411 10.0938 74.7559 13.7052 74.7559 18.155C74.7559 22.6049 78.411 26.2163 82.9146 26.2163C85.9823 26.2163 88.6366 24.5611 90.029 22.089L86.3086 20.0468Z",
    fill: "none"
  }), _jsx("path", {
    d: "M54.2829 3.4082H49.627V25.9153H54.2829V3.4082Z",
    fill: "none"
  }), _jsx("path", {
    d: "M91.8564 3.4082V25.9153H96.5124V19.1653L102.039 25.9153H108L100.972 17.897L107.478 10.4161H101.777L96.5124 16.6287V3.4082H91.8564Z",
    fill: "none"
  }), _jsx("path", {
    d: "M68.1197 20.0898C67.4452 21.1861 66.0528 22.003 64.4863 22.003C62.3324 22.003 60.5919 20.2832 60.5919 18.155C60.5919 16.0269 62.3324 14.3071 64.4863 14.3071C66.0528 14.3071 67.4452 15.167 68.1197 16.2848V20.0898ZM68.1197 10.4162V12.2434C67.3582 10.9751 65.4654 10.0938 63.4855 10.0938C59.3953 10.0938 56.1753 13.6622 56.1753 18.1335C56.1753 22.6049 59.3953 26.2163 63.4855 26.2163C65.4654 26.2163 67.3582 25.335 68.1197 24.0667V25.8939H72.7756V10.4162H68.1197Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.76572 20.0685C5.76572 21.6378 4.48208 22.9061 2.89385 22.9061C1.30561 22.9061 0.0219727 21.6378 0.0219727 20.0685C0.0219727 18.4993 1.30561 17.231 2.89385 17.231H5.76572V20.0685Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.20166 20.0685C7.20166 18.4993 8.4853 17.231 10.0735 17.231C11.6618 17.231 12.9454 18.4993 12.9454 20.0685V27.1625C12.9454 28.7317 11.6618 30 10.0735 30C8.4853 30 7.20166 28.7317 7.20166 27.1625V20.0685Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.0731 8.67515C8.48481 8.67515 7.20117 7.40684 7.20117 5.83757C7.20117 4.26831 8.48481 3 10.0731 3C11.6613 3 12.9449 4.26831 12.9449 5.83757V8.67515H10.0731Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.0733 10.1155C11.6615 10.1155 12.9452 11.3838 12.9452 12.9531C12.9452 14.5223 11.6615 15.7906 10.0733 15.7906H2.87187C1.28364 15.7906 0 14.5223 0 12.9531C0 11.3838 1.28364 10.1155 2.87187 10.1155H10.0733Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.5825 12.9531C21.5825 11.3838 22.8662 10.1155 24.4544 10.1155C26.0426 10.1155 27.3263 11.3838 27.3263 12.9531C27.3263 14.5223 26.0426 15.7906 24.4544 15.7906H21.5825V12.9531Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20.1466 12.953C20.1466 14.5223 18.8629 15.7906 17.2747 15.7906C15.6865 15.7906 14.4028 14.5223 14.4028 12.953V5.83758C14.4028 4.26831 15.6865 3 17.2747 3C18.8629 3 20.1466 4.26831 20.1466 5.83758V12.953Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.2747 24.325C18.8629 24.325 20.1466 25.5933 20.1466 27.1625C20.1466 28.7318 18.8629 30.0001 17.2747 30.0001C15.6865 30.0001 14.4028 28.7318 14.4028 27.1625V24.325H17.2747Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.2747 22.9061C15.6865 22.9061 14.4028 21.6378 14.4028 20.0685C14.4028 18.4993 15.6865 17.231 17.2747 17.231H24.4762C26.0644 17.231 27.348 18.4993 27.348 20.0685C27.348 21.6378 26.0644 22.9061 24.4762 22.9061H17.2747Z",
    fill: "none"
  })]
}), 'SlackMonoLogo', 'CompanyMono');
export default SlackMonoLogo;