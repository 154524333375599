/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AmaysimMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 138,
  height: 32,
  viewBox: "0 0 138 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M137.13 9.60671C136.752 7.74352 135.9 6.14062 134.445 4.90427C132.388 3.16038 130.024 2.6268 127.419 3.30137C126.038 3.65925 124.927 4.49216 123.947 5.50509C123.871 5.58318 123.829 5.6938 123.773 5.78923C123.686 5.70681 123.678 5.70464 123.673 5.7003C123.493 5.52244 123.313 5.34242 123.131 5.16456C121.285 3.34475 119.073 2.68536 116.544 3.16689C112.856 3.86748 110.503 7.06679 110.401 10.5177C110.306 13.7539 110.375 16.9944 110.366 20.2327C110.366 20.4388 110.442 20.4822 110.631 20.4822C111.837 20.4757 113.045 20.48 114.251 20.4778C114.475 20.4778 114.587 20.3658 114.587 20.1417C114.587 17.0703 114.589 13.9968 114.587 10.9255C114.587 10.3333 114.644 9.75204 114.848 9.1946C115.32 7.90403 116.158 6.9822 117.574 6.80434C119.114 6.60912 120.316 7.22079 121.125 8.55474C121.545 9.24882 121.721 10.0167 121.721 10.8257C121.721 13.9296 121.721 17.0334 121.721 20.1373C121.721 20.2393 121.721 20.339 121.721 20.4562H125.938V20.1286C125.938 17.0963 125.938 14.0641 125.938 11.0318C125.938 10.7151 125.944 10.3962 125.99 10.0839C126.15 8.99288 126.588 8.04502 127.495 7.37262C129.033 6.23171 131.323 6.72625 132.397 8.42026C132.879 9.17942 133.069 10.0123 133.067 10.9081C133.059 14.0033 133.065 17.1007 133.063 20.1959C133.063 20.3564 133.046 20.4844 133.284 20.4822C134.586 20.4713 135.887 20.4735 137.188 20.4713V9.75637C137.169 9.70649 137.141 9.65877 137.13 9.60671Z",
    fill: "white"
  }), _jsx("path", {
    d: "M18.3065 3.48375C17.0615 3.49243 15.8143 3.48809 14.5693 3.48809C14.4999 3.48809 14.4305 3.49893 14.3286 3.50761V4.86325C14.2787 4.86325 14.2591 4.86975 14.2483 4.86325C14.1876 4.82637 14.129 4.78516 14.0704 4.74395C11.8103 3.1584 9.33547 2.56408 6.65456 3.36011C3.05832 4.4251 0.869778 6.83272 0.145324 10.5352C0.0780848 10.8758 0.0477185 11.2228 0 11.5655V12.4223C0.0390424 12.7281 0.0650707 13.034 0.119296 13.3376C0.652876 16.3699 2.27747 18.6148 5.02562 20.0052C6.79771 20.901 8.68259 21.1743 10.6412 20.7925C11.9275 20.5409 13.0792 19.9596 14.1659 19.2417C14.2461 19.1875 14.3307 19.1397 14.4565 19.0638V20.4629H18.5365C18.543 20.3761 18.556 20.298 18.556 20.2221C18.556 14.7301 18.556 9.23816 18.5603 3.7462C18.5603 3.5575 18.5191 3.48158 18.3109 3.48375H18.3065ZM10.4655 16.7582C9.98184 16.8948 9.47429 16.9382 8.97541 17.025C6.55045 16.9577 4.44649 15.2854 3.991 13.0166C3.49213 10.5244 4.86945 8.12979 7.28358 7.28604C10.1944 6.26877 13.3069 7.98664 13.988 10.9842C14.5498 13.4526 12.9729 16.0532 10.4655 16.7582Z",
    fill: "white"
  }), _jsx("path", {
    d: "M46.237 6.98439C44.4519 3.82195 40.9663 2.50102 37.7388 3.21246C36.2248 3.54649 35.008 4.40759 33.9452 5.50511C33.8693 5.5832 33.8281 5.69165 33.763 5.79793C33.5613 5.5832 33.4355 5.45089 33.3118 5.31641C30.9931 2.80902 27.6377 2.40775 24.8592 3.77206C22.0546 5.14939 20.6339 7.53749 20.5211 10.598C20.404 13.8081 20.4929 17.027 20.4842 20.2436C20.4842 20.441 20.5558 20.4822 20.7337 20.4822C21.9396 20.4779 23.1478 20.48 24.3538 20.48C24.588 20.48 24.7052 20.3665 24.7052 20.1395C24.7052 17.0682 24.7073 13.9947 24.703 10.9233C24.703 10.3399 24.7572 9.76507 24.9589 9.21631C25.486 7.78259 26.436 6.84123 28.0281 6.7805C29.5139 6.72193 30.6157 7.40951 31.325 8.71093C31.6764 9.3573 31.8347 10.0557 31.8347 10.791C31.8347 13.9101 31.8347 17.0313 31.8347 20.1503V20.467H36.0513V20.1265C36.0513 17.0617 36.047 13.9968 36.0556 10.9342C36.0556 10.5633 36.0817 10.1859 36.1554 9.82363C36.381 8.70659 36.9016 7.76523 37.9232 7.18177C39.4935 6.28379 41.5585 6.86943 42.5475 8.47667C43.0095 9.22715 43.1896 10.0492 43.1874 10.9277C43.1809 14.0164 43.1874 17.105 43.1809 20.1916C43.1809 20.4171 43.2373 20.4909 43.4715 20.4887C44.6536 20.4779 45.8379 20.4844 47.02 20.4822C47.1046 20.4822 47.1892 20.4714 47.2933 20.4649V20.2111C47.2933 17.1463 47.2999 14.0814 47.2912 11.0188C47.2868 9.58938 46.9463 8.24025 46.2414 6.99089L46.237 6.98439Z",
    fill: "white"
  }), _jsx("path", {
    d: "M87.9387 3.49858C87.8476 3.49424 87.7781 3.48774 87.7087 3.48774C86.3119 3.48774 84.915 3.49207 83.5182 3.4834C83.3251 3.4834 83.2449 3.55281 83.1733 3.72199C81.5943 7.45921 80.0065 11.1921 78.421 14.925C78.3863 15.0052 78.3451 15.0833 78.2908 15.1983C78.241 15.0942 78.2106 15.0399 78.1867 14.9814C76.5903 11.2268 74.9961 7.47223 73.404 3.71548C73.3368 3.55715 73.2652 3.4834 73.0809 3.4834C71.7621 3.49207 70.4455 3.48774 69.1267 3.48774C69.0508 3.48774 68.9771 3.49641 68.8751 3.50509C68.9163 3.6092 68.9402 3.68078 68.9706 3.75236C71.3001 9.253 73.6296 14.7536 75.9613 20.2543C76.0264 20.4061 76.0568 20.5384 75.9852 20.7054C75.0547 22.8571 74.1307 25.0131 73.2045 27.1669C72.9485 27.7634 72.6883 28.3577 72.4128 28.9933H72.7208C74.0309 28.9933 75.341 28.9868 76.6511 28.9998C76.9048 29.0019 77.0176 28.9217 77.1174 28.6896C80.6963 20.3627 84.2817 12.038 87.8649 3.71331C87.8909 3.65041 87.9105 3.58317 87.9387 3.49858Z",
    fill: "white"
  }), _jsx("path", {
    d: "M98.0615 10.7303C96.836 10.4483 95.5866 10.2748 94.3481 10.0513C93.7538 9.94507 93.1703 9.81493 92.6281 9.52862C91.5002 8.92997 91.7583 7.47889 92.5587 7.01472C92.9513 6.78697 93.372 6.66117 93.8145 6.60477C95.2548 6.41824 96.6646 6.58092 98.0289 7.06461C98.6992 7.30103 99.3477 7.59819 100.02 7.87365C100.484 6.86723 100.961 5.8326 101.445 4.78497C101.306 4.70471 101.185 4.63096 101.061 4.56373C99.4974 3.72214 97.8251 3.2493 96.0595 3.08879C94.5303 2.95214 93.0142 2.99335 91.5436 3.48138C89.3919 4.19499 87.9343 5.54195 87.687 7.90836C87.5265 9.44836 87.9712 10.7823 89.188 11.7974C90.1337 12.5848 91.2529 13.0012 92.4437 13.2138C93.5261 13.4068 94.6214 13.5283 95.7081 13.6996C96.2894 13.7907 96.8447 13.9664 97.3349 14.3222C98.1396 14.9121 98.2957 16.617 96.9509 17.1007C96.7427 17.1744 96.5301 17.2482 96.3154 17.2872C95.142 17.5019 93.9642 17.4868 92.7864 17.3371C91.4286 17.1636 90.1684 16.7233 89.0427 15.9294C88.9364 15.8535 88.8301 15.7797 88.7043 15.693C88.2705 16.7558 87.8497 17.7948 87.4137 18.8663C87.9105 19.1179 88.3746 19.3825 88.8583 19.595C90.2573 20.2089 91.7258 20.5429 93.2462 20.66C94.8557 20.7837 96.4477 20.6969 98.0007 20.2197C99.2219 19.8445 100.309 19.248 101.126 18.2351C102.527 16.4999 102.504 14.0033 101.066 12.4199C100.254 11.5263 99.2154 10.9927 98.0636 10.7259L98.0615 10.7303Z",
    fill: "white"
  }), _jsx("path", {
    d: "M103.874 20.4603H108.054V3.50293H103.874V20.4603Z",
    fill: "white"
  }), _jsx("path", {
    d: "M63.4959 3.48355C63.3333 3.48355 63.292 3.55296 63.2942 3.69829C63.3007 4.00846 63.2964 4.31646 63.2942 4.62663C63.2942 4.70688 63.2812 4.78931 63.2725 4.91294C61.3985 3.55079 59.3705 2.83502 57.0843 3.06276C54.8112 3.28834 52.8807 4.24922 51.2974 5.90201C48.5991 8.7174 48.085 13.0468 50.0545 16.4521C51.0653 18.2004 52.5098 19.4779 54.3752 20.2436C57.5376 21.5385 60.494 21.0461 63.292 19.0376C63.292 19.4801 63.3051 19.8358 63.2877 20.1915C63.2769 20.4236 63.3571 20.4887 63.5849 20.4865C64.7605 20.4757 65.9339 20.4822 67.1095 20.4822C67.1941 20.4822 67.2787 20.4713 67.3741 20.467V3.51175C67.3199 3.50308 67.2744 3.48789 67.2288 3.48789C65.9816 3.48789 64.7366 3.48789 63.4894 3.48355H63.4959ZM58.7024 16.9163C56.3013 17.2395 54.0607 15.9728 53.1953 13.8016C52.0674 10.971 53.8004 7.7652 56.8023 7.12751C59.4247 6.57007 61.9841 7.9756 62.7932 10.4461C62.9537 10.9363 63.0101 11.4634 63.1142 11.9731C63.06 14.4566 61.1816 16.5844 58.7024 16.9185V16.9163Z",
    fill: "white"
  })]
}), 'AmaysimMonoLogo', 'CompanyMono');
export default AmaysimMonoLogo;