/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BrexMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 89,
  height: 32,
  viewBox: "0 0 89 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M88.9798 0H86.8098L64.4224 32H66.5924L79.7399 13.2054L88.9798 0Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.3796 15.3803H2.18894V8.67137H10.3786C12.1982 8.67137 13.68 10.1773 13.68 12.0249C13.68 13.8725 12.1992 15.3783 10.3786 15.3783L10.3796 15.3803ZM10.3796 23.875H2.18894V17.1777H10.3786C12.1982 17.1777 13.68 18.6836 13.68 20.5312C13.68 22.3788 12.1992 23.8846 10.3786 23.8846L10.3796 23.875ZM13.2273 16.2703C13.9147 15.7952 14.4762 15.1599 14.863 14.4192C15.2498 13.6785 15.4504 12.8547 15.4475 12.0191C15.4475 9.18106 13.1742 6.86914 10.3796 6.86914L0.422424 6.88169V25.682H10.3796C13.1742 25.682 15.4475 23.3721 15.4475 20.5321C15.4504 19.6965 15.2498 18.8727 14.863 18.132C14.4762 17.3913 13.9147 16.7559 13.2273 16.2809V16.2703ZM62.04 8.6733V6.87783H46.987V25.6676L62.04 25.6685V23.8731H48.7535V17.1661H58.2869V15.3706H48.7535V8.68296L62.04 8.6733ZM78.5179 17.8457L77.4251 19.3979L81.8125 25.6792H83.9786L78.5179 17.8457ZM70.863 6.88555H68.6958L74.1749 14.7306L75.259 13.1794L70.9016 6.94057L70.863 6.88555ZM32.9118 17.1729L38.8504 25.6792H36.6843L36.6456 25.6251L30.7447 17.1729H25.4915V25.6811H23.725V15.3764H33.6706C35.4902 15.3764 36.97 13.8725 36.9719 12.0239C36.9739 10.1753 35.4911 8.67137 33.6706 8.67137H23.725V6.8759H33.6706C36.4632 6.8759 38.7365 9.19264 38.7384 12.021C38.7404 14.8494 36.4642 17.1719 33.6706 17.1719H33.0335L32.9118 17.1729Z",
    fill: "none"
  })]
}), 'BrexMonoLogo', 'CompanyMono');
export default BrexMonoLogo;