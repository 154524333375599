/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MuralMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 173,
  height: 32,
  viewBox: "0 0 173 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M31.3772 32C31.7126 32 32 31.7126 32 31.3772H31.9521V0.622754C31.9521 0.287425 31.6647 0 31.3293 0H0.622754C0.287425 0 0 0.287425 0 0.622754V31.3772C0 31.7126 0.287425 32 0.622754 32H31.3772ZM22.2755 20.8862V11.2574C22.2755 11.0658 22.0839 10.9221 21.9402 10.9221H19.6887C19.5929 10.9221 19.4492 10.97 19.4013 11.0658L15.9522 15.7604L12.551 11.0658C12.4551 10.97 12.3593 10.9221 12.2635 10.9221H10.0599C9.86832 10.9221 9.72461 11.0658 9.72461 11.2574V20.9341C9.72461 21.1257 9.86832 21.2694 10.0599 21.2694H12.551C12.7426 21.2694 12.8863 21.1257 12.8863 20.9341V15.9042C12.9704 16.0444 13.9737 17.4485 14.8297 18.6464C15.4361 19.4949 15.9685 20.2401 16.048 20.3592C16.7639 19.3024 17.5041 18.2941 18.234 17.2998C18.5297 16.8969 18.8238 16.4963 19.1138 16.0958V20.8862C19.1138 21.0778 19.2575 21.2215 19.4492 21.2215H21.9402C22.1318 21.2215 22.2755 21.0778 22.2755 20.8862Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M66.491 32C66.8263 32 67.1138 31.7126 67.1138 31.3772H67.0659V0.622754C67.0659 0.287425 66.7784 0 66.4431 0H35.7365C35.4012 0 35.1138 0.287425 35.1138 0.622754V31.3772C35.1138 31.7126 35.4012 32 35.7365 32H66.491ZM56.5745 11.3054C56.5745 11.0658 56.3828 10.9221 56.1912 10.9221V10.97H53.7002C53.4607 10.97 53.317 11.1616 53.317 11.3533V16.479C53.317 16.7664 53.317 17.0539 53.2212 17.3413C53.0295 18.1557 52.5026 18.6347 51.6882 18.7784C51.0655 18.9221 50.4906 18.8742 49.9158 18.5868C49.4846 18.3473 49.2451 18.0119 49.0535 17.5808C48.8619 17.1018 48.814 16.5269 48.814 16V11.3533C48.814 11.1137 48.6224 10.97 48.4307 10.97H46.0834C45.8918 10.97 45.7002 11.1616 45.7002 11.3533V16.0479V16.0479C45.7002 16.5269 45.7002 17.0539 45.796 17.5329C45.9397 18.491 46.323 19.3054 46.9457 19.976C47.9038 20.982 49.1493 21.4131 50.4906 21.5089C51.1134 21.5569 51.784 21.5089 52.4068 21.4131C53.4607 21.2215 54.4188 20.8383 55.1852 20.0718C56.1433 19.1616 56.5266 17.964 56.5745 16.7185V11.3054Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M136.671 32C137.006 32 137.293 31.7126 137.293 31.3772V0.622754C137.293 0.287425 137.006 0 136.671 0H105.964C105.629 0 105.341 0.287425 105.341 0.622754V31.3772C105.341 31.7126 105.629 32 105.964 32H136.671ZM127.425 20.9341L122.826 10.9221H119.952L115.305 20.9341C115.209 21.0778 115.305 21.2694 115.497 21.2694H118.275C118.467 21.2694 118.611 21.1736 118.706 20.982L119.137 19.9281H123.593L124.024 20.982C124.12 21.1736 124.263 21.2694 124.455 21.2694H127.233C127.377 21.2694 127.473 21.0778 127.425 20.9341ZM121.293 14.5628L122.395 17.4371H120.191L121.293 14.5628Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M171.784 32C172.12 32 172.407 31.7126 172.407 31.3772V0.622754C172.407 0.287425 172.12 0 171.784 0H141.078C140.742 0 140.455 0.287425 140.455 0.622754V31.3772C140.455 31.7126 140.742 32 141.078 32H171.784ZM155.593 18.5868V11.3054C155.593 11.0658 155.449 10.9221 155.162 10.9221H152.814C152.623 10.9221 152.431 11.1137 152.431 11.3054V20.8862C152.431 21.0778 152.623 21.2694 152.814 21.2694H159.952C160.144 21.2694 160.335 21.0778 160.335 20.8862V18.97C160.335 18.7784 160.144 18.5868 159.952 18.5868H155.593Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M102.228 0.958084C102.228 0.431138 101.796 0 101.269 0H71.1856C70.6587 0 70.2275 0.431138 70.2275 0.958084V31.0419C70.2275 31.5689 70.6587 32 71.1856 32H93.6048L102.228 23.3772V0.958084ZM86.6587 10.9221C87.1377 10.9221 87.8084 11.0179 88.0958 11.1137C89.1497 11.3533 89.964 11.9281 90.5389 12.8383C91.4491 14.3233 91.2575 16.479 89.6766 17.5329C89.5443 17.599 89.4349 17.688 89.3167 17.7841L89.3167 17.7841C89.2637 17.8272 89.209 17.8717 89.1497 17.9161C89.1976 18.0119 90.6347 20.2634 91.1617 21.0299C91.2575 21.1257 91.1617 21.3173 91.0179 21.3173H87.8563C87.7126 21.3173 87.6167 21.2694 87.5209 21.1257L85.8443 18.491H84.503V20.9341C84.503 21.1257 84.3114 21.3173 84.1197 21.3173H81.7245C81.5329 21.3173 81.3413 21.1257 81.3413 20.9341V11.3533C81.3413 11.1616 81.5329 10.97 81.7245 10.97H86.6587V10.9221ZM84.503 15.9042H86.6587C86.8503 15.9042 87.0419 15.8563 87.2335 15.7604C87.6647 15.5688 87.8563 15.1856 87.8563 14.7065C87.8563 14.1796 87.6646 13.9401 87.2814 13.7006C87.0419 13.5568 86.8024 13.5089 86.5629 13.5089H84.503V15.9042ZM102.227 23.3772H94.5626C94.2961 23.3772 94.0542 23.4874 93.8801 23.6646H93.8441L85.5566 31.9999H93.6045V32L93.6045 31.9999L101.94 23.6646L102.227 23.3772Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.3,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M93.8801 23.6646H93.8441L85.5566 31.9999H93.6045V24.3353C93.6045 24.0748 93.7099 23.8377 93.8801 23.6646Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.5,
    d: "M102.227 23.3772H94.5626C94.0356 23.3772 93.6045 23.8083 93.6045 24.3353V32L102.227 23.3772Z",
    fill: "none"
  })]
}), 'MuralMonoLogo', 'CompanyMono');
export default MuralMonoLogo;