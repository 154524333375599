/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StripeMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 78,
  height: 32,
  viewBox: "0 0 78 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M42.0109 0V4.33635L36.6006 5.48986V1.13215L42.0109 0Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M53.2627 6.75024C51.1504 6.75024 49.7924 7.73287 49.038 8.41643L48.7577 7.09203H44.0156V31.9993L49.4044 30.8672L49.4259 24.8219C50.2019 25.3773 51.3443 26.1677 53.2412 26.1677C57.0995 26.1677 60.613 23.0917 60.613 16.3201C60.5915 10.1253 57.0349 6.75024 53.2627 6.75024ZM51.9694 21.4682C50.6977 21.4682 49.9433 21.0196 49.4259 20.4642L49.4044 12.5392C49.9648 11.9197 50.7408 11.4925 51.9694 11.4925C53.931 11.4925 55.2889 13.6713 55.2889 16.4697C55.2889 19.3321 53.9525 21.4682 51.9694 21.4682Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M77.5984 16.5337C77.5984 11.0652 74.9256 6.75024 69.817 6.75024C64.6869 6.75024 61.583 11.0652 61.583 16.491C61.583 22.9208 65.2474 26.1677 70.5068 26.1677C73.0719 26.1677 75.0118 25.5909 76.4775 24.7792V20.5069C75.0118 21.2332 73.3305 21.6818 71.1966 21.6818C69.1057 21.6818 67.252 20.9555 67.0149 18.4349H77.5553C77.5553 18.3173 77.563 18.0502 77.572 17.7425C77.5841 17.3238 77.5984 16.8291 77.5984 16.5337ZM66.9502 14.5044C66.9502 12.0906 68.4375 11.0866 69.7955 11.0866C71.1103 11.0866 72.5114 12.0906 72.5114 14.5044H66.9502Z",
    fill: "none"
  }), _jsx("path", {
    d: "M42.0109 7.11328H36.6006V25.8045H42.0109V7.11328Z",
    fill: "none"
  }), _jsx("path", {
    d: "M30.4572 7.11338L30.802 8.69412C32.0738 6.38709 34.5957 6.85705 35.2855 7.11338V12.0265C34.6173 11.7915 32.4618 11.4925 31.19 13.1373V25.8045H25.8013V7.11338H30.4572Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20.0246 2.47778L14.7652 3.58857L14.7437 20.699C14.7437 23.8605 17.1363 26.1889 20.3264 26.1889C22.0939 26.1889 23.3872 25.8684 24.0985 25.4839V21.1476C23.4088 21.4253 20.0031 22.4079 20.0031 19.2464V11.6632H24.0985V7.11319H20.0031L20.0246 2.47778Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.28561 11.3857C6.14319 11.3857 5.45343 11.7061 5.45343 12.5392C5.45343 13.4488 6.64052 13.8489 8.11324 14.3453C10.5141 15.1545 13.6741 16.2197 13.6875 20.1652C13.6875 23.9888 10.6051 26.1891 6.12164 26.1891C4.2679 26.1891 2.24173 25.8259 0.237105 24.9715V19.8875C2.04773 20.8701 4.33257 21.5964 6.12164 21.5964C7.32872 21.5964 8.19092 21.2759 8.19092 20.2933C8.19092 19.2858 6.90409 18.8252 5.35054 18.2692C2.98458 17.4225 0 16.3544 0 12.7955C0 9.01455 2.90993 6.75024 7.28561 6.75024C9.07468 6.75024 10.8422 7.02794 12.6313 7.73286V12.7528C10.9931 11.877 8.9238 11.3857 7.28561 11.3857Z",
    fill: "none"
  })]
}), 'StripeMonoLogo', 'CompanyMono');
export default StripeMonoLogo;