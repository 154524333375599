/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var GlossierMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 91,
  height: 33,
  viewBox: "0 0 91 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M20.3683 25.915H17L20.6192 6.91504H24L20.3683 25.915Z",
    fill: "none"
  }), _jsx("path", {
    d: "M63.4972 7.91505C62.62 7.91226 62 8.54471 62 9.4293C62.0056 10.3181 62.634 10.9394 63.5085 10.9143C63.9142 10.9166 64.3033 10.754 64.5848 10.4645C64.8664 10.1749 65.016 9.78368 64.9986 9.38194C64.9768 8.57282 64.314 7.92523 63.4972 7.91505Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M24.0137 19.867C23.78 23.9047 26.5655 26.0729 29.7524 25.9061C33.945 25.9027 36.9659 22.8623 36.9998 18.6161C37.0218 15.5957 35.4944 13.6027 32.7428 13.0657C28.1979 12.1784 24.2779 15.3089 24.0137 19.867ZM28.9751 23.0575C27.949 22.6372 27.397 21.5231 27.4325 19.9454C27.4867 17.6104 28.6246 16.016 30.4957 15.6591C31.7539 15.4206 32.758 15.931 33.2779 17.0634C33.9823 18.5961 33.5471 21.0578 32.3618 22.2536C31.4931 23.1242 30.0013 23.4778 28.9751 23.0575Z",
    fill: "none"
  }), _jsx("path", {
    d: "M45.2445 16.2177C45.4381 16.3267 45.6421 16.4416 45.8649 16.5567L48 15.1164C47.0051 12.6385 41.9461 12.078 39.9741 14.3365C38.4306 16.0993 38.8985 18.4742 41.021 19.6816C41.3546 19.871 41.7006 20.0426 42.0463 20.2141C42.5775 20.4775 43.1078 20.7405 43.59 21.0669C43.8965 21.2781 44.1582 21.8436 44.0758 22.1961C43.9933 22.5487 43.5326 22.9512 43.1382 23.0875C42.0626 23.4584 40.8382 22.9462 40.2699 22.0065C40.1547 21.8162 40.0507 21.6168 39.9408 21.4061C39.8865 21.3021 39.8308 21.1951 39.7715 21.0852L37 22.1496C37.072 22.4361 37.1612 22.7187 37.2671 22.9961C38.6851 26.091 43.7495 26.9359 46.27 24.5028C46.7554 24.0337 47.0883 23.5007 47.2746 22.9468C47.3287 23.0796 47.3856 23.2115 47.4453 23.3422C48.5382 25.3788 50.9139 26.3005 53.6408 25.7655C55.8586 25.3305 57.0881 24.0988 57.3134 22.0872C57.5103 20.3305 56.719 19.2305 54.59 18.3105C54.4715 18.2593 54.3525 18.2086 54.2336 18.1578C53.8379 17.9891 53.4417 17.8202 53.0571 17.6305C52.4734 17.3422 52.0919 16.8922 52.3829 16.2622C52.6739 15.6322 53.3055 15.4322 53.9584 15.6489C54.3761 15.7895 54.774 16.0034 55.197 16.2308C55.3956 16.3376 55.5999 16.4474 55.8142 16.5539L58 15.0539C56.9248 13.4172 55.3352 12.8756 53.5397 12.9172C51.7442 12.9589 50.1776 13.4972 49.4111 15.2022C48.6447 16.9072 49.1929 18.6005 50.8642 19.6005C51.2312 19.8238 51.6216 20.0195 52.0124 20.2154C52.517 20.4684 53.0224 20.7218 53.4794 21.0355C53.8111 21.2639 54.1021 21.8538 54.0116 22.2188C53.9211 22.5838 53.4084 22.9955 52.9897 23.1188C51.8826 23.4472 50.7577 22.9105 50.2095 21.9338C50.1102 21.7573 50.0205 21.5754 49.9242 21.38C49.8777 21.2859 49.8297 21.1886 49.7784 21.0872L47.4503 22.0103C47.5082 20.6992 46.7912 19.404 45.3737 18.6638C44.9891 18.4622 44.5842 18.2939 44.1791 18.1254C43.8421 17.9854 43.505 17.8452 43.1794 17.6859C42.5843 17.3948 42.1002 16.9658 42.405 16.2773C42.7097 15.5888 43.3928 15.4424 44.0722 15.6569C44.4694 15.7813 44.8336 15.9863 45.2445 16.2177Z",
    fill: "none"
  }), _jsx("path", {
    d: "M64 12.915H60.6121L58 25.915H61.404L64 12.915Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M67.5305 25.1106C70.5699 26.9224 75.3723 25.5395 77.0032 22.3065L74.5342 21.0947C72.6616 23.0844 71.2393 23.6246 69.7248 22.9747C68.6885 22.5309 68.2347 21.6931 68.4086 20.4249H77.8448C77.8619 20.0556 77.8901 19.6949 77.9179 19.3407L77.9179 19.3396C77.9777 18.5763 78.0352 17.8428 77.9734 17.1187C77.7909 14.9861 76.259 13.4619 74.0752 13.0513C69.69 12.2252 65.4301 15.2521 65.0424 19.5057C64.8354 21.7746 65.3432 23.8075 67.5305 25.1106ZM72.5155 15.6011C73.9361 15.656 74.6542 16.3674 74.7933 17.9465H69.1197C69.3284 16.6533 70.9645 15.5413 72.5155 15.6011Z",
    fill: "none"
  }), _jsx("path", {
    d: "M84.638 13.1453H81.4766L79 25.915H82.2159C82.3415 25.2914 82.4611 24.6744 82.5799 24.0617C82.7962 22.9466 83.0096 21.8456 83.2515 20.7447C84.0316 17.2051 85.815 15.7057 88.9967 15.9377L90 13.2288C87.7567 12.451 86.0398 13.1777 84.4404 14.7641L84.638 13.1453Z",
    fill: "none"
  }), _jsx("path", {
    d: "M88 24.4224C88.0056 25.3024 88.64 25.9234 89.5272 25.915C89.9188 25.9184 90.2954 25.7631 90.5723 25.484C90.8493 25.205 91.0033 24.8255 90.9999 24.4308C91.0023 23.596 90.3326 22.9174 89.5042 22.915C88.6758 22.9127 88.0023 23.5876 88 24.4224Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.8497 13.6468L17 12.8293C16.5607 9.70993 14.87 7.62442 12.3235 7.13674C9.32546 6.5533 6.49892 7.0995 4.07492 9.03604C0.777581 11.6695 -0.286529 15.2819 0.0635071 19.3749C0.418794 23.5618 3.3591 26.0836 7.50352 25.9063C9.05768 25.8389 10.3843 25.2377 11.5867 24.101L11.6987 25.6456H14.5077L16.2737 16.424H9.54948L9.02443 19.1763H12.2535C12.0505 20.2332 11.3347 21.2245 10.3283 21.9144C7.64179 23.7551 4.3392 22.4428 3.70913 19.2348C3.56704 18.3825 3.53702 17.5148 3.61987 16.6545C3.85265 13.3613 6.11388 10.7012 9.02618 10.2118C11.0354 9.8713 12.5423 10.6871 13.3806 12.5686C13.4815 12.7929 13.5789 13.019 13.6935 13.285C13.7419 13.3975 13.7935 13.5172 13.8497 13.6468Z",
    fill: "none"
  })]
}), 'GlossierMonoLogo', 'CompanyMono');
export default GlossierMonoLogo;