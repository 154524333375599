/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TrunkClubMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 164,
  height: 32,
  viewBox: "0 0 164 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M110.113 11.542L110.012 11.8443C109.257 13.5569 107.796 14.514 105.982 14.514C103.313 14.514 101.701 12.9524 101.701 10.4842V7.56265C101.701 5.04405 103.313 3.48252 105.882 3.48252C108.249 3.48252 109.458 5.1448 109.962 6.10186L110.113 6.35372L112.883 5.1448L112.783 4.89294C111.977 2.82769 109.508 0.611328 105.982 0.611328C101.55 0.611328 98.4771 3.5329 98.4771 7.71376V10.4842C98.4771 13.9599 100.794 17.4859 105.882 17.4859C109.609 17.4859 111.876 15.2695 112.783 13.1539L112.883 12.9021L110.113 11.542Z",
    fill: "white"
  }), _jsx("path", {
    d: "M0 3.8856V1.0144H13.7011V3.8856H8.46247V16.8815H5.23867V3.8856H0Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.6525 24.8402L17.8317 30.9856C17.7813 31.1367 17.8821 31.2375 18.0835 31.2375H18.6376C18.7146 31.2375 18.7622 31.1787 18.8028 31.1285C18.8153 31.113 18.8272 31.0982 18.8391 31.0864C18.9399 30.8849 19.028 30.6708 19.1162 30.4567C19.2043 30.2426 19.2925 30.0285 19.3932 29.8271H22.3652L22.9192 31.0864C22.9696 31.1871 23.02 31.2375 23.1207 31.2375H23.6748C23.8259 31.2375 23.8763 31.0864 23.8259 30.9856L21.0555 24.8402C21.0555 24.7899 20.9547 24.7395 20.9044 24.7395H20.8036C20.7533 24.7395 20.7029 24.7899 20.6525 24.8402ZM20.9044 26.5529L22.0125 29.0715H19.7458L20.854 26.5529H20.9044Z",
    fill: "white"
  }), _jsx("path", {
    d: "M28.4097 24.7395C28.3593 24.7395 28.2585 24.7899 28.2585 24.8906H28.3593V31.0864C28.3593 31.1367 28.4097 31.2375 28.5104 31.2375H29.0645C29.1652 31.2375 29.2156 31.1871 29.2156 31.0864V26.6033L33.1446 31.2878H33.3965C33.4468 31.2878 33.5476 31.2375 33.5476 31.1367V24.9914C33.5476 24.941 33.4972 24.8402 33.3965 24.8402H32.792C32.6913 24.8402 32.6409 24.8906 32.6409 24.9914V29.3233L28.6615 24.7395H28.4097Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M38.9373 24.7395C40.7506 24.7395 42.2114 26.2003 42.2114 28.064C42.2114 29.9278 40.7506 31.3382 38.9373 31.3382C37.1239 31.3382 35.6631 29.8774 35.6631 28.064C35.6631 26.2507 37.0735 24.7395 38.9373 24.7395ZM38.9373 30.4315C40.2469 30.4315 41.3047 29.3737 41.3047 28.064C41.3047 26.7544 40.2469 25.6966 38.9373 25.6966C37.6276 25.6966 36.5698 26.8048 36.5698 28.064C36.5698 29.3737 37.6276 30.4315 38.9373 30.4315Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M44.4282 24.8401C44.3275 24.8401 44.2771 24.8905 44.2771 24.9912H44.3275V31.0358C44.3275 31.0862 44.3778 31.1869 44.4786 31.1869H45.083C45.1334 31.1869 45.2342 31.1366 45.2342 31.0358V28.7187H46.5942L47.8031 31.1366C47.8031 31.1869 47.9039 31.2373 47.9542 31.2373H48.6595C48.8106 31.2373 48.8609 31.0862 48.8106 30.9855L47.5513 28.6684C48.3572 28.3661 48.9113 27.6609 48.9113 26.8046C48.9113 25.6964 48.0046 24.8401 46.8964 24.8401H44.4282ZM47.9542 26.8046C47.9542 27.4594 47.4505 27.9631 46.8461 27.9631V27.9128H45.2342V25.6964H46.8461C47.4505 25.6964 47.9542 26.2001 47.9542 26.8046Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M51.1274 24.9912C51.1274 24.8905 51.1778 24.8401 51.2786 24.8401H53.3942C55.1572 24.8401 56.618 26.3009 56.618 28.0135C56.618 29.7765 55.1572 31.2373 53.3942 31.2373H51.2786C51.1778 31.2373 51.1274 31.1366 51.1274 31.0862V24.9912ZM53.2431 30.381C54.6031 30.381 55.5602 29.3736 55.5602 28.0135C55.5602 26.6535 54.6031 25.6964 53.2431 25.6964H52.0341V30.381H53.2431Z",
    fill: "white"
  }), _jsx("path", {
    d: "M58.3558 30.1544C58.318 30.2174 58.2802 30.2803 58.2299 30.3307C58.1583 30.438 58.1884 30.4946 58.2299 30.5725C58.2468 30.6042 58.2657 30.6395 58.2802 30.6833C58.2952 30.6933 58.3155 30.7081 58.3411 30.7269C58.5747 30.8976 59.2461 31.3885 60.2448 31.3885C61.4537 31.3885 62.31 30.6329 62.31 29.6759C62.31 28.5677 61.4033 28.064 60.3455 27.6106C59.5899 27.3084 59.1869 26.9558 59.1869 26.4521C59.1869 26.1498 59.4892 25.6461 60.2448 25.6461C60.7988 25.6461 61.4537 26.0491 61.5041 26.0995C61.6048 26.1498 61.7559 26.0995 61.8063 25.9987C61.907 25.898 62.0078 25.7469 62.0581 25.6461C62.1589 25.495 62.0581 25.3943 62.0078 25.3439C61.907 25.2431 61.2018 24.7898 60.2951 24.7898C58.8343 24.7898 58.2802 25.7972 58.2802 26.5024C58.2802 27.6106 58.9855 28.064 59.9425 28.4669C60.8492 28.8195 61.3026 29.1721 61.3026 29.7262C61.3026 30.1796 60.8996 30.5826 60.2448 30.5826C59.5395 30.5826 58.8343 29.9781 58.784 29.9277C58.6832 29.827 58.5825 29.8774 58.4817 29.9781C58.4314 30.0285 58.3936 30.0915 58.3558 30.1544Z",
    fill: "white"
  }), _jsx("path", {
    d: "M65.1811 25.6966H63.6699C63.5692 25.6966 63.5188 25.5958 63.5188 25.5455V25.0417C63.5188 24.941 63.5692 24.8906 63.6699 24.8906H67.5989C67.6997 24.8906 67.75 24.9914 67.75 25.0417V25.5455C67.75 25.6462 67.6997 25.6966 67.5989 25.6966H66.0878V31.0864C66.0878 31.1871 65.987 31.2375 65.9367 31.2375H65.3322C65.2314 31.2375 65.1811 31.1367 65.1811 31.0864V25.6966Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M69.6643 24.8401C69.5636 24.8401 69.5132 24.8905 69.5132 24.9912H69.5636V31.0358C69.5636 31.0862 69.6139 31.1869 69.7147 31.1869H70.3191C70.3695 31.1869 70.4702 31.1366 70.4702 31.0358V28.7187H71.8303L73.0392 31.1366C73.0392 31.1869 73.14 31.2373 73.1903 31.2373H73.8955C74.0467 31.2373 74.097 31.0862 74.0467 30.9855L72.7874 28.6684C73.5933 28.3661 74.1474 27.6609 74.1474 26.8046C74.1474 25.6964 73.2407 24.8401 72.1325 24.8401H69.6643ZM73.2407 26.8046C73.2407 27.4594 72.737 27.9631 72.1325 27.9631V27.9128H70.5206V25.6964H72.1325C72.737 25.6964 73.2407 26.2001 73.2407 26.8046Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M79.1846 24.7395C80.998 24.7395 82.4587 26.2003 82.4587 28.064C82.4587 29.9278 80.998 31.3382 79.1846 31.3382C77.3712 31.3382 75.9104 29.8774 75.9104 28.064C75.9104 26.2507 77.3712 24.7395 79.1846 24.7395ZM79.1846 30.4315C80.4942 30.4315 81.552 29.3737 81.552 28.064C81.552 26.7544 80.4942 25.6966 79.1846 25.6966C77.8749 25.6966 76.8171 26.8048 76.8171 28.064C76.8171 29.3737 77.8749 30.4315 79.1846 30.4315Z",
    fill: "white"
  }), _jsx("path", {
    d: "M85.3805 24.7395C85.3301 24.7395 85.2294 24.8402 85.2294 24.8906L84.1212 31.036C84.0708 31.1367 84.1212 31.2375 84.2723 31.2375H84.8768C84.9271 31.2375 85.0279 31.1367 85.0279 31.0864L85.6827 27.0062H85.7331L87.3954 31.2375C87.3954 31.2878 87.4961 31.3382 87.5465 31.3382H87.6976C87.7983 31.3382 87.8487 31.2878 87.8487 31.2375L89.511 27.0062H89.5613L90.2162 31.0864C90.2162 31.1871 90.2665 31.2375 90.3673 31.2375H90.9718C91.0725 31.2375 91.1732 31.1871 91.1229 31.036L90.0147 24.8906C90.0147 24.7899 89.9139 24.7395 89.8636 24.7395H89.7125C89.6621 24.7395 89.5613 24.7899 89.5613 24.8402L87.6472 29.5248H87.5968L85.6827 24.8402C85.6827 24.7899 85.582 24.7395 85.5316 24.7395H85.3805Z",
    fill: "white"
  }), _jsx("path", {
    d: "M98.8298 24.7395C99.7364 24.7395 100.442 25.0417 101.046 25.5958C101.147 25.6462 101.147 25.7973 101.046 25.8477L100.643 26.2507C100.593 26.3514 100.492 26.3514 100.391 26.2507C99.9379 25.8981 99.3838 25.6462 98.8298 25.6462C97.5201 25.6462 96.5126 26.7544 96.5126 28.0137C96.5126 29.273 97.5201 30.3812 98.8298 30.3812C99.4846 30.3812 99.9883 30.1293 100.391 29.7767C100.492 29.7263 100.542 29.7263 100.593 29.7767L100.996 30.1797C101.046 30.23 101.046 30.3812 100.996 30.4315C100.391 31.036 99.5853 31.3382 98.7794 31.3382C96.966 31.3382 95.4548 29.8774 95.4548 28.064C95.4548 26.2507 96.966 24.7395 98.8298 24.7395Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M109.156 28.064C109.156 26.2003 107.695 24.7395 105.882 24.7395C104.068 24.7395 102.608 26.2507 102.608 28.064C102.608 29.8774 104.068 31.3382 105.882 31.3382C107.695 31.3382 109.156 29.9278 109.156 28.064ZM108.249 28.064C108.249 29.3737 107.192 30.4315 105.882 30.4315C104.572 30.4315 103.514 29.3737 103.514 28.064C103.514 26.8048 104.572 25.6966 105.882 25.6966C107.192 25.6966 108.249 26.7544 108.249 28.064Z",
    fill: "white"
  }), _jsx("path", {
    d: "M111.926 24.8906C111.926 24.8402 112.027 24.7395 112.077 24.7395H112.229C112.279 24.7395 112.38 24.7899 112.38 24.8402L114.294 29.5248H114.344L116.258 24.8402C116.258 24.7899 116.359 24.7395 116.409 24.7395H116.561C116.611 24.7395 116.712 24.7899 116.712 24.8906L117.82 31.036C117.87 31.1871 117.77 31.2375 117.669 31.2375H117.064C116.964 31.2375 116.913 31.1871 116.913 31.0864L116.258 27.0062H116.208L114.546 31.2375C114.546 31.2878 114.495 31.3382 114.395 31.3382H114.243C114.193 31.3382 114.092 31.2878 114.092 31.2375L112.43 27.0062H112.38L111.725 31.0864C111.725 31.1367 111.624 31.2375 111.574 31.2375H110.969C110.818 31.2375 110.768 31.1367 110.818 31.036L111.926 24.8906Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M120.086 24.8401C119.986 24.8401 119.935 24.8905 119.935 24.9912H119.986V31.0862C119.986 31.1366 120.036 31.2373 120.137 31.2373H120.741C120.792 31.2373 120.892 31.1869 120.892 31.0862V28.9202H122.152C123.31 28.9202 124.217 28.0135 124.217 26.855C124.217 25.7468 123.26 24.8401 122.152 24.8401H120.086ZM123.31 26.855C123.31 27.5098 122.806 28.0135 122.152 28.0135H120.892V25.7468H122.152C122.756 25.7468 123.31 26.2505 123.31 26.855Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M124.922 30.9856L127.743 24.8402C127.793 24.7899 127.844 24.7395 127.894 24.7395H127.995C128.045 24.7395 128.146 24.7899 128.146 24.8402L130.917 30.9856C130.967 31.0864 130.917 31.2375 130.765 31.2375H130.211C130.111 31.2375 130.06 31.1871 130.01 31.0864L129.456 29.8271H126.433C126.333 30.0286 126.245 30.2427 126.156 30.4567C126.068 30.6708 125.98 30.8849 125.879 31.0864C125.867 31.0983 125.856 31.113 125.843 31.1285C125.802 31.1787 125.755 31.2375 125.678 31.2375H125.124C124.973 31.2375 124.872 31.1367 124.922 30.9856ZM129.103 29.0715L127.995 26.5529H127.945L126.836 29.0715H129.103Z",
    fill: "white"
  }), _jsx("path", {
    d: "M132.73 24.7395C132.68 24.7395 132.579 24.7899 132.579 24.8906H132.68V31.036C132.68 31.0864 132.73 31.1871 132.831 31.1871H133.385C133.486 31.1871 133.536 31.1367 133.536 31.036V26.5529L137.515 31.2878H137.717C137.767 31.2878 137.868 31.2375 137.868 31.1367V24.9914C137.868 24.941 137.818 24.8402 137.717 24.8402H137.112C137.012 24.8402 136.961 24.8906 136.961 24.9914V29.3233L132.982 24.7395H132.73Z",
    fill: "white"
  }), _jsx("path", {
    d: "M141.696 28.1646L139.53 25.0919C139.48 24.9912 139.53 24.8401 139.681 24.8401H140.336C140.386 24.8401 140.437 24.8905 140.487 24.9408L142.149 27.2579L143.812 24.9408C143.862 24.8905 143.912 24.8401 143.963 24.8401H144.668C144.819 24.8401 144.87 24.9912 144.819 25.0919L142.653 28.1646V31.0862C142.653 31.1869 142.552 31.2373 142.502 31.2373H141.898C141.797 31.2373 141.746 31.1366 141.746 31.0862V28.1646H141.696Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M31.3313 15.4711C30.8276 15.1689 30.6261 14.8163 30.6261 14.1111V12.3481C30.6261 10.7362 29.9209 9.67836 28.5608 9.17465C29.9713 8.57018 30.6765 7.462 30.6765 5.79973V5.29601C30.6765 3.68411 29.9209 1.0144 24.9844 1.0144H17.9324V16.9319H21.1562V10.4843H24.8837C26.8482 10.4843 27.2512 11.0888 27.3015 12.2473L27.3519 14.2622C27.4023 15.4208 27.6541 16.9319 29.6186 16.9319H31.4824V15.4711H31.3313ZM27.3519 6.15233C27.3519 7.31089 26.5963 7.96572 25.1356 7.96572H21.1562V3.83523H25.1356C26.5963 3.83523 27.3519 4.38932 27.3519 5.44713V6.15233Z",
    fill: "white"
  }), _jsx("path", {
    d: "M45.9392 11.391V1.0144H49.163V11.391C49.163 15.1185 46.5436 17.4356 42.3124 17.4356C38.1315 17.4356 35.5122 15.1185 35.5122 11.391V1.0144H38.7864V11.391C38.7864 13.4563 39.9953 14.5141 42.4132 14.5141C44.831 14.5141 45.9392 13.5066 45.9392 11.391Z",
    fill: "white"
  }), _jsx("path", {
    d: "M64.2242 1.0144V11.2399L56.5173 1.0144H53.7468V16.8815H56.9203V6.50494L64.7783 16.8815H67.448V1.0144H64.2242Z",
    fill: "white"
  }), _jsx("path", {
    d: "M82.9626 1.0144H87.1435L79.7892 8.11684L86.8412 16.8815H82.8619L77.6232 10.2828L75.9609 11.8947V16.8815H72.6868V1.0144H75.9609V7.81461L82.9626 1.0144Z",
    fill: "white"
  }), _jsx("path", {
    d: "M120.439 1.0144V13.96H128.7V16.8815H117.216V1.0144H120.439Z",
    fill: "white"
  }), _jsx("path", {
    d: "M141.545 1.0144V11.391C141.545 13.5066 140.436 14.5141 138.019 14.5141C135.601 14.5141 134.392 13.4563 134.392 11.391V1.0144H131.118V11.391C131.118 15.1185 133.737 17.4356 137.918 17.4356C142.149 17.4356 144.768 15.1185 144.768 11.391V1.0144H141.545Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M163.155 5.54787C163.155 7.1094 162.449 8.26795 161.19 8.82204C162.55 9.37613 163.356 10.4843 163.356 12.0962V12.7007C163.356 13.96 162.852 16.9319 158.017 16.9319H150.461V1.0144H157.916C162.449 1.0144 163.155 3.63374 163.155 5.19527V5.54787ZM157.815 7.61312C158.823 7.61312 159.981 7.36126 159.981 5.90047V5.44713C159.981 4.33895 159.326 3.83523 158.017 3.83523H153.735V7.61312H157.815ZM157.866 10.2325H153.685V14.1111H158.067C159.377 14.1111 160.032 13.557 160.032 12.4992V11.9451C160.032 10.8369 159.276 10.2325 157.866 10.2325Z",
    fill: "white"
  })]
}), 'TrunkClubMonoLogo', 'CompanyMono');
export default TrunkClubMonoLogo;