/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CleanChoiceEnergyMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 87,
  height: 33,
  viewBox: "0 0 87 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M18.1718 21.7948C17.4741 22.0985 16.719 22.2478 15.9581 22.2324C15.2379 22.2404 14.5224 22.1137 13.8488 21.8586C13.2219 21.6224 12.6505 21.2594 12.1704 20.7922C11.6903 20.3251 11.3117 19.7638 11.0585 19.1436C10.5157 17.7757 10.5157 16.2522 11.0585 14.8843C11.3179 14.2657 11.7049 13.7089 12.1943 13.2501C12.6855 12.7946 13.2628 12.4419 13.8922 12.2128C15.2343 11.7253 16.7051 11.7253 18.0472 12.2128C18.6741 12.4287 19.2362 12.7995 19.6814 13.2907L18.0733 14.9046C17.8532 14.6005 17.552 14.3646 17.2041 14.2237C16.8515 14.0767 16.4734 14.0009 16.0914 14.0006C15.6933 13.9943 15.2983 14.0723 14.9324 14.2295C14.5926 14.3771 14.2869 14.5931 14.0342 14.864C13.7811 15.1413 13.5843 15.465 13.4547 15.8173C13.3121 16.203 13.2414 16.6115 13.2461 17.0227C13.2414 17.4404 13.3121 17.8556 13.4547 18.2483C13.5831 18.6012 13.7801 18.9251 14.0342 19.2016C14.2806 19.4705 14.5807 19.6847 14.915 19.8303C15.2714 19.9816 15.6551 20.0576 16.0422 20.0535C16.4731 20.0608 16.8992 19.9613 17.2823 19.7637C17.6182 19.5922 17.909 19.344 18.1313 19.0393L19.7973 20.604C19.3482 21.114 18.7935 21.5203 18.1718 21.7948Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20.4956 21.9685V11.4448H22.8136V21.9685H20.4956Z",
    fill: "none"
  }), _jsx("path", {
    d: "M31.0049 18.5872V18.877C31.0096 18.9648 31.0096 19.0528 31.0049 19.1406H25.9661C25.982 19.3285 26.0415 19.5101 26.14 19.6709C26.2388 19.8303 26.3666 19.9698 26.5166 20.0823C26.8368 20.3167 27.2239 20.4417 27.6206 20.4387C27.9388 20.4484 28.2545 20.3787 28.5391 20.2359C28.7793 20.11 28.9882 19.9317 29.1505 19.7144L30.7383 20.7169C30.4146 21.1892 29.9704 21.5664 29.4518 21.8092C28.8692 22.0745 28.2345 22.2052 27.5945 22.1917C27.0927 22.1946 26.594 22.1123 26.1197 21.9483C25.671 21.7946 25.2574 21.5533 24.9027 21.2384C24.5482 20.9165 24.2683 20.5209 24.0827 20.0794C23.8788 19.5856 23.7801 19.0547 23.793 18.5206C23.7846 17.9934 23.8831 17.47 24.0827 16.982C24.2658 16.5457 24.5359 16.1513 24.8766 15.823C25.2173 15.4987 25.6192 15.2455 26.0588 15.0784C26.5298 14.8996 27.0299 14.8102 27.5337 14.8147C28.0143 14.8094 28.4915 14.897 28.9389 15.0726C29.3561 15.2372 29.7319 15.4914 30.04 15.8172C30.356 16.1589 30.5994 16.561 30.7557 16.9994C30.9322 17.5097 31.0166 18.0474 31.0049 18.5872ZM28.9042 17.718C28.9112 17.3751 28.7892 17.0421 28.5623 16.785C28.4311 16.6485 28.2714 16.5428 28.0946 16.4751C27.9178 16.4075 27.7282 16.3798 27.5395 16.3938C27.3311 16.3917 27.124 16.427 26.9281 16.4981C26.7498 16.5648 26.5849 16.6629 26.4413 16.7879C26.3042 16.9063 26.1911 17.0499 26.1081 17.2109C26.0261 17.3681 25.9787 17.541 25.969 17.718H28.9042Z",
    fill: "none"
  }), _jsx("path", {
    d: "M31.7582 15.9825C32.1668 15.5955 32.6526 15.2993 33.1838 15.1133C33.7171 14.9209 34.2799 14.8229 34.8469 14.8235C35.3502 14.8115 35.8518 14.885 36.3305 15.0408C36.7128 15.1701 37.0525 15.4016 37.3127 15.7102C37.5795 16.0446 37.7677 16.4348 37.8632 16.8518C37.9875 17.3874 38.0459 17.9362 38.0371 18.486V21.9688H35.948V21.2328H35.9074C35.7166 21.5334 35.4372 21.7672 35.1077 21.9021C34.7423 22.0616 34.3474 22.1426 33.9487 22.1397C33.6555 22.1389 33.3636 22.102 33.0795 22.0296C32.7853 21.9573 32.5066 21.8326 32.2566 21.6616C32.0082 21.4869 31.8038 21.2567 31.6597 20.9894C31.4925 20.673 31.4106 20.3185 31.4221 19.9608C31.4003 19.5195 31.5475 19.0864 31.8335 18.7496C32.1243 18.4319 32.4869 18.1885 32.8911 18.0398C33.3571 17.8649 33.8443 17.7528 34.3399 17.7065C34.8528 17.6516 35.3683 17.6236 35.8842 17.6225V17.5124C35.895 17.3653 35.8673 17.2179 35.8038 17.0848C35.7403 16.9517 35.6432 16.8374 35.5221 16.7533C35.2568 16.5844 34.9468 16.4996 34.6325 16.5099C34.3081 16.5099 33.9876 16.5811 33.6937 16.7185C33.4083 16.8448 33.1443 17.0149 32.9114 17.2227L31.7582 15.9825ZM35.948 18.9612H35.6582C35.4071 18.9612 35.1541 18.9727 34.8991 18.9959C34.6679 19.0149 34.4395 19.0596 34.2182 19.1292C34.0329 19.1864 33.8634 19.2856 33.7227 19.419C33.6578 19.483 33.6071 19.5599 33.5736 19.6448C33.5402 19.7296 33.5249 19.8204 33.5286 19.9115C33.5251 20.029 33.5563 20.1449 33.6184 20.2447C33.6766 20.3357 33.7549 20.412 33.8473 20.4679C33.9457 20.526 34.0536 20.5662 34.166 20.5867C34.2806 20.6092 34.397 20.6208 34.5137 20.6214C34.7078 20.6344 34.9024 20.6071 35.0854 20.5413C35.2684 20.4755 35.4358 20.3725 35.5771 20.239C35.7039 20.0989 35.8016 19.9351 35.8648 19.7571C35.9279 19.5791 35.9552 19.3903 35.9451 19.2016L35.948 18.9612Z",
    fill: "none"
  }), _jsx("path", {
    d: "M43.7973 21.9689V18.1848C43.7978 17.9982 43.7813 17.812 43.748 17.6284C43.7202 17.4654 43.6664 17.3079 43.5887 17.1619C43.5154 17.0292 43.4072 16.9189 43.2758 16.8432C43.1202 16.7594 42.9453 16.7184 42.7687 16.7244C42.5831 16.7205 42.3992 16.7613 42.2327 16.8432C42.0812 16.9208 41.9502 17.033 41.8502 17.1706C41.7454 17.3158 41.6679 17.4788 41.6213 17.6516C41.5701 17.8356 41.5448 18.0257 41.5459 18.2166V21.9834H39.2482V15.0381H41.4619V15.9972H41.4909C41.5822 15.8388 41.6921 15.6919 41.8183 15.5596C41.9554 15.4149 42.1137 15.2918 42.2877 15.1945C42.4714 15.091 42.6657 15.0075 42.8672 14.9454C43.0881 14.8791 43.3175 14.8459 43.5481 14.8468C43.9564 14.8347 44.3617 14.9201 44.7303 15.096C45.0377 15.249 45.3062 15.47 45.5155 15.7422C45.7225 16.01 45.8742 16.3163 45.9617 16.6433C46.0494 16.9802 46.0942 17.3267 46.095 17.6748V21.9631L43.7973 21.9689Z",
    fill: "none"
  }), _jsx("path", {
    d: "M56.048 20.3695C55.8718 20.6286 55.6667 20.8668 55.4366 21.0794C55.1881 21.3068 54.9116 21.5015 54.6137 21.6589C54.2883 21.834 53.9429 21.9693 53.5851 22.0616C53.1827 22.1635 52.7688 22.2132 52.3537 22.2094C51.6479 22.216 50.9478 22.0831 50.2935 21.8182C49.6863 21.5737 49.1355 21.2078 48.6746 20.743C48.2138 20.2782 47.8526 19.7241 47.6133 19.1149C47.3526 18.4547 47.2227 17.75 47.2309 17.0403C47.221 16.3313 47.351 15.6273 47.6133 14.9685C47.8584 14.3571 48.2244 13.8014 48.6894 13.3349C49.1545 12.8684 49.7089 12.5006 50.3196 12.2536C50.9632 11.9906 51.6526 11.8577 52.3479 11.8624C53.0564 11.8619 53.7573 12.0099 54.4051 12.2971C54.7013 12.4285 54.9814 12.5938 55.2396 12.7896C55.4808 12.972 55.6919 13.191 55.8654 13.4387L55.0309 14.0356C54.914 13.8616 54.7758 13.7029 54.6195 13.5633C54.4356 13.4054 54.2321 13.272 54.0139 13.1663C53.7719 13.0431 53.5174 12.9459 53.2548 12.8766C52.9664 12.801 52.6692 12.764 52.371 12.7664C51.7799 12.7569 51.1937 12.8756 50.6528 13.1141C50.1599 13.3277 49.7169 13.6415 49.3518 14.0356C48.9955 14.4342 48.7209 14.8988 48.5434 15.4032C48.3526 15.9279 48.2546 16.4819 48.2537 17.0403C48.2525 17.5988 48.3506 18.1531 48.5434 18.6773C48.7247 19.1812 49.0011 19.6455 49.3576 20.045C49.7227 20.4487 50.169 20.7706 50.6673 20.9896C51.2043 21.2277 51.7866 21.3463 52.3739 21.3373C52.9307 21.3391 53.4813 21.2205 53.9878 20.9896C54.5112 20.7469 54.9552 20.3612 55.2685 19.8769L56.048 20.3695Z",
    fill: "none"
  }), _jsx("path", {
    d: "M57.8705 16.527C58.0537 16.1388 58.359 15.8213 58.7397 15.623C59.1408 15.4024 59.5916 15.2877 60.0494 15.2898C60.8742 15.2898 61.4933 15.5361 61.9067 16.0287C62.3408 16.5831 62.5609 17.2753 62.5267 17.9787V21.9685H61.5793V18.3641C61.5816 18.0511 61.5535 17.7386 61.4952 17.4311C61.4469 17.1757 61.3485 16.9324 61.2055 16.7154C61.0694 16.5146 60.8824 16.3536 60.6637 16.2489C60.3907 16.1268 60.0932 16.0692 59.7944 16.0808C59.543 16.0813 59.2945 16.1336 59.0642 16.2344C58.8207 16.3409 58.604 16.5002 58.4297 16.7009C58.2299 16.9334 58.0782 17.2033 57.9835 17.4948C57.8649 17.8605 57.8081 18.2434 57.8154 18.6277V21.9685H56.8853V11.4448H57.8328V16.527H57.8705Z",
    fill: "none"
  }), _jsx("path", {
    d: "M70.3268 18.7117C70.3315 19.1791 70.2439 19.6429 70.069 20.0764C69.9026 20.4834 69.6563 20.8529 69.3446 21.163C69.0302 21.4741 68.6555 21.7176 68.2435 21.8787C67.796 22.0542 67.3189 22.1417 66.8382 22.1365C66.3624 22.1409 65.8901 22.0533 65.4474 21.8787C65.0354 21.7177 64.6607 21.4741 64.3464 21.163C64.0377 20.8522 63.7943 20.4828 63.6307 20.0764C63.2869 19.1991 63.2869 18.2243 63.6307 17.347C63.7943 16.9406 64.0377 16.5712 64.3464 16.2604C64.6608 15.9494 65.0355 15.7058 65.4474 15.5447C65.8901 15.37 66.3624 15.2825 66.8382 15.2868C67.3189 15.2816 67.796 15.3692 68.2435 15.5447C68.6554 15.7059 69.0301 15.9494 69.3446 16.2604C69.6532 16.5713 69.8965 16.9407 70.0603 17.347C70.2381 17.7799 70.3287 18.2437 70.3268 18.7117ZM69.3243 18.7117C69.3265 18.3632 69.2677 18.017 69.1504 17.6889C69.0413 17.3811 68.873 17.0976 68.655 16.8544C68.4372 16.614 68.1718 16.4215 67.8755 16.2894C67.5517 16.1466 67.2008 16.0755 66.8469 16.0808C66.4949 16.0749 66.1458 16.1461 65.8241 16.2894C65.5303 16.4227 65.2669 16.615 65.0505 16.8544C64.8335 17.0978 64.6662 17.3813 64.5579 17.6889C64.3258 18.351 64.3258 19.0724 64.5579 19.7345C64.6662 20.0421 64.8336 20.3255 65.0505 20.569C65.2661 20.807 65.5298 20.9966 65.8241 21.1253C66.1469 21.2645 66.4955 21.3336 66.8469 21.3281C67.2002 21.3329 67.5505 21.2638 67.8755 21.1253C68.1722 20.9979 68.4381 20.8082 68.655 20.569C68.8729 20.3258 69.0412 20.0423 69.1504 19.7345C69.2677 19.4063 69.3265 19.0602 69.3243 18.7117Z",
    fill: "none"
  }), _jsx("path", {
    d: "M72.6652 12.9488C72.6678 13.0394 72.6505 13.1294 72.6145 13.2126C72.5784 13.2957 72.5245 13.3699 72.4565 13.4298C72.3341 13.5503 72.1691 13.6178 71.9973 13.6178C71.8255 13.6178 71.6605 13.5503 71.538 13.4298C71.472 13.3683 71.4194 13.2938 71.3835 13.211C71.3475 13.1283 71.3289 13.039 71.3289 12.9488C71.3289 12.8586 71.3475 12.7693 71.3835 12.6866C71.4194 12.6038 71.472 12.5294 71.538 12.4678C71.6605 12.3473 71.8255 12.2798 71.9973 12.2798C72.1691 12.2798 72.3341 12.3473 72.4565 12.4678C72.5245 12.5278 72.5784 12.6019 72.6145 12.6851C72.6505 12.7682 72.6678 12.8582 72.6652 12.9488ZM72.471 21.9687H71.5235V15.4552H72.471V21.9687Z",
    fill: "none"
  }), _jsx("path", {
    d: "M78.8339 16.9328C78.6623 16.6693 78.4245 16.4555 78.1443 16.3127C77.8427 16.1499 77.5048 16.0662 77.1621 16.0693C76.8088 16.0645 76.4584 16.1336 76.1334 16.2722C75.8359 16.401 75.5685 16.5905 75.3482 16.8285C75.1266 17.0739 74.9571 17.3617 74.8499 17.6746C74.7317 18.0075 74.6728 18.3586 74.676 18.7119C74.6726 19.0623 74.7314 19.4106 74.8499 19.7405C74.9592 20.0458 75.1265 20.3272 75.3424 20.5692C75.5595 20.8081 75.8253 20.9978 76.1219 21.1255C76.4478 21.2643 76.7992 21.3334 77.1534 21.3283C77.4951 21.3432 77.8352 21.2736 78.1436 21.1255C78.4519 20.9774 78.7189 20.7554 78.9208 20.4793L79.6307 21.0096C79.3546 21.3517 79.0089 21.6312 78.6166 21.8296C78.1645 22.0443 77.6682 22.1495 77.1679 22.1367C76.6776 22.1438 76.1907 22.0562 75.7336 21.8788C75.3237 21.7187 74.9517 21.475 74.6412 21.1631C74.3359 20.8519 74.0965 20.4824 73.9371 20.0766C73.6028 19.1975 73.6028 18.2262 73.9371 17.3471C74.0965 16.9413 74.3359 16.5718 74.6412 16.2606C74.9475 15.9504 75.3144 15.7068 75.7191 15.5449C76.1762 15.3675 76.6632 15.28 77.1534 15.287C77.6117 15.2931 78.064 15.3916 78.4833 15.5768C78.9165 15.7546 79.2939 16.0459 79.5757 16.4199L78.8339 16.9328Z",
    fill: "none"
  }), _jsx("path", {
    d: "M86.4601 18.5176V18.7262C86.4645 18.8054 86.4645 18.8847 86.4601 18.9638H81.0447C81.0517 19.2794 81.1175 19.5909 81.2388 19.8823C81.3551 20.1629 81.5255 20.4179 81.7401 20.6328C81.956 20.8477 82.2106 21.0198 82.4906 21.1398C82.8457 21.2872 83.2301 21.3503 83.6136 21.3241C83.9972 21.298 84.3695 21.1833 84.7014 20.9892C85.0281 20.7916 85.3063 20.5233 85.5156 20.204L86.2254 20.7603C85.887 21.2103 85.4459 21.5729 84.9389 21.8179C84.4647 22.0362 83.9486 22.1489 83.4265 22.1482C82.9656 22.1522 82.5086 22.0645 82.082 21.8903C81.6811 21.7276 81.3181 21.484 81.0157 21.1746C80.7135 20.861 80.4744 20.4921 80.3116 20.0881C80.1366 19.6546 80.0489 19.1908 80.0538 18.7233C80.0485 18.2567 80.1331 17.7933 80.3029 17.3586C80.4611 16.9542 80.6974 16.585 80.9983 16.272C81.2947 15.9662 81.6494 15.7228 82.0414 15.5564C82.4509 15.3825 82.8918 15.2947 83.3366 15.2985C83.7998 15.2894 84.2597 15.3772 84.6869 15.5564C85.0579 15.7149 85.3901 15.9522 85.6604 16.2518C85.9194 16.551 86.1163 16.8987 86.2399 17.2746C86.3818 17.6738 86.4563 18.0939 86.4601 18.5176ZM85.5011 18.2105C85.496 17.6496 85.2904 17.109 84.9216 16.6864C84.7199 16.4786 84.4756 16.3169 84.2055 16.2124C83.9354 16.1078 83.646 16.063 83.3569 16.0808C83.0583 16.0789 82.7625 16.1381 82.4877 16.2547C82.2239 16.3668 81.9832 16.527 81.7778 16.727C81.5762 16.9231 81.4114 17.1537 81.291 17.4079C81.1716 17.6589 81.1008 17.9302 81.0824 18.2076L85.5011 18.2105Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.8538 9.36755C23.596 9.3649 23.3489 9.26383 23.1631 9.08502C22.9772 8.9062 22.8668 8.66315 22.8542 8.40558C22.8491 8.27477 22.8703 8.14426 22.9165 8.02181C22.9628 7.89937 23.0333 7.78747 23.1237 7.69279C23.2153 7.59007 23.3279 7.5082 23.4538 7.45271C23.5798 7.39723 23.7162 7.36942 23.8538 7.37117C24.0472 7.37192 24.2361 7.42871 24.3978 7.53464C24.5595 7.64058 24.687 7.79113 24.7649 7.96805C24.8428 8.14497 24.8677 8.34068 24.8366 8.53147C24.8055 8.72227 24.7198 8.89996 24.5898 9.04303C24.4969 9.14585 24.3833 9.2279 24.2565 9.28382C24.1296 9.33974 23.9924 9.36827 23.8538 9.36755Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.81906 17.0488C7.76696 17.0524 7.71468 17.0524 7.66259 17.0488C7.51871 17.0318 7.37971 16.9861 7.2538 16.9144C7.12788 16.8428 7.01762 16.7466 6.92952 16.6316C6.79051 16.4569 6.70709 16.2446 6.69003 16.0221C6.67297 15.7995 6.72307 15.577 6.83384 15.3832C6.94462 15.1894 7.11097 15.0333 7.3114 14.9351C7.51183 14.8369 7.73712 14.801 7.95814 14.8322C8.24047 14.8676 8.4987 15.0093 8.68024 15.2285C8.86177 15.4476 8.95296 15.7276 8.93523 16.0116C8.9175 16.2956 8.79219 16.5622 8.58482 16.7571C8.37745 16.9519 8.10361 17.0604 7.81906 17.0604V17.0488Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.74916 14.2061C8.55967 14.2065 8.3732 14.1586 8.20733 14.067C8.07945 13.9994 7.9664 13.9069 7.87484 13.7949C7.78327 13.683 7.71505 13.5538 7.67419 13.4151C7.6243 13.2475 7.61407 13.0706 7.64431 12.8985C7.67455 12.7263 7.74442 12.5635 7.84841 12.4229C7.95241 12.2824 8.08766 12.168 8.24349 12.0887C8.39932 12.0095 8.57145 11.9676 8.74626 11.9663C9.03342 11.9809 9.304 12.1053 9.50208 12.3137C9.70016 12.5221 9.81061 12.7987 9.81061 13.0862C9.81061 13.3737 9.70016 13.6503 9.50208 13.8587C9.304 14.0671 9.03342 14.1915 8.74626 14.2061H8.74916Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.6441 11.8824C10.4831 11.8819 10.3241 11.8461 10.1785 11.7775C10.0328 11.709 9.90391 11.6093 9.80094 11.4855C9.70585 11.3765 9.63353 11.2496 9.58825 11.1123C9.54297 10.9749 9.52563 10.8299 9.53727 10.6857C9.56116 10.4036 9.68981 10.1407 9.89789 9.94871C10.106 9.75672 10.3784 9.6496 10.6615 9.64844C10.8906 9.64821 11.1143 9.71874 11.3018 9.85038C11.4894 9.98202 11.6317 10.1684 11.7094 10.3839C11.787 10.5995 11.7963 10.8338 11.7358 11.0548C11.6753 11.2758 11.548 11.4728 11.3714 11.6187C11.1674 11.7892 10.91 11.8826 10.6441 11.8824Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.2635 10.4021C13.0778 10.3991 12.8958 10.3499 12.7339 10.259C12.572 10.168 12.4353 10.0382 12.3361 9.88125C12.2369 9.72427 12.1783 9.54506 12.1657 9.3598C12.1531 9.17453 12.1868 8.98904 12.2638 8.82007C12.3574 8.62921 12.5025 8.46836 12.6828 8.3557C12.863 8.24305 13.0712 8.18309 13.2837 8.18262C13.5474 8.18351 13.8021 8.278 14.0026 8.44923C14.2031 8.62046 14.3362 8.85732 14.3783 9.11757C14.4204 9.37783 14.3687 9.64459 14.2325 9.87029C14.0962 10.096 13.8843 10.266 13.6343 10.3499C13.5144 10.3876 13.3891 10.4053 13.2635 10.4021Z",
    fill: "none"
  }), _jsx("path", {
    d: "M16.1957 9.97891C16.0496 9.97606 15.9055 9.94445 15.7716 9.8859C15.6377 9.82735 15.5166 9.74299 15.4153 9.63766C15.2108 9.42492 15.0991 9.13963 15.1048 8.84454C15.1077 8.69842 15.1393 8.5543 15.1978 8.4204C15.2564 8.2865 15.3407 8.16545 15.4461 8.06415C15.5514 7.96285 15.6757 7.88329 15.8118 7.83001C15.9478 7.77673 16.0931 7.75078 16.2392 7.75363H16.2885C16.5843 7.76498 16.8635 7.89314 17.065 8.11002C17.211 8.26949 17.3075 8.46796 17.3428 8.68128C17.3781 8.8946 17.3507 9.11358 17.2638 9.31158C17.1769 9.50959 17.0344 9.67807 16.8535 9.79655C16.6726 9.91502 16.4612 9.97838 16.245 9.97891H16.1957Z",
    fill: "none"
  }), _jsx("path", {
    d: "M19.1831 10.6394C19.0275 10.6439 18.8726 10.6163 18.7282 10.5583C18.4928 10.4575 18.2994 10.2787 18.1806 10.052C18.0618 9.82522 18.0248 9.56444 18.0758 9.31358C18.1269 9.06273 18.2629 8.83715 18.4609 8.67489C18.6589 8.51263 18.9068 8.42362 19.1628 8.42285C19.3194 8.42285 19.4743 8.45542 19.6177 8.51847C19.885 8.63082 20.098 8.84282 20.2117 9.10956C20.278 9.27797 20.3023 9.45998 20.2825 9.63988C20.2628 9.81979 20.1996 9.99218 20.0983 10.1422C19.9971 10.2922 19.8608 10.4153 19.7014 10.501C19.5419 10.5866 19.364 10.6321 19.1831 10.6336V10.6394Z",
    fill: "none"
  }), _jsx("path", {
    d: "M19.9596 25.2544C19.7133 25.2546 19.474 25.1731 19.2791 25.0227C19.0841 24.8723 18.9445 24.6615 18.8823 24.4233C18.82 24.185 18.8385 23.9329 18.9348 23.7063C19.0312 23.4797 19.2 23.2915 19.4149 23.1711C19.5857 23.0721 19.7794 23.0191 19.977 23.0176C20.1461 23.0199 20.3125 23.0605 20.4637 23.1363C20.6149 23.2121 20.747 23.3211 20.8501 23.4551C20.9532 23.5892 21.0247 23.7448 21.0591 23.9104C21.0936 24.076 21.0901 24.2472 21.049 24.4113C20.9837 24.6512 20.8415 24.863 20.6441 25.0143C20.4468 25.1655 20.2053 25.2479 19.9567 25.2487L19.9596 25.2544Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.1287 26.2369C16.8476 26.2367 16.577 26.13 16.3713 25.9385C16.1655 25.7469 16.04 25.4845 16.0198 25.2041C15.9996 24.9237 16.0863 24.6461 16.2625 24.427C16.4386 24.2079 16.6912 24.0636 16.9694 24.0232C17.0263 24.019 17.0834 24.019 17.1403 24.0232C17.4067 24.0212 17.6647 24.1158 17.8666 24.2895C18.0685 24.4631 18.2006 24.7041 18.2385 24.9678C18.2625 25.1105 18.2577 25.2566 18.2244 25.3974C18.191 25.5382 18.1298 25.671 18.0443 25.7878C17.9562 25.9089 17.8446 26.011 17.7161 26.0882C17.5877 26.1653 17.4451 26.2159 17.2968 26.2369C17.2408 26.241 17.1847 26.241 17.1287 26.2369Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.1414 26.1325C14.0566 26.1321 13.972 26.1224 13.8893 26.1035C13.6219 26.0446 13.3856 25.8891 13.2257 25.6668C13.0657 25.4446 12.9933 25.1711 13.0224 24.8989C13.0515 24.6266 13.1799 24.3746 13.3832 24.1911C13.5864 24.0076 13.8502 23.9055 14.124 23.9043C14.2098 23.9045 14.2953 23.9142 14.379 23.9333C14.6467 23.9923 14.8831 24.148 15.0431 24.3705C15.203 24.5931 15.2752 24.8669 15.2457 25.1393C15.2163 25.4118 15.0873 25.6639 14.8835 25.8471C14.6797 26.0304 14.4155 26.132 14.1414 26.1325Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.3772 24.9471C11.1533 24.9471 10.9344 24.8805 10.7484 24.7559C10.5988 24.6533 10.4766 24.5158 10.3923 24.3552C10.3081 24.1946 10.2643 24.0158 10.265 23.8344C10.2656 23.6531 10.3105 23.4746 10.3959 23.3146C10.4812 23.1546 10.6044 23.0178 10.7547 22.9163C10.9049 22.8148 11.0778 22.7515 11.2581 22.732C11.4384 22.7125 11.6207 22.7374 11.7892 22.8045C11.9577 22.8716 12.1073 22.9789 12.2248 23.117C12.3424 23.2551 12.4244 23.4198 12.4637 23.5969C12.4956 23.7583 12.4916 23.9248 12.452 24.0845C12.4123 24.2442 12.338 24.3932 12.2343 24.521C12.1306 24.6487 12.0001 24.7521 11.8519 24.8237C11.7038 24.8954 11.5417 24.9336 11.3772 24.9355V24.9471Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.22432 22.8526C8.93716 22.838 8.66659 22.7136 8.46851 22.5052C8.27042 22.2968 8.15997 22.0202 8.15997 21.7327C8.15997 21.4451 8.27042 21.1686 8.46851 20.9602C8.66659 20.7518 8.93716 20.6274 9.22432 20.6128C9.45995 20.6156 9.68872 20.6925 9.87814 20.8327C10.0676 20.9729 10.208 21.1692 10.2795 21.3937C10.3511 21.6182 10.35 21.8596 10.2766 22.0835C10.2032 22.3074 10.0611 22.5025 9.87046 22.641C9.68335 22.779 9.45682 22.8532 9.22432 22.8526Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.96393 20.1202C7.76685 20.1173 7.57398 20.0627 7.40467 19.9617C7.23537 19.8608 7.09557 19.7172 6.9993 19.5452C6.90303 19.3732 6.85367 19.1789 6.85617 18.9818C6.85867 18.7847 6.91295 18.5917 7.01355 18.4222C7.08805 18.2965 7.1869 18.1868 7.30432 18.0997C7.42175 18.0127 7.55537 17.9499 7.69736 17.9152C7.7921 17.8914 7.88945 17.8797 7.98711 17.8804C8.28412 17.8804 8.56897 17.9984 8.77899 18.2084C8.98901 18.4184 9.107 18.7033 9.107 19.0003C9.107 19.2973 8.98901 19.5821 8.77899 19.7922C8.56897 20.0022 8.28412 20.1202 7.98711 20.1202H7.96393Z",
    fill: "none"
  }), _jsx("path", {
    d: "M1.11137 14.4005C1.04309 14.4006 0.975035 14.3928 0.908544 14.3773C0.800667 14.352 0.698823 14.3058 0.608835 14.2411C0.518847 14.1765 0.442479 14.0948 0.384097 14.0006C0.30614 13.8799 0.259309 13.7418 0.247793 13.5986C0.236276 13.4554 0.260434 13.3116 0.318104 13.18C0.375775 13.0484 0.46516 12.9332 0.578266 12.8446C0.691371 12.756 0.824669 12.6968 0.966237 12.6724C1.10781 12.6479 1.25323 12.6589 1.3895 12.7044C1.52578 12.7499 1.64865 12.8285 1.74713 12.9331C1.84561 13.0377 1.91663 13.165 1.95384 13.3038C1.99105 13.4426 1.99328 13.5884 1.96034 13.7282C1.91575 13.9198 1.80752 14.0906 1.65332 14.2127C1.49911 14.3348 1.30806 14.401 1.11137 14.4005Z",
    fill: "none"
  }), _jsx("path", {
    d: "M2.16312 11.3898C2.03383 11.39 1.90613 11.3612 1.78934 11.3058C1.6866 11.2595 1.59417 11.1931 1.51753 11.1105C1.44089 11.0279 1.38159 10.9307 1.34312 10.8248C1.29272 10.6928 1.27526 10.5505 1.29226 10.4102C1.30927 10.2699 1.36022 10.1359 1.4407 10.0197C1.52118 9.90354 1.62877 9.80876 1.75415 9.74356C1.87952 9.67836 2.01891 9.64471 2.16022 9.64552C2.28755 9.64624 2.41318 9.67493 2.5282 9.72955C2.71123 9.81108 2.86047 9.95334 2.95067 10.1322C3.04088 10.3112 3.0665 10.5157 3.02322 10.7114C2.97993 10.907 2.8704 11.0817 2.71314 11.2058C2.55588 11.33 2.36056 11.396 2.16022 11.3927L2.16312 11.3898Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.98493 6.36848C5.86195 6.36905 5.74024 6.34352 5.62786 6.29357C5.51548 6.24362 5.41497 6.1704 5.33299 6.07873C5.25763 5.99495 5.19982 5.89691 5.163 5.79041C5.12617 5.68391 5.11106 5.5711 5.11857 5.45867C5.13117 5.23677 5.22828 5.02813 5.38995 4.87562C5.55162 4.72311 5.76557 4.63832 5.98782 4.63867C6.16436 4.64051 6.33616 4.69606 6.48035 4.79793C6.62454 4.8998 6.7343 5.04317 6.79501 5.20895C6.85573 5.37473 6.86452 5.55507 6.82022 5.72597C6.77593 5.89688 6.68064 6.05024 6.54704 6.16566C6.38953 6.29757 6.19038 6.36943 5.98493 6.36848Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.61587 4.54319C8.48247 4.54224 8.35107 4.5106 8.23186 4.45071C8.11265 4.39083 8.00882 4.3043 7.92841 4.19785C7.84801 4.09139 7.79319 3.96785 7.7682 3.83681C7.7432 3.70576 7.74871 3.57072 7.78429 3.44214C7.81566 3.32991 7.86928 3.22514 7.94195 3.13405C8.01462 3.04296 8.10487 2.96741 8.20733 2.9119C8.33607 2.84125 8.48061 2.80436 8.62746 2.80469C8.81985 2.80617 9.00632 2.87144 9.15763 2.99027C9.30895 3.1091 9.41656 3.27477 9.46361 3.46133C9.51067 3.64788 9.4945 3.84478 9.41764 4.02116C9.34078 4.19754 9.20758 4.34343 9.03891 4.43598C8.90899 4.50629 8.7636 4.54314 8.61587 4.54319Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.6746 2.73509C14.4565 2.73591 14.2461 2.65474 14.0851 2.50768C13.9241 2.36062 13.8242 2.15842 13.8053 1.94117C13.7939 1.82804 13.8054 1.71376 13.8393 1.60521C13.8731 1.49666 13.9286 1.39608 14.0023 1.30952C14.077 1.21925 14.1691 1.14499 14.2732 1.09122C14.3772 1.03744 14.4911 1.00525 14.6079 0.996588H14.6804C14.8984 0.995768 15.1088 1.07694 15.2698 1.224C15.4308 1.37106 15.5307 1.57326 15.5496 1.7905C15.5599 1.90542 15.5472 2.02123 15.5123 2.13119C15.4774 2.24115 15.421 2.34307 15.3462 2.43099C15.2715 2.51891 15.1801 2.59108 15.0772 2.64329C14.9743 2.6955 14.8621 2.7267 14.747 2.73509H14.6746Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20.9563 3.53493C20.8575 3.53371 20.7596 3.51608 20.6666 3.48278C20.4492 3.40853 20.27 3.25122 20.1682 3.04525C20.1043 2.91238 20.0753 2.76545 20.0838 2.61826C20.0923 2.47107 20.1382 2.32848 20.217 2.20388C20.2958 2.07928 20.405 1.97678 20.5343 1.90601C20.6637 1.83524 20.8089 1.79853 20.9563 1.79933C21.055 1.79957 21.1529 1.81621 21.2461 1.84859C21.464 1.92352 21.6433 2.08194 21.7444 2.28901C21.8083 2.42188 21.8374 2.56882 21.8288 2.716C21.8203 2.86319 21.7745 3.00578 21.6957 3.13038C21.6168 3.25498 21.5076 3.35748 21.3783 3.42825C21.249 3.49902 21.1037 3.53573 20.9563 3.53493Z",
    fill: "none"
  }), _jsx("path", {
    d: "M28.1653 12.2564C27.9405 12.2522 27.7257 12.1628 27.5643 12.0063C27.4029 11.8498 27.3071 11.6378 27.2961 11.4132C27.2914 11.2989 27.3097 11.1849 27.35 11.0778C27.3904 10.9708 27.4518 10.873 27.5308 10.7902C27.609 10.7001 27.7055 10.6276 27.8139 10.5776C27.9223 10.5276 28.0402 10.5014 28.1595 10.5005C28.3861 10.5004 28.6038 10.5888 28.7661 10.7468C28.9285 10.9049 29.0227 11.1201 29.0288 11.3466C29.0327 11.4631 29.0131 11.5793 28.9711 11.6881C28.9292 11.7969 28.8658 11.8962 28.7847 11.98C28.7035 12.0638 28.6064 12.1305 28.499 12.176C28.3916 12.2214 28.2762 12.2448 28.1595 12.2448L28.1653 12.2564Z",
    fill: "none"
  }), _jsx("path", {
    d: "M31.6771 9.62844C31.4523 9.6243 31.2375 9.53492 31.0761 9.37838C30.9147 9.22184 30.8188 9.00983 30.8078 8.78527C30.8031 8.671 30.8215 8.55694 30.8618 8.44991C30.9021 8.34289 30.9636 8.24507 31.0425 8.16231C31.1208 8.07212 31.2173 7.99963 31.3257 7.94967C31.4341 7.89971 31.5519 7.87342 31.6713 7.87256C31.8979 7.87248 32.1155 7.96087 32.2779 8.11891C32.4403 8.27694 32.5345 8.49213 32.5406 8.71863C32.5444 8.83519 32.5248 8.95135 32.4829 9.06018C32.4409 9.16901 32.3775 9.26829 32.2964 9.3521C32.2153 9.43591 32.1182 9.50254 32.0108 9.54803C31.9034 9.59351 31.7879 9.61692 31.6713 9.61685L31.6771 9.62844Z",
    fill: "none"
  }), _jsx("path", {
    d: "M28.0262 27.1203C27.8638 27.1211 27.7044 27.0764 27.5661 26.9912C27.4278 26.9061 27.3161 26.7839 27.2437 26.6385C27.1713 26.4931 27.1411 26.3303 27.1565 26.1686C27.1719 26.0069 27.2323 25.8528 27.3308 25.7237C27.4115 25.6161 27.516 25.5287 27.6362 25.4684C27.7564 25.4081 27.8889 25.3764 28.0233 25.376C28.2121 25.3776 28.3951 25.4407 28.5449 25.5556C28.6356 25.6222 28.712 25.7062 28.7697 25.8027C28.8275 25.8992 28.8653 26.0064 28.881 26.1177C28.9004 26.2425 28.8923 26.3701 28.8574 26.4914C28.8225 26.6128 28.7615 26.7251 28.6788 26.8205C28.596 26.9159 28.4935 26.9922 28.3783 27.044C28.2631 27.0957 28.138 27.1218 28.0117 27.1203H28.0262Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.2309 31.2492C23.0394 31.2466 22.8542 31.1809 22.704 31.0622C22.5537 30.9436 22.4469 30.7786 22.4 30.593C22.3531 30.4074 22.3688 30.2115 22.4446 30.0357C22.5205 29.8599 22.6522 29.7141 22.8194 29.6208C22.9499 29.5489 23.0964 29.5111 23.2454 29.5107C23.3788 29.5117 23.5102 29.5433 23.6294 29.6032C23.7486 29.6631 23.8524 29.7496 23.9328 29.8561C24.0132 29.9625 24.0681 30.0861 24.093 30.2171C24.118 30.3482 24.1125 30.4832 24.077 30.6118C24.0442 30.7219 23.99 30.8244 23.9174 30.9135C23.8447 31.0025 23.7552 31.0762 23.6539 31.1304C23.5254 31.2056 23.3797 31.2465 23.2309 31.2492Z",
    fill: "none"
  }), _jsx("path", {
    d: "M33.9951 26.1614C33.8037 26.1587 33.6184 26.093 33.4682 25.9743C33.318 25.8557 33.2111 25.6907 33.1642 25.5051C33.1173 25.3195 33.133 25.1236 33.2088 24.9478C33.2847 24.772 33.4164 24.6262 33.5837 24.533C33.7141 24.4611 33.8606 24.4232 34.0096 24.4229C34.143 24.4238 34.2744 24.4554 34.3936 24.5153C34.5128 24.5752 34.6166 24.6617 34.697 24.7682C34.7774 24.8747 34.8323 24.9982 34.8573 25.1292C34.8823 25.2603 34.8767 25.3953 34.8412 25.5239C34.8098 25.6361 34.7562 25.7409 34.6835 25.832C34.6108 25.9231 34.5206 25.9986 34.4181 26.0541C34.2885 26.1253 34.1429 26.1622 33.9951 26.1614Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.985 32.9964H13.872C13.7586 32.9826 13.6491 32.9465 13.5496 32.8904C13.4502 32.8342 13.3628 32.759 13.2925 32.669C13.193 32.5409 13.1313 32.3875 13.1145 32.2262C13.0976 32.0649 13.1262 31.9021 13.197 31.7562C13.2679 31.6103 13.3781 31.4871 13.5153 31.4006C13.6525 31.3141 13.8112 31.2677 13.9734 31.2666H14.0893C14.3178 31.2938 14.5262 31.4105 14.6688 31.5911C14.7692 31.7194 14.8316 31.8733 14.8489 32.0354C14.8661 32.1974 14.8374 32.361 14.7661 32.5075C14.6948 32.654 14.5838 32.7775 14.4456 32.864C14.3075 32.9504 14.1479 32.9963 13.985 32.9964Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.49236 28.9808C5.26578 28.9809 5.04812 28.8925 4.88576 28.7345C4.72339 28.5764 4.62915 28.3612 4.62311 28.1347C4.6188 28.021 4.63735 27.9074 4.67766 27.8009C4.71797 27.6944 4.77923 27.5971 4.85781 27.5147C4.97592 27.3872 5.12952 27.2981 5.29878 27.2588C5.46804 27.2196 5.64519 27.2319 5.80737 27.2943C5.96955 27.3566 6.10931 27.4662 6.20862 27.6088C6.30793 27.7514 6.36223 27.9204 6.36451 28.0942C6.36823 28.2083 6.34943 28.3221 6.30916 28.429C6.26888 28.5359 6.20794 28.6338 6.12981 28.7171C6.04826 28.8037 5.94928 28.872 5.83939 28.9174C5.7295 28.9629 5.61122 28.9845 5.49236 28.9808Z",
    fill: "none"
  }), _jsx("path", {
    d: "M0.963572 20.7374C0.733032 20.7581 0.503693 20.6864 0.326005 20.5381C0.148318 20.3897 0.0368376 20.1769 0.016089 19.9463C-0.00465954 19.7158 0.0670232 19.4865 0.215368 19.3088C0.363712 19.1311 0.576567 19.0196 0.807107 18.9989C0.859079 18.9934 0.911431 18.9924 0.963572 18.996C1.07772 18.9853 1.19286 18.9972 1.3024 19.0311C1.41193 19.0649 1.51374 19.12 1.60199 19.1932C1.69024 19.2664 1.76321 19.3562 1.81674 19.4576C1.87026 19.559 1.9033 19.6699 1.91395 19.7841C1.92461 19.8982 1.91267 20.0134 1.87883 20.1229C1.84499 20.2324 1.78991 20.3343 1.71672 20.4225C1.64354 20.5108 1.55369 20.5837 1.4523 20.6373C1.35092 20.6908 1.23998 20.7238 1.12583 20.7345C1.07196 20.7406 1.01763 20.7416 0.963572 20.7374Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.15081 17.3124C4.02226 17.3072 3.89605 17.2763 3.77961 17.2215C3.66317 17.1668 3.55885 17.0893 3.4728 16.9937C3.34405 16.8509 3.25921 16.674 3.22845 16.4842C3.19769 16.2944 3.22232 16.0997 3.29939 15.9236C3.37645 15.7474 3.50269 15.5973 3.66295 15.491C3.82321 15.3848 4.01071 15.3271 4.20297 15.3247H4.26382C4.39468 15.3309 4.52297 15.3633 4.64114 15.4198C4.75931 15.4764 4.86498 15.556 4.95192 15.654C5.03885 15.752 5.10531 15.8664 5.14736 15.9905C5.18942 16.1146 5.20623 16.2458 5.19681 16.3765C5.18559 16.6291 5.07708 16.8676 4.89399 17.0421C4.71091 17.2165 4.46744 17.3134 4.21456 17.3124H4.15081Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.75932 14.4238C4.65111 14.4246 4.54348 14.408 4.44059 14.3745C4.31693 14.3376 4.20194 14.2763 4.1024 14.1942C4.00286 14.1121 3.92081 14.0108 3.86109 13.8964C3.75993 13.7053 3.72406 13.4863 3.75896 13.2729C3.79386 13.0595 3.89761 12.8634 4.05438 12.7144C4.21115 12.5655 4.41234 12.4719 4.62725 12.448C4.84217 12.4241 5.05901 12.4711 5.2447 12.5819C5.4304 12.6927 5.57475 12.8612 5.65574 13.0617C5.73673 13.2622 5.74991 13.4837 5.69327 13.6924C5.63663 13.9011 5.51328 14.0855 5.34204 14.2176C5.1708 14.3496 4.96106 14.422 4.74483 14.4238H4.75932Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.99943 11.7635C5.81236 11.7625 5.62944 11.7082 5.47208 11.6071C5.35987 11.5417 5.262 11.4544 5.18433 11.3504C5.10666 11.2463 5.05079 11.1276 5.02007 11.0015C4.98674 10.8551 4.98664 10.703 5.01976 10.5565C5.05289 10.4101 5.11842 10.2729 5.21152 10.155C5.30462 10.0372 5.42294 9.9417 5.55778 9.87558C5.69262 9.80947 5.84056 9.7744 5.99074 9.77295C6.25509 9.77295 6.50861 9.87796 6.69554 10.0649C6.88246 10.2518 6.98748 10.5053 6.98748 10.7697C6.98748 11.034 6.88246 11.2876 6.69554 11.4745C6.50861 11.6614 6.25509 11.7664 5.99074 11.7664L5.99943 11.7635Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.86833 9.48912C7.60372 9.48606 7.35111 9.3782 7.16589 9.18919C6.98068 9.00017 6.87797 8.74542 6.88029 8.48079C6.88706 8.21891 6.99548 7.96995 7.18261 7.78661C7.36973 7.60327 7.62086 7.49996 7.88282 7.49854C8.01704 7.49809 8.14991 7.5254 8.27307 7.57876C8.39623 7.63211 8.50703 7.71035 8.5985 7.80857C8.68921 7.90293 8.7602 8.01442 8.80734 8.13652C8.85448 8.25862 8.87681 8.3889 8.87303 8.51973C8.86925 8.65056 8.83945 8.77932 8.78534 8.89851C8.73124 9.01769 8.65394 9.12489 8.55794 9.21386C8.37194 9.39075 8.12502 9.48931 7.86833 9.48912Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.2356 7.75362C10.0841 7.75162 9.93502 7.7151 9.79974 7.64684C9.66446 7.57859 9.54653 7.48038 9.4549 7.35971C9.36328 7.23903 9.30037 7.09905 9.27096 6.95041C9.24156 6.80177 9.24643 6.64838 9.2852 6.5019C9.33912 6.31414 9.44703 6.14636 9.59551 6.01941C9.74399 5.89247 9.92651 5.81195 10.1204 5.78787C10.3142 5.7638 10.5109 5.79722 10.6859 5.88398C10.8609 5.97074 11.0066 6.10703 11.1048 6.2759C11.1691 6.38978 11.2101 6.51533 11.2253 6.64521C11.2406 6.77509 11.2298 6.90671 11.1937 7.03239C11.1576 7.15807 11.0968 7.2753 11.0148 7.37724C10.9329 7.47918 10.8315 7.56379 10.7166 7.62613C10.5693 7.70791 10.404 7.75173 10.2356 7.75362Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.9795 6.66109C12.8054 6.66186 12.6341 6.61688 12.4829 6.53064C12.3316 6.44439 12.2056 6.31992 12.1176 6.16969C12.0296 6.01946 11.9826 5.84873 11.9813 5.67461C11.98 5.50049 12.0245 5.32909 12.1103 5.17757C12.1772 5.06231 12.2665 4.96163 12.373 4.8815C12.4796 4.80138 12.601 4.74342 12.7303 4.71107C12.8127 4.68995 12.8974 4.67924 12.9824 4.6792C13.2227 4.6813 13.4541 4.77066 13.6334 4.93063C13.8128 5.09061 13.9278 5.3103 13.9573 5.54881C13.9867 5.78732 13.9284 6.0284 13.7933 6.22715C13.6582 6.4259 13.4555 6.56879 13.2229 6.62922C13.1433 6.64949 13.0616 6.66019 12.9795 6.66109Z",
    fill: "none"
  }), _jsx("path", {
    d: "M15.9176 6.29041C15.7873 6.29234 15.6579 6.26824 15.537 6.21953C15.4161 6.17081 15.3062 6.09846 15.2136 6.00671C15.1211 5.91496 15.0477 5.80565 14.998 5.68521C14.9482 5.56476 14.9229 5.43559 14.9237 5.30526C14.9228 5.17582 14.9479 5.04751 14.9977 4.92802C15.0475 4.80853 15.1209 4.70031 15.2135 4.60986C15.3529 4.47776 15.5275 4.38863 15.7163 4.35315C15.9051 4.31766 16.1001 4.33731 16.278 4.40976C16.4559 4.4822 16.6091 4.60437 16.7194 4.76164C16.8297 4.91891 16.8923 5.10463 16.8998 5.29657C16.901 5.42643 16.8764 5.55523 16.8276 5.67557C16.7788 5.79591 16.7067 5.90541 16.6154 5.99777C16.5241 6.09014 16.4155 6.16354 16.2957 6.21376C16.1759 6.26399 16.0474 6.29003 15.9176 6.29041Z",
    fill: "none"
  }), _jsx("path", {
    d: "M18.8382 6.65816C18.7523 6.6583 18.6666 6.64759 18.5833 6.62629C18.3509 6.56595 18.1483 6.4233 18.0132 6.22484C17.8781 6.02638 17.8196 5.78562 17.8486 5.5473C17.8777 5.30897 17.9923 5.08929 18.1711 4.92909C18.3499 4.76888 18.5808 4.67904 18.8209 4.67627C18.9059 4.67625 18.9906 4.68696 19.0729 4.70814C19.2008 4.73925 19.3212 4.79551 19.4271 4.87365C19.533 4.9518 19.6223 5.05025 19.6897 5.16327C19.7572 5.27629 19.8014 5.4016 19.8199 5.53191C19.8383 5.66221 19.8307 5.79489 19.7973 5.92219C19.7433 6.13475 19.6194 6.32294 19.4454 6.45644C19.2714 6.58995 19.0575 6.66101 18.8382 6.65816Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.5793 7.74508C21.4099 7.74379 21.2435 7.69995 21.0954 7.61758C20.8664 7.49441 20.6947 7.28636 20.6173 7.03808C20.5744 6.89035 20.5662 6.73468 20.5935 6.58327C20.6207 6.43186 20.6827 6.28881 20.7744 6.16533C20.8662 6.04185 20.9853 5.94129 21.1224 5.87152C21.2595 5.80175 21.411 5.76467 21.5648 5.76318C21.7349 5.7631 21.9024 5.80595 22.0516 5.88778C22.2441 5.99317 22.3963 6.15954 22.4841 6.36075C22.5719 6.56197 22.5904 6.78664 22.5366 6.9995C22.4829 7.21236 22.3601 7.40136 22.1873 7.53683C22.0146 7.6723 21.8017 7.74656 21.5822 7.74797L21.5793 7.74508Z",
    fill: "none"
  }), _jsx("path", {
    d: "M24.9433 25.515C24.6886 25.5013 24.4489 25.3904 24.2734 25.2053C24.098 25.0201 24.0002 24.7748 24.0002 24.5197C24.0002 24.2647 24.098 24.0193 24.2734 23.8342C24.4489 23.649 24.6886 23.5382 24.9433 23.5244C25.1875 23.5277 25.4222 23.6198 25.6035 23.7835C25.7848 23.9472 25.9003 24.1713 25.9284 24.4139C25.9421 24.5531 25.9267 24.6935 25.883 24.8263C25.8394 24.9591 25.7686 25.0813 25.6751 25.1852C25.5815 25.2891 25.4674 25.3723 25.3399 25.4296C25.2124 25.4869 25.0743 25.517 24.9346 25.5179L24.9433 25.515Z",
    fill: "none"
  }), _jsx("path", {
    d: "M22.7875 27.5292C22.632 27.5302 22.4784 27.4938 22.3398 27.4232C22.2012 27.3527 22.0815 27.2499 21.9907 27.1235C21.9127 27.0183 21.8565 26.8986 21.8255 26.7713C21.7944 26.6441 21.7891 26.5119 21.8099 26.3826C21.8307 26.2533 21.8772 26.1294 21.9465 26.0183C22.0159 25.9072 22.1068 25.8111 22.2138 25.7356C22.382 25.612 22.5846 25.5441 22.7933 25.5415C22.9351 25.543 23.0749 25.5747 23.2034 25.6345C23.3319 25.6944 23.4462 25.7809 23.5386 25.8884C23.6311 25.9959 23.6995 26.1219 23.7393 26.2579C23.7792 26.394 23.7896 26.5369 23.7698 26.6773C23.7326 26.9138 23.6125 27.1294 23.431 27.2855C23.2494 27.4416 23.0183 27.528 22.7788 27.5292H22.7875Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20.2117 28.9399C19.9818 28.938 19.7597 28.8562 19.5835 28.7085C19.4072 28.5609 19.2878 28.3565 19.2457 28.1305C19.2036 27.9045 19.2414 27.6709 19.3526 27.4696C19.4638 27.2684 19.6415 27.1121 19.8553 27.0275C19.9742 26.9799 20.101 26.9553 20.2291 26.9551C20.4267 26.9548 20.6197 27.0145 20.7827 27.1262C20.9456 27.238 21.0708 27.3965 21.1418 27.5809C21.1919 27.6997 21.2177 27.8273 21.2177 27.9562C21.2177 28.0851 21.1919 28.2126 21.1418 28.3314C21.0638 28.5127 20.9344 28.6671 20.7696 28.7758C20.6049 28.8844 20.4119 28.9424 20.2146 28.9428L20.2117 28.9399Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.3518 29.6671C17.1016 29.6635 16.8619 29.5653 16.6811 29.3923C16.5002 29.2193 16.3916 28.9842 16.3769 28.7344C16.3622 28.4845 16.4426 28.2384 16.602 28.0454C16.7613 27.8524 16.9878 27.7268 17.2359 27.6939H17.3634C17.6058 27.691 17.8407 27.7784 18.0222 27.9391C18.2038 28.0997 18.3191 28.3222 18.3457 28.5632C18.3637 28.6906 18.3561 28.8203 18.3232 28.9447C18.2904 29.0691 18.233 29.1857 18.1544 29.2875C18.0721 29.3911 17.9701 29.4772 17.8542 29.5408C17.7383 29.6045 17.6108 29.6445 17.4793 29.6584L17.3518 29.6671Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.4312 29.6585H14.3008C14.0398 29.6262 13.8022 29.4918 13.6402 29.2847C13.5264 29.1385 13.4559 28.9632 13.4367 28.7789C13.4175 28.5947 13.4503 28.4087 13.5315 28.2421C13.6126 28.0755 13.7389 27.9351 13.8959 27.8366C14.0529 27.7382 14.2343 27.6858 14.4196 27.6853C14.464 27.6824 14.5085 27.6824 14.5529 27.6853C14.8133 27.7171 15.0507 27.8504 15.2135 28.0562C15.3271 28.2029 15.3974 28.3785 15.4163 28.5631C15.4353 28.7477 15.4022 28.9339 15.3208 29.1007C15.2394 29.2674 15.1129 29.4081 14.9557 29.5067C14.7984 29.6052 14.6167 29.6578 14.4312 29.6585Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.5742 28.9198C11.4452 28.92 11.3173 28.8954 11.1975 28.8474C10.9837 28.7628 10.806 28.6065 10.6948 28.4053C10.5836 28.2041 10.5458 27.9704 10.5879 27.7444C10.6301 27.5184 10.7495 27.3141 10.9257 27.1664C11.1019 27.0187 11.324 26.937 11.5539 26.9351C11.6829 26.935 11.8108 26.9596 11.9306 27.0075C12.1753 27.1031 12.3722 27.2916 12.4782 27.5319C12.5433 27.6827 12.5701 27.8473 12.5561 28.0109C12.542 28.1746 12.4877 28.3322 12.3978 28.4697C12.308 28.6072 12.1855 28.7202 12.0412 28.7988C11.897 28.8774 11.7355 28.9189 11.5713 28.9198H11.5742Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.00702 27.4973C8.7934 27.4976 8.58529 27.4296 8.41303 27.3032C8.29405 27.2331 8.19103 27.1389 8.11058 27.0267C8.03014 26.9144 7.97405 26.7866 7.9459 26.6514C7.91776 26.5162 7.91819 26.3766 7.94716 26.2416C7.97613 26.1065 8.03301 25.979 8.11414 25.8673C8.19527 25.7555 8.29886 25.662 8.41828 25.5926C8.53769 25.5232 8.67029 25.4796 8.80756 25.4645C8.94483 25.4493 9.08374 25.4631 9.21539 25.5048C9.34704 25.5465 9.46852 25.6153 9.57203 25.7067C9.67698 25.7814 9.76617 25.876 9.8345 25.9852C9.90282 26.0944 9.94893 26.216 9.9702 26.343C9.99146 26.4701 9.98745 26.6 9.95841 26.7255C9.92936 26.851 9.87585 26.9695 9.80093 27.0743C9.7131 27.2042 9.59484 27.3106 9.45647 27.3844C9.3181 27.4581 9.16381 27.4969 9.00702 27.4973Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6.85996 25.4773C6.69148 25.4783 6.52551 25.4365 6.37755 25.3559C6.2296 25.2753 6.1045 25.1585 6.01393 25.0164C5.92336 24.8744 5.87028 24.7116 5.85965 24.5435C5.84902 24.3753 5.88119 24.2072 5.95316 24.0549C6.02512 23.9026 6.13453 23.7709 6.27115 23.6724C6.40778 23.5738 6.56717 23.5114 6.73443 23.4911C6.90169 23.4709 7.07136 23.4933 7.22759 23.5564C7.38382 23.6195 7.52151 23.7211 7.6278 23.8519C7.7471 23.9968 7.82293 24.1725 7.84651 24.3587C7.87008 24.5449 7.84044 24.734 7.76102 24.9041C7.68159 25.0742 7.55562 25.2183 7.3977 25.3197C7.23978 25.4212 7.05635 25.4758 6.86865 25.4773H6.85996Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.28086 22.9886C5.01651 22.9886 4.76298 22.8836 4.57606 22.6967C4.38913 22.5097 4.28412 22.2562 4.28412 21.9919C4.28412 21.7275 4.38913 21.474 4.57606 21.2871C4.76298 21.1001 5.01651 20.9951 5.28086 20.9951C5.46991 20.9942 5.65522 21.0478 5.81455 21.1496C5.97388 21.2513 6.10045 21.3969 6.17908 21.5688C6.29189 21.8057 6.30647 22.0775 6.21965 22.3251C6.17614 22.4483 6.10832 22.5616 6.02023 22.6582C5.93213 22.7547 5.82555 22.8326 5.70679 22.8872C5.5745 22.9533 5.42874 22.988 5.28086 22.9886Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.37682 20.1752C4.19737 20.171 4.02234 20.1187 3.8701 20.0236C3.71786 19.9285 3.59404 19.7941 3.51164 19.6347C3.42924 19.4752 3.3913 19.2965 3.4018 19.1173C3.4123 18.9381 3.47085 18.7651 3.57132 18.6163C3.64401 18.5083 3.73763 18.416 3.84662 18.3448C3.95561 18.2737 4.07777 18.2251 4.20587 18.202C4.26798 18.1904 4.33103 18.1846 4.39421 18.1846C4.65818 18.1846 4.91133 18.2894 5.09798 18.4761C5.28464 18.6627 5.3895 18.9159 5.3895 19.1799C5.3895 19.4438 5.28464 19.697 5.09798 19.8836C4.91133 20.0703 4.65818 20.1752 4.39421 20.1752H4.37682Z",
    fill: "none"
  }), _jsx("path", {
    d: "M57.3055 30.0439H59.9132V30.4843H56.8071V25.6021H59.835V26.0425H57.3026V27.7201H59.6699V28.1605H57.3026L57.3055 30.0439Z",
    fill: "none"
  }), _jsx("path", {
    d: "M65.0447 29.76V25.6021H65.5402V30.4843H64.9201L62.1008 26.283V30.4843H61.6053V25.6021H62.2254L65.0447 29.76Z",
    fill: "none"
  }), _jsx("path", {
    d: "M68.0378 30.0439H70.6456V30.4843H67.5394V25.6021H70.5673V26.0425H68.0349V27.7201H70.4022V28.1605H68.0349L68.0378 30.0439Z",
    fill: "none"
  }), _jsx("path", {
    d: "M72.8216 30.4844H72.3261V25.6021H73.7749C73.9989 25.6008 74.2224 25.6242 74.4413 25.6716C74.6307 25.7112 74.8108 25.7869 74.9715 25.8947C75.12 25.9971 75.2397 26.1357 75.3192 26.2975C75.4071 26.487 75.4498 26.6943 75.4438 26.9031C75.4458 27.0647 75.4149 27.2251 75.3532 27.3745C75.2914 27.5239 75.2 27.6592 75.0845 27.7723C74.9707 27.8824 74.837 27.97 74.6905 28.0302C74.5325 28.0964 74.3655 28.1384 74.195 28.1548L75.6235 30.4728H75.0179L73.6648 28.1896H72.8216V30.4844ZM72.8216 27.7752H73.6908C74.0136 27.7947 74.3349 27.7191 74.6151 27.5579C74.7213 27.4869 74.8072 27.3894 74.8641 27.2751C74.921 27.1607 74.9471 27.0335 74.9397 26.906C74.9438 26.7654 74.9129 26.626 74.8498 26.5003C74.7909 26.3909 74.7051 26.2982 74.6007 26.2309C74.4825 26.1575 74.3519 26.1064 74.2153 26.0802C74.0494 26.0463 73.8804 26.0298 73.7111 26.0309H72.8419L72.8216 27.7752Z",
    fill: "none"
  }), _jsx("path", {
    d: "M80.839 26.4946C80.6683 26.315 80.462 26.1729 80.2334 26.0774C79.9766 25.9744 79.7016 25.9241 79.425 25.9296C79.1339 25.9246 78.8452 25.9829 78.5789 26.1005C78.3366 26.2093 78.1196 26.3671 77.9415 26.5641C77.7651 26.7623 77.6284 26.9925 77.5387 27.2422C77.3505 27.7676 77.3505 28.3422 77.5387 28.8677C77.6277 29.1168 77.7645 29.3461 77.9415 29.5428C78.1201 29.7384 78.3371 29.8952 78.5789 30.0035C78.845 30.1221 79.1337 30.1814 79.425 30.1773C79.6808 30.1797 79.9359 30.1495 80.1841 30.0875C80.3814 30.0411 80.5701 29.9638 80.7434 29.8586V28.3664H79.6046V27.926H81.2388V30.131C80.9686 30.2934 80.6756 30.4145 80.3696 30.4903C80.0561 30.5698 79.7339 30.6098 79.4105 30.6091C79.0611 30.6119 78.7145 30.5459 78.3906 30.4149C78.0879 30.294 77.8132 30.1123 77.5834 29.8811C77.3537 29.6499 77.1738 29.374 77.0548 29.0705C76.9249 28.7433 76.8599 28.3939 76.8636 28.0419C76.86 27.6896 76.926 27.34 77.0577 27.0132C77.1795 26.7105 77.3611 26.4354 77.5916 26.2044C77.8221 25.9734 78.0968 25.7913 78.3993 25.6688C78.7201 25.5375 79.0639 25.4715 79.4105 25.4747C79.7587 25.4676 80.1049 25.5276 80.4304 25.6514C80.7093 25.7611 80.9627 25.9268 81.1751 26.1382L80.839 26.4946Z",
    fill: "none"
  }), _jsx("path", {
    d: "M84.5449 30.4843H84.0494V28.3808L82.2211 25.6021H82.8411L84.3102 27.9693L85.7937 25.6021H86.3732L84.5449 28.3808V30.4843Z",
    fill: "none"
  })]
}), 'CleanChoiceEnergyMonoLogo', 'CompanyMono');
export default CleanChoiceEnergyMonoLogo;