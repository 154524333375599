/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MeredithMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 139,
  height: 32,
  viewBox: "0 0 139 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M134.126 12.428C134.355 12.574 134.564 12.7409 134.752 12.9496C135.253 13.5128 135.503 14.2638 135.503 15.2232V22.3574H138.381V14.3265C138.381 13.6378 138.298 12.9913 138.089 12.428C137.881 11.8231 137.568 11.2807 137.13 10.8426C136.254 9.92481 135.065 9.46581 133.563 9.46581C132.061 9.46581 130.851 10.0499 129.954 11.1973V4.9392H127.054V22.3574H129.954V15.2232C129.954 14.2429 130.205 13.471 130.705 12.9287C130.893 12.72 131.101 12.5532 131.331 12.428C131.706 12.2195 132.186 12.0942 132.728 12.0942C133.25 12.0942 133.729 12.2195 134.126 12.428",
    fill: "none"
  }), _jsx("path", {
    d: "M124.509 12.2195V9.73702H121.652V4.93923H118.752V17.9142C118.752 19.6249 119.253 20.8558 120.254 21.6065C121.151 22.2739 122.57 22.6287 124.509 22.6287V20.1256C123.592 20.1256 122.924 20.0003 122.528 19.7497C121.944 19.3746 121.652 18.6654 121.652 17.6223V12.2195H124.509",
    fill: "none"
  }), _jsx("path", {
    d: "M112.411 7.77612H115.268V4.9391H112.411V7.77612V7.77612Z",
    fill: "none"
  }), _jsx("path", {
    d: "M106.111 16.0159V16.0784C106.111 17.2467 105.735 18.2271 105.005 18.999C104.296 19.7291 103.44 20.1045 102.439 20.1045C101.417 20.1045 100.583 19.7497 99.8941 19.0199C99.1851 18.2482 98.8098 17.2677 98.8098 16.0784V16.0159C98.8098 14.8061 99.1644 13.8257 99.8941 13.0748C100.124 12.8035 100.395 12.5949 100.687 12.428C101.188 12.136 101.793 11.9899 102.439 11.9899C103.086 11.9899 103.67 12.136 104.171 12.428C104.463 12.6157 104.734 12.8245 105.005 13.0956C105.735 13.8674 106.111 14.848 106.111 16.0159ZM106.048 4.93923V11.1763C105.068 10.0499 103.649 9.46584 101.751 9.46584C100.166 9.46584 98.8098 10.0499 97.7041 11.1763C97.3286 11.5728 97.0155 11.9899 96.7654 12.428C96.1813 13.4502 95.8892 14.6393 95.8892 16.0159V16.0784C95.8892 18.0602 96.4941 19.6873 97.7041 20.9182C98.8098 22.0446 100.166 22.6287 101.751 22.6287C103.482 22.6287 104.901 22.0446 106.048 20.9182V22.3575H108.948V4.93923H106.048Z",
    fill: "none"
  }), _jsx("path", {
    d: "M112.411 22.3574H115.289V9.737H112.411V22.3574V22.3574Z",
    fill: "none"
  }), _jsx("path", {
    d: "M90.0481 12.428L90.4446 12.8034C90.9871 13.4085 91.2999 14.2011 91.4042 15.1608H84.9165C85.0626 14.1802 85.4173 13.3667 86.0013 12.7617L86.3769 12.428C86.8773 12.0316 87.5035 11.8439 88.1916 11.8439C88.9215 11.8439 89.5476 12.0316 90.0481 12.428V12.428ZM93.3861 12.428C93.1773 12.1151 92.9688 11.8023 92.7391 11.5101C91.6545 10.1541 90.1315 9.46576 88.2122 9.46576C86.4393 9.46576 84.9584 10.1125 83.7902 11.4058C83.498 11.7396 83.2268 12.0733 83.0182 12.428C82.3717 13.4919 82.0586 14.6809 82.0586 16.0576V16.0994C82.0586 18.0185 82.6845 19.6039 83.9154 20.8345C85.1462 22.0445 86.6688 22.6494 88.5253 22.6494C90.6531 22.6494 92.3844 21.8567 93.6989 20.2713L91.9883 18.7696C90.9659 19.7708 89.8396 20.2713 88.588 20.2713C87.6284 20.2713 86.8358 20.0002 86.1892 19.437C85.5006 18.8738 85.1044 18.081 84.9374 17.0798H94.2203L94.2621 16.2661C94.2621 14.7852 93.9702 13.5128 93.3861 12.428",
    fill: "none"
  }), _jsx("path", {
    d: "M80.4526 12.4698H81.0785V9.50759C78.9713 9.50759 77.4904 10.2585 76.5935 11.7813V9.737H73.7144V22.3574H76.5935V16.433C76.5935 15.2232 76.9272 14.2638 77.5946 13.5753C78.2621 12.8452 79.2217 12.4698 80.4526 12.4698Z",
    fill: "none"
  }), _jsx("path", {
    d: "M65.2033 11.8439C65.9335 11.8439 66.5594 12.0316 67.081 12.428L67.4563 12.8034C67.9988 13.3876 68.3116 14.1802 68.4159 15.1608H61.9492C62.0743 14.1802 62.4292 13.3667 63.0131 12.7617L63.3883 12.428C63.91 12.0316 64.5152 11.8439 65.2033 11.8439V11.8439ZM70.3978 12.428C70.2099 12.1151 70.0012 11.8023 69.7719 11.5101C68.6662 10.1541 67.1644 9.46576 65.2451 9.46576C63.4511 9.46576 61.9912 10.1125 60.8019 11.4058C60.51 11.7396 60.2594 12.0733 60.0302 12.428C59.4043 13.4919 59.0703 14.6809 59.0703 16.0576V16.0994C59.0703 18.0185 59.6965 19.6039 60.948 20.8345C62.158 22.0445 63.7014 22.6494 65.5582 22.6494C67.686 22.6494 69.3964 21.8567 70.7107 20.2713L69.0209 18.7696C67.9988 19.7708 66.8513 20.2713 65.5997 20.2713C64.6401 20.2713 63.8475 20.0002 63.2007 19.437C62.5333 18.8738 62.1162 18.081 61.9701 17.0798H71.2323L71.2945 16.2661C71.2945 14.7852 71.0026 13.5128 70.3978 12.428",
    fill: "none"
  }), _jsx("path", {
    d: "M56.7968 14.3053C56.7968 13.6171 56.6926 12.9913 56.484 12.428C56.2755 11.8439 55.9208 11.3015 55.4828 10.8217C54.5857 9.92481 53.4593 9.46581 52.0824 9.46581C50.3301 9.46581 48.9952 10.2169 48.0981 11.6979C47.3057 10.2169 46.0121 9.46581 44.2183 9.46581C43.2587 9.46581 42.3615 9.737 41.548 10.3001C41.1727 10.5506 40.818 10.8635 40.505 11.239V9.73699H37.6055V22.3574H40.505V15.2232C40.505 14.2429 40.7344 13.471 41.2142 12.9287C41.3812 12.72 41.5689 12.5532 41.7983 12.428C42.1739 12.2195 42.6119 12.0942 43.1335 12.0942C43.6339 12.0942 44.0931 12.2195 44.4686 12.428C44.6772 12.5532 44.8648 12.72 45.0318 12.9287C45.5116 13.471 45.762 14.2429 45.762 15.2232V22.3574H48.6406V15.2232C48.6406 14.2429 48.8909 13.471 49.3705 12.9078C49.5377 12.72 49.7254 12.5532 49.9548 12.428C50.3301 12.2195 50.7684 12.0942 51.2688 12.0942C51.7904 12.0942 52.2496 12.2195 52.6249 12.428C52.8334 12.574 53.0213 12.7409 53.209 12.9287C53.6679 13.4919 53.9182 14.2429 53.9182 15.2232V22.3574H56.7968V14.3053",
    fill: "none"
  }), _jsx("path", {
    d: "M26.8064 8.28203C27.4111 8.32382 27.9119 8.55332 28.3499 8.97044C28.8299 9.4503 29.0591 10.0135 29.0385 10.681C28.9966 11.2859 28.7881 11.8076 28.3499 12.2454L26.6603 13.9144L28.7463 16.0005L30.4358 14.3315C31.4791 13.2884 32.0004 12.0369 31.9798 10.5559C31.9798 9.13723 31.4582 7.90652 30.4358 6.88446C29.3722 5.84147 28.1204 5.34076 26.6185 5.34076C26.681 3.90133 26.1805 2.62889 25.0957 1.5441C24.0736 0.542917 22.8637 0.0215455 21.4244 0.000641482C19.9433 -0.0202625 18.6916 0.501355 17.6486 1.5441L15.98 3.21298L18.0658 5.2992L19.7347 3.63032C20.1935 3.17142 20.7359 2.94196 21.3826 2.94196C22.0085 2.94196 22.5509 3.17142 23.0097 3.63032C23.4687 4.08923 23.6982 4.6524 23.6773 5.2992C23.6773 5.90394 23.4478 6.44622 23.0097 6.88446L21.3409 8.55332L23.4269 10.6394L25.0957 8.97044C25.5755 8.4906 26.1596 8.26128 26.8064 8.28203",
    fill: "none"
  }), _jsx("path", {
    d: "M21.3409 8.55331L18.0658 5.29919L15.98 7.38492L19.2549 10.6394L21.3409 8.55331",
    fill: "none"
  }), _jsx("path", {
    d: "M21.3409 19.2545L18.0658 16.0006L15.98 18.0866L19.2549 21.3406L21.3409 19.2545Z",
    fill: "none"
  }), _jsx("path", {
    d: "M24.5952 16.0006L21.3408 19.2545L23.4269 21.3406L26.6603 18.0866L24.5952 16.0006Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.3408 12.7252L24.5952 16.0005L26.6603 13.9144L23.4269 10.6394L21.3408 12.7252Z",
    fill: "none"
  }), _jsx("path", {
    d: "M18.0658 16.0005L21.3409 12.7252L19.2549 10.6394L15.98 13.9144L18.0658 16.0005Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.3409 12.7251L23.4269 10.6393L21.3409 8.55322L19.2549 10.6393L21.3409 12.7251Z",
    fill: "none"
  }), _jsx("path", {
    d: "M26.6603 18.0866L28.3499 19.7553C28.7881 20.1724 28.9966 20.6938 29.0385 21.3199C29.0591 21.9662 28.8299 22.5293 28.3499 23.0095C27.9119 23.4266 27.4111 23.656 26.8064 23.698C26.1596 23.7189 25.5755 23.51 25.0957 23.0095L23.4269 21.3405L21.3409 23.4266L23.0097 25.0953C23.4478 25.5334 23.6773 26.0756 23.6773 26.7015C23.6982 27.3271 23.4687 27.8905 23.0097 28.3701C22.5509 28.8088 22.0085 29.038 21.3826 29.038C20.7359 29.038 20.1935 28.8088 19.7347 28.3701L18.0658 26.6806L15.98 28.7875L17.6486 30.4357C18.6916 31.4994 19.9433 32 21.4244 31.9791C22.8637 31.9585 24.0736 31.4578 25.0957 30.4357C26.1805 29.3718 26.681 28.0994 26.6185 26.6391C28.1204 26.6599 29.3722 26.1383 30.4358 25.0953C31.4582 24.0731 31.9798 22.8423 31.9798 21.4241C32.0004 19.9639 31.4791 18.7124 30.4358 17.6692L28.7463 16.0005L26.6603 18.0866Z",
    fill: "none"
  }), _jsx("path", {
    d: "M26.6603 18.0864L28.7463 16.0004L26.6603 13.9143L24.5952 16.0004L26.6603 18.0864Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.9147 5.29919L10.6396 8.55331L12.7257 10.6394L15.9801 7.38492L13.9147 5.29919Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.2457 3.63032L13.9144 5.2992L15.9797 3.21298L14.3316 1.5441C13.2677 0.501355 12.0161 -0.0202625 10.5559 0.000641482C9.13737 0.000641482 7.90667 0.542917 6.88453 1.5441C5.84152 2.58733 5.32008 3.85977 5.34083 5.34076C3.88072 5.2992 2.60823 5.79967 1.52325 6.88446C0.542895 7.88576 0.0215437 9.11647 0.000601211 10.5559C-0.0202452 12.0161 0.501298 13.2884 1.52325 14.3315L3.21305 16.0005L5.29905 13.9144L3.63037 12.2454C3.17136 11.7865 2.94196 11.2233 2.94196 10.5976C2.94196 9.95081 3.17136 9.42927 3.63037 8.97044C4.08927 8.51153 4.65252 8.28203 5.29905 8.30289C5.90396 8.30289 6.44626 8.53239 6.88453 8.97044L8.55339 10.6394L10.6393 8.55332L8.97061 6.88446C8.49075 6.40465 8.26125 5.82057 8.2822 5.17402C8.32379 4.54813 8.55339 4.04742 8.97061 3.63032C9.45037 3.15051 10.0135 2.94196 10.681 2.94196C11.286 2.96286 11.8075 3.19207 12.2457 3.63032",
    fill: "none"
  }), _jsx("path", {
    d: "M13.9146 5.29928L15.9799 7.38501L18.0657 5.29928L15.9799 3.21306L13.9146 5.29928Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3.21305 16.0005L1.52325 17.6691C0.501298 18.7124 -0.0202452 19.9639 0.000601211 21.4241C0.0215437 22.8636 0.542895 24.0938 1.52325 25.0952C2.60823 26.1798 3.88072 26.7015 5.34083 26.639C5.32008 28.1202 5.84152 29.3927 6.88453 30.4357C7.90667 31.4577 9.13737 31.9791 10.5559 32C12.0161 32 13.2677 31.4993 14.3316 30.4357L15.9797 28.7874L13.9144 26.6805L12.2457 28.37C11.8075 28.7874 11.286 29.0171 10.681 29.038C10.0135 29.0589 9.45037 28.8294 8.97061 28.37C8.55339 27.9324 8.32379 27.4316 8.2822 26.8268C8.26125 26.1592 8.49075 25.5962 8.97061 25.0952L10.6393 23.4266L8.55339 21.3405L6.88453 23.0094C6.44626 23.4472 5.90396 23.6766 5.29905 23.698C4.65252 23.698 4.08927 23.4893 3.63037 23.0094C3.17136 22.5714 2.94196 22.0288 2.94196 21.3821C2.94196 20.7564 3.17136 20.2139 3.63037 19.7552L5.29905 18.0865L3.21305 16.0005",
    fill: "none"
  }), _jsx("path", {
    d: "M5.29938 18.0864L7.38545 16.0004L5.29938 13.9143L3.21338 16.0004L5.29938 18.0864Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.38539 16.0006L5.29932 18.0866L8.55366 21.3406L10.3892 19.5259L10.6395 19.2545L7.38539 16.0006Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.29932 13.9144L7.38539 16.0005L10.6395 12.7252L8.55366 10.6394L5.29932 13.9144",
    fill: "none"
  }), _jsx("path", {
    d: "M15.9801 13.9144L12.7257 10.6394L10.6396 12.7252L13.9147 16.0005L15.9801 13.9144Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.55371 10.6393L10.6396 12.7251L12.7257 10.6393L10.6396 8.55322L8.55371 10.6393",
    fill: "none"
  }), _jsx("path", {
    d: "M10.6396 19.2543L10.3893 19.5257L8.55371 21.3404L10.6396 23.4265L12.7257 21.3404L10.6396 19.2543",
    fill: "none"
  }), _jsx("path", {
    d: "M13.9146 16.0004L15.9799 18.0864L18.0657 16.0004L15.9799 13.9143L13.9146 16.0004Z",
    fill: "none"
  }), _jsx("path", {
    d: "M15.9801 18.0866L13.9147 16.0006L10.6396 19.2545L12.7257 21.3406L15.9801 18.0866Z",
    fill: "none"
  }), _jsx("path", {
    d: "M15.9801 24.5944L12.7257 21.3404L10.6396 23.4265L13.9147 26.6804L15.9801 24.5944Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.9146 26.6804L15.9799 28.7874L18.0657 26.6804L15.9799 24.5944L13.9146 26.6804Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.3409 23.4265L23.4269 21.3404L21.3409 19.2543L19.2549 21.3404L21.3409 23.4265",
    fill: "none"
  }), _jsx("path", {
    d: "M18.0658 26.6804L21.3409 23.4265L19.2549 21.3404L15.98 24.5944L18.0658 26.6804Z",
    fill: "none"
  })]
}), 'MeredithMonoLogo', 'CompanyMono');
export default MeredithMonoLogo;