/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var HubspotMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 97,
  height: 33,
  fill: "none",
  viewBox: "0 0 97 33",
  children: [_jsx("path", {
    fill: "none",
    fillRule: "evenodd",
    d: "M36.8 12.13c-1.59 0-2.703.49-3.736 1.552v-6.29h-3.18v11.6c0 4.33 3.1 7.352 6.518 7.352 3.815 0 7.153-3.023 7.153-7.107-.08-4.003-3.179-7.107-6.755-7.107zM3.418 7.392H0v18.952h3.418v-7.842h7.153v7.842h3.418V7.392H10.57v7.76H3.418v-7.76zm18.44 15.684c1.51 0 2.781-1.307 2.781-2.86h.159v-8.168h3.18v8.169c0 3.43-2.703 6.208-6.041 6.208-3.338 0-6.04-2.777-6.04-6.208v-8.169h3.178v8.169c0 1.552 1.272 2.859 2.782 2.859zm11.206-3.84c0 2.206 1.67 4.085 3.815 4.085 1.828 0 3.497-1.879 3.497-4.084 0-2.206-1.669-4.085-3.497-4.085-2.146 0-3.815 1.88-3.815 4.085zm15.816-6.29c0-1.633 1.113-2.205 2.226-2.205.954 0 2.225.736 3.02 1.634l2.067-2.532c-1.033-1.47-3.18-2.45-4.928-2.45-3.497 0-6.04 2.041-6.04 5.554 0 3.657 2.539 4.569 4.703 5.347 1.606.577 3.006 1.08 3.006 2.576 0 1.144-1.033 2.043-2.225 2.043-1.715 0-2.402-.793-3.159-1.665-.085-.098-.17-.197-.26-.296l-2.225 2.533c1.51 1.878 3.339 2.859 5.564 2.859 3.338 0 5.961-2.124 5.961-5.392-.048-4.276-2.829-5.18-5.037-5.899-1.462-.476-2.672-.87-2.672-2.106zm9.618 6.373c0-4.411 3.1-7.352 6.517-7.352 3.815 0 7.153 3.022 7.074 7.188 0 4.003-3.1 7.107-6.677 7.107-1.59 0-2.702-.49-3.735-1.552V31h-3.18V19.319zm7.074 3.757c-2.147 0-3.816-1.879-3.816-4.084 0-2.206 1.67-4.085 3.816-4.085 1.828 0 3.497 1.88 3.497 4.085 0 2.287-1.67 4.084-3.498 4.084zm27.818-1.879c0 1.307.556 2.124 2.464 2.124.21 0 .42-.023.617-.044.177-.02.345-.038.495-.038l-.794 3.023c-.318.082-.875.082-1.272.082-2.782 0-4.769-1.553-4.769-4.493v-11.6l3.259-1.47v3.839h2.94v2.86h-2.94v5.717z",
    clipRule: "evenodd"
  }), _jsx("path", {
    fill: "none",
    fillRule: "evenodd",
    d: "M84.806 13.029a7.889 7.889 0 012.543 2.614c.636 1.061.954 2.205 1.113 3.512v.082c0 1.307-.398 2.532-1.033 3.594-.636 1.062-1.51 1.96-2.623 2.532-1.034.654-2.146.899-3.338.899h-.16a6.793 6.793 0 01-2.94-.654c-.477-.245-.874-.49-1.272-.735l-2.782 2.86c.08.163.08.408.08.653 0 .653-.239 1.225-.636 1.634-.477.408-1.033.653-1.59.653a2.188 2.188 0 01-1.59-.653c-.397-.49-.635-1.062-.635-1.634 0-.654.238-1.226.636-1.634.636-.572 1.51-.817 2.305-.49l2.623-2.696c-.398-.572-.795-1.225-1.034-1.879a6.816 6.816 0 01-.477-2.532v-.245c0-1.307.398-2.45 1.034-3.512.397-.49.794-.98 1.192-1.39l-8.187-6.044-.556-.49a2.69 2.69 0 01-1.43.408c-1.59 0-2.862-1.307-2.862-2.94C63.187 3.307 64.459 2 66.048 2c1.59 0 2.862 1.307 2.862 2.94 0 .123-.02.246-.04.368-.02.123-.04.245-.04.368 1.469 1.164 3.514 2.761 5.327 4.178a593.411 593.411 0 013.734 2.928c.715-.408 1.43-.653 2.225-.735V8.7c-.953-.408-1.51-1.307-1.51-2.369 0-1.47 1.113-2.614 2.544-2.614 1.43 0 2.543 1.144 2.543 2.614 0 1.062-.556 1.96-1.51 2.37v3.43c.954.082 1.828.408 2.623.899zm-3.497 9.965c.953 0 1.828-.49 2.623-1.388.715-.736 1.033-1.552.953-2.614v-.409c-.08-.653-.318-1.225-.636-1.797-.317-.572-.794-.98-1.35-1.307-.557-.245-1.114-.408-1.75-.408h-.079c-.715 0-1.351.163-1.907.571-.557.409-1.034.899-1.352 1.553-.238.653-.397 1.225-.397 1.878v.164c0 .653.239 1.307.636 1.879.397.571.795 1.061 1.43 1.388.557.327 1.113.49 1.67.49h.159z",
    clipRule: "evenodd"
  })]
}), 'HubspotMonoLogo', 'CompanyMono');
export default HubspotMonoLogo;