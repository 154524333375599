/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LensOnlineMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 195,
  height: 32,
  viewBox: "0 0 195 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#LensOnlineMonoLogo_svg__clip0_2366_14796)",
    children: [_jsx("path", {
      d: "M84.8212 12.6843C84.5319 11.9054 84.0869 11.1933 83.486 10.5703C82.8407 9.90267 81.9951 9.4131 81.0382 9.05705C80.0145 8.701 78.8574 8.54523 77.5445 8.54523C76.0757 8.54523 74.7628 8.65649 73.5834 8.90128C72.4262 9.14606 71.3581 9.47986 70.4012 9.92492L70.2232 10.0139L69.7559 10.2365V29.3519H75.0076V12.9736C75.2301 12.9068 75.4527 12.8401 75.6529 12.7956C76.0312 12.7066 76.5876 12.6621 77.2997 12.6621C77.9228 12.6621 78.4123 12.7511 78.7239 12.8846C79.1022 13.0404 79.3024 13.1961 79.4582 13.3742C79.6362 13.5967 79.7698 13.8192 79.8365 14.0863C79.9255 14.3978 79.97 14.7316 79.97 15.1099V29.3296H85.2218V15.0431C85.2218 14.2198 85.0883 13.4187 84.8212 12.6843Z",
      fill: "none"
    }), _jsx("path", {
      d: "M99.0858 19.4047C98.6853 18.8039 98.1289 18.292 97.4836 17.847C96.8383 17.4019 96.1039 17.0236 95.2583 16.6898L95.236 16.6676L95.1915 16.6453C94.2569 16.2893 93.6116 15.911 93.211 15.5549C93.033 15.3991 92.944 15.2656 92.8772 15.1099C92.8104 14.9541 92.7659 14.7538 92.7659 14.4868C92.7659 14.1975 92.8104 13.9749 92.8995 13.7747C92.9885 13.5744 93.0997 13.3964 93.2778 13.2183C93.4335 13.0626 93.5893 12.9513 93.8341 12.8623C94.0566 12.7733 94.3682 12.7288 94.7465 12.7288C95.2583 12.7288 95.7033 12.8178 96.0816 12.9513C96.5267 13.1293 96.8828 13.3296 97.1498 13.5076L98.2625 14.3532L99.9537 10.4367L99.1971 9.92487C98.5517 9.50206 97.8396 9.16827 97.0385 8.90123C96.1929 8.63419 95.2805 8.50067 94.3237 8.50067H94.3014C93.389 8.50067 92.5211 8.63419 91.72 8.94573C90.9189 9.23502 90.2068 9.68009 89.606 10.2809C89.0052 10.8595 88.5378 11.5271 88.2263 12.2614C87.9148 12.9958 87.759 13.7969 87.759 14.6203V14.6425C87.759 15.2211 87.8257 15.7774 87.9593 16.3338C88.0928 16.9346 88.3598 17.5132 88.7159 18.0473C89.0942 18.6481 89.6282 19.1599 90.2736 19.6272C90.9412 20.1168 91.7423 20.5396 92.6324 20.8734C93.6115 21.2517 94.2791 21.6523 94.6129 21.9861C94.7465 22.1196 94.8577 22.2754 94.9245 22.4756C94.9912 22.6759 95.0357 22.9207 95.0357 23.2322C95.0357 23.566 94.969 23.8108 94.88 24.0556C94.791 24.2781 94.6352 24.4784 94.4127 24.701C94.1901 24.9012 93.9676 25.0347 93.7006 25.146C93.4335 25.235 93.122 25.3018 92.7214 25.3018C92.0538 25.3018 91.4975 25.2128 91.0079 25.0347C90.4739 24.8567 90.0956 24.6342 89.873 24.4562L88.7381 23.5215L87.0469 27.6161L87.7145 28.1279C88.3598 28.6175 89.1609 28.9735 90.0733 29.2406C91.0079 29.4854 92.0093 29.6189 93.0552 29.6189C94.0789 29.6189 95.058 29.4409 95.9481 29.1071C96.8383 28.7733 97.6171 28.306 98.2625 27.7051C98.8855 27.1265 99.3974 26.4144 99.7534 25.6578C100.109 24.879 100.287 24.0556 100.287 23.21V23.1877V23.1432C100.243 21.7858 99.8424 20.4951 99.0858 19.4047Z",
      fill: "none"
    }), _jsx("path", {
      d: "M43.0525 3.8053H37.8008V29.3296H50.129V25.1015H43.0525V3.8053Z",
      fill: "none"
    }), _jsx("path", {
      d: "M67.3751 18.6258C67.3751 17.0236 67.2193 15.5994 66.8855 14.3532C66.5517 13.1071 66.0399 12.0167 65.3056 11.1265C64.5935 10.2587 63.7256 9.59107 62.7242 9.14601C61.7006 8.70095 60.5879 8.50067 59.3417 8.50067H59.3195C58.0733 8.50067 56.9161 8.7232 55.8702 9.21277C54.8243 9.68009 53.912 10.4144 53.1331 11.3268C52.3542 12.2615 51.7757 13.3964 51.4196 14.687C51.0413 15.9777 50.8855 17.4019 50.8633 19.0041V19.0264C50.8633 20.6731 51.0636 22.1641 51.4641 23.477C51.8647 24.79 52.4878 25.9249 53.3556 26.8595C54.2013 27.7719 55.2026 28.484 56.3375 28.9513C57.4725 29.4186 58.7186 29.6411 60.0983 29.6411H60.1206C61.3668 29.6411 62.5239 29.5076 63.5698 29.2406C64.5935 28.9958 65.4613 28.662 66.1957 28.2169L66.4182 28.0834L66.9968 27.7274L65.3946 23.6996L64.2819 24.3449C63.8591 24.5897 63.3028 24.8345 62.6129 24.9902C61.9676 25.146 61.211 25.235 60.3209 25.235C59.5643 25.235 58.9412 25.1015 58.4071 24.8567C57.873 24.6342 57.428 24.2781 57.0274 23.8108C56.6713 23.388 56.3821 22.7427 56.1373 21.9193C55.9815 21.2962 55.848 20.5396 55.8035 19.6717H67.3528L67.3751 18.6258ZM56.0483 15.7774C56.1373 15.3991 56.2263 15.0653 56.3598 14.7538C56.5601 14.2197 56.8049 13.8192 57.0719 13.5076C57.3834 13.1516 57.695 12.929 58.0288 12.7733C58.3626 12.6175 58.7631 12.5285 59.2082 12.5285C59.7423 12.5285 60.1651 12.6175 60.5211 12.7733C60.8772 12.929 61.1887 13.1516 61.4558 13.4854C61.7006 13.7747 61.9231 14.1752 62.0789 14.687C62.1679 14.9986 62.2569 15.3769 62.3014 15.7774H56.0483Z",
      fill: "none"
    }), _jsx("path", {
      d: "M118.201 7.56605C117.488 6.36438 116.554 5.4075 115.397 4.71765C114.217 4.00555 112.838 3.67175 111.302 3.67175C109.789 3.67175 108.409 4.00555 107.23 4.71765C106.073 5.4075 105.138 6.38663 104.426 7.56605C103.736 8.74547 103.224 10.1029 102.913 11.6384C102.601 13.1516 102.445 14.7983 102.445 16.5563C102.445 18.3143 102.601 19.961 102.913 21.4965C103.224 23.032 103.736 24.4117 104.448 25.5911C105.16 26.7928 106.095 27.7496 107.252 28.4395C108.409 29.1293 109.789 29.4631 111.302 29.4631C112.838 29.4631 114.217 29.1293 115.397 28.4395C116.532 27.7719 117.488 26.7928 118.201 25.5911C118.913 24.4117 119.424 23.032 119.736 21.4965C120.048 19.961 120.203 18.3143 120.203 16.5563C120.203 14.7983 120.048 13.1516 119.736 11.6384C119.402 10.1029 118.89 8.74547 118.201 7.56605ZM116.443 20.3616C116.265 21.541 115.975 22.5869 115.552 23.5215C115.152 24.4117 114.618 25.1015 113.906 25.6356C113.572 25.8804 113.194 26.0584 112.771 26.1919C112.348 26.3254 111.858 26.3922 111.302 26.3922C110.746 26.3922 110.278 26.3254 109.833 26.1919C109.411 26.0584 109.032 25.8804 108.698 25.6356C108.009 25.1015 107.452 24.4339 107.074 23.5215C106.673 22.5869 106.362 21.5188 106.184 20.3616C106.006 19.1822 105.917 17.8915 105.917 16.5341C105.917 15.1989 106.006 13.9304 106.206 12.7288C106.384 11.5494 106.696 10.4812 107.141 9.52433C107.564 8.61195 108.12 7.89985 108.81 7.38803C109.144 7.14324 109.5 6.96522 109.9 6.8317C110.301 6.69818 110.768 6.63142 111.302 6.63142C111.814 6.63142 112.281 6.69818 112.704 6.8317C113.127 6.96522 113.483 7.14324 113.817 7.38803C114.529 7.9221 115.063 8.61195 115.486 9.52433C115.908 10.459 116.22 11.5271 116.42 12.7065C116.598 13.9082 116.71 15.1989 116.71 16.5563C116.687 17.9138 116.621 19.1822 116.443 20.3616Z",
      fill: "none"
    }), _jsx("path", {
      d: "M137.095 12.7511C136.872 12.039 136.494 11.3936 135.96 10.7928C135.404 10.1697 134.691 9.70238 133.846 9.34633C132.956 8.96803 131.888 8.81226 130.686 8.81226C129.306 8.81226 128.105 8.92352 127.036 9.14605C125.968 9.36858 125.011 9.72463 124.144 10.1919L123.721 10.4145V29.0181H127.214V12.1502C127.593 11.9945 127.971 11.9054 128.327 11.8164C128.817 11.7274 129.529 11.6607 130.419 11.6607C131.198 11.6607 131.799 11.7719 132.244 11.9499C132.733 12.1502 133.045 12.395 133.267 12.6398C133.512 12.9291 133.668 13.2629 133.757 13.6189C133.868 14.0195 133.913 14.4646 133.913 14.9096V28.9958H137.406V14.8429C137.406 14.1308 137.295 13.4187 137.095 12.7511Z",
      fill: "none"
    }), _jsx("path", {
      d: "M145.306 0.823364H141.812V29.0181H145.306V0.823364Z",
      fill: "none"
    }), _jsx("path", {
      d: "M153.072 3.40473C152.872 3.2267 152.649 3.07093 152.404 2.95967C152.159 2.8484 151.892 2.80389 151.625 2.80389C151.358 2.80389 151.091 2.8484 150.847 2.95967C150.602 3.04868 150.379 3.20445 150.179 3.40473C149.979 3.58275 149.823 3.80528 149.734 4.07232C149.623 4.31711 149.578 4.58414 149.578 4.85118C149.578 5.11822 149.623 5.38526 149.734 5.63004C149.845 5.87483 150.001 6.09736 150.179 6.27538C150.379 6.45341 150.602 6.60918 150.847 6.72044C151.091 6.83171 151.358 6.87622 151.625 6.87622C151.892 6.87622 152.159 6.83171 152.404 6.72044C152.649 6.63143 152.872 6.47566 153.072 6.27538C153.25 6.09736 153.406 5.87483 153.517 5.63004C153.628 5.38526 153.673 5.11822 153.673 4.85118C153.673 4.58414 153.628 4.31711 153.517 4.07232C153.406 3.80528 153.25 3.58275 153.072 3.40473Z",
      fill: "none"
    }), _jsx("path", {
      d: "M153.361 9.16827H149.867V29.0403H153.361V9.16827Z",
      fill: "none"
    }), _jsx("path", {
      d: "M171.03 12.751C170.808 12.0389 170.43 11.3936 169.895 10.815C169.339 10.1919 168.627 9.7246 167.781 9.36855C166.891 8.99024 165.823 8.83447 164.621 8.83447C163.242 8.83447 162.018 8.94574 160.972 9.16827C159.904 9.3908 158.947 9.74685 158.079 10.2142L157.656 10.4367V29.0403H161.172V12.1502C161.551 11.9944 161.929 11.9054 162.285 11.8164C162.774 11.7274 163.487 11.6606 164.377 11.6606C165.156 11.6606 165.756 11.7719 166.201 11.9499C166.691 12.1502 167.003 12.395 167.225 12.6398C167.47 12.929 167.626 13.2628 167.715 13.6189C167.826 14.0195 167.87 14.4423 167.87 14.9096V28.9958H171.364V14.8428C171.364 14.1307 171.253 13.4186 171.03 12.751Z",
      fill: "none"
    }), _jsx("path", {
      d: "M187.876 11.1933C187.231 10.4144 186.43 9.81361 185.517 9.41305C184.605 9.0125 183.581 8.83447 182.446 8.83447C181.334 8.83447 180.266 9.057 179.331 9.52432C178.374 9.96938 177.551 10.6592 176.839 11.5494C176.104 12.4395 175.592 13.5299 175.236 14.7983C174.88 16.0445 174.725 17.4687 174.725 19.0709C174.725 20.8067 174.925 22.3199 175.37 23.6328C175.793 24.9235 176.394 26.0139 177.172 26.8818C177.951 27.7274 178.886 28.3727 179.954 28.7733C181 29.1738 182.135 29.3741 183.359 29.3741C184.494 29.3741 185.495 29.2406 186.385 28.9513C187.231 28.6843 187.987 28.3505 188.677 27.9277L189.3 27.5494L188.121 24.968L187.342 25.4131C186.875 25.6801 186.296 25.9249 185.629 26.1029C184.983 26.2809 184.249 26.3699 183.381 26.3699C182.491 26.3699 181.734 26.2142 181.111 25.9249C180.466 25.6356 179.932 25.1905 179.465 24.5897C178.997 23.9889 178.641 23.2322 178.396 22.2976C178.152 21.363 178.018 20.2726 178.018 19.0042V18.9596H189.768V18.1585C189.768 16.6453 189.612 15.2879 189.3 14.1307C189.033 12.9958 188.544 11.9944 187.876 11.1933ZM178.263 16.2448C178.374 15.6217 178.508 15.0653 178.686 14.5758C178.93 13.8637 179.264 13.3296 179.643 12.9068C180.021 12.484 180.444 12.1724 180.889 11.9722C181.334 11.7719 181.846 11.6606 182.424 11.6606C183.092 11.6606 183.67 11.7719 184.16 11.9722C184.649 12.1724 185.05 12.484 185.406 12.8845C185.74 13.2851 186.029 13.8192 186.207 14.509C186.341 14.9986 186.43 15.5772 186.474 16.2448H178.263Z",
      fill: "none"
    }), _jsx("path", {
      d: "M194.129 7.96663C194.129 7.32129 193.773 7.12101 193.172 7.09875H191.592V9.9249H192.215V8.78999H192.704L193.328 9.9249H194.04L193.372 8.74549C193.862 8.70098 194.129 8.47845 194.129 7.96663ZM193.06 8.32268H192.237V7.58832H192.927C193.194 7.58832 193.528 7.61058 193.528 7.92212C193.528 8.25592 193.372 8.32268 193.06 8.32268Z",
      fill: "none"
    }), _jsx("path", {
      d: "M192.817 5.89709C190.969 5.89709 190.213 7.29904 190.213 8.50071C190.213 9.74689 190.992 11.1266 192.817 11.1266C194.419 11.1266 195.442 9.94716 195.442 8.50071C195.442 7.14327 194.53 5.89709 192.817 5.89709ZM192.794 10.4812C191.615 10.4812 190.836 9.68013 190.836 8.50071C190.836 7.3213 191.615 6.52018 192.794 6.52018C193.974 6.52018 194.775 7.29904 194.775 8.4562C194.797 9.65787 193.974 10.4812 192.794 10.4812Z",
      fill: "none"
    }), _jsx("path", {
      d: "M29.8781 8.38943C27.1854 3.84979 22.2452 0.823364 16.5929 0.823364C8.62632 0.823364 2.06165 6.87622 1.23828 14.6426C6.86832 11.6829 14.3676 7.4993 21.6444 7.4993C24.3815 7.47705 27.1187 7.72183 29.8781 8.38943Z",
      fill: "none"
    }), _jsx("path", {
      d: "M2.99609 23.5661C5.59971 28.395 10.7179 31.6885 16.5928 31.6885C20.6428 31.6885 24.3146 30.1307 27.074 27.5939C19.8862 23.0097 13.9224 21.541 2.99609 23.5661Z",
      fill: "none"
    }), _jsx("path", {
      d: "M32.037 15.7997C30.724 15.0876 24.0036 11.7051 17.0384 11.7051C9.47229 11.7051 2.30678 15.6217 1.17188 16.2448V16.267C1.17188 18.4478 1.61694 20.5174 2.4403 22.3866C4.08703 21.9193 12.4542 20.0501 17.0384 20.0501C22.5794 20.0501 26.674 21.363 30.368 23.2545C31.4361 21.1627 32.037 18.7816 32.037 16.267C32.037 16.1113 32.037 15.9555 32.037 15.7997Z",
      fill: "none"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "LensOnlineMonoLogo_svg__clip0_2366_14796",
      children: _jsx("rect", {
        width: 194,
        height: 32,
        fill: "none",
        transform: "translate(0.949219)"
      })
    })
  })]
}), 'LensOnlineMonoLogo', 'CompanyMono');
export default LensOnlineMonoLogo;