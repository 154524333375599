/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CazooMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 133,
  height: 33,
  viewBox: "0 0 133 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M50.8 12.7853C50.354 12.7149 49.9033 12.682 49.4667 12.5881C48.664 12.4238 48.0584 12.5881 47.7908 13.635C46.8519 11.5459 45.913 9.62115 45.0726 7.6776C44.0445 5.34909 42.3451 4.10972 39.7349 4.17075C37.9369 4.21769 36.1341 4.17075 34.3361 4.17075C31.3645 4.17075 29.9044 5.166 28.7026 7.92641C27.8999 9.75729 27.0314 11.56 26.1863 13.3909C25.8483 13.0764 25.5714 12.6163 25.3178 12.6304C24.3152 12.6122 23.3171 12.7712 22.3697 13.0998C21.9612 13.2782 21.8439 14.2547 21.7453 14.8932C21.7453 15.0105 22.4307 15.3016 22.825 15.3955C23.3978 15.5316 23.9893 15.5645 24.5855 15.6443C24.4229 16.1421 24.3097 16.6547 24.2475 17.1747C24.2162 20.2825 24.2162 23.3888 24.2475 26.4935C24.2475 27.5263 24.8296 28.0286 25.8953 28.0568C26.7591 28.0568 27.6182 28.0568 28.482 28.0568C29.6087 28.0286 30.1204 27.5263 30.1533 26.4043C30.1814 25.5076 30.1533 24.6062 30.1533 23.7096C30.1533 23.0946 30.3974 22.8458 31.0264 22.8458C34.9605 22.8646 38.8961 22.8646 42.8333 22.8458C43.5422 22.8458 43.7722 23.1556 43.7347 23.7847C43.7347 24.6485 43.7347 25.5076 43.7347 26.3667C43.7722 27.5169 44.2605 28.0145 45.3825 28.0427C46.2463 28.0427 47.1054 28.0427 47.9692 28.0427C49.0677 28.0145 49.678 27.4887 49.678 26.4043C49.678 22.8035 49.6357 19.2075 49.6029 15.5222C50.045 15.5058 50.4848 15.4508 50.9174 15.3579C51.3868 15.2312 52.152 15.4988 52.1943 14.6162C52.2173 14.1951 52.0897 13.7796 51.8342 13.4441C51.5787 13.1086 51.2121 12.875 50.8 12.7853ZM31.3973 16.9212C31.571 16.4048 31.7072 15.9307 31.8903 15.4706C32.4771 13.9777 33.1155 12.5036 33.6601 10.9967C33.7218 10.6821 33.903 10.4037 34.1656 10.2198C34.4282 10.036 34.7518 9.9611 35.0685 10.0108C36.3736 10.0624 37.6881 10.0108 38.9931 10.0484C39.3452 10.0484 39.8804 10.2596 39.9931 10.5178C40.8898 12.5928 41.7066 14.7007 42.5939 16.9165L31.3973 16.9212Z",
    fill: "white"
  }), _jsx("path", {
    d: "M132.993 15.7666C132.758 8.95008 127.275 3.95505 120.284 4.18508C113.937 4.39164 108.787 10.0111 108.994 16.5083C109.2 23.0056 114.754 28.3575 121.143 28.1321C128.04 27.888 133.223 22.4751 132.993 15.7666ZM121.003 22.1559C119.413 22.1331 117.894 21.4937 116.767 20.3726C115.64 19.2515 114.992 17.7364 114.961 16.1469C114.992 14.5574 115.636 13.0414 116.758 11.9155C117.881 10.7897 119.395 10.141 120.984 10.1049C122.553 10.1487 124.043 10.8028 125.137 11.928C126.231 13.0532 126.843 14.5609 126.843 16.1304C126.843 17.7 126.231 19.2076 125.137 20.3329C124.043 21.4581 122.553 22.1121 120.984 22.1559H121.003Z",
    fill: "white"
  }), _jsx("path", {
    d: "M91.8447 4.18016C89.4866 4.23748 87.1979 4.98961 85.2655 6.34227C83.3331 7.69492 81.843 9.58791 80.982 11.7839C80.1209 13.9799 79.9273 16.3813 80.4254 18.6869C80.9234 20.9925 82.091 23.0997 83.7817 24.7445C85.4724 26.3893 87.611 27.4984 89.9295 27.9328C92.2479 28.3672 94.643 28.1076 96.8145 27.1864C98.986 26.2653 100.837 24.7236 102.136 22.7547C103.435 20.7858 104.124 18.4772 104.116 16.1185C104.093 9.39114 98.6894 4.13791 91.8447 4.18016ZM92.0747 22.1557C90.476 22.152 88.9443 21.5133 87.8165 20.3802C86.6887 19.2471 86.0572 17.7125 86.061 16.1138C86.0647 14.5151 86.7034 12.9834 87.8364 11.8556C88.9695 10.7278 90.5042 10.0963 92.1029 10.1C93.7016 10.1038 95.2333 10.7424 96.3611 11.8755C97.4889 13.0086 98.1204 14.5433 98.1166 16.1419C98.1129 17.7406 97.4742 19.2723 96.3412 20.4001C95.2081 21.5279 93.6734 22.1594 92.0747 22.1557Z",
    fill: "white"
  }), _jsx("path", {
    d: "M74.2072 10.7478C74.7878 10.1778 75.234 9.48552 75.5133 8.72135C75.7927 7.95717 75.8982 7.14033 75.8221 6.33025C75.7001 4.49936 75.3057 4.02991 73.4467 4.02991H59.0531C56.0486 4.02991 55.8749 4.20361 55.8749 7.19875C55.8749 9.5742 56.1706 9.87465 58.5038 9.87465H67.0573C66.5879 10.4004 66.3391 10.7338 66.0574 11.0577C63.0998 14.4378 60.2408 17.9118 57.1377 21.1557C55.6167 22.7425 55.2599 24.4419 55.4195 26.4371C55.5087 27.4511 55.9875 28.0614 57.0344 28.0661C62.7336 28.0661 68.4313 28.0661 74.1274 28.0661C75.193 28.0661 75.7423 27.4605 75.7846 26.362C75.8127 25.6484 75.7846 24.9536 75.7846 24.226C75.7846 22.8176 75.2118 22.2543 73.7894 22.2449C71.0149 22.2449 68.2451 22.2449 65.4706 22.2449H64.1983C64.5598 21.7754 64.7664 21.4843 65.0011 21.2121C68.0714 17.7052 71.0571 14.1514 74.2072 10.7478Z",
    fill: "white"
  }), _jsx("path", {
    d: "M17.7126 22.8082C16.7737 21.3998 16.7737 21.3998 15.2245 22.0946C11.0463 23.9208 6.17806 20.8975 6.01845 16.4235C5.83536 11.3018 11.7083 7.8841 15.4921 10.4708C16.2573 11.0013 16.8723 10.79 17.3699 10.0014C17.8676 9.21266 18.4309 8.46153 18.9473 7.68223C19.6046 6.70107 19.4496 6.03913 18.3933 5.51334C10.5769 1.61214 0.32862 6.14711 0 15.9916C0.107975 20.5688 2.00928 24.179 6.03253 26.4511C10.1403 28.7703 14.3889 28.7374 18.614 26.6483C19.398 26.2634 19.52 25.578 19.0835 24.8503C18.6469 24.1226 18.1821 23.4795 17.7126 22.8082Z",
    fill: "white"
  })]
}), 'CazooMonoLogo', 'CompanyMono');
export default CazooMonoLogo;