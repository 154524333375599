/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var RitualMonoLogo = createCompanyLogosComponent(_jsx("svg", {
  width: 64,
  height: 32,
  viewBox: "0 0 64 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M14.6104 25.7564L10.5785 19.7191C13.0188 18.4989 14.3875 16.345 14.3875 13.6712C14.3875 9.68177 11.4061 6.66845 6.12213 6.66845H0V25.7564H3.42712V20.6634H7.16194L10.5042 25.7564H14.6104ZM6.47227 17.162H3.42712V10.1805H6.47227C8.90203 10.1805 10.7907 11.2627 10.7907 13.6712C10.7907 16.0692 8.90203 17.162 6.47227 17.162ZM60.224 6.67906H63.6511V25.767H60.224V6.67906ZM26.2392 15.1673V21.5016C26.2923 22.3398 26.6743 22.7324 27.417 22.7324C28.1597 22.7324 28.7114 22.4035 29.1677 22.0428V25.2683C28.5098 25.7245 27.3958 26.064 26.165 25.9898C25.3374 25.9367 24.3824 25.5866 23.7034 24.8969C23.1198 24.1648 22.8227 23.4433 22.8227 22.2125V15.1673H21.0614V12.0691H22.8121V7.61276H26.2392V12.0691H28.5735V15.1673H26.2392ZM43.2687 25.7564H40.1918L39.8735 24.1966C39.2474 25.0136 37.953 25.9155 36.0644 25.9155C32.6691 25.9155 30.3666 23.9208 30.3666 20.2814V12.0691H33.7619V19.1355C33.7619 21.5441 34.8336 22.5839 36.8071 22.5839C38.7806 22.5839 39.8841 21.1621 39.8841 19.1355V12.0691H43.2794L43.2687 25.7564ZM58.4627 12.0903V25.7564H55.7146L55.3963 24.3346C54.8233 25.0773 53.3167 25.9155 51.4917 25.9155C47.5871 25.9155 44.6269 23.0189 44.6269 18.9127C44.6269 14.8171 47.5765 11.9099 51.4917 11.9099C53.2955 11.9099 54.8233 12.7588 55.3963 13.4909L55.7146 12.0691L58.4627 12.0903ZM51.5978 15.1673C49.5182 15.1673 47.916 16.8755 47.916 18.9339C47.916 20.9923 49.5182 22.69 51.5978 22.69C53.6244 22.69 55.1841 20.9817 55.1841 18.9339C55.1841 16.8649 53.6244 15.1673 51.5978 15.1673ZM16.0428 12.0691H19.4699V25.7564H16.0428V12.0691ZM19.9686 8.25999C19.9686 9.512 18.9712 10.52 17.7404 10.52C16.5096 10.52 15.5122 9.512 15.5122 8.25999C15.5122 7.00798 16.5096 6 17.7404 6C18.9712 6.01061 19.9686 7.01859 19.9686 8.25999Z",
    fill: "none"
  })
}), 'RitualMonoLogo', 'CompanyMono');
export default RitualMonoLogo;