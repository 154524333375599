/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FlowersComMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 70,
  height: 32,
  viewBox: "0 0 70 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#FlowersComMonoLogo_svg__clip0_11433_133617)",
    children: [_jsx("path", {
      d: "M24.3413 18.0034H25.1924V12.7515L24.3413 12.8816V11.9471C24.9323 11.7815 25.3933 11.4857 25.866 11.0599H26.6697V18.0034H27.5208V19.1863H24.3059V18.0034H24.3413Z",
      fill: "white"
    }), _jsx("path", {
      d: "M30.7225 15.8505C31.3134 16.4065 31.6089 16.8205 31.6089 17.3292C31.6089 17.8378 31.3134 18.1808 30.8879 18.1808C30.4624 18.1808 30.2142 17.8378 30.2142 17.2464C30.2142 16.7377 30.3915 16.2291 30.7225 15.8505ZM31.0652 14.0644C30.6397 13.6031 30.427 13.2128 30.427 12.7987C30.427 12.2901 30.6752 12.0417 30.9825 12.0417C31.3253 12.0417 31.5735 12.3374 31.5735 12.8461C31.5735 13.2601 31.3962 13.6386 31.0652 14.0644ZM30.0842 15.1645C29.2805 15.8033 28.9022 16.4302 28.9022 17.2818C28.9022 18.512 29.706 19.3164 30.8525 19.3164C32.0817 19.3164 32.8854 18.512 32.8854 17.1517C32.8854 16.2645 32.5071 15.4957 31.7389 14.7386C32.4126 14.0999 32.7081 13.5085 32.7081 12.7514C32.7081 11.6987 31.9872 10.9772 30.8879 10.9772C29.8714 10.9772 29.115 11.7815 29.115 13.0117C29.1623 13.7687 29.4578 14.5375 30.0842 15.1645Z",
      fill: "white"
    }), _jsx("path", {
      d: "M34.9547 15.0811V14.2294C34.9547 12.7035 35.0019 12.0293 35.4156 12.0293C35.8766 12.0293 35.8766 12.7035 35.8766 14.2294V15.0811V15.9327C35.8766 17.4586 35.8293 18.1329 35.4156 18.1329C34.9901 18.1329 34.9547 17.4114 34.9547 15.9327V15.0811ZM33.43 15.0811C33.43 17.8726 34.0682 19.2685 35.4629 19.2685C36.7749 19.2685 37.4486 17.8726 37.4486 15.0811C37.4486 12.2895 36.7749 10.9292 35.4156 10.9292C34.1155 10.9292 33.43 12.325 33.43 15.0811Z",
      fill: "white"
    }), _jsx("path", {
      d: "M39.6588 15.0811V14.2294C39.6588 12.7035 39.7061 12.0293 40.1197 12.0293C40.5452 12.0293 40.5807 12.7035 40.5807 14.2294V15.0811V15.9327C40.5807 17.4586 40.5334 18.1329 40.1197 18.1329C39.6943 18.1329 39.6588 17.4114 39.6588 15.9327V15.0811ZM38.1341 15.0811C38.1341 17.8726 38.8078 19.2685 40.167 19.2685C41.4317 19.2685 42.1527 17.8726 42.1527 15.0811C42.1527 12.2895 41.479 10.9292 40.1197 10.9292C38.8433 10.9292 38.1341 12.325 38.1341 15.0811Z",
      fill: "white"
    }), _jsx("path", {
      d: "M28.6543 15.1288C28.6543 15.5073 28.3588 15.803 27.9806 15.803C27.6023 15.803 27.3068 15.5073 27.3068 15.1288C27.3068 14.7503 27.6023 14.4545 27.9806 14.4545C28.3588 14.49 28.6543 14.7857 28.6543 15.1288Z",
      fill: "white"
    }), _jsx("path", {
      d: "M19.01 11.2725C18.289 13.0114 17.2371 14.5373 15.3696 15.247C14.9087 15.3772 14.4004 15.4126 13.8922 15.247C13.0412 12.4909 14.1049 9.35634 15.8424 7.12068C17.0717 5.38185 18.9746 4.24629 21.0903 3.39462C19.1282 5.64208 20.192 8.72935 19.01 11.2725Z",
      fill: "white"
    }), _jsx("path", {
      d: "M17.3551 4.28201C14.4358 6.56495 11.8118 10.7168 12.8283 14.6085C11.1381 14.1827 9.99164 12.4438 9.56614 10.7168C9.22337 8.94255 9.64887 6.99078 11.0081 5.7606C12.5328 4.23469 14.2703 2.37758 14.093 0C15.8304 0.638752 16.8469 2.54318 17.3551 4.28201Z",
      fill: "white"
    }), _jsx("path", {
      d: "M16.3385 22.534H15.2865V20.7123H16.3385V19.1036C16.3385 17.2347 17.1895 16.6078 18.5369 16.6078C18.9151 16.6078 19.2933 16.6078 19.6834 16.655V18.5595C19.5534 18.5595 19.4352 18.5595 19.2579 18.5595C18.6669 18.5595 18.4541 18.7724 18.4541 19.4111V20.7715H19.6834V22.5931H18.4541V31.1452H16.3385V22.534Z",
      fill: "white"
    }), _jsx("path", {
      d: "M22.5559 16.9035H20.4402V31.0862H22.5559V16.9035Z",
      fill: "white"
    }), _jsx("path", {
      d: "M26.9645 20.464C29.5057 20.4167 30.1794 22.333 30.1794 25.9289C30.1794 29.4893 29.3757 31.3938 26.9645 31.3938C24.4233 31.4411 23.7496 29.5248 23.7496 25.9289C23.7496 22.3684 24.5533 20.464 26.9645 20.464ZM26.9645 29.5603C27.8155 29.5603 28.111 28.5075 28.111 25.9171C28.111 23.3384 27.8627 22.2738 26.9645 22.2738C25.948 22.2738 25.818 23.7997 25.818 25.9171C25.8653 28.0462 25.948 29.5603 26.9645 29.5603Z",
      fill: "white"
    }), _jsx("path", {
      d: "M30.4272 20.712H32.5073L33.4766 28.0813L34.493 20.712H36.7742L37.8734 28.0813L38.8899 20.712H40.8756L39.1026 31.0858H36.7387L35.675 23.7165H35.6277L34.6231 31.0858H32.1646L30.4272 20.712Z",
      fill: "white"
    }), _jsx("path", {
      d: "M43.0755 26.3071V27.1587C43.0755 28.3889 43.3237 29.5718 44.092 29.5718C44.813 29.5718 45.0612 28.8975 45.1912 27.6201H47.0587C46.9759 30.0331 46.0895 31.3934 44.092 31.3934C41.0426 31.3934 40.9244 28.4244 40.9244 25.8457C40.9244 23.0541 41.3499 20.4637 44.1866 20.4637C46.645 20.4637 47.1887 22.5337 47.1887 25.7156V26.3071H43.0755ZM45.2385 24.7339C45.1912 23.1724 45.0258 22.2734 44.1866 22.2734C43.3474 22.2734 43.1346 23.3735 43.1346 24.3435V24.7693H45.2503V24.7339H45.2385Z",
      fill: "white"
    }), _jsx("path", {
      d: "M48.0273 20.7122H50.013V22.108H50.0602C50.4857 21.0907 51.0767 20.4519 51.9277 20.4519C52.0578 20.4519 52.176 20.4519 52.306 20.4993V23.0425C52.0932 23.0425 51.8805 22.9951 51.6677 22.9951C50.9467 22.9951 50.1075 23.4565 50.1075 24.8641V31.086H47.9918V20.7122H48.0273Z",
      fill: "white"
    }), _jsx("path", {
      d: "M54.6346 27.7028V28.0458C54.6346 28.933 55.0128 29.5717 55.7338 29.5717C56.372 29.5717 56.7857 29.1459 56.7857 28.3415C56.7857 27.6673 56.4429 27.3716 56.0293 27.1587L54.5519 26.4844C53.4054 25.9758 52.8144 25.0413 52.8144 23.6101C52.8144 21.8712 53.6654 20.4754 55.8165 20.4754C57.7195 20.4754 58.6059 21.5282 58.6059 23.4444V23.8703H56.7503C56.7503 22.8175 56.502 22.3089 55.8165 22.3089C55.261 22.3089 54.8474 22.6874 54.8474 23.409C54.8474 23.9176 55.06 24.3434 55.781 24.6392L57.0458 25.1951C58.3577 25.7511 58.866 26.5909 58.866 28.1641C58.866 30.3288 57.6013 31.4288 55.8165 31.4288C53.4527 31.4288 52.8144 30.1159 52.8144 28.1286V27.7501L54.6346 27.7028Z",
      fill: "white"
    }), _jsx("path", {
      d: "M59.0307 30.4945C59.0307 30.2816 59.078 30.1515 59.2434 29.9858C59.3734 29.8557 59.5389 29.7729 59.7044 29.7729C59.9171 29.7729 60.0826 29.8557 60.2126 29.9858C60.3427 30.1159 60.4255 30.2816 60.4255 30.4945C60.4255 30.6601 60.3782 30.8375 60.2126 30.9557C60.0826 31.0859 59.9171 31.1687 59.7044 31.1687C59.5389 31.1687 59.3616 31.0859 59.2434 30.9557C59.1134 30.873 59.0307 30.6601 59.0307 30.4945Z",
      fill: "white"
    }), _jsx("path", {
      d: "M62.1166 31.1334C61.3601 31.1334 60.9346 30.4946 60.9346 29.2644C60.9346 27.9514 61.3956 27.2299 62.1166 27.2299C62.7902 27.2299 63.133 27.6912 63.133 28.5429V28.673H62.3766V28.5074C62.3766 28.0815 62.2938 27.8686 62.1284 27.8686C61.9157 27.8686 61.8802 28.2117 61.8802 28.8859C61.8802 29.9032 61.9275 30.329 62.1757 30.329C62.3885 30.329 62.5184 30.0806 62.5184 29.6074V29.5602L63.2394 29.6074C63.2158 30.6247 62.8021 31.1334 62.1166 31.1334Z",
      fill: "white"
    }), _jsx("path", {
      d: "M64.8232 27.9514C65.1186 27.9514 65.1659 28.33 65.1659 29.2171C65.1659 30.0688 65.0831 30.4473 64.8232 30.4473C64.5631 30.4473 64.4804 30.0214 64.4804 29.2171C64.4922 28.3418 64.5749 27.9514 64.8232 27.9514ZM63.5585 29.1816C63.5585 30.4473 63.984 31.1334 64.7877 31.1334C65.5914 31.1334 66.0523 30.4592 66.0523 29.1816C66.0523 27.8686 65.6268 27.2299 64.7877 27.2299C63.984 27.2299 63.5585 27.916 63.5585 29.1816Z",
      fill: "white"
    }), _jsx("path", {
      d: "M66.5251 27.3245H67.376V27.6675C67.6242 27.4191 67.837 27.2416 68.097 27.2416C68.3925 27.2416 68.558 27.3717 68.688 27.6675C68.9362 27.4191 69.149 27.2416 69.409 27.2416C69.8345 27.2416 70 27.49 70 27.9632V31.0505H69.149V28.2116C69.149 28.0815 69.1017 27.9632 68.9362 27.9632C68.8535 27.9632 68.7235 28.0106 68.688 28.1288V31.0032H67.837V28.1643C67.837 28.0342 67.7542 27.9159 67.6242 27.9159C67.4942 27.9159 67.4115 27.9632 67.376 28.0815V30.9558H66.5251V27.3245Z",
      fill: "white"
    }), _jsx("path", {
      d: "M13.0412 15.5076C12.9111 15.4603 12.7929 15.5549 12.7929 15.6377C11.8592 17.5895 10.9727 19.7068 10.429 21.8241C9.79075 19.7068 8.95163 17.6367 7.88786 15.685C5.85491 11.8288 3.65648 8.06734 0.394306 5.68974C0.264292 5.60693 0.146097 5.60693 0.0515409 5.73705C-0.0430149 5.86716 0.00426317 6.03276 0.0988187 6.16288C3.0655 8.91898 4.54294 13.1064 6.49316 16.7496C8.01788 20.937 9.49534 25.3846 9.03437 30.0451C9.11711 30.8968 9.75529 31.5237 10.4763 31.4054C10.7718 31.3581 11.0318 31.1925 11.2328 30.9796C11.4455 30.7312 11.5755 30.4237 11.611 30.0925C11.3155 28.531 11.1855 26.9578 11.2682 25.3965C11.3155 24.0006 11.5164 22.6048 11.8592 21.2445C12.3202 19.4229 13.0057 17.6486 13.2185 15.7442C13.2066 15.6732 13.1239 15.5431 13.0412 15.5076Z",
      fill: "white"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "FlowersComMonoLogo_svg__clip0_11433_133617",
      children: _jsx("rect", {
        width: 69.7969,
        height: 31.3376,
        fill: "white",
        transform: "scale(1.00291)"
      })
    })
  })]
}), 'FlowersComMonoLogo', 'CompanyMono');
export default FlowersComMonoLogo;