/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AtlassianMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 168,
  height: 33,
  viewBox: "0 0 168 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M6.09279 15.6963C6.03176 15.6164 5.95117 15.5537 5.8588 15.5141C5.76643 15.4745 5.6654 15.4594 5.56548 15.4702C5.46557 15.4811 5.37014 15.5175 5.28843 15.576C5.20672 15.6346 5.14149 15.7132 5.09903 15.8043L0.0635283 25.8721C0.0176555 25.9639 -0.00400317 26.0658 0.000608593 26.1683C0.00522036 26.2708 0.0359495 26.3704 0.0898785 26.4577C0.143807 26.5449 0.219146 26.617 0.308742 26.6669C0.398337 26.7169 0.499215 26.7431 0.601799 26.7431H7.61348C7.72578 26.7457 7.83642 26.7157 7.93198 26.6566C8.02753 26.5975 8.10387 26.512 8.15175 26.4104C9.66426 23.285 8.74763 18.5328 6.09279 15.6963Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.78609 6.5391C8.53139 8.46969 7.8002 10.6933 7.66428 12.9918C7.52835 15.2903 7.99237 17.5846 9.01079 19.6496L12.3912 26.4104C12.4412 26.5104 12.518 26.5945 12.6131 26.6532C12.7081 26.712 12.8177 26.7431 12.9295 26.7431H19.9399C20.0425 26.7431 20.1434 26.7169 20.2329 26.6669C20.3225 26.617 20.3979 26.5449 20.4518 26.4577C20.5057 26.3704 20.5365 26.2708 20.5411 26.1683C20.5457 26.0659 20.524 25.9639 20.4782 25.8721C20.4782 25.8721 11.0468 7.00718 10.8098 6.53533C10.7629 6.43928 10.6899 6.35839 10.5992 6.30193C10.5084 6.24547 10.4036 6.21574 10.2967 6.21613C10.1899 6.21652 10.0853 6.24703 9.99496 6.30416C9.90463 6.36129 9.83225 6.44271 9.78609 6.5391V6.5391Z",
    fill: "none"
  }), _jsx("path", {
    d: "M92.0146 14.6116C92.0146 17.1047 93.1711 19.084 97.6948 19.9578C100.394 20.5244 100.959 20.9601 100.959 21.8597C100.959 22.7335 100.392 23.2992 98.4915 23.2992C96.194 23.2579 93.9424 22.6486 91.9375 21.5257V25.5864C93.2999 26.2546 95.0988 27 98.4402 27C103.169 27 105.046 24.891 105.046 21.7568L92.0146 14.6116ZM105.046 21.7568C105.046 18.801 103.478 17.4128 99.0572 16.4622C96.6154 15.9224 96.0243 15.3828 96.0243 14.6116C96.0243 13.6358 96.8981 13.2238 98.5173 13.2238C100.471 13.2238 102.399 13.8149 104.223 14.6374V10.7562C102.474 9.95983 100.568 9.5647 98.6457 9.59974C94.2763 9.59974 92.0146 11.5016 92.0146 14.6116",
    fill: "none"
  }), _jsx("path", {
    d: "M152.772 9.85657V26.7432H156.371V13.8662L157.887 17.2844L162.976 26.7432H167.5V9.85657H163.902V20.7545L162.539 17.5929L158.453 9.85657H152.772Z",
    fill: "none"
  }), _jsx("path", {
    d: "M129.886 9.85657H125.954V26.7432H129.886V9.85657Z",
    fill: "none"
  }), _jsx("path", {
    d: "M121.416 21.7568C121.416 18.801 119.848 17.4128 115.428 16.4622C112.986 15.9224 112.395 15.3828 112.395 14.6116C112.395 13.6358 113.269 13.2238 114.888 13.2238C116.841 13.2238 118.769 13.8149 120.594 14.6374V10.7562C118.844 9.95983 116.938 9.5647 115.016 9.59974C110.647 9.59974 108.385 11.5016 108.385 14.6116C108.385 17.1047 109.542 19.084 114.065 19.9578C116.764 20.5244 117.33 20.9601 117.33 21.8597C117.33 22.7335 116.763 23.2992 114.862 23.2992C112.565 23.2579 110.313 22.6486 108.308 21.5257V25.5864C109.67 26.2546 111.469 27 114.811 27C119.54 27 121.416 24.891 121.416 21.7568",
    fill: "none"
  }), _jsx("path", {
    d: "M61.4658 9.85657V26.7432H69.5487L70.8213 23.0933H65.4238V9.85657H61.4658Z",
    fill: "none"
  }), _jsx("path", {
    d: "M45.4966 9.85657V13.5061H49.866V26.7432H53.824V13.5061H58.502V9.85657H45.4966Z",
    fill: "none"
  }), _jsx("path", {
    d: "M39.7569 9.85657H34.5697L28.6812 26.7432H33.1781L34.0129 23.8991C36.0688 24.5028 38.255 24.5028 40.3109 23.8991L41.1457 26.7432H45.6436L39.7569 9.85657ZM37.1632 20.854C36.4306 20.8539 35.7019 20.7491 34.9991 20.5427L37.1632 13.1734L39.3273 20.5452C38.6243 20.7508 37.8956 20.8547 37.1632 20.854V20.854Z",
    fill: "none"
  }), _jsx("path", {
    d: "M83.4832 9.85657H78.2963L72.4077 26.7432H76.9056L77.7404 23.8991C79.7963 24.5028 81.9825 24.5028 84.0385 23.8991L84.8733 26.7432H89.3711L83.4832 9.85657ZM80.8897 20.854C80.1572 20.8539 79.4285 20.7491 78.7256 20.5427L80.8897 13.1734L83.0538 20.5452C82.3509 20.7508 81.6221 20.8547 80.8897 20.854Z",
    fill: "none"
  }), _jsx("path", {
    d: "M144.057 9.85657H138.87L132.981 26.7432H137.479L138.314 23.8991C140.37 24.5028 142.556 24.5028 144.612 23.8991L145.447 26.7432H149.945L144.057 9.85657ZM141.462 20.854C140.729 20.8539 140.001 20.7491 139.298 20.5427L141.462 13.1734L143.626 20.5452C142.923 20.7508 142.194 20.8547 141.462 20.854V20.854Z",
    fill: "none"
  })]
}), 'AtlassianMonoLogo', 'CompanyMono');
export default AtlassianMonoLogo;