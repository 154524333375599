/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PackhelpMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 127,
  height: 32,
  viewBox: "0 0 127 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.7,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.8667 6.27413C15.5087 6.27752 17.1011 6.83663 18.3849 7.86044C19.6686 8.88424 20.568 10.3124 20.9365 11.9125L27.712 8L13.8667 0L0 8L6.7776 11.9125C7.14621 10.3089 8.04823 8.87781 9.33604 7.85353C10.6238 6.82925 12.2212 6.27241 13.8667 6.27413Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M27.712 24V8L13.8667 16V32L27.712 24Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.8667 16L0 8V24L13.8667 32V16Z",
    fill: "white"
  }), _jsx("path", {
    d: "M55.4454 10.1675C52.0193 10.1675 50.4235 11.5136 50.4235 14.4021V14.5067L52.9174 14.4832V14.3787C52.9174 12.608 53.9371 12.2453 55.4774 12.2453C57.6876 12.2453 58.2081 13.1477 58.287 14.6133L54.8523 14.9547C51.5073 15.2768 50.0801 16.2987 50.0801 18.368C50.0801 20.5931 51.7441 21.9221 54.5323 21.9221C56.6998 21.9221 58.2486 21.0944 58.9078 20.2155L59.3494 21.7664H60.8001V15.2789C60.8001 12.5867 60.3905 10.1675 55.4454 10.1675ZM58.2828 16.8384C58.2828 18.7392 57.0988 19.8741 55.1169 19.8741C53.5574 19.8741 52.6934 19.3344 52.6934 18.3467C52.6934 17.632 53.1051 17.1733 55.2129 16.9365L58.2785 16.5973L58.2828 16.8384Z",
    fill: "white"
  }), _jsx("path", {
    d: "M67.8082 10.1675C63.1298 10.1675 62.1484 13.3675 62.1484 16.0469C62.1484 19.9488 64.0684 21.9285 67.8508 21.9285C71.1468 21.9285 72.9708 20.2688 72.9708 17.2565V17.1499H70.3682V17.2459C70.1868 18.9867 69.4487 19.6971 67.7868 19.6971C65.7154 19.6971 64.7511 18.5301 64.7511 16.0256C64.7511 13.4656 65.7026 12.288 67.7378 12.288C69.4103 12.288 70.1932 13.0752 70.3554 14.9163V15.0123H72.9602V14.9056C72.9602 11.8507 71.1319 10.1675 67.8082 10.1675Z",
    fill: "white"
  }), _jsx("path", {
    d: "M43.1508 7.51123H37.9731V21.7747H40.5993V17.0302H43.3257C49.1177 17.0302 49.1177 13.8451 49.1177 12.3155C49.1177 11.0014 49.1177 7.51123 43.1508 7.51123ZM46.4276 12.3155C46.4276 13.5955 46.0564 14.8883 43.3044 14.8883H40.5993V9.6979H43.2382C46.0649 9.6979 46.4276 11.0974 46.4276 12.3155Z",
    fill: "white"
  }), _jsx("path", {
    d: "M84.8276 10.321H81.2436L77.1562 14.5791V6.0415H74.5962V21.7727H77.1562V16.8831L81.7471 21.7727H85.2607L78.9823 15.7418L84.8276 10.321Z",
    fill: "white"
  }), _jsx("path", {
    d: "M92.0254 10.1674C90.3571 10.1674 89.1923 10.6068 88.5523 11.4708V6.0415H86.0371V21.7748H88.5523L88.595 15.3748C88.595 13.3354 89.011 12.3626 91.3683 12.3626C93.6424 12.3626 94.0094 13.25 94.0094 15.3748V21.7748H96.5459V15.5882C96.5438 12.3348 95.7864 10.1674 92.0254 10.1674Z",
    fill: "white"
  }), _jsx("path", {
    d: "M106.283 18.1973C106.104 18.9355 105.643 19.9168 103.706 19.9168C101.877 19.9168 100.866 18.9611 100.702 17.0752H108.898V16.9792C109.088 14.6325 108.621 12.768 107.548 11.6053C106.669 10.6517 105.395 10.1675 103.765 10.1675C99.9659 10.1675 98.0396 12.1387 98.0396 16.0256C98.0396 19.9125 99.9745 21.9264 103.8 21.9264C107.951 21.9264 108.811 19.6117 108.954 18.2336L108.964 18.1163H106.302L106.283 18.1973ZM100.723 14.9973C100.883 13.0773 101.79 12.1984 103.552 12.1984C104.416 12.1984 105.09 12.4373 105.555 12.9109C106.153 13.5147 106.283 14.368 106.281 14.9973H100.723Z",
    fill: "white"
  }), _jsx("path", {
    d: "M121.446 10.1675C118.72 10.1675 117.589 11.1787 117.156 11.7824L116.717 10.3232H115.26V26.1653H117.777V20.6016C118.27 21.1861 119.324 21.9264 121.491 21.9264C124.708 21.9264 126.338 19.9573 126.338 16.0704C126.338 12.1835 124.649 10.1675 121.446 10.1675ZM120.745 19.7675C118.255 19.7675 117.758 18.3552 117.758 16.0747C117.758 13.7941 118.278 12.3157 120.815 12.3157C123.279 12.3157 123.785 13.8091 123.785 16.0747C123.78 18.2869 123.264 19.7632 120.747 19.7632L120.745 19.7675Z",
    fill: "white"
  }), _jsx("path", {
    d: "M113.188 6.03955H110.628V21.7729H113.188V6.03955Z",
    fill: "white"
  })]
}), 'PackhelpMonoLogo', 'CompanyMono');
export default PackhelpMonoLogo;