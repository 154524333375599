/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CampingWorldHomeMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 203,
  height: 32,
  viewBox: "0 0 203 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#CampingWorldHomeMonoLogo_svg__clip0_18194_5440)",
    children: [_jsx("path", {
      d: "M27.388 25.5504L25.966 21.5694H19.9927L18.5999 25.5504H14.4471L21.5865 6.2998H24.628L31.7675 25.5504H27.388ZM23.5789 14.2325C23.3778 13.6952 23.1804 12.975 23.0086 12.2877H22.9538C22.8112 12.975 22.5845 13.6952 22.3835 14.2325L21.0748 18.0636H24.8876L23.5789 14.2325Z",
      fill: "white"
    }), _jsx("path", {
      d: "M49.5851 25.5504L48.9893 18.5133C48.9052 17.4057 48.8175 16.2066 48.8175 14.982H48.7626C48.3349 16.2103 47.7683 17.497 47.2273 18.6047L43.8129 25.6089H42.0509L38.6109 18.6047C38.0699 17.497 37.5289 16.2395 37.0756 15.0112H37.0171C37.0171 16.2688 36.933 17.4386 36.8453 18.5133L36.275 25.5504H32.0381L33.6027 6.44971H37.1852L41.1406 14.4738C41.6817 15.5815 42.4786 17.2887 42.9611 18.5462H43.016C43.4729 17.2887 44.1273 15.7935 44.7524 14.4738L48.6786 6.44971H52.2903L53.9098 25.5504H49.5851Z",
      fill: "white"
    }), _jsx("path", {
      d: "M61.6597 18.0344H59.2433V25.5504H55.0064V6.44971H61.6633C64.7926 6.44971 67.5233 8.63578 67.5233 12.2293C67.516 15.8191 64.7852 18.0344 61.6597 18.0344ZM61.0346 10.1017H59.2433V14.3824H61.0346C62.1715 14.3824 63.3084 13.7244 63.3084 12.2256C63.3084 10.7889 62.1715 10.1017 61.0346 10.1017Z",
      fill: "white"
    }), _jsx("path", {
      d: "M68.6237 25.5504V6.44971H73.0324V25.5504H68.6237Z",
      fill: "white"
    }), _jsx("path", {
      d: "M89.0477 25.7588L81.996 17.614C80.8591 16.298 79.5796 14.6785 78.6108 13.421L78.556 13.4503C78.6108 14.7992 78.6401 16.2651 78.6401 17.4641V25.5468H74.2314V6.44971H77.7847L83.9298 13.5745C84.7816 14.5616 86.0647 16.1189 86.8872 17.2265L86.9457 17.1973C86.8872 16.0604 86.8616 14.8321 86.8616 13.633V6.44971H91.2703V25.7624H89.0477V25.7588Z",
      fill: "white"
    }), _jsx("path", {
      d: "M102.19 25.9998C97.1851 25.9998 92.1221 22.5269 92.1221 16.0016C92.1221 9.41413 97.2985 6.00342 102.076 6.00342C104.606 6.00342 106.598 6.66143 107.849 7.53147L107.509 11.6039C106.258 10.6461 104.522 10.0173 102.533 10.0173C99.2323 10.0173 96.5015 12.3533 96.5015 16.1259C96.5015 19.7194 99.0897 22.0517 102.391 22.0517C103.444 22.0517 104.241 21.8725 104.866 21.485V18.7908H101.565V15.2266H108.901V24.0221C107.366 25.3125 105.118 25.9998 102.19 25.9998Z",
      fill: "white"
    }), _jsx("path", {
      d: "M132.736 25.6711H129.921L127.275 17.2265C126.876 15.9397 126.478 14.653 126.079 12.975H126.021C125.622 14.6493 125.224 15.969 124.825 17.2265L122.179 25.6711H119.364L113.307 6.44971H117.543L119.905 14.1741C120.274 15.3439 120.618 16.5685 120.874 18.0052H120.932C121.217 16.5685 121.528 15.3695 121.897 14.1741L124.284 6.44971H128.097L130.459 14.1448C130.773 15.194 131.113 16.6307 131.398 18.0088H131.453C131.709 16.6307 132.078 15.2854 132.422 14.1777L134.838 6.45336H138.79L132.736 25.6711Z",
      fill: "white"
    }), _jsx("path", {
      d: "M147.202 25.9998C142.197 25.9998 137.445 22.1357 137.445 15.9723C137.445 9.86377 142.193 6.00342 147.202 6.00342C152.235 6.00342 156.984 9.86377 156.984 15.9723C156.988 22.1357 152.235 25.9998 147.202 25.9998ZM147.202 10.0721C144.471 10.0721 141.912 12.4081 141.912 15.9687C141.912 19.5622 144.471 21.8981 147.202 21.8981C149.932 21.8981 152.521 19.5622 152.521 15.9687C152.521 12.4081 149.932 10.0721 147.202 10.0721Z",
      fill: "white"
    }), _jsx("path", {
      d: "M168.942 25.5504L164.05 18.0344H162.486V25.5504H158.249V6.44971H165.019C168.119 6.44971 170.879 8.63578 170.879 12.2293C170.879 14.4738 169.629 16.2724 168.094 17.0182L173.866 25.5504H168.942ZM164.365 10.1017H162.489V14.3824H164.365C165.502 14.3824 166.639 13.7244 166.639 12.2256C166.639 10.7889 165.502 10.1017 164.365 10.1017Z",
      fill: "white"
    }), _jsx("path", {
      d: "M173.943 25.5504V6.44971H178.351V21.5694H185.633V25.5504H173.943Z",
      fill: "white"
    }), _jsx("path", {
      d: "M192.374 25.5504H186.258V6.44971H192.374C197.606 6.44971 202.102 10.0432 202.102 16.0019C202.102 21.9606 197.606 25.5504 192.374 25.5504ZM191.863 10.4307H190.499V21.5694H191.863C195.021 21.5694 197.693 19.7124 197.693 16.0019C197.693 12.2878 195.017 10.4307 191.863 10.4307Z",
      fill: "white"
    }), _jsx("path", {
      d: "M10.5502 21.9569C7.11022 21.9569 4.46353 19.5917 4.46353 15.9982C4.46353 12.3754 7.11022 10.0102 10.4076 10.0102C12.3707 10.0102 13.7927 10.5805 14.7322 11.2093L15.0466 7.16615C13.9645 6.50813 12.1733 6 9.89947 6C5.15079 6 0 9.41437 0 15.9982C0 22.5564 5.11789 25.9963 9.89947 25.9963C11.2557 25.9963 12.3597 25.8465 13.248 25.5833L15.0137 20.82C14.0157 21.5109 12.5096 21.9569 10.5502 21.9569Z",
      fill: "white"
    }), _jsx("path", {
      d: "M200.548 25.419C199.952 25.419 199.492 24.951 199.492 24.3186C199.492 23.6789 199.949 23.2183 200.548 23.2183C201.141 23.2183 201.605 23.6789 201.605 24.3186C201.605 24.951 201.141 25.419 200.548 25.419ZM200.548 23.4559C200.084 23.4559 199.733 23.8141 199.733 24.3186C199.733 24.8158 200.084 25.174 200.548 25.174C201.005 25.174 201.356 24.8121 201.356 24.3186C201.36 23.8141 201.009 23.4559 200.548 23.4559ZM200.852 24.9693L200.541 24.4831H200.45V24.9583H200.172V23.6752H200.563C200.775 23.6752 200.991 23.8251 200.991 24.081C200.991 24.2236 200.914 24.3405 200.815 24.4063L201.093 24.8121L200.852 24.9693ZM200.537 23.9275H200.45V24.2309H200.537C200.625 24.2309 200.698 24.1834 200.698 24.081C200.698 23.975 200.625 23.9275 200.537 23.9275Z",
      fill: "white"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "CampingWorldHomeMonoLogo_svg__clip0_18194_5440",
      children: _jsx("rect", {
        width: 202.102,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'CampingWorldHomeMonoLogo', 'CompanyMono');
export default CampingWorldHomeMonoLogo;