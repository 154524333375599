/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TwilioSegmentMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 116,
  height: 32,
  viewBox: "0 0 116 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M24.6116 10.5226H10.2479C10.0884 10.5226 9.95911 10.6519 9.95911 10.8114V13.5518C9.95911 13.7113 10.0884 13.8406 10.2479 13.8406H24.6116C24.7711 13.8406 24.9003 13.7113 24.9003 13.5518V10.8114C24.9003 10.6519 24.7711 10.5226 24.6116 10.5226Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.6525 17.1585H0.288745C0.129275 17.1585 0 17.2878 0 17.4473V20.1877C0 20.3472 0.129275 20.4764 0.288745 20.4764H14.6525C14.812 20.4764 14.9412 20.3472 14.9412 20.1877V17.4473C14.9412 17.2878 14.812 17.1585 14.6525 17.1585Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.15262 25.4586C5.06885 25.4586 5.81159 24.7159 5.81159 23.7997C5.81159 22.8834 5.06885 22.1407 4.15262 22.1407C3.2364 22.1407 2.49365 22.8834 2.49365 23.7997C2.49365 24.7159 3.2364 25.4586 4.15262 25.4586Z",
    fill: "none"
  }), _jsx("path", {
    d: "M19.9234 8.02894C20.8396 8.02894 21.5823 7.28619 21.5823 6.36997C21.5823 5.45375 20.8396 4.711 19.9234 4.711C19.0072 4.711 18.2644 5.45375 18.2644 6.36997C18.2644 7.28619 19.0072 8.02894 19.9234 8.02894Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.4528 25.4639C11.5445 25.4639 10.6363 25.3379 9.76483 25.0806C9.35009 24.9704 8.92485 25.1961 8.77785 25.5951L8.21086 27.1543C8.05336 27.5848 8.27911 28.0678 8.7096 28.2253C8.7306 28.2306 8.74635 28.2358 8.76735 28.2463C9.96433 28.5981 11.2033 28.7766 12.4528 28.7766C17.7027 28.7766 22.4539 25.6739 24.5801 20.8755C24.7743 20.4555 24.5906 19.962 24.1706 19.7677C24.1496 19.7572 24.1286 19.7467 24.1023 19.7415L22.5431 19.1692C22.1441 19.017 21.6979 19.2007 21.5141 19.584C19.9076 23.1487 16.364 25.4429 12.4528 25.4534V25.4691V25.4639Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.4528 5.54049C13.361 5.54049 14.2693 5.66648 15.1407 5.92373C15.5555 6.03398 15.9807 5.80823 16.1277 5.40924L16.6895 3.85002C16.847 3.41953 16.6212 2.93653 16.1907 2.77904C16.1697 2.77379 16.154 2.76854 16.133 2.75804C9.75959 0.894321 2.99246 4.04951 0.32551 10.1289C0.131263 10.5489 0.31501 11.0424 0.735002 11.2366C0.756002 11.2471 0.777002 11.2576 0.803251 11.2629L2.36247 11.8351C2.76147 11.9874 3.20771 11.8036 3.39145 11.4204C4.99792 7.85569 8.54161 5.56149 12.4528 5.55099V5.53524V5.54049Z",
    fill: "none"
  }), _jsx("path", {
    d: "M35.2846 26.2986V24.5294C36.6443 25.8734 38.214 26.5821 39.8887 26.5821C41.3062 26.5821 42.12 25.8996 42.12 24.8286C42.12 23.4847 40.8232 23.3324 39.3375 23.1697C37.4213 22.9439 35.1113 22.6552 35.1113 20.151C35.1113 18.051 36.828 16.833 39.096 16.833C41.364 16.833 42.6869 17.589 43.5952 18.2873V20.0407C42.4349 18.8333 40.8285 18.1665 39.1538 18.198C37.5683 18.198 36.7965 18.9382 36.7965 20.0092C36.7965 21.243 38.1405 21.4267 39.6367 21.5947C41.5687 21.8204 43.8472 22.1512 43.8472 24.7079C43.8472 26.6819 42.3352 27.9891 39.9727 27.9891C37.6103 27.9891 36.3766 27.2488 35.2898 26.3144H35.2793L35.2846 26.2986Z",
    fill: "none"
  }), _jsx("path", {
    d: "M45.2594 22.5397V22.2824C45.2594 18.9068 47.3594 16.8225 50.2731 16.8225C53.0188 16.8225 54.998 18.4973 54.998 22.0042V22.8337H46.9971C47.1441 25.4901 48.9291 26.5454 51.3703 26.5454C52.6145 26.5769 53.8272 26.1516 54.7827 25.3484V26.9024C54.1947 27.3696 53.108 27.9891 51.071 27.9891C47.4591 27.9891 45.2594 25.9994 45.2594 22.5502V22.5397ZM47.0129 21.5422H53.2655C53.1553 19.2952 52.0475 18.1875 50.2626 18.1875C48.4776 18.1875 47.2596 19.2742 47.0181 21.5422H47.0129Z",
    fill: "none"
  }), _jsx("path", {
    d: "M56.0375 29.7688C56.0375 28.5718 56.9615 27.7003 59.1192 27.5533C59.1349 27.5533 59.1507 27.5376 59.1507 27.5218C59.1507 27.5061 59.1349 27.4903 59.1192 27.4903C57.2187 27.4903 56.5205 27.0231 56.5205 26.1044C56.5205 25.2591 57.4077 24.4611 59.5654 24.4454C59.5812 24.4454 59.5969 24.4296 59.5969 24.4139C59.5969 24.3981 59.5812 24.3824 59.5654 24.3824C57.6807 24.1094 56.5782 22.7812 56.5782 20.7705C56.5782 18.4133 58.3894 16.8435 61.0459 16.8435C62.2796 16.8435 62.8729 17.0273 63.6603 17.0273H66.5163V18.429H64.3271C65.0253 18.9277 65.4716 19.8832 65.4716 20.9332C65.4716 23.0017 63.8703 24.6606 61.1404 24.6606H60.4736C58.9984 24.6606 58.2267 24.9756 58.2267 25.5216C58.2267 26.1096 58.7412 26.2041 60.6994 26.2041H62.6734C65.1986 26.2041 66.4743 26.8499 66.4743 28.6926C66.4743 30.5353 64.8888 32 60.9041 32H60.6469C57.1295 32 56.0585 31.1128 56.0585 29.7846L56.0427 29.7741L56.0375 29.7688ZM64.7733 28.9393C64.7733 27.9418 63.9596 27.6846 62.3006 27.6846H60.5471C58.4472 27.6846 57.7227 28.4406 57.7227 29.3121C57.7227 30.11 58.5207 30.656 60.7834 30.656H60.9829C63.7443 30.656 64.7576 29.9945 64.7576 28.9393H64.7681H64.7733ZM63.7968 20.8072C63.7968 19.2532 62.8151 18.0458 61.0354 18.0458C59.2557 18.0458 58.2529 19.1167 58.2529 20.8072C58.2529 22.2824 59.2347 23.4059 61.0354 23.4059C62.8361 23.4059 63.7968 22.2824 63.7968 20.8072Z",
    fill: "none"
  }), _jsx("path", {
    d: "M67.9547 17.0115H69.6504V20.6655C69.9654 18.009 71.1624 16.833 73.2466 16.833C75.5881 16.833 76.8061 18.1928 77.0055 20.5342C77.3205 18.0615 78.5333 16.833 80.6175 16.833C83.1637 16.833 84.4342 18.5288 84.4342 21.3637V27.7948H82.7174V21.6945C82.7174 19.626 81.9037 18.24 79.956 18.24C78.2813 18.24 77.0423 19.5367 77.0581 21.8362V27.7948H75.3413V21.558C75.3413 19.584 74.5118 18.24 72.5799 18.24C70.9367 18.24 69.6662 19.5367 69.6662 22.0409V27.7948H67.9495V17.0063L67.9652 17.0168L67.9547 17.0115Z",
    fill: "none"
  }), _jsx("path", {
    d: "M86.1929 22.5397V22.2824C86.1929 18.9068 88.2928 16.8225 91.2065 16.8225C93.9522 16.8225 95.9314 18.4973 95.9314 22.0042V22.8337H87.9306C88.0776 25.4901 89.8626 26.5454 92.3038 26.5454C93.548 26.5769 94.7607 26.1516 95.7162 25.3484V26.9024C95.1282 27.3696 94.0415 27.9891 92.0045 27.9891C88.3926 27.9891 86.1929 25.9994 86.1929 22.5502V22.5397ZM87.9411 21.5422H94.1937C94.0835 19.2952 92.9757 18.1875 91.1908 18.1875C89.4058 18.1875 88.1878 19.2742 87.9463 21.5422H87.9411Z",
    fill: "none"
  }), _jsx("path", {
    d: "M97.7427 17.0115H99.4384V20.6445C99.8059 17.9145 101.192 16.8278 103.239 16.8278C106.001 16.8278 107.329 18.5445 107.329 21.474V27.8001H105.612V21.8257C105.612 19.6365 104.751 18.2505 102.609 18.2505C100.814 18.2505 99.4541 19.6102 99.4541 22.2352V27.8053H97.7374V17.0168H97.7479L97.7427 17.0115Z",
    fill: "none"
  }), _jsx("path", {
    d: "M110.227 24.5294V18.4028H108.279V17.169L108.867 17.1533C109.986 17.0955 110.343 16.6703 110.437 15.3106L110.495 14.5126H111.949V17.001H115.619V18.4028H111.949V24.4664C111.949 25.8681 112.595 26.4929 113.865 26.4929C114.474 26.5139 115.083 26.3669 115.619 26.0676V27.4116C114.936 27.7843 114.164 27.9628 113.387 27.9261C111.214 27.9261 110.232 26.8708 110.232 24.5136L110.227 24.5294Z",
    fill: "none"
  }), _jsx("path", {
    d: "M68.254 0.0385742H64.6473C64.6097 0.0385742 64.5791 0.0691302 64.5791 0.106823V2.34328C64.5791 2.38098 64.6097 2.41153 64.6473 2.41153H68.254C68.2917 2.41153 68.3223 2.38098 68.3223 2.34328V0.106823C68.3223 0.0691302 68.2917 0.0385742 68.254 0.0385742Z",
    fill: "none"
  }), _jsx("path", {
    d: "M68.233 2.99951H64.6264C64.5887 2.99951 64.5581 3.03007 64.5581 3.06776V11.9768C64.5581 12.0145 64.5887 12.0451 64.6264 12.0451H68.233C68.2707 12.0451 68.3013 12.0145 68.3013 11.9768V3.06776C68.3013 3.03007 68.2707 2.99951 68.233 2.99951Z",
    fill: "none"
  }), _jsx("path", {
    d: "M63.8336 0.0385742H60.2269C60.1892 0.0385742 60.1587 0.0691302 60.1587 0.106823V11.9821C60.1587 12.0198 60.1892 12.0504 60.2269 12.0504H63.8336C63.8713 12.0504 63.9019 12.0198 63.9019 11.9821V0.106823C63.9019 0.0691302 63.8713 0.0385742 63.8336 0.0385742Z",
    fill: "none"
  }), _jsx("path", {
    d: "M59.4342 0.0385742H55.8275C55.7898 0.0385742 55.7593 0.0691302 55.7593 0.106823V2.34328C55.7593 2.38098 55.7898 2.41153 55.8275 2.41153H59.4342C59.4719 2.41153 59.5025 2.38098 59.5025 2.34328V0.106823C59.5025 0.0691302 59.4719 0.0385742 59.4342 0.0385742Z",
    fill: "none"
  }), _jsx("path", {
    d: "M59.2977 2.99952H52.5253C52.4098 2.99952 52.3416 3.04677 52.3101 3.18327L51.5383 6.9107L50.7508 3.18327C50.7403 3.07302 50.6458 2.99427 50.5356 2.99952H47.3751C47.2649 2.99427 47.1704 3.07302 47.1599 3.18327L46.3724 6.9422L45.6322 3.18327C45.6007 3.05202 45.5324 2.99952 45.4169 2.99952H39.999V0.180324C39.999 0.033327 39.8993 -0.0349218 39.7313 0.0175772L36.4868 1.07281C36.3766 1.10431 36.2978 1.20406 36.3031 1.31955V2.55328C36.3031 2.89977 36.1194 3.05202 35.7729 3.05202H35.0694C34.9644 3.05202 34.8751 3.13077 34.8699 3.23577C34.8699 3.23577 34.8699 3.24627 34.8699 3.25152V5.67697C34.8699 5.78197 34.9486 5.87122 35.0536 5.87647C35.0536 5.87647 35.0641 5.87647 35.0694 5.87647H36.2558V8.92141C36.2558 11.0476 37.0276 12.1816 39.5843 12.1816C40.3298 12.2079 41.0753 12.0819 41.7735 11.8141C41.8837 11.7669 41.952 11.6514 41.9362 11.5306V9.15766C41.9362 9.02641 41.8207 8.95816 41.6527 9.04216C41.4428 9.14191 41.2118 9.19441 40.9808 9.18916C40.3245 9.18916 39.9938 8.94241 39.9938 8.11818V5.86072H41.721C41.826 5.86072 41.9152 5.78197 41.9205 5.67697C41.9205 5.67697 41.9205 5.66647 41.9205 5.66122V3.55076L44.115 11.8771C44.1465 11.9874 44.2515 12.0556 44.3617 12.0399H47.5904C47.7059 12.0399 47.7531 11.9926 47.7899 11.8771L48.8451 8.06568L49.8636 11.8771C49.8951 11.9926 49.9476 12.0399 50.0631 12.0399H53.3443C53.4545 12.0556 53.5648 11.9874 53.591 11.8771L55.7645 3.61901V11.8456C55.7645 11.9506 55.8432 12.0399 55.9482 12.0451C55.9482 12.0451 55.9587 12.0451 55.964 12.0451H59.3082C59.4132 12.0451 59.5024 11.9664 59.5077 11.8614C59.5077 11.8614 59.5077 11.8509 59.5077 11.8456V3.19902C59.5077 3.09402 59.4289 3.00477 59.3239 2.99952C59.3239 2.99952 59.3134 2.99952 59.3082 2.99952H59.2977Z",
    fill: "none"
  }), _jsx("path", {
    d: "M73.8242 2.85254C70.8108 2.85254 68.8105 4.71101 68.8105 7.39896V7.6142C68.8105 10.3022 70.8003 12.1816 73.8242 12.1816C76.8481 12.1816 78.8379 10.3074 78.8379 7.6142V7.39896C78.8379 4.71101 76.8481 2.85254 73.8242 2.85254ZM75.0579 7.56695C75.0579 8.78493 74.5434 9.27842 73.8242 9.27842C73.105 9.27842 72.5905 8.77968 72.5905 7.56695V7.45146C72.5905 6.21773 73.105 5.72424 73.8242 5.72424C74.5434 5.72424 75.0579 6.22298 75.0579 7.45146V7.56695Z",
    fill: "none"
  })]
}), 'TwilioSegmentMonoLogo', 'CompanyMono');
export default TwilioSegmentMonoLogo;