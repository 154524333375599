/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AngiMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 53,
  height: 32,
  viewBox: "0 0 53 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#AngiMonoLogo_svg__a)",
    children: [_jsx("path", {
      d: "M16.573.66h-5.948L8.59 13.344c-.043.006-.086.009-.131.014-4.495.598-7.983 4.382-7.944 8.613.017 1.769.62 3.15 1.703 4.283a5.07 5.07 0 0 0 3.655 1.579c.526 0 1.053-.085 1.562-.26 2.423-.834 3.228-2.867 3.605-4.728l-.002.007c.11-2.077-1-4.129-3.117-5.343l-.67 4.228c-.214 1.2-.494 1.917-1.093 2.123-.358.123-.8-.004-1.098-.317-.384-.402-.61-.851-.617-1.61-.02-2.114 1.643-3.775 3.478-4.424a5.33 5.33 0 0 1 1.846-.306c.68 0 1.395.131 2.048.35 2.48.585 3.982 3.215 4.353 6.062l.218 1.596h4.125L16.573.66Zm-4.23 12.957 1.182-9.077h.146l1.32 10.129a11.643 11.643 0 0 0-2.649-1.053Zm39.57-6.11V25.21h-4.115V7.507h4.116ZM39.9 31.341c5.405 0 5.823-3.08 5.823-7.435l.035-16.397h-2.13c-.767 0-1.708.575-1.772 1.316l-.05.572-.2-.539c-.397-1.071-1.396-1.637-2.89-1.637-1.5 0-2.61.576-3.294 1.712-.667 1.104-.806 2.531-.806 3.535v6.883c0 1.708.25 2.965.763 3.843.623 1.066 1.633 1.585 3.088 1.585 1.376 0 2.417-.585 2.93-1.647l.245-.506V25.159c0 1.733-.107 2.96-1.49 2.96-.318 0-1.348-.107-1.348-1.49v-.05H34.65v.085c0 1.345.364 2.428 1.082 3.216.88.97 2.283 1.461 4.166 1.461Zm1.67-12.705c0 1.687-.237 2.602-1.49 2.602-1.187 0-1.348-1.134-1.348-2.244v-6.166c0-1.39.499-2.065 1.527-2.065 1.155 0 1.312 1.135 1.312 2.244v5.629ZM52.13 3.615c0 1.254-.998 2.237-2.273 2.237a2.212 2.212 0 0 1-2.236-2.237c0-1.275.982-2.272 2.236-2.272a2.275 2.275 0 0 1 2.273 2.272ZM25.94 13.222v11.989h-4.152l-.036-17.702h2.308c.793 0 1.708.559 1.808 1.392l.046.381.194-.33c.67-1.148 1.753-1.73 3.223-1.73 2.364 0 3.564 1.524 3.564 4.532v13.458H28.78V12.685c0-1.275-.442-1.922-1.312-1.922-1.127 0-1.527.644-1.527 2.46Z",
      fill: "none"
    }), _jsx("path", {
      d: "M11.04 22.852c.207-.888.689-4.296.775-5.299a6.605 6.605 0 0 0-2.049-.35c-.67 0-1.269.1-1.845.306 2.118 1.214 3.227 3.265 3.118 5.343Z",
      fill: "none"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "AngiMonoLogo_svg__a",
      children: _jsx("path", {
        fill: "none",
        d: "M0 0h52.667v32H0z"
      })
    })
  })]
}), 'AngiMonoLogo', 'CompanyMono');
export default AngiMonoLogo;