/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BugHerdMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 90,
  height: 32,
  viewBox: "0 0 90 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5.52429 11.2381C6.25854 11.1486 6.80019 10.6857 6.83781 9.972C6.91373 8.53218 6.91618 7.08012 6.80333 5.64452C6.77375 5.26806 6.26849 4.73802 5.87908 4.62215C5.16909 4.41071 4.38237 4.45605 3.61064 4.39287V11.3475C4.27415 11.3127 4.90399 11.3138 5.52429 11.2381ZM3.61432 21.988C4.48568 21.9318 5.32352 21.9417 6.13736 21.8068C6.92177 21.6768 7.4712 21.1273 7.51004 20.3276C7.57778 18.9325 7.58746 17.5283 7.5065 16.1347C7.45757 15.2935 6.89587 14.7101 6.03269 14.5929C5.2551 14.4874 4.4621 14.4944 3.61432 14.4486V21.988ZM8.72761 12.7882C10.6988 13.7717 11.2902 15.4463 11.2113 17.4767C11.1655 18.6524 11.2806 19.8512 11.0901 21.001C10.6841 23.4506 8.71453 25.051 6.21656 25.1014C4.71916 25.1316 3.22041 25.1178 1.72247 25.1041C0.696953 25.0949 0.00754772 24.4224 0.00618472 23.3991C-0.00240217 16.597 -0.00144807 9.795 0.00536692 2.99295C0.00618472 2.07079 0.587368 1.31228 1.43897 1.30098C3.25012 1.27702 5.07968 1.21861 6.87025 1.43414C9.11211 1.70386 10.4672 3.3799 10.5371 5.64439C10.5756 6.89018 10.5767 8.13911 10.5377 9.38491C10.4939 10.7824 9.81678 11.8819 8.72761 12.7882Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M42.4211 14.6652C42.4211 17.6717 42.427 20.6185 42.4187 23.5651C42.4144 25.0631 40.9164 25.8939 39.6184 25.1318C38.9476 24.7381 38.7849 24.1108 38.7863 23.3892C38.7923 20.0095 38.7889 16.6296 38.7889 13.2498C38.7889 9.80199 38.7839 6.35406 38.7917 2.90627C38.7944 1.66592 39.4858 0.980934 40.6739 1.0004C41.7149 1.01742 42.4147 1.70894 42.4182 2.7629C42.427 5.30337 42.4211 7.84398 42.4211 10.3844C42.4212 10.6314 42.4211 10.8784 42.4211 11.1763H46.3855C46.3973 10.9252 46.4196 10.6645 46.42 10.4039C46.4227 7.88605 46.4132 5.36818 46.4259 2.85045C46.4319 1.66238 47.1318 0.989239 48.2945 1.0004C49.3502 1.01048 50.0465 1.68389 50.0506 2.74057C50.0605 5.2357 50.0539 7.73097 50.0539 10.2261C50.0539 14.6266 50.0547 19.0271 50.0535 23.4277C50.0531 24.704 49.4153 25.3853 48.2346 25.3802C47.0713 25.3751 46.4255 24.6779 46.4231 23.4012C46.4184 20.7699 46.4217 18.1386 46.4215 15.5075V14.6652H42.4211Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M30.5175 24.2896C30.5169 24.2975 30.5164 24.3056 30.5158 24.3135C30.3572 24.3135 30.1985 24.3133 30.0399 24.3135C29.158 24.3143 28.7818 24.6382 28.4805 25.6562C28.213 26.5597 28.4338 27.3586 29.0685 27.7841C30.0061 28.4127 31.9681 28.2774 32.7731 27.5004C33.3353 26.9575 33.5369 26.2931 33.3182 25.518C33.101 24.7479 32.5283 24.4572 31.803 24.3779C31.3764 24.3312 30.9461 24.318 30.5175 24.2896ZM32.3277 13.6673H32.3251C32.3251 13.0342 32.3581 12.399 32.3174 11.7686C32.255 10.8026 31.659 10.2571 30.7447 10.2635C29.8426 10.2699 29.2387 10.8338 29.2088 11.8043C29.1705 13.0467 29.1703 14.2919 29.209 15.5342C29.2392 16.501 29.8492 17.0701 30.7471 17.0763C31.6778 17.0827 32.2719 16.5054 32.3221 15.4983C32.3525 14.8894 32.3277 14.2777 32.3277 13.6673ZM34.9454 10.0293C35.6871 10.8467 35.781 11.7998 35.7428 12.7883C35.6982 13.9411 35.7806 15.1151 35.5874 16.2422C35.2037 18.4819 33.3818 19.9131 31.1104 19.9336C30.5247 19.9387 29.939 19.9344 29.1816 19.9344C29.1704 20.1889 29.0201 20.638 29.1761 20.9152C29.324 21.1777 29.7829 21.3682 30.1227 21.3995C30.8883 21.4702 31.6648 21.4206 32.4368 21.4238C35.4094 21.4356 37.2425 23.5978 36.7775 26.5416C36.4258 28.7674 34.9317 30.2726 32.4848 30.7806C30.9329 31.1028 29.3657 31.0756 27.8302 30.6646C25.0507 29.9206 24.0068 26.9065 25.8016 24.822C26.1054 24.4692 26.5058 24.1992 26.9136 23.8459C26.0043 23.2084 25.6205 22.3179 25.7119 21.2209C25.8014 20.1507 26.375 19.3726 27.2718 18.7256C25.8665 17.3381 25.6879 15.6054 25.7831 13.7934C25.8328 12.8451 25.7813 11.8803 25.9409 10.9512C26.2961 8.88383 27.988 7.52898 30.0888 7.38969C31.6909 7.28349 33.2854 7.06279 34.8832 6.89287C35.0185 6.87844 35.1534 6.85788 35.2891 6.84781C36.4758 6.75999 36.9703 7.09887 37.0845 8.0759C37.2125 9.17166 36.7511 9.76692 35.6171 9.9593C35.4206 9.99252 35.2198 10.0014 34.9454 10.0293Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M79.486 16.483C79.486 14.873 79.4503 13.2618 79.5037 11.6535C79.5247 11.0234 79.2395 10.7444 78.7339 10.551C77.5614 10.1025 76.6428 10.7259 76.637 12.0107C76.6237 14.9585 76.6289 17.9065 76.6392 20.8543C76.6402 21.1432 76.6888 21.4459 76.7849 21.7175C76.9978 22.3185 77.6283 22.6051 78.3547 22.4752C79.1747 22.3284 79.485 21.993 79.4856 21.2449C79.4868 19.6577 79.486 18.0703 79.486 16.483ZM79.5338 23.9477C78.8255 24.8997 77.9703 25.4841 76.8213 25.5144C74.6667 25.5713 73.1679 24.1914 73.1473 22.0312C73.1115 18.2648 73.1202 14.4978 73.1468 10.7312C73.1557 9.45971 73.5971 8.34366 74.829 7.76542C76.126 7.15655 77.4277 7.2268 78.6566 7.99865C78.9214 8.16503 79.1456 8.39581 79.486 8.67751V7.43838C79.486 5.9181 79.4846 4.39769 79.4864 2.87741C79.4879 1.62685 80.0564 1.00735 81.2032 1.00055C82.3811 0.993601 82.9796 1.62263 82.9803 2.89947C82.9837 9.5252 82.9819 16.1509 82.9819 22.7768C82.9819 23.0717 82.9891 23.367 82.9797 23.6616C82.9502 24.5856 82.4719 25.2244 81.7145 25.363C80.7557 25.5382 80.0498 25.1761 79.6642 24.3081C79.6225 24.2146 79.5939 24.1152 79.5338 23.9477Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.8504 23.9595C18.9595 25.4507 17.5934 25.732 16.061 25.4332C14.5482 25.1383 13.5529 23.8906 13.5356 22.1552C13.4923 17.7994 13.5108 13.4428 13.5248 9.08662C13.5277 8.20721 14.1599 7.59807 15.02 7.53694C16.1089 7.45946 16.8078 7.93777 16.9705 8.8907C17.0121 9.13428 17.011 9.38684 17.0111 9.63532C17.0136 13.1294 17.0119 16.6235 17.0144 20.1177C17.0145 20.4119 17.025 20.7072 17.05 21.0004C17.141 22.0735 17.78 22.5224 18.8187 22.2478C19.4024 22.0936 19.7273 21.8052 19.7217 21.1079C19.6906 17.2057 19.7035 13.303 19.709 9.40046C19.7107 8.20476 20.3101 7.55259 21.3893 7.53571C22.5648 7.51719 23.2004 8.13437 23.2013 9.32108C23.205 14.0404 23.2031 18.7597 23.2027 23.479C23.2027 24.6143 22.8389 25.1995 22.0368 25.359C20.9552 25.5743 20.29 25.1624 19.8504 23.9595Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M59.0792 14.7003C59.0792 13.567 59.1325 12.5072 59.0614 11.4559C59.0095 10.6892 58.5049 10.3092 57.799 10.3124C57.1359 10.3154 56.5751 10.7045 56.5284 11.3899C56.454 12.4813 56.5085 13.5814 56.5085 14.7003H59.0792ZM56.4725 17.4833C56.4725 18.8206 56.4405 20.0653 56.4836 21.3075C56.5134 22.1676 57.0386 22.643 57.8091 22.6392C58.594 22.6354 59.0722 22.1718 59.162 21.3236C59.174 21.2111 59.1736 21.0973 59.1768 20.9842C59.2108 19.8357 59.7475 19.2922 60.839 19.3019C61.9169 19.3112 62.4664 19.8872 62.4793 21.0202C62.5099 23.7339 60.7703 25.511 58.047 25.5071C57.3953 25.5062 56.7236 25.4708 56.0956 25.3137C54.2467 24.851 53.0886 23.5227 53.0176 21.4005C52.9092 18.1642 52.896 14.916 53.0454 11.6821C53.1983 8.37302 55.7355 6.99284 58.8256 7.474C61.1282 7.83249 62.488 9.48497 62.5674 11.876C62.614 13.28 62.5901 14.687 62.5714 16.0923C62.5593 17.0131 62.0503 17.4789 61.1024 17.482C59.5853 17.4868 58.0682 17.4833 56.4725 17.4833Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M68.4113 9.26335C68.7038 7.95397 69.4692 7.42229 70.7034 7.53271C71.6788 7.61985 72.1593 8.21797 72.1343 9.37908C72.1114 10.4454 71.5556 11.0953 70.6267 11.0988C70.2906 11.1 69.9534 11.0269 69.6181 10.9783C68.8543 10.8672 68.5911 11.0874 68.5909 11.8527C68.5904 15.7765 68.595 19.7004 68.5883 23.6242C68.586 24.977 67.4223 25.7588 66.1501 25.2813C65.4075 25.0026 65.0961 24.4621 65.0955 23.4195C65.0936 18.7925 65.0937 14.1657 65.0954 9.53865C65.0956 8.37563 65.4936 7.79875 66.3647 7.68071C67.5361 7.52195 68.0433 7.88916 68.4113 9.26335Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M89.8386 23.2547C89.8299 24.525 88.9524 25.384 87.6825 25.3654C86.4672 25.3477 85.6299 24.4685 85.6343 23.2146C85.6388 21.9435 86.508 21.0976 87.7901 21.1165C89.0175 21.1345 89.8472 22.0006 89.8386 23.2547Z",
    fill: "white"
  })]
}), 'BugHerdMonoLogo', 'CompanyMono');
export default BugHerdMonoLogo;