/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ToggleMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 123,
  height: 32,
  viewBox: "0 0 123 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#ToggleMonoLogo_svg__clip0_11879_153471)",
    children: [_jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9.20333 22.2773H23.6773C28.4404 22.2773 32.3542 18.3634 32.3542 13.6003C32.3542 8.80028 28.4404 4.92334 23.6773 4.92334H9.20333C4.44023 4.92334 0.526367 8.80028 0.526367 13.6003C0.526367 18.3634 4.44023 22.2773 9.20333 22.2773ZM29.1047 13.6742C29.1047 16.9777 26.4267 19.6558 23.1232 19.6558C19.8196 19.6558 17.1416 16.9777 17.1416 13.6742C17.1416 10.3707 19.8196 7.69263 23.1232 7.69263C26.4267 7.69263 29.1047 10.3707 29.1047 13.6742Z",
      fill: "none"
    }), _jsx("path", {
      d: "M45.0196 7.58137H39.5181V4.73828H53.6228V7.58137H48.1212V22.1291H45.0566V7.58137H45.0196Z",
      fill: "none"
    }), _jsx("path", {
      d: "M52.958 15.6307V15.5938C52.958 11.8276 55.9488 8.72607 59.9734 8.72607C63.9981 8.72607 66.9519 11.7907 66.9519 15.52V15.5569C66.9519 19.2861 63.9611 22.3877 59.9365 22.3877C55.9119 22.4246 52.958 19.36 52.958 15.6307ZM63.9242 15.6307V15.5938C63.9242 13.2676 62.2627 11.3846 59.8996 11.3846C57.4996 11.3846 55.9488 13.2676 55.9488 15.5569V15.5938C55.9488 17.883 57.6103 19.8031 59.9365 19.8031C62.3734 19.8031 63.9242 17.92 63.9242 15.6307Z",
      fill: "none"
    }), _jsx("path", {
      d: "M69.2046 15.6307V15.5938C69.2046 11.8276 72.1954 8.72607 76.22 8.72607C80.2446 8.72607 83.1985 11.7907 83.1985 15.52V15.5569C83.1985 19.2861 80.2077 22.3877 76.1831 22.3877C72.1584 22.4246 69.2046 19.36 69.2046 15.6307ZM80.1708 15.6307V15.5938C80.1708 13.2676 78.5093 11.3846 76.1462 11.3846C73.7462 11.3846 72.1954 13.2676 72.1954 15.5569V15.5938C72.1954 17.883 73.8569 19.8031 76.1831 19.8031C78.62 19.8031 80.1708 17.92 80.1708 15.6307Z",
      fill: "none"
    }), _jsx("path", {
      d: "M86.189 15.6307V15.5938C86.189 11.8276 89.1797 8.72607 93.2044 8.72607C97.229 8.72607 100.183 11.7907 100.183 15.52V15.5569C100.183 19.2861 97.1921 22.3877 93.1675 22.3877C89.1428 22.4246 86.189 19.36 86.189 15.6307ZM97.1552 15.6307V15.5938C97.1552 13.2676 95.4936 11.3846 93.1305 11.3846C90.7305 11.3846 89.1798 13.2676 89.1798 15.5569V15.5938C89.1798 17.883 90.8413 19.8031 93.1675 19.8031C95.6044 19.8031 97.1552 17.92 97.1552 15.6307Z",
      fill: "none"
    }), _jsx("path", {
      d: "M97.2663 12.8247V8.65234H100.257V21.2801C100.257 23.4955 99.7033 25.1201 98.5956 26.2278C97.414 27.4094 95.5679 28.0001 93.2048 28.0001C91.0263 28.0001 89.0325 27.4463 87.334 26.4494L88.4417 24.197C89.8448 25.0832 91.3217 25.6001 93.094 25.6001C95.7894 25.6001 97.2294 24.197 97.2294 21.5755V20.5416",
      fill: "none"
    }), _jsx("path", {
      d: "M80.281 12.8247V8.65234H83.2718V21.2801C83.2718 23.4955 82.7179 25.1201 81.6102 26.2278C80.4287 27.4094 78.5825 28.0001 76.2194 28.0001C74.041 28.0001 72.0471 27.4463 70.3486 26.4494L71.4563 24.197C72.8594 25.0832 74.3363 25.6001 76.1087 25.6001C78.8041 25.6001 80.2441 24.197 80.2441 21.5755V20.5416",
      fill: "none"
    }), _jsx("path", {
      d: "M103.838 4H106.829V22.1293H103.838V4Z",
      fill: "none"
    }), _jsx("path", {
      d: "M109.672 15.6309V15.594C109.672 11.8278 112.331 8.76318 116.097 8.76318C120.269 8.76318 122.374 12.0494 122.374 15.8155C122.374 16.074 122.337 16.3694 122.337 16.6648H112.7C113.032 18.8063 114.546 19.9879 116.466 19.9879C117.943 19.9879 118.977 19.434 120.011 18.4371L121.783 19.9879C120.528 21.4648 118.829 22.4617 116.429 22.4617C112.626 22.4248 109.672 19.6555 109.672 15.6309ZM119.383 14.6709C119.198 12.7509 118.054 11.237 116.06 11.237C114.214 11.237 112.922 12.6401 112.663 14.6709H119.383Z",
      fill: "none"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "ToggleMonoLogo_svg__clip0_11879_153471",
      children: _jsx("rect", {
        width: 121.847,
        height: 32,
        fill: "none",
        transform: "translate(0.526367)"
      })
    })
  })]
}), 'ToggleMonoLogo', 'CompanyMono');
export default ToggleMonoLogo;