/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var NextdoorMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 144,
  height: 32,
  viewBox: "0 0 144 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M58.6904 11.0342H54.2636C54.125 11.0342 53.9968 11.1035 53.9205 11.2178L50.6338 16.1142L47.347 11.2178C47.2708 11.1035 47.1408 11.0342 47.004 11.0342H42.6066C42.2757 11.0342 42.0782 11.4032 42.2635 11.6787L47.5324 19.4997L42.1509 26.9776C41.9534 27.2513 42.1509 27.6325 42.4871 27.6325H46.952C47.0854 27.6325 47.2119 27.5684 47.2881 27.4592L50.665 22.7206L54.0418 27.4592C54.1198 27.5684 54.2445 27.6325 54.3779 27.6325H58.8117C59.1495 27.6325 59.3436 27.2513 59.1478 26.9776L53.7663 19.4997L59.0352 11.6787C59.2206 11.4032 59.023 11.0342 58.6921 11.0342H58.6904Z",
    fill: "none"
  }), _jsx("path", {
    d: "M68.7169 20.9258V15.0176H72.3588C72.5875 15.0176 72.7729 14.8322 72.7729 14.6035V11.4467C72.7729 11.218 72.5875 11.0326 72.3588 11.0326H68.7169V6.83108C68.7169 6.60238 68.5315 6.41699 68.3028 6.41699H64.5361C64.3074 6.41699 64.122 6.60238 64.122 6.83108C64.122 11.5022 64.5049 11.0292 61.1004 11.0292C60.8041 11.0292 60.5615 11.2683 60.5615 11.5646C60.5615 12.3269 60.5615 13.7164 60.5615 14.4771C60.5615 14.7733 60.8024 15.0124 61.0986 15.0124H64.1203V21.8181C64.1203 25.8672 66.9704 27.8268 70.524 27.8268C71.6242 27.8268 72.4489 27.728 73.1281 27.5513C73.3118 27.5028 73.44 27.3399 73.44 27.1493V23.9994C73.44 23.7292 73.187 23.5386 72.9237 23.6009C72.52 23.6962 72.073 23.7447 71.5618 23.7447C69.5832 23.7447 68.7117 22.9478 68.7117 20.9223L68.7169 20.9258Z",
    fill: "none"
  }), _jsx("path", {
    d: "M87.5588 12.8934C86.8207 11.8314 85.0447 10.6688 82.73 10.6688C78.2703 10.6688 74.8501 14.6191 74.8501 19.3335C74.8501 24.0479 78.2703 27.9983 82.73 27.9983C85.043 27.9983 86.8207 26.8357 87.5588 25.7736V27.0956C87.5588 27.3919 87.7996 27.6327 88.0959 27.6327H91.7378C91.9665 27.6327 92.1519 27.4473 92.1519 27.2186V4.41409C92.1519 4.18539 91.9665 4 91.7378 4H87.9711C87.7424 4 87.557 4.18539 87.557 4.41409V12.8934H87.5588ZM83.702 23.8487C81.188 23.8487 79.4779 21.9238 79.4779 19.3335C79.4779 16.7433 81.188 14.8184 83.702 14.8184C86.216 14.8184 87.9261 16.7433 87.9261 19.3335C87.9261 21.9238 86.216 23.8487 83.702 23.8487Z",
    fill: "none"
  }), _jsx("path", {
    d: "M142.446 10.835C140.568 10.835 138.858 12.0634 138.288 13.6903V11.4483C138.288 11.2196 138.102 11.0342 137.874 11.0342H134.107C133.878 11.0342 133.693 11.2196 133.693 11.4483V27.2202C133.693 27.4489 133.878 27.6343 134.107 27.6343H137.874C138.102 27.6343 138.288 27.4489 138.288 27.2202V19.699C138.288 16.977 139.729 15.2843 141.909 15.2843C142.462 15.2843 142.959 15.3588 143.468 15.4939C143.73 15.5632 143.988 15.3674 143.988 15.0972V11.3565C143.988 11.159 143.848 10.9926 143.655 10.951C143.219 10.8575 142.822 10.8367 142.446 10.8367V10.835Z",
    fill: "none"
  }), _jsx("path", {
    d: "M103.251 10.6689C98.3894 10.6689 94.4321 14.4859 94.4321 19.3337C94.4321 24.1815 98.3894 27.9984 103.251 27.9984C108.113 27.9984 112.07 24.1815 112.07 19.3337C112.07 14.4859 108.113 10.6689 103.251 10.6689ZM103.251 23.7484C100.838 23.7484 99.0599 21.8893 99.0599 19.3337C99.0599 16.7781 100.838 14.919 103.251 14.919C105.665 14.919 107.409 16.7781 107.409 19.3337C107.409 21.8893 105.666 23.7484 103.251 23.7484Z",
    fill: "none"
  }), _jsx("path", {
    d: "M122.595 10.6689C117.734 10.6689 113.776 14.4859 113.776 19.3337C113.776 24.1815 117.734 27.9984 122.595 27.9984C127.457 27.9984 131.414 24.1815 131.414 19.3337C131.414 14.4859 127.457 10.6689 122.595 10.6689ZM122.595 23.7484C120.182 23.7484 118.404 21.8893 118.404 19.3337C118.404 16.7781 120.182 14.919 122.595 14.919C125.009 14.919 126.754 16.7781 126.754 19.3337C126.754 21.8893 125.011 23.7484 122.595 23.7484Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.2333 10.4678C11.1458 10.4678 8.51569 11.8851 7.03951 14.0543C6.91649 14.2345 6.70858 14.6209 6.4279 14.6226C5.07647 14.633 4.9933 12.968 4.94999 11.4831C4.94306 11.2648 4.75593 11.0846 4.53763 11.0846L0.625402 11.0621C0.394965 11.0621 0.204379 11.2527 0.209577 11.4831C0.296207 15.1112 0.96326 17.575 5.35888 18.5539C5.59971 18.6076 5.76778 18.8259 5.76778 19.0719C5.76778 20.5793 5.76778 25.7217 5.76778 27.2169C5.76778 27.4456 5.95317 27.6275 6.18187 27.6275H9.98147C10.2102 27.6275 10.3956 27.4421 10.3956 27.2134V18.8016C10.3956 16.9322 11.721 14.8115 14.235 14.8115C16.749 14.8115 18.0745 16.9322 18.0745 18.8016V27.2134C18.0745 27.4421 18.2599 27.6275 18.4886 27.6275H22.2882C22.5169 27.6275 22.7023 27.4421 22.7023 27.2134V18.1779C22.7023 13.8568 19.0153 10.4644 14.2368 10.4644L14.2333 10.4678Z",
    fill: "none"
  }), _jsx("path", {
    d: "M33.4459 10.6689C28.3486 10.6689 24.627 14.42 24.627 19.3337C24.627 24.5783 28.5167 27.9049 33.5135 27.9984C37.4344 28.0712 40.0471 25.8257 40.6674 25.0391C40.8095 24.8607 40.7627 24.5956 40.5791 24.4605L37.9698 22.4229C37.6856 22.1405 37.4673 22.2947 37.2958 22.4316C36.8782 22.7694 36.0812 23.7449 33.5464 23.8488C31.3356 23.9389 29.3552 22.6204 29.0867 20.3958H41.2461C41.4627 20.3958 41.6446 20.2242 41.6602 20.0077C41.6949 19.5173 41.6949 19.1552 41.6949 18.8364C41.6949 13.4584 37.8381 10.6707 33.4459 10.6707V10.6689ZM29.2547 17.3083C29.6914 15.2829 31.6024 14.42 33.3125 14.42C35.0226 14.42 36.6998 15.3487 37.1017 17.3083H29.2547Z",
    fill: "none"
  })]
}), 'NextdoorMonoLogo', 'CompanyMono');
export default NextdoorMonoLogo;