/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var HotjarMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 68,
  height: 32,
  viewBox: "0 0 68 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M4.13483 12.5987C5.33371 12.013 6.6608 11.735 7.99532 11.79C10.5767 11.8787 11.9947 13.1807 12.0508 15.7353C12.1136 18.4115 12.0938 21.0647 12.0838 23.7376C12.0838 24.826 11.7963 25.0757 10.7155 25.0757H9.2182C8.26297 25.0757 7.96551 24.7864 7.94567 23.8297V16.6657C7.94567 15.5578 7.42011 15.1007 6.29311 15.1468C6.09689 15.1501 5.90133 15.1687 5.70801 15.2027C4.23063 15.5019 4.14464 15.607 4.14464 17.1062V23.491C4.14464 24.8554 3.90999 25.0855 2.55815 25.0855H1.061C0.356987 25.0855 0.0463051 24.7568 0 24.0335V7.16407C0 5.93116 0.264452 5.67151 1.48407 5.67151H2.8061C3.83076 5.67151 4.14471 5.95748 4.16463 6.98656C4.19436 8.52851 4.16463 10.0704 4.16463 11.6091C4.13483 11.8919 4.13483 12.1977 4.13483 12.5987Z",
    fill: "none"
  }), _jsx("path", {
    d: "M26.2005 18.8488C26.1113 19.5588 26.0584 20.4695 25.8699 21.3573C25.5719 23.3572 24.0111 24.9377 22.0061 25.2697C20.6359 25.5624 19.217 25.5445 17.8548 25.217C16.1286 24.8862 14.7533 23.5873 14.3314 21.8898C13.7072 19.7486 13.7072 17.4753 14.3314 15.3341C14.9925 13.0853 16.721 11.8787 19.2529 11.7931C20.1664 11.7446 21.0824 11.8009 21.983 11.9607C24.3726 12.4375 25.7278 14.0485 26.0352 16.6786C26.1113 17.3462 26.1343 17.9906 26.2005 18.8488ZM22.0293 18.9014C21.983 18.1681 21.9566 17.6684 21.9102 17.1752C21.8812 16.9166 21.828 16.6614 21.7516 16.4124C21.5999 15.5828 20.8417 15.002 19.9966 15.0678C19.1723 15.0057 18.4344 15.5738 18.2877 16.3828C17.9129 17.861 17.9129 19.4088 18.2877 20.8871C18.4419 21.7101 19.2099 22.2746 20.0461 22.1791C20.8384 22.2335 21.5471 21.692 21.6987 20.9168C21.8453 20.2502 21.9556 19.5763 22.0293 18.898V18.9014V18.9014Z",
    fill: "none"
  }), _jsx("path", {
    d: "M52.6388 16.8564C52.8206 15.4821 52.5132 15.058 51.1813 15.1204C50.0957 15.2116 49.0172 15.3708 47.952 15.5971C47.0496 15.7583 46.3887 15.5314 46.2631 14.8345C46.0581 13.6838 46.1375 12.6974 47.0662 12.4213C49.2118 11.7191 51.508 11.6055 53.713 12.0924C55.716 12.5691 56.6876 13.8875 56.6876 16.0377V23.652C56.6876 24.2274 56.6876 24.8193 56.0003 25.0428C55.2348 25.3205 54.3766 25.0856 53.8617 24.4576L53.6502 24.1519C52.4781 25.0846 51.0007 25.5531 49.5021 25.4669C46.2465 25.3486 44.3757 22.2614 45.7903 19.3255C46.4514 17.9283 47.7736 17.4876 49.1387 17.2707C50.3152 17.0963 51.482 16.9878 52.6388 16.8564ZM52.7017 19.0986C51.9117 19.2169 51.2176 19.2826 50.5434 19.4274C49.8402 19.5182 49.3093 20.1063 49.2939 20.8115C49.1453 21.52 49.5222 22.234 50.1929 22.5145C50.854 22.771 52.2587 22.5145 52.5826 22.0675C52.6448 21.9808 52.6793 21.8778 52.6817 21.7715C52.7017 20.9068 52.7017 20.0453 52.7017 19.0887V19.0986V19.0986Z",
    fill: "none"
  }), _jsx("path", {
    d: "M29.0328 12.2502V10.5505C28.9727 10.0295 29.311 9.54509 29.8229 9.41948C30.4968 9.19472 31.182 9.00375 31.8754 8.84738C32.3759 8.68199 32.9167 8.9517 33.083 9.44964C33.1275 9.58272 33.1417 9.72408 33.1248 9.8633C33.1479 10.5965 33.1248 11.3231 33.1248 12.1384H35.0848C36.04 12.1615 36.3672 12.4671 36.4069 13.4535C36.4598 15.4524 36.3507 15.5644 34.3643 15.5644H33.2107C33.1799 15.7427 33.159 15.9228 33.1479 16.1034V20.3217C33.1479 21.6368 33.5446 22.0379 34.8303 22.1299C35.9573 22.2121 36.2648 22.4588 36.2648 23.3727C36.3044 24.8291 36.0068 25.3223 34.8303 25.3914C33.897 25.4613 32.9587 25.4138 32.0373 25.2501C30.1236 24.8654 29.0924 23.629 29.0296 21.6171C28.9734 19.8614 29.0065 18.1024 28.9999 16.3566V15.5675H27.8431C27.2985 15.6111 26.8211 15.2083 26.7756 14.6667C26.7756 14.636 26.7756 14.6065 26.7756 14.5779C26.6168 13.2332 26.8581 12.8749 28.1439 12.5131L29.0328 12.2502Z",
    fill: "none"
  }), _jsx("path", {
    d: "M43.4077 20.427C43.4077 22.7051 43.4506 24.9836 43.4077 27.2619C43.3965 29.8894 41.2461 32.0104 38.6049 31.9992C38.3537 31.9981 38.1028 31.9775 37.8549 31.9372C36.9328 31.8221 36.6054 31.4605 36.5923 30.5103V29.6653C36.632 28.8697 36.8799 28.5935 37.6731 28.4488C38.896 28.2221 39.296 27.7649 39.2992 26.4927V13.5357C39.2992 12.4375 39.5803 12.1449 40.6876 12.1089H42.1913C43.0805 12.1482 43.4142 12.4705 43.4208 13.3613V20.384L43.4077 20.427Z",
    fill: "none"
  }), _jsx("path", {
    d: "M61.8704 13.0392C62.9531 12.2664 64.2434 11.8324 65.5756 11.7931C66.8976 11.7931 67.2018 12.0693 67.1984 13.3713V13.9565C67.1984 14.7983 66.8481 15.0974 66.0218 15.1829C65.2439 15.2773 64.4717 15.4123 63.7081 15.5873C63.1959 15.6959 63.0008 16.0048 63.0107 16.5736C63.0503 18.9506 63.0107 21.3079 63.0107 23.6719C63.0107 24.8325 62.7561 25.0757 61.5762 25.0988H59.874C59.3847 25.1385 58.9555 24.7762 58.9155 24.2893C58.9108 24.2305 58.9117 24.1712 58.9189 24.1126V13.1773C58.881 12.6903 59.2472 12.2649 59.7367 12.2272C59.7374 12.2271 59.7379 12.2271 59.7385 12.2271H59.7782C60.6012 12.089 61.4671 11.9608 61.8704 13.0392Z",
    fill: "none"
  }), _jsx("path", {
    d: "M42.9645 9.79429C43.7115 7.12464 43.4438 5.77994 42.1315 5.52014C42.3332 6.4276 42.5579 7.35804 41.5995 7.98596C41.097 8.31474 40.7004 8.18979 39.8113 7.50924C39.4014 8.34764 39.5403 9.18271 39.7286 10.0769C38.4529 9.85669 37.448 9.33058 36.9821 8.10432C36.5014 6.99023 36.7647 5.69647 37.6431 4.856C37.95 4.54607 38.2817 4.26128 38.6346 4.00444C40.0692 2.93263 40.3467 1.89372 39.5899 0.282718C39.556 0.19045 39.5285 0.0960391 39.5074 0C41.1023 0.241096 42.6107 0.877659 43.8933 1.85106C46.6896 4.12613 46.3526 8.21279 43.2654 9.7154C43.1609 9.72339 43.059 9.75 42.9645 9.79429Z",
    fill: "none"
  })]
}), 'HotjarMonoLogo', 'CompanyMono');
export default HotjarMonoLogo;