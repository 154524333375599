/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var GoogleMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 95,
  height: 32,
  viewBox: "0 0 95 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M40.5461 16.8104C40.5461 21.2521 37.0713 24.5251 32.807 24.5251C28.5427 24.5251 25.0679 21.2521 25.0679 16.8104C25.0679 12.3373 28.5427 9.09558 32.807 9.09558C37.0713 9.09558 40.5461 12.3373 40.5461 16.8104ZM37.1583 16.8104C37.1583 14.0347 35.1444 12.1356 32.807 12.1356C30.4696 12.1356 28.4557 14.0347 28.4557 16.8104C28.4557 19.5582 30.4696 21.4851 32.807 21.4851C35.1444 21.4851 37.1583 19.5547 37.1583 16.8104Z",
    fill: "none"
  }), _jsx("path", {
    d: "M57.2419 16.8104C57.2419 21.2521 53.7671 24.5251 49.5028 24.5251C45.2385 24.5251 41.7637 21.2521 41.7637 16.8104C41.7637 12.3408 45.2385 9.09558 49.5028 9.09558C53.7671 9.09558 57.2419 12.3373 57.2419 16.8104ZM53.8541 16.8104C53.8541 14.0347 51.8402 12.1356 49.5028 12.1356C47.1654 12.1356 45.1515 14.0347 45.1515 16.8104C45.1515 19.5582 47.1654 21.4851 49.5028 21.4851C51.8402 21.4851 53.8541 19.5547 53.8541 16.8104Z",
    fill: "none"
  }), _jsx("path", {
    d: "M73.2417 9.56167V23.4121C73.2417 29.1095 69.8817 31.4365 65.9095 31.4365C62.1704 31.4365 59.92 28.9356 59.0713 26.8904L62.0209 25.6625C62.5461 26.9182 63.833 28.3999 65.9061 28.3999C68.4487 28.3999 70.0243 26.8312 70.0243 23.8782V22.7686H69.9061C69.1478 23.7043 67.6869 24.5217 65.8435 24.5217C61.9861 24.5217 58.4521 21.1617 58.4521 16.8382C58.4521 12.4834 61.9861 9.09558 65.8435 9.09558C67.6835 9.09558 69.1443 9.91297 69.9061 10.8208H70.0243V9.56515H73.2417V9.56167ZM70.2643 16.8382C70.2643 14.1217 68.4522 12.1356 66.1461 12.1356C63.8087 12.1356 61.8504 14.1217 61.8504 16.8382C61.8504 19.5269 63.8087 21.4851 66.1461 21.4851C68.4522 21.4851 70.2643 19.5269 70.2643 16.8382Z",
    fill: "none"
  }), _jsx("path", {
    d: "M78.5461 1.44348V24.0522H75.2417V1.44348H78.5461Z",
    fill: "none"
  }), _jsx("path", {
    d: "M91.4227 19.3496L94.0522 21.1026C93.2035 22.3582 91.1583 24.5217 87.6244 24.5217C83.2418 24.5217 79.9688 21.1339 79.9688 16.8069C79.9688 12.2191 83.2696 9.09216 87.2453 9.09216C91.2488 9.09216 93.207 12.2782 93.847 14L94.1983 14.8765L83.8853 19.1478C84.6748 20.6956 85.9027 21.4852 87.6244 21.4852C89.3496 21.4852 90.5461 20.6365 91.4227 19.3496ZM83.3288 16.5739L90.2227 13.7113C89.8435 12.7478 88.7027 12.0765 87.3601 12.0765C85.6383 12.0765 83.2418 13.5965 83.3288 16.5739V16.5739Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.5602 14.8035V11.5304H23.5898C23.6976 12.1009 23.7533 12.7757 23.7533 13.5061C23.7533 15.9617 23.082 18.9983 20.9185 21.1617C18.8141 23.353 16.1254 24.5217 12.5637 24.5217C5.96195 24.5217 0.410645 19.1443 0.410645 12.5426C0.410645 5.94087 5.96195 0.563477 12.5637 0.563477C16.2159 0.563477 18.8176 1.99652 20.7724 3.86435L18.4628 6.17391C17.0611 4.85913 15.162 3.83652 12.5602 3.83652C7.73934 3.83652 3.96891 7.72174 3.96891 12.5426C3.96891 17.3635 7.73934 21.2487 12.5602 21.2487C15.6872 21.2487 17.468 19.993 18.6089 18.8522C19.5341 17.927 20.1428 16.6052 20.3828 14.8L12.5602 14.8035V14.8035Z",
    fill: "none"
  })]
}), 'GoogleMonoLogo', 'CompanyMono');
export default GoogleMonoLogo;