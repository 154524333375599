/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BigRedGroupMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 173,
  height: 32,
  viewBox: "0 0 173 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M10.6724 15.4997C12.7232 13.4518 12.1146 9.4165 8.9417 8.53675C8.34293 8.36328 7.72458 8.26636 7.10144 8.24831C6.64282 8.24831 6.15535 8.24831 5.65923 8.23389H3.80167C2.48926 8.23966 1.15377 8.24542 0 8.24831V23.7405H0.703799C3.08345 23.7405 5.46021 23.804 7.83409 23.7146C9.47532 23.6511 10.9406 23.0713 11.8261 21.557C13.0145 19.561 12.5732 17.2765 10.6724 15.4997ZM3.96031 11.8279C4.96697 11.8279 5.9621 11.7702 6.9428 11.851C7.50526 11.8942 7.75621 12.3759 7.76198 12.9441C7.76775 13.5124 7.50238 13.9941 6.94569 14.0402C5.9621 14.1239 4.96697 14.0633 3.96031 14.0633V11.8279ZM7.29182 20.161C6.2015 20.2244 5.10831 20.1783 3.97762 20.1783V17.5534C5.13138 17.5534 6.18997 17.5102 7.2572 17.5679C7.98985 17.6083 8.38213 18.1448 8.38501 18.8832C8.3879 19.561 7.97831 20.1148 7.29182 20.161Z",
    fill: "white"
  }), _jsx("path", {
    d: "M100.562 14.9402V18.4563H103.75C103.751 18.7894 103.659 19.1162 103.484 19.3993C103.308 19.6824 103.056 19.9104 102.757 20.0572C100.525 21.1417 97.5223 20.2245 96.8012 18.0063C96.3872 16.7728 96.3611 15.442 96.7262 14.1931C97.1906 12.6038 98.278 11.7154 99.9394 11.5539C101.69 11.3808 103.112 11.9173 104.015 13.8384L107.9 13.2211C107.543 11.9741 106.863 10.8431 105.93 9.94151C103.254 7.43783 98.4309 7.52725 95.7282 9.36462C92.9505 11.2539 92.1803 13.9999 92.5207 17.1526C92.8091 19.9129 94.1475 22.0272 96.7521 23.2098C97.8272 23.695 98.9881 23.9614 100.167 23.9936C101.346 24.0258 102.52 23.8231 103.62 23.3973C105.199 22.8306 106.489 21.6618 107.208 20.1466C108.033 18.5054 108.192 16.7516 108.073 14.9546L100.562 14.9402Z",
    fill: "white"
  }), _jsx("path", {
    d: "M128.957 9.06471C125.596 10.9136 124.192 14.9749 125.495 18.8342C126.586 22.0417 129.479 23.9426 133.283 23.9541C136.396 23.9281 139.023 22.8724 140.535 20.0342C142.046 17.1959 141.948 14.2653 140.264 11.4905C137.927 7.64269 132.349 7.20426 128.957 9.06471ZM136.768 18.7189C135.695 20.3861 133.306 20.9918 131.328 20.0284C129.655 19.2208 129.156 17.7209 129.138 15.6787C129.138 14.2509 129.678 12.7481 131.362 11.978C132.998 11.2309 134.648 11.3146 136.084 12.5029C137.633 13.7836 137.945 16.893 136.768 18.7189Z",
    fill: "white"
  }), _jsx("path", {
    d: "M79.7773 8.42448C77.4986 8.18219 75.1825 8.2918 72.8836 8.24854C72.4596 8.24854 72.0356 8.24854 71.5827 8.24854V23.7408C73.7374 23.7408 75.8228 23.7408 77.9054 23.7408C78.289 23.7408 78.6755 23.7408 79.0591 23.7004C81.1532 23.5158 82.9646 22.7456 84.3203 21.1044C85.849 19.2468 86.1808 17.0662 85.9269 14.7587C85.5548 11.3752 83.1579 8.78504 79.7773 8.42448ZM81.7762 17.9431C81.3061 19.4112 80.2302 20.1641 78.739 20.2823C77.7179 20.3631 76.6824 20.2996 75.6065 20.2996V11.606C76.8843 11.6666 78.1303 11.6493 79.3562 11.8021C80.51 11.9492 81.2945 12.7107 81.6897 13.801C82.1865 15.1351 82.217 16.5981 81.7762 17.9517V17.9431Z",
    fill: "white"
  }), _jsx("path", {
    d: "M121.434 17.089C123.041 15.2747 123.101 13.1691 122.273 11.0317C121.512 9.07029 119.845 8.28862 117.814 8.26266C115.535 8.22516 113.254 8.24824 110.975 8.24824C110.828 8.25624 110.682 8.27164 110.537 8.29439V23.6972H114.457V18.5485H115.36C116.037 18.5716 116.378 18.687 116.672 19.2263C117.024 19.8724 119 23.6943 119 23.6943H123.514L120.404 17.9255C120.791 17.7049 121.139 17.4223 121.434 17.089ZM116.969 15.0324C116.161 15.0526 115.351 15.0324 114.448 15.0324C114.448 14.0171 114.428 13.071 114.468 12.1278V11.7499C114.468 11.7499 115.14 11.7499 115.833 11.7499C116.147 11.7499 116.461 11.7499 116.773 11.7643L117.061 11.7788C117.277 11.7819 117.49 11.8287 117.688 11.9162C117.885 12.0037 118.063 12.1302 118.21 12.288C118.357 12.4458 118.471 12.6318 118.545 12.8347C118.619 13.0377 118.651 13.2534 118.639 13.469C118.636 14.3661 117.973 15.0093 116.975 15.0324H116.969Z",
    fill: "white"
  }), _jsx("path", {
    d: "M62.9063 17.5706H68.0983V14.1093H62.9063V11.7268H69.0155V8.28857H58.9201V23.6914H69.0155V20.1839H62.9006L62.9063 17.5706Z",
    fill: "white"
  }), _jsx("path", {
    d: "M172.064 11.0404C171.297 9.07029 169.633 8.28862 167.602 8.26266C165.323 8.22516 163.042 8.24824 160.763 8.24824C160.616 8.25624 160.47 8.27164 160.325 8.29439V23.6972H164.242V18.5485C165.54 18.5485 166.714 18.5802 167.888 18.5485C168.514 18.5531 169.134 18.4273 169.708 18.179C170.283 17.9306 170.799 17.5653 171.225 17.1063C172.834 15.2747 172.892 13.1691 172.064 11.0404ZM166.763 15.0324C165.952 15.0526 165.145 15.0324 164.242 15.0324C164.242 14.0171 164.222 13.071 164.262 12.1278V11.7499C164.262 11.7499 164.937 11.7499 165.629 11.7499C165.944 11.7499 166.258 11.7499 166.57 11.7643L166.858 11.7788C167.074 11.7801 167.287 11.825 167.485 11.9107C167.683 11.9965 167.862 12.1214 168.011 12.2778C168.159 12.4343 168.275 12.6192 168.35 12.8214C168.426 13.0236 168.46 13.239 168.45 13.4546C168.424 14.3661 167.755 15.0093 166.763 15.0324Z",
    fill: "white"
  }), _jsx("path", {
    d: "M14.9759 23.6827H18.8728V8.28857H14.9759V23.6827Z",
    fill: "white"
  }), _jsx("path", {
    d: "M153.581 8.30322V9.04452C153.581 11.603 153.581 14.1615 153.581 16.72C153.586 17.1497 153.555 17.579 153.489 18.0035C153.2 19.6188 152.246 20.4409 150.679 20.4409C149.113 20.4409 148.15 19.6188 147.87 18.0035C147.803 17.579 147.772 17.1497 147.778 16.72C147.778 14.1615 147.778 11.603 147.778 9.04452V8.30322H143.803C143.803 11.5597 143.762 14.7586 143.823 17.9574C143.84 19.337 144.334 20.6682 145.222 21.7244C146.664 23.4349 148.568 23.931 150.676 23.9281C152.785 23.9252 154.715 23.4349 156.131 21.7244C157.019 20.6682 157.513 19.337 157.53 17.9574C157.593 14.7586 157.55 11.5597 157.55 8.30322H153.581Z",
    fill: "white"
  }), _jsx("path", {
    d: "M29.3893 14.9402V18.4563H32.5622C32.5638 18.7894 32.4716 19.1162 32.2961 19.3993C32.1206 19.6824 31.869 19.9104 31.57 20.0572C29.3374 21.1417 26.3347 20.2245 25.6136 18.0063C25.1982 16.773 25.172 15.4418 25.5386 14.1931C26.0174 12.6154 27.1135 11.7154 28.7634 11.5539C30.5143 11.3808 31.9363 11.9173 32.8391 13.8384L36.7273 13.2211C36.3673 11.9751 35.6882 10.8446 34.7572 9.94151C32.0805 7.43783 27.2577 7.52725 24.5522 9.36462C21.7774 11.2626 21.0072 14.0085 21.3447 17.1554C21.6331 19.9158 22.9744 22.0301 25.5761 23.2127C26.6517 23.698 27.8131 23.9644 28.9926 23.9966C30.1722 24.0288 31.3464 23.8261 32.4468 23.4002C34.0255 22.8335 35.3156 21.6647 36.035 20.1495C36.86 18.5082 37.0186 16.7545 36.9004 14.9575L29.3893 14.9402Z",
    fill: "white"
  }), _jsx("path", {
    d: "M54.804 17.089C56.4106 15.2747 56.4712 13.1691 55.6434 11.0317C54.8876 9.07029 53.2204 8.28862 51.1898 8.26266C48.9111 8.22516 46.6295 8.24824 44.3508 8.24824C44.204 8.25624 44.0577 8.27164 43.9124 8.29439V23.6972H47.8323V18.5485H48.7467C49.4245 18.5716 49.7649 18.687 50.0591 19.2263C50.4081 19.8724 52.3868 23.6943 52.3868 23.6943H56.901L53.7916 17.9255C54.1721 17.7034 54.5141 17.4209 54.804 17.089ZM50.3389 15.0324C49.5313 15.0526 48.7207 15.0324 47.8179 15.0324C47.8179 14.0171 47.7977 13.071 47.8381 12.1278V11.7499C47.8381 11.7499 48.5102 11.7499 49.2024 11.7499C49.5168 11.7499 49.8312 11.7499 50.1428 11.7643L50.4312 11.7788C50.6471 11.7819 50.8601 11.8287 51.0575 11.9162C51.2549 12.0037 51.4326 12.1302 51.5799 12.288C51.7273 12.4458 51.8412 12.6318 51.9149 12.8347C51.9887 13.0377 52.0207 13.2534 52.009 13.469C52.0119 14.3661 51.3427 15.0093 50.3504 15.0324H50.3389Z",
    fill: "white"
  })]
}), 'BigRedGroupMonoLogo', 'CompanyMono');
export default BigRedGroupMonoLogo;