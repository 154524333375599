/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var Cyclofix = createCompanyLogosComponent(_jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 149,
  height: 32,
  fill: "none",
  children: _jsx("path", {
    fill: "#fff",
    fillRule: "evenodd",
    d: "M24.498 8.178a4.089 4.089 0 1 0 0-8.178 4.089 4.089 0 0 0 0 8.178Zm-12.9 17.902H0l8.246-8.115 4.746 4.75c1.241 1.242.362 3.365-1.394 3.365Zm14.535 0h6.309L19.197 12.818a4.049 4.049 0 0 0-5.708-.022L12.3 13.97l10.87 10.88a4.186 4.186 0 0 0 2.964 1.23Zm26.982-5.125 1.642 3.449c-1.24 1.181-3.377 1.785-5.328 1.785-5.389 0-8.548-3.57-8.548-7.988 0-4.417 3.222-7.99 8.548-7.99 1.951 0 3.903.544 5.328 1.786l-1.642 3.48c-.868-.848-2.17-1.391-3.563-1.391-2.91 0-4.708 1.845-4.708 4.115 0 2.268 1.766 4.116 4.708 4.116 1.396 0 2.695-.514 3.563-1.362ZM62.795 32 72.1 10.697h-4.459l-3.717 8.835-3.717-8.835h-4.461l5.946 13.92L58.333 32h4.462Zm22.034-11.045 1.643 3.449c-1.24 1.181-3.377 1.785-5.328 1.785-5.389 0-8.548-3.57-8.548-7.988 0-4.417 3.222-7.99 8.548-7.99 1.951 0 3.903.544 5.328 1.786l-1.642 3.48c-.868-.848-2.17-1.391-3.563-1.391-2.913 0-4.708 1.845-4.708 4.115 0 2.268 1.766 4.116 4.708 4.116 1.396 0 2.695-.514 3.563-1.362Zm8.333 4.753V4.405h-3.966v21.303h3.966Zm18.832-7.506c0 4.417-3.654 7.987-8.178 7.987-4.522 0-8.176-3.57-8.176-7.988 0-4.417 3.654-7.99 8.176-7.99 4.521.003 8.178 3.573 8.178 7.99Zm-3.966 0c0-2.27-1.889-4.116-4.212-4.116-2.324 0-4.213 1.845-4.213 4.115 0 2.268 1.889 4.116 4.213 4.116 2.323 0 4.212-1.845 4.212-4.116ZM120.419 3.92c-4.09 0-4.925 3.389-4.957 6.777h-1.98v3.391h1.983v11.618h3.963v-11.62h3.469v-3.389h-3.469c0-2.178 0-3.6 3.469-2.905V4.403c-.744-.302-1.302-.483-2.478-.483Zm5.944 6.777h3.963v15.009h-3.963V10.697Zm17.592 15.009h4.956l-5.7-7.99 5.203-7.019h-4.956l-2.725 4.237-2.727-4.237h-4.956l5.203 7.02-5.698 7.989h4.956l3.222-4.84 3.222 4.84Z",
    clipRule: "evenodd"
  })
}), 'Cyclofix', 'CompanyMono');
export default Cyclofix;