/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AllerganMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 144,
  height: 32,
  viewBox: "0 0 144 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M108.894 12.0133C109.022 11.6046 109.169 11.2681 109.226 10.917C109.3 10.4544 109.544 10.3661 109.971 10.3797C110.96 10.4111 111.951 10.3897 112.998 10.3897C112.816 11.8119 112.646 13.1826 112.465 14.5518C112.115 17.1995 111.767 19.8475 111.403 22.4934C111.136 24.4379 110.509 26.2115 108.923 27.5093C107.826 28.4077 106.545 28.8525 105.159 29.0193C102.765 29.3073 100.526 28.8508 98.4671 27.578C98.3174 27.4855 98.1823 27.3692 98.002 27.2355C98.5377 26.3514 99.0564 25.4955 99.581 24.6299C100.977 25.5361 102.406 26.1144 104.049 26.0453C105.767 25.9729 106.815 25.1882 107.271 23.5295C107.431 22.945 107.523 22.3416 107.558 21.7059C107.385 21.8805 107.216 22.0582 107.04 22.2294C105.025 24.1906 101.488 24.0101 99.7965 21.8598C99.1994 21.1006 98.8735 20.2201 98.7638 19.2772C98.4511 16.59 99.0043 14.124 100.857 12.0777C102.331 10.4493 104.19 9.77425 106.378 10.1839C107.483 10.3907 108.131 10.8878 108.894 12.0133ZM108.34 15.9207C108.322 15.7635 108.3 15.5301 108.268 15.298C108.009 13.3874 106.278 12.5257 104.62 13.5169C104.214 13.7593 103.834 14.1139 103.565 14.5021C102.741 15.6932 102.451 17.0327 102.709 18.465C103.028 20.2292 104.574 21.0306 106.16 20.1871C106.705 19.8974 107.188 19.3922 107.551 18.8802C108.149 18.0367 108.3 17.0212 108.34 15.9207Z",
    fill: "none"
  }), _jsx("path", {
    d: "M55.8376 5.65745C57.0261 11.815 58.2045 17.92 59.3894 24.0588C57.9931 24.0588 56.66 24.0588 55.2674 24.0588C55.0542 22.8304 54.8395 21.5938 54.6158 20.305C54.4427 20.2958 54.2692 20.279 54.0958 20.2788C52.3248 20.2768 50.5537 20.2926 48.7831 20.2665C48.3672 20.2604 48.1884 20.4128 48.0394 20.7763C47.5881 21.8769 47.0992 22.9622 46.6206 24.0636C45.3632 24.0636 44.1192 24.0636 42.792 24.0636C42.8608 23.8796 42.9099 23.7199 42.9785 23.5691C45.6276 17.7412 48.2814 11.9155 50.9215 6.08359C51.0708 5.7537 51.244 5.64163 51.6061 5.64782C52.9955 5.67159 54.3855 5.65745 55.8376 5.65745ZM49.5272 17.1089C51.1138 17.1089 52.6171 17.1089 54.1546 17.1089C53.7177 14.5665 53.2888 12.0711 52.8599 9.57571C52.8257 9.57514 52.7915 9.57457 52.7572 9.574C51.6882 12.0678 50.6191 14.5617 49.5272 17.1089Z",
    fill: "none"
  }), _jsx("path", {
    d: "M124.591 12.0987C124.733 11.5939 124.831 11.1599 124.985 10.7463C125.038 10.6019 125.224 10.409 125.354 10.4057C126.435 10.3784 127.516 10.3902 128.682 10.3902C128.085 14.974 127.494 19.5076 126.901 24.0568C125.695 24.0568 124.528 24.0568 123.298 24.0568C123.298 23.5044 123.298 22.9739 123.298 22.3432C123.14 22.4922 123.061 22.5559 122.995 22.6303C121.616 24.1714 119.911 24.7345 117.909 24.2253C115.964 23.7307 114.981 22.3019 114.625 20.443C114.062 17.5061 114.528 14.7441 116.424 12.3564C117.819 10.5993 119.681 9.81782 121.93 10.1406C122.981 10.2916 123.804 10.8624 124.372 11.7758C124.426 11.8627 124.487 11.9457 124.591 12.0987ZM123.982 16.3263C123.982 16.0735 124.002 15.8188 123.978 15.5683C123.861 14.3364 123.241 13.4525 122.308 13.2077C121.338 12.9534 120.528 13.2905 119.842 13.9617C118.495 15.2777 117.933 18.2064 118.679 19.9423C119.08 20.8746 119.801 21.3333 120.816 21.2696C121.871 21.2034 122.577 20.5813 123.105 19.7349C123.753 18.6961 124.006 17.5418 123.982 16.3263Z",
    fill: "none"
  }), _jsx("path", {
    d: "M131.677 10.4094C132.904 10.4094 134.055 10.4094 135.251 10.4094C135.251 10.9789 135.251 11.5305 135.251 12.1705C135.436 11.9932 135.562 11.8833 135.676 11.7619C137.14 10.2023 138.952 9.77585 140.974 10.2324C142.566 10.5915 143.54 11.9241 143.481 13.7091C143.434 15.113 143.237 16.5137 143.07 17.9113C142.825 19.9516 142.546 21.9879 142.279 24.0518C140.98 24.0518 139.707 24.0518 138.389 24.0518C138.559 22.7343 138.723 21.4484 138.892 20.1633C139.086 18.6955 139.304 17.2306 139.472 15.76C139.563 14.9686 139.527 14.1479 138.726 13.702C137.882 13.2319 137.037 13.41 136.292 13.9796C135.34 14.7067 134.852 15.6742 134.72 16.8819C134.464 19.2145 134.13 21.5386 133.827 23.866C133.821 23.9177 133.794 23.9669 133.767 24.0482C132.498 24.0482 131.227 24.0482 129.9 24.0482C130.493 19.4954 131.083 14.9705 131.677 10.4094Z",
    fill: "none"
  }), _jsx("path", {
    d: "M85.3423 19.9715C85.7849 20.7212 86.2237 21.4644 86.6956 22.2638C86.4017 22.5045 86.1341 22.7732 85.8218 22.9714C83.7601 24.2792 81.5142 24.7068 79.1324 24.2015C77.0961 23.7694 75.7617 22.4909 75.2604 20.472C74.5334 17.5441 75.0376 14.836 77.0126 12.4971C78.8695 10.298 81.976 9.4774 84.7028 10.4166C86.1638 10.9198 87.1349 11.8706 87.3622 13.4584C87.5829 14.9998 87.0182 16.2206 85.8047 17.152C84.5102 18.1455 83.0009 18.6201 81.4151 18.8675C80.5798 18.9978 79.734 19.0612 78.8889 19.1552C78.7463 20.2474 79.6086 21.2225 80.8731 21.3717C82.3465 21.5455 83.6479 21.0717 84.8662 20.2957C85.0183 20.1988 85.1646 20.0929 85.3423 19.9715ZM78.9723 16.507C79.4213 16.4621 79.8791 16.4711 80.3069 16.362C81.1901 16.1367 82.1015 15.9427 82.9186 15.5594C83.7204 15.1832 83.9625 14.2972 83.6626 13.5741C83.3936 12.9256 82.6412 12.5975 81.7862 12.756C80.2154 13.0472 78.9557 14.7001 78.9723 16.507Z",
    fill: "none"
  }), _jsx("path", {
    d: "M64.9218 24.0601C63.6174 24.0601 62.3596 24.0601 61.0254 24.0601C61.8467 17.7417 62.6641 11.4542 63.4848 5.14038C64.7867 5.14038 66.0593 5.14038 67.3814 5.14038C66.5612 11.4495 65.7453 17.7253 64.9218 24.0601Z",
    fill: "none"
  }), _jsx("path", {
    d: "M71.7957 24.0531C70.4797 24.0531 69.2052 24.0531 67.8984 24.0531C68.7213 17.7291 69.5401 11.4373 70.3597 5.13879C71.6712 5.13879 72.9278 5.13879 74.2543 5.13879C73.4337 11.4516 72.618 17.7272 71.7957 24.0531Z",
    fill: "none"
  }), _jsx("path", {
    d: "M92.309 24.0815C91.1262 24.0815 89.9703 24.0815 88.8144 24.0815C88.7265 24.0815 88.6386 24.0815 88.4609 24.0815C89.0569 19.5016 89.6469 14.9682 90.2394 10.4154C91.4484 10.4154 92.6142 10.4154 93.8309 10.4154C93.8309 11.059 93.8309 11.6784 93.8309 12.2821C94.3109 11.773 94.7291 11.2295 95.2447 10.8041C96.1743 10.0372 97.2594 9.88898 98.4105 10.2336C98.7561 10.3371 98.888 10.5175 98.7774 10.9163C98.4888 11.9563 98.2524 13.0109 97.9881 14.0887C97.6989 14.0182 97.4441 13.9347 97.1827 13.8962C96.1669 13.7466 95.2936 14.0229 94.5764 14.7786C93.597 15.8108 93.1995 17.0592 93.0633 18.4525C92.886 20.2666 92.618 22.0719 92.3868 23.8806C92.3803 23.9318 92.3493 23.9798 92.309 24.0815Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.0176 7.32286C13.0173 5.51837 14.4812 4.04314 16.284 4.0312C18.0967 4.01919 19.612 5.53893 19.5894 7.34617C19.5668 9.15175 18.0826 10.6194 16.2875 10.6114C14.486 10.6033 13.0179 9.12683 13.0176 7.32286Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.0176 24.652C13.0269 22.8538 14.5161 21.3787 16.3124 21.3883C18.1094 21.3979 19.5906 22.8845 19.5904 24.6785C19.5902 26.495 18.0692 27.9972 16.2591 27.9686C14.4535 27.9401 13.0083 26.4614 13.0176 24.652Z",
    fill: "none"
  }), _jsx("path", {
    d: "M15.7338 16.0204C15.727 17.8229 14.248 19.2946 12.4471 19.2906C10.6477 19.2867 9.17185 17.8096 9.16603 16.0067C9.16017 14.1877 10.6736 12.6855 12.4882 12.7094C14.2959 12.7333 15.7406 14.2071 15.7338 16.0204Z",
    fill: "none"
  }), _jsx("path", {
    d: "M33.6299 15.9957C33.6317 14.1976 35.1098 12.7156 36.9075 12.7096C38.6999 12.7036 40.1919 14.1791 40.2121 15.9775C40.2323 17.7806 38.7098 19.3026 36.8982 19.2904C35.098 19.2782 33.6281 17.7964 33.6299 15.9957Z",
    fill: "none"
  }), _jsx("path", {
    d: "M29.7989 7.34802C29.7869 5.52896 31.2223 4.05696 33.033 4.03145C34.8323 4.0061 36.3297 5.46854 36.3483 7.26915C36.3674 9.11352 34.9137 10.6028 33.0846 10.6128C31.2806 10.6226 29.8109 9.16225 29.7989 7.34802Z",
    fill: "none"
  }), _jsx("path", {
    d: "M36.3492 24.6844C36.3465 26.4953 34.8864 27.9618 33.0793 27.9686C31.2546 27.9754 29.7828 26.4838 29.799 24.6439C29.8148 22.8303 31.2903 21.3713 33.0924 21.387C34.9017 21.4029 36.3518 22.871 36.3492 24.6844Z",
    fill: "none"
  }), _jsx("path", {
    d: "M24.6233 8.45586e-05C26.4241 0.012832 27.8861 1.49912 27.8728 3.30361C27.8595 5.10148 26.3699 6.576 24.5741 6.56904C22.7764 6.56207 21.2864 5.07153 21.29 3.28372C21.2937 1.46429 22.7932 -0.0128698 24.6233 8.45586e-05Z",
    fill: "none"
  }), _jsx("path", {
    d: "M24.6004 32C22.7765 32.0052 21.2739 30.5041 21.2902 28.6931C21.3063 26.8998 22.8122 25.4149 24.5974 25.432C26.4083 25.4493 27.87 26.9162 27.8729 28.7194C27.8759 30.5222 26.4069 31.9948 24.6004 32Z",
    fill: "none"
  }), _jsx("path", {
    d: "M30.2344 17.7989C29.234 17.7916 28.4402 17.0043 28.4327 16.012C28.425 15.0019 29.239 14.1975 30.2652 14.2009C31.2566 14.2043 32.0627 15.0021 32.0714 15.9884C32.0802 16.9911 31.2531 17.8063 30.2344 17.7989Z",
    fill: "none"
  }), _jsx("path", {
    d: "M28.739 9.80963C28.7254 10.8116 27.8807 11.6244 26.8774 11.601C25.8998 11.5781 25.0895 10.7395 25.1087 9.77046C25.1284 8.77673 25.9323 7.98157 26.9163 7.98254C27.9297 7.98355 28.7525 8.80842 28.739 9.80963Z",
    fill: "none"
  }), _jsx("path", {
    d: "M26.9152 24.0021C25.8917 23.9996 25.0858 23.184 25.1079 22.1732C25.1293 21.1938 25.9534 20.3924 26.9325 20.3988C27.9358 20.4055 28.7557 21.2434 28.7385 22.2446C28.7216 23.231 27.919 24.0046 26.9152 24.0021Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.80505 14.2006C6.82742 14.2098 7.61385 15.0204 7.59363 16.0441C7.57402 17.0372 6.77909 17.8025 5.77116 17.7987C4.74562 17.7949 3.97417 16.9962 3.9915 15.9563C4.00819 14.9545 4.79636 14.1915 5.80505 14.2006Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.9483 22.2142C11.9456 23.2288 11.1847 23.9957 10.1732 24.0035C9.13679 24.0115 8.34599 23.2316 8.34864 22.2041C8.35126 21.1887 9.16749 20.3866 10.1838 20.4006C11.172 20.4142 11.9511 21.215 11.9483 22.2142Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.9483 9.80476C11.9446 10.7996 11.153 11.5963 10.1647 11.5997C9.14818 11.6033 8.34399 10.796 8.34865 9.77672C8.35324 8.77582 9.13052 8.00247 10.1382 7.99626C11.1764 7.98985 11.9522 8.76493 11.9483 9.80476Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.77055 12.8886C5.12354 12.8804 4.56871 12.3172 4.56934 11.6692C4.56996 11.0224 5.12569 10.461 5.77383 10.4524C6.44382 10.4435 7.01605 11.0244 7.00193 11.6991C6.9884 12.3457 6.41794 12.8967 5.77055 12.8886Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.00271 20.3352C7.00005 20.9846 6.44244 21.5437 5.79319 21.548C5.1477 21.5522 4.58172 20.9982 4.56953 20.3503C4.55699 19.6833 5.14139 19.0967 5.80459 19.1107C6.45434 19.1244 7.00535 19.6876 7.00271 20.3352Z",
    fill: "none"
  }), _jsx("path", {
    d: "M1.72108e-05 15.9939C0.00351281 15.3348 0.547574 14.7847 1.20009 14.7805C1.88037 14.7762 2.4209 15.3376 2.40652 16.0336C2.39275 16.6995 1.84491 17.2325 1.18723 17.2198C0.530414 17.2071 -0.00349308 16.6558 1.72108e-05 15.9939Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.0562 12.3711C22.3819 12.3652 21.8353 11.8382 21.8263 11.1853C21.817 10.5143 22.3848 9.96877 23.086 9.9749C23.7458 9.98068 24.2928 10.536 24.281 11.1881C24.269 11.849 23.7224 12.3769 23.0562 12.3711Z",
    fill: "none"
  }), _jsx("path", {
    d: "M27.3533 16.0171C27.3477 16.6895 26.8014 17.2448 26.1613 17.2291C25.5105 17.213 24.9584 16.6561 24.9532 16.0103C24.9478 15.3453 25.5325 14.7617 26.1952 14.7705C26.8392 14.7791 27.3591 15.3386 27.3533 16.0171Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.0575 22.0252C22.3838 22.0198 21.8366 21.4931 21.8263 20.8402C21.8157 20.1686 22.3822 19.6232 23.0847 19.6288C23.7455 19.634 24.2914 20.187 24.281 20.8404C24.2705 21.5004 23.7229 22.0306 23.0575 22.0252Z",
    fill: "none"
  })]
}), 'AllerganMonoLogo', 'CompanyMono');
export default AllerganMonoLogo;