/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var KlaviyoMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 138,
  height: 32,
  viewBox: "0 0 138 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M0.963959 14.4851L22.1178 0.399629C23.0395 -0.13321 24.1687 -0.13321 25.0904 0.399629L46.2442 14.4851C47.0738 14.9021 47.4425 15.8983 47.0277 16.7555C46.8664 17.103 46.5899 17.381 46.2442 17.5431L43.4099 19.4196C36.4738 8.41537 22.0026 5.14884 11.057 12.0989C8.13045 13.9754 5.64177 16.4543 3.77525 19.4196L0.917872 17.5431C0.0883096 17.103 -0.234298 16.0836 0.180483 15.2496C0.364831 14.9253 0.641351 14.6473 0.963959 14.4851ZM23.5695 14.3229C17.5091 14.3461 11.8635 17.4736 8.63741 22.6398L13.4996 25.8832C16.8409 20.3 24.0534 18.5161 29.5838 21.8753C31.2199 22.8715 32.5795 24.2384 33.5704 25.8832L38.4325 22.6398C35.2525 17.4505 29.6299 14.2998 23.5695 14.3229ZM23.5695 26.0917C21.4265 26.1149 19.4678 27.2732 18.4078 29.1497L21.68 31.3043C22.21 31.7676 22.8782 31.9993 23.5695 31.9993C24.2608 31.9993 24.9521 31.7444 25.4591 31.3043L28.7312 29.1497C27.6943 27.2501 25.7126 26.0917 23.5695 26.0917Z",
    fill: "none"
  }), _jsx("path", {
    d: "M60.6146 15.8969L65.8484 23.4288H62.1619L58.2025 17.5858V23.4288H55.1987V8.50189H58.2025V14.459L61.9571 8.50189H65.416L60.6146 15.8969ZM71.0139 8.54753H68.0101V23.4744H75.6788V20.4617H70.9456L71.0139 8.54753ZM84.5535 7.99976L90.8113 23.4516H87.4434L86.8518 21.8311H81.9138L81.3222 23.4516H77.9316L84.1894 7.99976H84.5535ZM85.7595 19.0466L84.417 15.1893L83.0289 19.0466H85.7595ZM95.0211 16.8327L91.9491 8.54753H88.5812L94.839 23.9994H95.1349L101.393 8.54753H98.0021L95.0211 16.8327ZM103.691 23.4744H106.695V8.54753H103.691V23.4744ZM118.573 8.54753L115.547 13.7058L112.52 8.54753H108.902L114.068 16.7414V23.4744H117.026V16.7414L122.237 8.54753H118.573ZM137.597 15.7371C137.597 20.0052 134.138 23.4744 129.883 23.4744C125.627 23.4744 122.169 20.0052 122.169 15.7371C122.169 11.469 125.627 7.99976 129.883 7.99976C134.161 8.02258 137.597 11.469 137.597 15.7371ZM134.707 15.7371C134.707 13.0667 132.545 10.9212 129.905 10.9212C127.243 10.9212 125.104 13.0895 125.104 15.7371C125.104 18.3847 127.266 20.553 129.905 20.553C132.545 20.553 134.707 18.4075 134.707 15.7599C134.707 15.7599 134.707 15.7599 134.707 15.7371Z",
    fill: "none"
  })]
}), 'KlaviyoMonoLogo', 'CompanyMono');
export default KlaviyoMonoLogo;