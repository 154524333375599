/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FacebookMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 126,
  height: 32,
  viewBox: "0 0 126 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M3.23416 27.9872H7.98219V15.9817H11.5604L11.9101 12.0729H7.98219V9.42051C7.98219 7.98523 8.63921 7.46612 10.3218 7.46612H11.9733V3.83654C11.9733 3.83654 10.401 3.63183 9.39954 3.63183C4.66458 3.63183 3.23416 5.99559 3.23416 9.29154V12.0729H0V15.9817H3.23416V27.9872Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.7111 11.7636C18.7665 11.7621 18.8221 11.7605 18.8762 11.7605C21.0165 11.7791 22.4997 12.9473 23.2157 14.5219V12.0746H27.7315V27.9889H23.2157V25.6157C22.3387 27.3654 20.8959 28.303 18.6999 28.303C14.8615 28.303 12.2488 25.8989 12.2488 19.8921C12.2488 15.1239 14.2629 11.7648 18.6655 11.7648L18.7111 11.7636ZM22.9834 19.0546C22.9834 16.8293 21.8736 15.521 20.0245 15.521C18.6386 15.521 16.9624 16.1275 16.9624 19.9881L16.9623 20.0757C16.9602 21.3012 16.9546 24.5818 20.0245 24.5818C22.0711 24.5818 22.9877 22.8272 22.9877 21.3274L22.9834 19.0546Z",
    fill: "white"
  }), _jsx("path", {
    d: "M29.5552 20.0324L29.5552 19.9686C29.5542 17.9634 29.5511 11.7707 37.5915 11.7707C40.854 11.7707 42.2162 12.823 42.2162 12.823L41.1213 16.2678C41.1213 16.2678 39.7607 15.5079 37.988 15.5079C35.7172 15.5079 34.2684 16.8431 34.2684 19.1923L34.271 20.8768C34.271 23.1342 35.6688 24.641 37.9926 24.641C39.6076 24.641 41.0963 23.8739 41.0963 23.8739L42.1885 27.2469C42.1885 27.2469 40.9428 28.3057 37.6142 28.3057C29.8755 28.3057 29.5552 22.6284 29.5552 20.0324Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M75.1426 19.8923C75.1426 15.1241 73.0967 11.765 68.6942 11.765C67.1992 11.765 64.9536 12.4504 64.2703 14.3065V3.69702L59.5361 4.11581V27.9872L63.9951 27.9891V25.6159C64.8721 27.3656 66.4955 28.3032 68.6915 28.3032C72.5299 28.3032 75.1426 25.8991 75.1426 19.8923ZM64.2703 19.0547C64.2703 16.8295 65.5178 15.5211 67.3669 15.5211C68.7528 15.5211 70.429 16.1276 70.429 19.9883L70.4291 20.0759C70.4312 21.3014 70.4368 24.582 67.3669 24.582C65.1299 24.582 64.2632 22.8274 64.266 21.3276L64.2703 19.0547Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M50.4305 11.7777C45.4371 11.7777 42.801 14.5393 42.801 19.4469V20.3587C42.801 26.7282 46.503 28.3682 50.9165 28.3682C55.2065 28.3682 57.1483 27.151 57.1483 27.151L56.2623 23.9097C56.2623 23.9097 53.9782 24.8956 51.4842 24.8956C48.8987 24.8956 47.7867 24.2271 47.5017 21.7067H57.5267V19.1066C57.5267 13.7832 54.5681 11.7777 50.4305 11.7777ZM50.5509 15.0234C52.2794 15.0234 53.3993 16.0985 53.3378 18.5919H47.5103C47.6114 15.9675 48.8192 15.0234 50.5509 15.0234Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M76.7555 19.94C76.7555 14.6871 79.4551 11.756 84.5829 11.756C88.8647 11.756 92.35 14.0128 92.35 20.0054C92.35 25.7629 89.2504 28.3072 84.5915 28.3072C81.4089 28.3072 76.7555 27.147 76.7555 19.94ZM87.6665 19.194C87.6665 17.2456 86.7732 15.5339 84.5184 15.5339C82.9647 15.5339 81.4735 16.5826 81.4735 19.194V20.8692C81.4735 23.3177 82.7267 24.5947 84.5098 24.5947C86.4163 24.5947 87.6665 23.2296 87.6665 20.8692V19.194Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M101.782 11.756C96.6538 11.756 93.9543 14.6871 93.9543 19.94C93.9543 27.147 98.6077 28.3072 101.79 28.3072C106.449 28.3072 109.549 25.7629 109.549 20.0054C109.549 14.0128 106.063 11.756 101.782 11.756ZM101.717 15.5339C103.972 15.5339 104.865 17.2456 104.865 19.194V20.8692C104.865 23.2296 103.615 24.5947 101.709 24.5947C99.9254 24.5947 98.6722 23.3177 98.6722 20.8692V19.194C98.6722 16.5826 100.163 15.5339 101.717 15.5339Z",
    fill: "white"
  }), _jsx("path", {
    d: "M111.338 4.11581V27.9872H116.086V20.202L120.971 27.9872H126L120.897 19.9694L125.811 12.073H120.796L116.086 19.6971V3.69702L111.338 4.11581Z",
    fill: "white"
  })]
}), 'FacebookMonoLogo', 'CompanyMono');
export default FacebookMonoLogo;