/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ShiftMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 80,
  height: 33,
  viewBox: "0 0 80 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13.2197 13.7261H9.16932C8.98437 13.7261 8.84422 13.5591 8.87634 13.377L9.41344 10.3332C9.43855 10.1911 9.56207 10.0875 9.70642 10.0875H15.9374C17.301 10.0875 18.4678 9.10906 18.7049 7.76674L19.1723 5.12054C19.2045 4.93846 19.0643 4.77148 18.8794 4.77148H7.35281C5.44356 4.77148 3.80984 6.14175 3.47821 8.02136L2.30343 14.6793C1.90183 16.9554 3.65334 19.0422 5.96538 19.0422H10.0284C10.2134 19.0422 10.3535 19.2091 10.3214 19.3912L9.78362 22.436C9.75852 22.5781 9.635 22.6816 9.4907 22.6816H3.23949C1.87592 22.6816 0.709127 23.6601 0.472022 25.0025L0.00461531 27.6487C-0.0275601 27.8308 0.112586 27.9978 0.297541 27.9978H11.8308C13.7397 27.9978 15.3733 26.6279 15.7053 24.7487L16.8815 18.0896C17.2836 15.8133 15.532 13.7261 13.2197 13.7261Z",
    fill: "none"
  }), _jsx("path", {
    d: "M53.6663 19.2883L52.1709 27.754C52.1458 27.896 52.0223 27.9996 51.8778 27.9998H46.1386C45.9537 27.9998 45.8135 27.8327 45.8457 27.6507L49.8436 5.01714C49.8687 4.87505 49.9922 4.77148 50.1366 4.77148H79.0662C79.2511 4.77148 79.3913 4.93846 79.3591 5.12054L78.8917 7.76674C78.6546 9.10906 77.4878 10.0875 76.1242 10.0875H72.7488C72.6045 10.0875 72.481 10.1911 72.4559 10.3332L69.3787 27.754C69.3535 27.8961 69.2301 27.9997 69.0857 27.9997H63.3466C63.1616 27.9997 63.0215 27.8326 63.0536 27.6506L66.0943 10.4366C66.1265 10.2545 65.9864 10.0875 65.8014 10.0875H55.5411C55.3968 10.0875 55.2732 10.1911 55.2481 10.3332L54.7104 13.3774C54.6782 13.5595 54.8183 13.7265 55.0033 13.7265H60.5139C60.6989 13.7265 60.8391 13.8935 60.8069 14.0756L59.9725 18.7966C59.9474 18.9389 59.8237 19.0426 59.6792 19.0426H53.9592C53.8149 19.0426 53.6914 19.1462 53.6663 19.2883Z",
    fill: "none"
  }), _jsx("path", {
    d: "M36.8244 27.6507L40.8224 5.01758C40.8475 4.87548 40.971 4.77186 41.1153 4.77197H46.8545C47.0394 4.77197 47.1796 4.93895 47.1474 5.12103L43.1494 27.7541C43.1243 27.8962 43.0009 27.9998 42.8565 27.9998H37.1174C36.9324 27.9998 36.7923 27.8327 36.8244 27.6507Z",
    fill: "none"
  }), _jsx("path", {
    d: "M30.4252 13.481L31.9201 5.01758C31.9452 4.87548 32.0687 4.77187 32.2131 4.77208H37.9522C38.1371 4.77208 38.2773 4.93905 38.2451 5.12114L34.2471 27.7542C34.222 27.8963 34.0986 27.9999 33.9542 27.9999H28.215C28.0301 27.9999 27.8899 27.8329 27.9221 27.6508L29.381 19.3919C29.4131 19.2098 29.2731 19.0428 29.088 19.0428H25.4778C25.3335 19.0428 25.21 19.1464 25.1849 19.2885L23.6895 27.7542C23.6644 27.8963 23.5409 27.9998 23.3966 27.9998H17.6574C17.4724 27.9998 17.3323 27.8328 17.3645 27.6507L21.3624 5.01763C21.3875 4.87553 21.511 4.77197 21.6554 4.77197H27.3946C27.5795 4.77197 27.7197 4.93895 27.6875 5.12103L26.2291 13.3776C26.1969 13.5596 26.337 13.7266 26.522 13.7266H30.1322C30.2765 13.7266 30.4001 13.6231 30.4252 13.481Z",
    fill: "none"
  })]
}), 'ShiftMonoLogo', 'CompanyMono');
export default ShiftMonoLogo;