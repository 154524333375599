/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RakutenMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 89,
  height: 26,
  viewBox: "0 0 89 26",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M71.1838 21.7962H18.3237L22.5353 26.0044L71.1838 21.7962ZM23.2213 4.76551V5.38667C22.3236 4.74873 21.2509 4.40311 20.1496 4.39691C16.4466 4.39691 13.6343 7.77575 13.6343 11.9225C13.6343 16.0693 16.4466 19.4481 20.1496 19.4481C21.2509 19.4419 22.3236 19.0963 23.2213 18.4583V19.0932H26.4773V4.75869L23.2213 4.76551ZM20.1496 16.0044C18.3237 16.0044 16.9858 14.2092 16.9858 11.9362C16.9858 9.66312 18.3237 7.8679 20.1496 7.8679C21.9756 7.8679 23.2657 9.66654 23.2657 11.9362C23.2657 14.2058 21.9824 16.0044 20.1531 16.0044H20.1496ZM49.2998 4.76551V13.1887C49.2998 14.7723 48.2111 16.1068 46.6309 16.1068C45.0507 16.1068 43.9653 14.7723 43.9653 13.1887V4.76551H40.706V13.1887C40.706 16.6461 43.0711 19.4618 46.5285 19.4618C47.5187 19.4468 48.4821 19.1379 49.2964 18.5744V19.1068H52.549V4.77234L49.2998 4.76551ZM79.6309 19.1068V10.6802C79.6309 9.09998 80.7162 7.76551 82.2998 7.76551C83.8834 7.76551 84.9653 9.09998 84.9653 10.6802V19.1068H88.2213V10.6802C88.2213 7.22285 85.8561 4.41056 82.3988 4.41056C81.4086 4.42554 80.4452 4.7344 79.6309 5.29794V4.76551H76.3749V19.1L79.6309 19.1068Z",
    fill: "white"
  }), _jsx("path", {
    d: "M4.25526 19.1068V13.5505H6.64434L10.8321 19.1068H15.1085L10.0539 12.4037C11.1226 11.6452 11.9223 10.5665 12.3376 9.32349C12.7528 8.08051 12.762 6.73769 12.3638 5.48915C11.9656 4.2406 11.1806 3.15105 10.1224 2.37799C9.06424 1.60493 7.78761 1.18843 6.47711 1.1887H0.849121V19.1068H4.25526ZM4.25526 4.59143H6.47711C7.21392 4.59143 7.92056 4.88412 8.44156 5.40513C8.96257 5.92614 9.25527 6.63277 9.25527 7.36958C9.25527 8.1064 8.96257 8.81303 8.44156 9.33404C7.92056 9.85504 7.21392 10.1477 6.47711 10.1477H4.25526V4.59143ZM61.327 15.7143C61.0064 15.9345 60.6271 16.0534 60.2382 16.0556C59.7058 16.0556 58.7058 15.6494 58.7058 14.2979V8.15457H61.4874V4.7416H58.7058V1.1887H55.4498V4.76549H53.7433V8.17846H55.4498V14.3662C55.4498 17.5709 57.8594 19.4856 60.2894 19.4856C61.4093 19.455 62.5038 19.1453 63.4737 18.5846L61.327 15.7143ZM34.5693 11.4856L40.1529 4.76207H35.5897L31.6853 9.76208V0.00439453H28.3304V19.1068H31.6853V13.216L36.4839 19.1068H41.0471L34.5693 11.4856Z",
    fill: "white"
  }), _jsx("path", {
    d: "M68.8455 4.40039C65.0912 4.40039 62.3608 7.71097 62.3608 11.9362C62.3608 16.3731 65.7533 19.4721 69.1561 19.4721C70.8625 19.4721 73.0742 18.885 74.9206 16.2605L72.0605 14.6018C69.8352 17.8782 66.1288 16.2161 65.7021 12.9396H75.081C75.883 7.77582 72.552 4.40039 68.8455 4.40039ZM71.6885 10.083H65.825C66.511 6.72462 71.0537 6.5335 71.6885 10.083Z",
    fill: "white"
  })]
}), 'RakutenMonoLogo', 'CompanyMono');
export default RakutenMonoLogo;