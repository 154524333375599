/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var AdjustMonoLogo = createCompanyLogosComponent(_jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 106,
  height: 33,
  viewBox: "0 0 64 20",
  xmlSpace: "preserve",
  children: _jsx("path", {
    d: "M31 4.4v6.8c0 4.3-3.3 5.7-4.8 6.1-.4.1-1-.1-1.2-.7-.1-.5.2-1 .7-1.2 1.5-.4 3.3-1.5 3.3-4.2V4.4c0-.5.4-1 1-1 .5 0 1 .4 1 1zm-16.7 8.3c.4.8-.4 1.9-1.4 1.3 0 0-3.8-2.5-6.6-3.6l-1.7 3.2c-.1.3-.8.8-1.4.4-4.7-2.7-3.8-8 2.1-6.2l2.4-4.6c.5-.8 1.5-.6 1.8 0l4.8 9.5zm-9.9-3c-3.8-1.1-1.8 1.5-1 2l1-2zm6.5.7L8.5 5.9 7.2 8.6c.9.3 2.9 1.4 3.7 1.8zm15-1.7c0 3-2.4 5.4-5.4 5.4h-3.2c-.6 0-1-.5-1-1V4.4c0-.6.5-1 1-1h3.2c3 0 5.4 2.4 5.4 5.3zm-2 0c0-1.8-1.5-3.3-3.3-3.3h-2.2v6.7h2.2c1.8 0 3.3-1.5 3.3-3.4zm17.6-4.3v5c0 1.6-1.1 2.7-2.7 2.7s-2.7-1.1-2.7-2.7v-5c0-1.3-2-1.3-2 0v5c0 2.7 2 4.7 4.7 4.7s4.7-2 4.7-4.7v-5c0-1.3-2-1.3-2 0zm21.4-1h-7.4c-1.3 0-1.3 2 0 2h2.7v7.7c0 1.3 2 1.3 2 0V5.4h2.7c1.4 0 1.4-2 0-2zM47.5 6c.1-.9 1.9-1.4 3.4-.1 1 .8 2.3-.4 1.4-1.5-1.7-1.9-6.8-1.8-6.8 1.8 0 3.8 5.9 3 5.3 5.2-.3 1-2.6 1.4-3.7-.1-.9-1.3-2.5 0-1.7 1.1 2.3 3.3 7.5 1.9 7.5-1.3 0-3.7-5.5-3-5.4-5.1z"
  })
}), 'AdjustMonoLogo', 'CompanyMono');
export default AdjustMonoLogo;