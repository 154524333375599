/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var IntegraBeautyMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 61,
  height: 32,
  viewBox: "0 0 61 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#IntegraBeautyMonoLogo_svg__clip0_11741_136417)",
    children: [_jsx("path", {
      d: "M1.00651 0.0908203H0.186768V13.2665H1.00651V0.0908203Z",
      fill: "none"
    }), _jsx("path", {
      d: "M16.7137 13.2665H17.5334V0.910202H21.6331V0.0908203H12.614V0.910202H16.7137V13.2665Z",
      fill: "none"
    }), _jsx("path", {
      d: "M40.6211 19.2881H44.7217V31.6444H45.5415V19.2881H49.6411V18.4688H40.6211V19.2881Z",
      fill: "none"
    }), _jsx("path", {
      d: "M22.4644 13.2665H29.5566V12.4471H23.2841V7.09026H28.5324V6.27088H23.2841V0.910202H29.5566V0.0908203H22.4644V13.2665Z",
      fill: "none"
    }), _jsx("path", {
      d: "M11.6119 13.2665H11.7074V0.0908203H10.8877V12.0991L3.42802 0.0908203H2.46362V13.2665H3.28336V1.41051L10.661 13.2877H11.6254L11.6119 13.2665Z",
      fill: "none"
    }), _jsx("path", {
      d: "M35.9237 7.07754H39.3232V12.1587C38.6237 12.4096 37.886 12.5378 37.1427 12.5375C33.7374 12.5375 30.9706 9.90875 30.9706 6.67846C30.9706 3.44817 33.7374 0.818432 37.1427 0.818432C37.9616 0.816345 38.7733 0.970445 39.5344 1.27247L39.6685 1.32645L39.9723 0.564906L39.8382 0.511887C38.9807 0.170595 38.0657 -0.00348176 37.1427 -0.000949324C33.2851 -0.000949324 30.147 2.9951 30.147 6.67846C30.147 10.3618 33.2851 13.3569 37.1427 13.3569C38.1451 13.3564 39.1367 13.1503 40.0562 12.7515L40.143 12.7139V6.25527H35.9237V7.07754Z",
      fill: "none"
    }), _jsx("path", {
      d: "M47.1529 7.09033H47.3265C48.8493 7.09033 50.0876 5.52001 50.0876 3.59012C50.0876 1.66024 48.8464 0.0957031 47.3236 0.0957031H41.6423V13.2665H42.4621V7.09033H46.2126L49.643 13.2665H50.5804L47.1529 7.09033ZM42.465 6.27095V0.910264H47.3265C48.397 0.910264 49.2678 2.11234 49.2678 3.59012C49.2678 5.0679 48.3999 6.27095 47.3265 6.27095H42.465Z",
      fill: "none"
    }), _jsx("path", {
      d: "M59.912 0.0952501V0.0605469L59.8927 0.0904302H59.2582L50.6335 13.2661H51.6134L53.8315 9.87866H59.6227V13.2661H60.4425V0.0952501H59.912ZM54.3667 9.06025L59.6227 1.03224V9.06025H54.3667Z",
      fill: "none"
    }), _jsx("path", {
      d: "M28.2817 18.439L28.2624 18.4688H27.6288L19.0032 31.6445H19.983L22.2011 28.2571H27.9933V31.6445H28.813V18.4688H28.2826L28.2817 18.439ZM22.7364 27.4377L27.9923 19.4097V27.4377H22.7364Z",
      fill: "none"
    }), _jsx("path", {
      d: "M54.5604 24.2844L50.9429 18.4688H49.9775L54.1505 25.1771V31.5384H54.9702V25.1771L59.1422 18.4688H58.1769L54.5604 24.2844Z",
      fill: "none"
    }), _jsx("path", {
      d: "M11.6042 31.6444H18.6955V30.825H12.424V25.4682H17.6713V24.6488H12.424V19.2881H18.6955V18.4688H11.6042V31.6444Z",
      fill: "none"
    }), _jsx("path", {
      d: "M38.9757 27.2419C38.9838 27.7553 38.8897 28.2651 38.6988 28.7417C38.5078 29.2183 38.2239 29.6522 37.8636 30.0181C37.5033 30.384 37.0738 30.6745 36.6 30.8729C36.1263 31.0712 35.6178 31.1734 35.1042 31.1734C34.5905 31.1734 34.082 31.0712 33.6083 30.8729C33.1345 30.6745 32.705 30.384 32.3447 30.0181C31.9844 29.6522 31.7005 29.2183 31.5095 28.7417C31.3186 28.2651 31.2245 27.7553 31.2326 27.2419V18.4697H30.4128V27.2419C30.4039 27.8634 30.5187 28.4804 30.7504 29.0572C30.9822 29.6339 31.3263 30.1588 31.7629 30.6015C32.1994 31.0441 32.7195 31.3956 33.2932 31.6355C33.8668 31.8754 34.4824 31.9989 35.1042 31.9989C35.726 31.9989 36.3415 31.8754 36.9152 31.6355C37.4888 31.3956 38.0089 31.0441 38.4455 30.6015C38.882 30.1588 39.2261 29.6339 39.4579 29.0572C39.6896 28.4804 39.8044 27.8634 39.7955 27.2419V18.4697H38.9757V27.2419Z",
      fill: "none"
    }), _jsx("path", {
      d: "M8.26555 24.8349C8.86612 24.4159 9.32001 23.8191 9.56325 23.1286C9.80648 22.438 9.8268 21.6886 9.62134 20.986C9.41587 20.2833 8.99499 19.6628 8.418 19.2119C7.84101 18.761 7.13701 18.5024 6.40522 18.4727H2.16187V31.6445H7.83254V31.5722C8.58457 31.4178 9.26501 31.0207 9.76905 30.4418C10.2731 29.863 10.5728 29.1346 10.622 28.3688C10.6712 27.603 10.4672 26.8422 10.0414 26.2037C9.61564 25.5651 8.99165 25.0842 8.26555 24.8349ZM9.80859 28.1443C9.80757 28.8543 9.52521 29.535 9.02329 30.0374C8.52137 30.5398 7.84078 30.823 7.13045 30.8251H2.98353V25.4682H7.27511C7.9602 25.5058 8.6048 25.8045 9.07613 26.3029C9.54746 26.8012 9.80962 27.4613 9.80859 28.1471V28.1443ZM8.94063 21.968C8.93961 22.6781 8.65725 23.3588 8.15533 23.8612C7.65341 24.3636 6.97282 24.6468 6.26249 24.6489H2.98353V19.2882H6.26249C6.97307 19.2895 7.65416 19.5722 8.15652 20.0746C8.65888 20.5769 8.94154 21.2578 8.94256 21.968H8.94063Z",
      fill: "none"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "IntegraBeautyMonoLogo_svg__clip0_11741_136417",
      children: _jsx("rect", {
        width: 60.2557,
        height: 32,
        fill: "none",
        transform: "translate(0.186768 -0.000976562)"
      })
    })
  })]
}), 'IntegraBeautyMonoLogo', 'CompanyMono');
export default IntegraBeautyMonoLogo;