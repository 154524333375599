/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var EventbriteMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 154,
  height: 28,
  viewBox: "0 0 154 28",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M75.7942 27.5103V14.0781H72.9746V9.61375H75.7942V3.34802L80.5914 2.23193V9.61375H84.1942V14.0781H80.5914V27.5103H75.7942Z",
    fill: "none"
  }), _jsx("path", {
    d: "M24.7872 27.5103L17.6012 9.61377H22.6529L27.2152 21.7536L31.8166 9.61377H36.8683L29.6823 27.5103H24.7872Z",
    fill: "none"
  }), _jsx("path", {
    d: "M85.7605 27.5105V1.07692L90.4011 0V10.9455C91.8304 9.77063 93.5339 9.28112 95.3745 9.28112C100.642 9.28112 104.871 13.4322 104.871 18.621C104.871 23.8098 100.661 28 95.3745 28C93.5339 28 91.8304 27.5105 90.4011 26.3357V27.5105H85.7605ZM90.2836 18.8559C90.4011 21.4601 92.6332 23.6531 95.3549 23.6531C98.0766 23.6531 100.387 21.3427 100.387 18.621C100.387 15.8993 98.1157 13.628 95.3549 13.628C92.5941 13.628 90.4011 15.8601 90.2836 18.4252V18.8559Z",
    fill: "none"
  }), _jsx("path", {
    d: "M117.011 3.32852C117.011 1.56628 118.362 0.254395 120.124 0.254395C121.887 0.254395 123.198 1.56628 123.198 3.32852C123.198 5.09076 121.887 6.44181 120.124 6.44181C118.362 6.44181 117.011 5.09076 117.011 3.32852ZM117.794 27.5103V9.61383H122.435V27.5103H117.794Z",
    fill: "none"
  }), _jsx("path", {
    d: "M116.208 9.67251C113.839 9.78999 112.449 10.162 111.019 11.2194V9.61377H106.398V27.5103H111.019V18.7382C111.019 15.9774 111.685 14.411 116.208 14.2543V9.67251Z",
    fill: "none"
  }), _jsx("path", {
    d: "M126.821 27.5104V14.1565H124.001V9.61388H126.821V3.34814H131.618V9.61388H135.221V14.1565H131.618V27.5104H126.821Z",
    fill: "none"
  }), _jsx("path", {
    d: "M48.8124 21.4799C48.127 22.498 47.0501 23.2813 45.7578 23.575C43.6823 24.0645 41.6068 23.1834 40.4515 21.519L49.6152 19.4043L51.1033 19.0519L53.962 18.3862C53.9424 17.7792 53.8641 17.1526 53.727 16.526C52.5718 11.4939 47.5396 8.34141 42.5075 9.51623C37.4753 10.6715 34.3229 15.7036 35.4977 20.7358C36.6529 25.768 41.6851 28.9204 46.7173 27.7456C49.6935 27.0603 51.9844 25.0435 53.1592 22.4589L48.8124 21.4799ZM43.4865 13.726C45.562 13.2561 47.618 14.1176 48.7732 15.7428L39.6291 17.8575C39.9424 15.8799 41.411 14.196 43.4865 13.726Z",
    fill: "none"
  }), _jsx("path", {
    d: "M148.77 21.4799C148.085 22.498 147.008 23.2813 145.716 23.575C143.64 24.0645 141.565 23.1834 140.409 21.519L149.573 19.4043L151.061 19.0519L153.92 18.3862C153.9 17.7792 153.822 17.1526 153.685 16.526C152.53 11.4939 147.498 8.34141 142.465 9.51623C137.433 10.6715 134.281 15.7036 135.456 20.7358C136.611 25.768 141.643 28.9204 146.675 27.7456C149.651 27.0603 151.942 25.0435 153.117 22.4589L148.77 21.4799ZM143.425 13.726C145.5 13.2561 147.556 14.1176 148.712 15.7428L139.567 17.8575C139.881 15.8799 141.369 14.196 143.425 13.726Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.9984 21.4799C13.3131 22.498 12.2362 23.2813 10.9438 23.575C8.86833 24.0645 6.7928 23.1834 5.63756 21.519L14.8012 19.4043L16.2893 19.0519L19.148 18.3862C19.1285 17.7792 19.0501 17.1526 18.9131 16.526C17.7578 11.4939 12.7257 8.34141 7.6935 9.51623C2.66133 10.6715 -0.491114 15.7036 0.683711 20.7358C1.83896 25.768 6.87112 28.9204 11.9033 27.7456C14.8795 27.0603 17.1704 25.0435 18.3452 22.4589L13.9984 21.4799ZM8.67252 13.7064C10.748 13.2365 12.804 14.098 13.9592 15.7232L4.7956 17.8575C5.12847 15.8799 6.597 14.196 8.67252 13.7064Z",
    fill: "none"
  }), _jsx("path", {
    d: "M67.5508 27.5106V17.9553C67.5508 14.4896 65.7102 13.7455 63.8697 13.7455C62.0291 13.7455 60.1885 14.47 60.1885 17.8966V27.5302H55.548V9.63365H60.1885V10.9651C61.2067 10.2211 62.4403 9.30078 64.8878 9.30078C68.8039 9.30078 72.1913 11.5917 72.1913 17.2113V27.5302H67.5508V27.5106Z",
    fill: "none"
  })]
}), 'EventbriteMonoLogo', 'CompanyMono');
export default EventbriteMonoLogo;