/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var QuartzMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 129,
  height: 32,
  viewBox: "0 0 129 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.5538 21.4317C18.7836 19.7712 19.4462 17.7589 19.4435 15.6925C19.4435 10.3494 15.0786 6 9.72142 6C4.36421 6 0 10.352 0 15.6925C0 21.0331 4.35907 25.3851 9.72142 25.3851C11.7503 25.3885 13.7289 24.7537 15.3771 23.5704L17.8163 26L19.9801 23.8445L17.5563 21.4265L17.5538 21.4317ZM15.3546 19.239L11.7601 15.6566L9.59882 17.8153L13.1729 21.3778C12.1319 22.0089 10.9375 22.342 9.72014 22.3406C6.04403 22.3406 3.05668 19.3616 3.05668 15.6964C3.05668 12.0312 6.0466 9.05154 9.7227 9.05154C13.3988 9.05154 16.3836 12.0338 16.3836 15.6957C16.385 16.9504 16.0287 18.1795 15.3566 19.239H15.3546Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M107.184 6.47998V9.56169H101.401V24.9773H98.2709V9.56169H92.4907V6.47998H107.184Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M58.8512 6.47998H55.0923L47.9448 24.9773H51.2262L52.5651 21.3737H61.3802L62.7179 24.9773H66.0005L58.8512 6.47998ZM53.7571 18.4904L56.9749 10.1407L60.1844 18.4904H53.7571Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M128.378 9.56165L118.011 21.8538H128.378V24.9773H113.928V21.6908L124.331 9.56165H114.649V6.51074H128.378V9.56165Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M41.8831 6.47998V18.1816C41.8831 22.1491 38.6505 25.3753 34.6734 25.3753C30.6962 25.3753 27.4604 22.1479 27.4604 18.1816V6.47998H30.589V18.1816C30.5203 20.4661 32.3831 22.3237 34.6746 22.3237C36.9662 22.3237 38.8238 20.4661 38.7789 18.1816V6.47998H41.8831Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M82.3174 18.7125C83.3907 18.1839 84.2949 17.366 84.928 16.3508C85.5611 15.3357 85.8979 14.1639 85.9004 12.9676C85.9004 9.5771 83.0979 6.54866 79.6099 6.47998H72.7417V24.9767H75.8716V19.4064H78.7087L85.1636 25.9279L87.3396 23.784L82.3181 18.7131L82.3174 18.7125ZM79.3801 16.3542H75.8716V9.58095H79.3808C81.2891 9.58095 82.8425 11.0997 82.8425 12.9682C82.8425 14.8367 81.2891 16.3542 79.3808 16.3542H79.3801Z",
    fill: "white"
  })]
}), 'QuartzMonoLogo', 'CompanyMono');
export default QuartzMonoLogo;