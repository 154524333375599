/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CraftJackMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 113,
  height: 33,
  viewBox: "0 0 113 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13.128 7.85352C12.4583 7.85352 10.4697 7.87637 9.20341 9.24323C8.40798 10.1118 8.33484 11.0718 8.33484 12.4958V22.0249C8.33484 22.9964 8.33484 24.3609 9.72455 25.2798C10.7554 25.9609 12.2091 26.1392 13.128 26.1392C17.896 26.1392 17.896 23.0832 17.896 21.6707V19.1815H14.696V21.4672C14.696 22.5347 14.696 23.6272 13.1828 23.6272C11.6697 23.6272 11.5691 22.6832 11.5691 21.6409V12.2535C11.5691 11.4581 11.6423 10.3404 13.0594 10.3404C14.4766 10.3404 14.6983 11.0855 14.6983 12.3975V14.6329H17.8983V11.9312C17.896 10.6878 17.896 7.85352 13.128 7.85352Z",
    fill: "none"
  }), _jsx("path", {
    d: "M29.8274 23.3959V19.8256C29.8274 17.4668 28.2137 16.9936 27.4457 16.7971C28.4811 16.5685 29.1326 16.3125 29.4571 15.5216C29.6491 14.9754 29.7337 14.3948 29.7063 13.8165V11.9308C29.7063 10.8611 29.7063 9.57651 28.792 8.82908C28.3348 8.44965 27.6263 8.20508 26.5611 8.20508H20.0948V25.7616H23.3268V18.0405H24.8674C26.5566 18.0405 26.5566 18.8839 26.5566 20.0748V23.8256C26.552 24.4999 26.7143 25.1651 27.0274 25.7616H30.5726C29.9028 25.2245 29.8274 24.2965 29.8274 23.3959ZM26.4994 13.8416C26.4994 14.7376 26.4743 15.5308 25.0091 15.5308H23.3268V10.7308H25.016C25.736 10.7308 26.5063 10.7308 26.5063 12.1708L26.4994 13.8416Z",
    fill: "none"
  }), _jsx("path", {
    d: "M38.8697 8.23047H35.16L31.7566 25.7619H34.984L35.4572 22.9093H38.4286L38.8697 25.7619H42.1383L38.8697 8.23047ZM35.8572 20.1962L37.0229 12.6716L38.0903 20.1962H35.8572Z",
    fill: "none"
  }), _jsx("path", {
    d: "M43.9417 25.7596H47.1966V17.9402H51.6057V15.3322H47.1966V10.9345H51.9051V8.23047H43.9417V25.7596Z",
    fill: "none"
  }), _jsx("path", {
    d: "M63.0571 8.23047H53.3474V10.9436H56.5748V25.7596H59.8274V10.9436H63.0571V8.23047Z",
    fill: "none"
  }), _jsx("path", {
    d: "M67.32 21.707C67.32 22.475 67.32 23.5196 66.1771 23.5196C65.0343 23.5196 65.0114 22.5505 65.0114 21.8053V20.1413H62.04V22.5002C62.04 24.5368 62.9543 26.0773 66.136 26.0773C70.5566 26.0773 70.5794 23.2728 70.5794 21.7345V8.23047H67.32V21.707Z",
    fill: "none"
  }), _jsx("path", {
    d: "M78.9451 8.23047H75.2468L71.8457 25.7619H75.0731L75.5417 22.9093H78.5131L78.936 25.7619H82.216L78.9451 8.23047ZM75.944 20.1962L77.1097 12.6716L78.184 20.1962H75.944Z",
    fill: "none"
  }), _jsx("path", {
    d: "M88.3509 7.85366C87.4778 7.83766 86.6069 7.99309 85.7932 8.3108C85.2767 8.52109 84.8081 8.8388 84.4218 9.24109C83.6287 10.1097 83.5532 11.0697 83.5532 12.4937V22.0251C83.5532 22.9965 83.5532 24.3611 84.9429 25.2799C85.9624 25.9519 87.4275 26.1234 88.3464 26.1234C93.1144 26.1234 93.1144 23.0674 93.1144 21.6548V19.1817H89.9144V21.4674C89.9144 22.5348 89.9144 23.6274 88.3989 23.6274C86.8835 23.6274 86.7852 22.6834 86.7852 21.6411V12.2537C86.7852 11.4582 86.8584 10.3405 88.2755 10.3405C89.6927 10.3405 89.9167 11.0857 89.9167 12.3977V14.6331H93.1167V11.9314C93.1167 10.7885 93.1052 8.3428 89.4595 7.9268C89.0915 7.88109 88.7212 7.85595 88.3509 7.85366Z",
    fill: "none"
  }), _jsx("path", {
    d: "M98.488 17.195L101.914 25.7596H105.416L101.318 15.8053L105.293 8.23047H101.987L98.488 15.0579V8.23047H95.208V25.7596H98.488V17.195Z",
    fill: "none"
  }), _jsx("path", {
    d: "M0.0125732 0.996094V32.9961H112.47V0.996094H0.0125732ZM108.584 28.8818H4.12686V5.11038H108.584V28.8818Z",
    fill: "none"
  })]
}), 'CraftJackMonoLogo', 'CompanyMono');
export default CraftJackMonoLogo;