/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AgorapulseMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 147,
  height: 32,
  viewBox: "0 0 147 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#AgorapulseMonoLogo_svg__clip0_11055_126682)",
    children: [_jsx("path", {
      d: "M6.8131 24.0668C8.55325 24.0668 9.85092 23.4769 10.7947 22.2676L10.8538 22.2971V23.8013H15.2483V9.37873H10.6178V10.8829L10.5589 10.9124C9.55604 9.73265 8.11084 9.11328 6.57716 9.11328C2.86091 9.11328 0 12.1806 0 16.6048C0 21.0584 2.86091 24.0668 6.8131 24.0668ZM7.78641 19.9965C5.95779 19.9965 4.68954 18.6398 4.68954 16.6048C4.68954 14.5402 6.01678 13.1835 7.78641 13.1835C9.49701 13.1835 10.8243 14.6287 10.8243 16.6048C10.8243 18.6694 9.43811 19.9965 7.78641 19.9965Z",
      fill: "white"
    }), _jsx("path", {
      d: "M24.4122 29.9066C30.252 29.9066 32.1987 26.6032 32.1987 21.9137V9.37873H27.5681V10.7944H27.5091C26.4179 9.67366 24.9432 9.11328 23.3799 9.11328C19.7522 9.11328 16.9798 12.1512 16.9798 16.4278C16.9798 20.5274 19.7522 23.5358 23.6455 23.5358C25.3266 23.5358 26.6539 22.946 27.6271 21.9432L27.6862 21.9726V23.2999C27.6862 24.8041 26.4179 26.0429 23.9109 26.0429C22.3182 26.0429 20.7256 25.4824 19.5162 24.7746L18.4839 28.5203C20.2536 29.4937 22.0822 29.9066 24.4122 29.9066ZM24.7367 19.4362C22.9966 19.4362 21.6693 18.1974 21.6693 16.3394C21.6693 14.4812 22.9671 13.2129 24.7367 13.2129C26.4473 13.2129 27.7746 14.4812 27.7746 16.3098C27.7746 18.1385 26.4768 19.4362 24.7367 19.4362Z",
      fill: "white"
    }), _jsx("path", {
      d: "M41.7769 24.0668C46.3189 24.0668 49.5632 21.0288 49.5632 16.6048C49.5632 12.1512 46.3189 9.11328 41.7769 9.11328C37.1168 9.11328 33.961 12.2102 33.961 16.6048C33.961 20.9699 37.1168 24.0668 41.7769 24.0668ZM41.7769 19.9965C39.9483 19.9965 38.621 18.5219 38.621 16.6048C38.621 14.6287 39.9187 13.2129 41.7769 13.2129C43.576 13.2129 44.9032 14.5991 44.9032 16.6048C44.9032 18.5809 43.517 19.9965 41.7769 19.9965Z",
      fill: "white"
    }), _jsx("path", {
      d: "M51.447 23.8013H56.048V17.3126C56.048 15.425 57.1687 14.0388 59.3514 14.0388C59.5873 14.0388 60.0002 14.0682 60.4425 14.0978V9.11328H60.2656C58.673 9.11328 57.1687 9.90962 56.107 11.3843H56.048V9.37873H51.447V23.8013Z",
      fill: "white"
    }), _jsx("path", {
      d: "M67.762 24.0668C69.5021 24.0668 70.7998 23.4769 71.7436 22.2676L71.8027 22.2971V23.8013H76.1973V9.37873H71.5667V10.8829L71.5077 10.9124C70.505 9.73265 69.0597 9.11328 67.526 9.11328C63.8098 9.11328 60.9488 12.1806 60.9488 16.6048C60.9488 21.0584 63.8098 24.0668 67.762 24.0668ZM68.7353 19.9965C66.9067 19.9965 65.6384 18.6398 65.6384 16.6048C65.6384 14.5402 66.9656 13.1835 68.7353 13.1835C70.4459 13.1835 71.7731 14.6287 71.7731 16.6048C71.7731 18.6694 70.3869 19.9965 68.7353 19.9965Z",
      fill: "white"
    }), _jsx("path", {
      d: "M80.1353 29.6411H83.999V22.2087L84.058 22.1793C85.0607 23.3885 86.4765 24.0668 88.2756 24.0668C92.2867 24.0668 95.2066 21.088 95.2066 16.6343C95.2066 12.2692 92.2867 9.17236 88.3936 9.17236C86.6239 9.17236 85.0903 9.82124 84.058 11.0305H83.999V9.37882H80.1353V29.6411ZM87.5973 20.5865C85.4737 20.5865 83.822 19.0234 83.822 16.6638C83.822 14.3338 85.3557 12.6231 87.5677 12.6231C89.6322 12.6231 91.284 14.1863 91.284 16.6638C91.284 18.9644 89.7503 20.5865 87.5973 20.5865Z",
      fill: "white"
    }), _jsx("path", {
      d: "M102.411 24.0668C104.033 24.0668 105.567 23.3886 106.658 22.0613L106.717 22.0907V23.8014H110.61V9.37891H106.717V16.3984C106.717 19.171 105.213 20.5866 103.532 20.5866C101.732 20.5866 100.936 19.7018 100.936 17.0768V9.37891H97.1019V18.0797C97.1019 22.4742 99.2844 24.0668 102.411 24.0668Z",
      fill: "white"
    }), _jsx("path", {
      d: "M113.624 23.8013H117.488V2.09375H113.624V23.8013Z",
      fill: "white"
    }), _jsx("path", {
      d: "M125.287 24.0668C129.062 24.0668 130.862 22.1202 130.862 19.5837C130.862 16.7228 129.033 16.0444 125.907 14.8942C124.49 14.3634 123.783 13.9504 123.783 13.3605C123.783 12.5937 124.55 12.2988 125.139 12.2988C126.201 12.2988 127.499 12.7706 128.59 13.8324L130.479 10.9715C128.826 9.70326 127.205 9.17236 125.229 9.17236C122.249 9.17236 120.008 10.8535 120.008 13.449C120.008 16.251 122.073 17.3717 124.461 18.168C126.32 18.7874 127.057 19.1119 127.057 19.7312C127.057 20.439 126.437 20.911 125.317 20.911C123.724 20.911 122.485 20.3506 121.306 19.2594L119.448 21.9433C121.011 23.3 122.987 24.0668 125.287 24.0668Z",
      fill: "white"
    }), _jsx("path", {
      d: "M146.296 16.4574C146.296 12.2398 143.465 9.17236 139.395 9.17236C135.118 9.17236 132.08 12.1807 132.08 16.5753C132.08 21.088 135.206 24.0668 139.631 24.0668C142.845 24.0668 144.704 22.8871 145.913 21.3239L143.406 18.9938C142.639 19.9376 141.518 20.616 139.896 20.616C137.949 20.616 136.564 19.5837 136.092 17.8436H146.208C146.238 17.6076 146.296 16.9292 146.296 16.4574ZM139.365 12.5346C141.046 12.5346 142.079 13.5375 142.463 15.0712H136.121C136.564 13.4196 137.802 12.5346 139.365 12.5346Z",
      fill: "white"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "AgorapulseMonoLogo_svg__clip0_11055_126682",
      children: _jsx("rect", {
        width: 146.296,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'AgorapulseMonoLogo', 'CompanyMono');
export default AgorapulseMonoLogo;