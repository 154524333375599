/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var EarnestMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 118,
  height: 32,
  viewBox: "0 0 118 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M9.85176 9.45947C4.97762 9.45947 0.437988 13.5117 0.437988 19.6283C0.437988 26.1821 5.08514 29.9954 10.3296 29.9954C14.9768 29.9954 18.0351 27.2548 19.0672 23.8788L15.1751 22.6459C14.5348 24.555 13.1012 26.0244 10.3296 26.0244C8.96076 26.052 7.63659 25.5368 6.64634 24.5913C5.65609 23.6458 5.08023 22.3469 5.04452 20.9782H19.1867C19.2788 20.4941 19.3188 20.0015 19.3062 19.5088C19.3133 13.3922 15.8106 9.45947 9.85176 9.45947ZM5.16399 17.6021C5.25472 16.4184 5.79529 15.3144 6.67466 14.5167C7.55402 13.7191 8.70543 13.2885 9.89238 13.3134C13.0701 13.3134 14.5801 15.3371 14.6709 17.6021H5.16399Z",
    fill: "none"
  }), _jsx("path", {
    d: "M37.1015 16.6894C37.1015 12.8355 34.8365 9.45947 28.8776 9.45947C24.0991 9.45947 21.0934 12.4389 20.735 15.7743L24.9449 16.7301C25.1432 14.7828 26.4931 13.2369 28.9183 13.2369C31.4581 13.2369 32.5715 14.5486 32.5715 16.1781C32.5715 16.8519 32.2537 17.4086 31.1379 17.5687L26.1706 18.3213C22.8758 18.7992 20.3312 20.7106 20.3312 24.1607C20.3312 27.219 22.8351 30.0001 26.8874 30.0001C30.0245 30.0001 31.9311 28.4113 32.8462 26.9419C32.8462 28.4901 33.0039 29.2857 33.0445 29.4052H37.3333C37.1632 28.3788 37.0832 27.3395 37.0944 26.2991L37.1015 16.6894ZM32.5619 21.3748C32.5619 25.1499 30.3375 26.3923 27.7547 26.3923C25.9293 26.3923 24.9353 25.1977 24.9353 23.8907C24.9353 22.2995 26.087 21.5015 27.5181 21.2625L32.5619 20.5099V21.3748Z",
    fill: "none"
  }), _jsx("path", {
    d: "M44.1619 13.1533V10.0472H39.6796V29.4004H44.2885V20.185C44.2885 16.5676 45.918 14.5033 49.4923 14.5033C49.998 14.5053 50.5027 14.5453 51.0024 14.6227V9.93734C50.5961 9.8585 50.1833 9.81849 49.7695 9.81787C47.2297 9.81787 45.0841 11.0484 44.1619 13.1533Z",
    fill: "none"
  }), _jsx("path", {
    d: "M62.877 9.5C60.7266 9.5 58.4282 10.4127 57.1571 12.6371V10.0543H52.6676V29.4075H57.2765V18.2376C57.2765 15.6954 58.6671 13.6693 61.2881 13.6693C64.1863 13.6693 65.2591 15.5807 65.2591 17.97V29.4099H69.868V17.1648C69.868 12.9143 67.603 9.5 62.877 9.5Z",
    fill: "none"
  }), _jsx("path", {
    d: "M80.9568 9.45947C76.0683 9.45947 71.5406 13.5213 71.5406 19.6283C71.5406 26.1821 76.1878 29.9954 81.4323 29.9954C86.0794 29.9954 89.1377 27.2548 90.1723 23.8788L86.2777 22.6459C85.6422 24.5573 84.2134 26.0244 81.4323 26.0244C80.0624 26.0539 78.7367 25.5395 77.7452 24.5937C76.7538 23.648 76.1775 22.3479 76.1424 20.9782H90.275C90.3684 20.4942 90.4085 20.0015 90.3945 19.5088C90.4016 13.3922 86.9061 9.45947 80.9568 9.45947ZM76.2619 17.6021C76.352 16.4186 76.8921 15.3146 77.7711 14.5169C78.65 13.7193 79.8012 13.2886 80.9879 13.3134C84.1656 13.3134 85.6756 15.3371 85.7545 17.6021H76.2619Z",
    fill: "none"
  }), _jsx("path", {
    d: "M117.274 15.908V11.8176H113.212V8.95042C113.212 7.10706 112.48 5.33919 111.176 4.03573C109.873 2.73227 108.105 2 106.262 2C104.418 2 102.65 2.73227 101.347 4.03573C100.043 5.33919 99.3111 7.10706 99.3111 8.95042C99.3111 9.12484 99.3255 9.29687 99.3374 9.4689C99.2036 9.4689 99.0746 9.45217 98.9336 9.45217C94.922 9.45217 91.8231 12.3193 91.8231 15.6882C91.8231 18.3499 93.6103 20.5743 97.027 21.3293L99.8941 21.9648C101.442 22.2802 102.159 23.0759 102.159 24.1152C102.159 25.3098 101.165 26.342 99.2586 26.342C96.7952 26.342 95.4859 24.7914 95.3258 23.0448L91.3142 24.1558C91.5531 26.342 93.7035 29.9952 99.2968 29.9952C104.224 29.9952 106.608 26.7386 106.608 23.7974C106.608 20.9303 104.697 18.7154 101.046 17.9222L98.1069 17.3249C96.9123 17.0859 96.1597 16.3309 96.1597 15.2987C96.1597 14.1041 97.3137 13.0743 98.9408 13.0743C99.8008 13.0372 100.647 13.3036 101.33 13.8269C101.354 13.8485 101.397 13.8795 101.397 13.8795L103.667 11.679C103.236 11.2672 102.909 10.7597 102.711 10.1976C102.571 9.79998 102.5 9.38146 102.501 8.95998C102.498 8.22268 102.711 7.50071 103.114 6.8831C103.517 6.26549 104.091 5.77924 104.767 5.4843C105.443 5.18935 106.19 5.09862 106.917 5.22327C107.644 5.34792 108.318 5.68252 108.857 6.1858C109.396 6.68908 109.775 7.33905 109.949 8.05557C110.123 8.77208 110.083 9.52381 109.835 10.2181C109.587 10.9123 109.141 11.5188 108.552 11.9626C107.964 12.4065 107.258 12.6684 106.522 12.7159V15.908H108.656V23.8787C108.656 27.4936 110.88 29.6392 114.457 29.6392C116.129 29.6392 116.997 29.3191 117.276 29.202V25.3791C116.752 25.4773 116.22 25.5309 115.687 25.5392C113.979 25.5392 113.224 24.8224 113.224 23.1165V15.908H117.274Z",
    fill: "none"
  })]
}), 'EarnestMonoLogo', 'CompanyMono');
export default EarnestMonoLogo;