/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TrivagoMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 107,
  height: 32,
  viewBox: "0 0 107 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M33.4668 7.19995H38.8001L42.9335 18.2666H43.0668L46.8001 7.19995H51.8668L45.4668 23.4666H40.1335L33.4668 7.19995Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.8667 11.2H8.93333V16.6666C8.93333 17.6 9.06667 18.8 9.86667 19.3333C10.6667 19.8666 11.8667 19.7333 12.8 19.6C13.3333 19.6 13.6 19.4666 13.8667 19.2V23.3333C13.2 23.4666 12.6667 23.6 12 23.7333C8.53333 24.1333 3.46667 23.4666 3.46667 18.9333V11.2H0V7.19997H3.46667V2.39997H8.93333V7.19997H13.8667V11.2V11.2ZM27.8667 7.19997H32.8V23.4666H27.8667V7.19997ZM27.6 2.79997C27.6 1.3333 28.8 0.133301 30.2667 0.133301C31.7333 0.133301 32.9333 1.3333 32.9333 2.79997C32.9333 4.26663 31.7333 5.46663 30.2667 5.46663C28.9333 5.46663 27.6 4.26663 27.6 2.79997Z",
    fill: "none"
  }), _jsx("path", {
    d: "M89.4664 15.3334C89.4664 17.4667 90.7998 19.4667 93.0664 19.4667C95.4664 19.4667 96.6664 17.6001 96.6664 15.3334C96.6664 13.2001 95.3331 11.2001 93.0664 11.2001C90.6664 11.2001 89.4664 13.0667 89.4664 15.3334ZM84.9331 15.3334C84.9331 11.8667 86.7998 8.53339 90.1331 7.33339C93.4664 6.13339 97.4664 7.06672 99.5998 9.86672C101.6 12.5334 101.866 16.5334 100.266 19.6001C98.6664 22.8001 94.9331 24.2667 91.4664 23.6001C87.3331 22.9334 84.9331 19.3334 84.9331 15.3334Z",
    fill: "none"
  }), _jsx("path", {
    d: "M103.6 11.7334C102.4 11.7334 101.466 10.8 101.466 9.60005C101.466 8.40005 102.4 7.46672 103.6 7.46672C104.8 7.46672 105.733 8.40005 105.733 9.60005C105.866 10.6667 104.8 11.7334 103.6 11.7334ZM103.6 6.80005C102.133 6.80005 100.933 8.00005 100.933 9.46672C100.933 10.9334 102.133 12.1334 103.6 12.1334C105.066 12.1334 106.266 10.9334 106.266 9.46672C106.266 8.00005 105.2 6.80005 103.6 6.80005V6.80005ZM104 9.06672C104 8.80005 103.733 8.80005 103.466 8.80005H103.2V9.33338H103.333C103.733 9.33338 104 9.33338 104 9.06672V9.06672ZM105.066 10.8H104.266L103.6 9.86672H103.333V10.8H102.666V8.26672H104.133C104.666 8.40005 104.933 8.93338 104.533 9.46672C104.4 9.60005 104.266 9.73338 104.133 9.73338L105.066 10.8V10.8Z",
    fill: "none"
  }), _jsx("path", {
    d: "M79.5998 7.0666V9.33326H79.4665C79.3331 9.19993 79.1998 9.0666 78.9331 8.6666C75.7331 5.8666 70.1331 6.39993 68.1331 10.3999C67.0665 12.3999 66.9331 14.9333 67.1998 17.1999C67.4665 19.1999 68.2665 21.4666 69.9998 22.6666C71.9998 23.9999 74.6665 24.2666 76.9331 23.3333C77.9998 22.9333 78.6665 22.1333 78.9331 21.7333V23.3333C78.9331 24.5333 78.6665 25.4666 77.9998 26.1333C76.5331 27.5999 73.8665 27.4666 71.9998 27.0666C71.0665 26.7999 69.9998 26.3999 69.0665 25.7333L66.7998 29.3333C68.1331 30.2666 69.3331 30.7999 70.6665 31.1999C74.2665 32.1333 79.1998 32.1333 81.8665 29.3333C83.4665 27.7333 83.9998 25.4666 83.9998 22.2666V7.0666H79.5998ZM78.3998 18.2666C77.0665 19.8666 74.3998 19.8666 73.0665 18.2666C71.7331 16.6666 71.7331 13.8666 73.0665 12.2666C74.3998 10.6666 77.0665 10.6666 78.3998 12.2666C79.7331 13.8666 79.7331 16.6666 78.3998 18.2666C79.0665 17.4666 77.7331 19.0666 78.3998 18.2666Z",
    fill: "none"
  }), _jsx("path", {
    d: "M15.2002 7.19993H20.2669V9.8666C20.8002 8.79993 21.4669 8.13326 22.1336 7.59993C23.7336 6.53326 26.2669 6.93326 26.5336 7.0666V11.5999C25.4669 11.3333 23.3336 10.9333 21.8669 11.8666C20.9336 12.3999 20.1336 13.1999 20.1336 16.7999V23.5999H15.0669V7.19993H15.2002Z",
    fill: "none"
  }), _jsx("path", {
    d: "M56.5332 16.9334C55.5998 17.6 54.7998 18.8 55.7332 19.7334C56.6665 20.6667 58.2665 20.5334 59.3332 20.1334C60.5332 19.7334 61.4665 18.8 61.4665 17.3334V16.2667C61.5998 16.4 57.8665 16.1334 56.5332 16.9334ZM61.5998 21.4667C60.9332 22.2667 60.2665 22.9334 59.3332 23.3334C55.7332 24.8 50.6665 23.3334 50.6665 18.9334C50.6665 14.2667 55.7332 13.3334 59.3332 13.2H61.5998C61.5998 12.2667 61.3332 11.6 60.6665 11.0667C59.4665 10 57.5998 10.1334 56.1332 10.8C55.4665 11.2 54.7998 11.6 54.2665 12.2667L51.5998 9.46671C52.5332 8.66671 53.5998 8.00005 54.9332 7.46671C57.0665 6.66671 59.7332 6.53338 61.8665 7.20005C63.9998 7.73338 65.3332 9.46671 65.8665 11.6C66.1332 12.6667 66.1332 13.8667 66.1332 15.2V23.4667H61.5998V21.4667V21.4667Z",
    fill: "none"
  })]
}), 'TrivagoMonoLogo', 'CompanyMono');
export default TrivagoMonoLogo;