/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AttributionMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 171,
  height: 32,
  viewBox: "0 0 171 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M47.0331 22.7432H39.9567V25.0351H47.0331V22.7432Z",
    fill: "none"
  }), _jsx("path", {
    d: "M169.128 22.7434V14.8935C169.128 12.6015 167.589 11.4556 165.69 11.4556H164.105C163.045 11.5262 161.627 12.4201 160.782 13.5286V11.5893H158.413H156.351V13.8812H158.413V22.7434H156.351V25.0354H162.739V22.7434H160.782V14.9317C161.126 14.1677 162.233 13.7284 163.016 13.7475H165.461C166.415 13.7475 166.607 14.1861 166.607 14.8935V21.5975C166.607 21.6316 166.607 22.2175 166.606 22.7434H164.716V25.0354H170.532V22.7434H169.128Z",
    fill: "none"
  }), _jsx("path", {
    d: "M51.0153 8.50453C50.5856 7.38721 50.0413 7.38721 50.0413 7.38721H48.5228H48.2936H47.3482C47.3482 7.38721 46.8039 7.38721 46.3741 8.50453L41.3605 24.1471L44.0822 23.8893L45.428 19.5632H51.7227L52.7193 22.7433H50.1848V25.0352H57.2612V22.7433H55.5795L51.0153 8.50453ZM46.1231 17.3285L48.5634 9.48305L51.0222 17.3285H46.1231Z",
    fill: "none"
  }), _jsx("path", {
    d: "M62.5897 22.9729C61.8448 23.0015 61.7875 22.2566 61.7875 22.2566V13.7764H65.1108V11.4845H61.7875V8.0752H59.295V11.4845H57.2609V13.7764H59.295V22.2566C59.295 22.2566 59.1804 25.2676 61.6443 25.2362C61.9666 25.2321 63.2394 25.3614 65.1306 24.5592L65.1494 22.9916C65.1494 22.992 62.8145 22.9644 62.5897 22.9729Z",
    fill: "none"
  }), _jsx("path", {
    d: "M71.987 22.9729C71.2421 23.0015 71.1848 22.2566 71.1848 22.2566V13.7764H74.5082V11.4845H71.1848V8.0752H68.6923V11.4845H66.6582V13.7764H68.6923V22.2566C68.6923 22.2566 68.5777 25.2676 71.0416 25.2362C71.3642 25.2321 72.6367 25.3614 74.5279 24.5592L74.5467 22.9916C74.5464 22.992 72.2114 22.9644 71.987 22.9729Z",
    fill: "none"
  }), _jsx("path", {
    d: "M130.604 22.9729C129.859 23.0015 129.802 22.2566 129.802 22.2566V13.7764H133.125V11.4845H129.802V8.0752H127.309V11.4845H125.275V13.7764H127.309V22.2566C127.309 22.2566 127.195 25.2676 129.659 25.2362C129.981 25.2321 131.254 25.3614 133.145 24.5592L133.163 22.9916C133.163 22.992 130.829 22.9644 130.604 22.9729Z",
    fill: "none"
  }), _jsx("path", {
    d: "M80.6773 13.462V13.4228V11.5893H75.9311V13.8812H78.118V22.7434H75.9311V25.0354H82.8165V22.7434H80.6773V14.6906C82.1562 14.1039 83.59 14.1773 83.59 14.1773H85.2517V11.4556H83.59C82.5034 11.4556 81.5089 12.5142 80.6773 13.462Z",
    fill: "none"
  }), _jsx("path", {
    d: "M105.02 11.4553H103.014C101.847 11.4553 100.619 12.1739 99.7483 13.2033V9.22066V8.93417V6.92871H94.9925V9.22066H97.1698V22.7432H94.9925V25.0351H99.7483V24.2616V23.3704C100.502 24.2739 101.524 24.9604 102.642 25.0351H105.02C106.918 25.0351 108.458 23.8892 108.458 21.5972V14.8932C108.458 12.5153 106.919 11.4553 105.02 11.4553ZM105.937 21.5972C105.937 22.3046 105.745 22.7432 104.791 22.7432H101.868C101.373 22.7432 100.499 22.6467 99.7483 22.0266V14.5709C100.39 14.1289 101.442 13.7473 101.868 13.7473H104.791C105.765 13.7473 105.937 14.1166 105.937 14.8932V21.5972Z",
    fill: "none"
  }), _jsx("path", {
    d: "M122.85 13.7475V13.5374V11.4556H118.113V13.7475H120.481V21.6929C120.137 22.4569 119.03 22.8962 118.247 22.8771H115.802C114.848 22.8771 114.656 22.4385 114.656 21.7311V15.0272C114.656 14.9296 114.659 13.0047 114.665 12.9201C114.659 12.9174 114.653 12.9153 114.646 12.9123V11.4556H110.091V13.7475H112.135V21.7311C112.135 24.0231 113.674 25.1691 115.573 25.1691H117.158C118.218 25.0985 119.636 24.2045 120.481 23.0961V25.0354H122.85H124.912V22.7434H122.85V13.7475Z",
    fill: "none"
  }), _jsx("path", {
    d: "M151.146 11.5225H150.229H149.226H146.705C144.807 11.5225 143.267 12.5825 143.267 14.9604V21.6643C143.267 23.9563 144.807 25.1023 146.705 25.1023H149.226H149.771H151.146C153.045 25.1023 154.584 23.9563 154.584 21.6643V14.9604C154.584 12.5825 153.045 11.5225 151.146 11.5225ZM152.063 21.6643C152.063 22.3717 151.871 22.8103 150.917 22.8103H150H149.226H146.935C145.98 22.8103 145.789 22.3717 145.789 21.6643V14.9604C145.789 14.1838 145.96 13.8144 146.935 13.8144H149.226H150H150.917C151.891 13.8144 152.063 14.1838 152.063 14.9604V21.6643Z",
    fill: "none"
  }), _jsx("path", {
    d: "M91.4782 13.8813V13.4229V11.5894H86.7319V13.8813H88.9188V22.7435H86.7319V25.0355H93.6173V22.7435H91.4782V13.8813Z",
    fill: "none"
  }), _jsx("path", {
    d: "M139.839 13.8813V13.4229V11.5894H135.092V13.8813H137.279V22.7435H135.092V25.0355H141.978V22.7435H139.839V13.8813Z",
    fill: "none"
  }), _jsx("path", {
    d: "M91.5546 6.87158H88.575V9.76517H91.5546V6.87158Z",
    fill: "none"
  }), _jsx("path", {
    d: "M139.915 6.87158H136.936V9.76517H139.915V6.87158Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.0344 29.7339C26.2623 29.7339 28.8789 27.1172 28.8789 23.8894C28.8789 20.6616 26.2623 18.0449 23.0344 18.0449C19.8066 18.0449 17.19 20.6616 17.19 23.8894C17.19 27.1172 19.8066 29.7339 23.0344 29.7339Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.4963 11.6681C22.3274 10.5975 22.9195 9.30691 22.9195 7.84545C22.9195 4.61762 20.3029 2.00098 17.075 2.00098C15.1061 2.00098 13.7756 2.91025 12.5972 4.08931C9.37858 7.30964 13.8363 13.724 3.72033 18.7121C3.46078 18.8134 3.2101 18.9328 2.96999 19.0685C1.19714 20.0723 0 21.974 0 24.1565C0 27.3843 2.61664 30.001 5.84448 30.001C9.07231 30.001 11.689 27.3843 11.689 24.1565C11.689 23.9832 11.6859 23.4253 11.6975 23.3182C12.6668 14.3847 19.7343 13.9382 21.4963 11.6681Z",
    fill: "none"
  })]
}), 'AttributionMonoLogo', 'CompanyMono');
export default AttributionMonoLogo;