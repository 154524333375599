/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ApartmentListMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 186,
  height: 33,
  viewBox: "0 0 186 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.5,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M133.879 26.8113C133.41 26.9656 132.942 27.1193 132.478 27.273C132.469 26.9165 132.459 26.5586 132.449 26.1999C132.342 22.2377 132.233 18.1752 132.707 14.7171C135.062 14.5547 136.93 12.6223 136.93 10.2738C136.93 9.13363 136.49 8.11117 135.767 7.33398C138.821 4.14019 143.915 3.61972 147.627 6.18307C151.708 9.00111 152.728 14.6372 149.853 18.637C147.143 22.4511 140.349 24.6847 133.879 26.8113Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M133.406 26.0307C133.095 26.4467 132.785 26.861 132.478 27.2729C132.23 26.9419 131.98 26.6099 131.73 26.2772C127.585 20.7625 123.295 15.0548 123.295 10.4253C123.295 5.51642 127.469 1.5166 132.478 1.5166C137.579 1.5166 141.66 5.48612 141.66 10.4253C141.66 15.0092 137.426 20.6631 133.406 26.0307ZM132.385 14.7281C134.889 14.7281 136.93 12.7282 136.93 10.2738C136.93 7.81934 134.889 5.91033 132.385 5.81943C129.881 5.81943 127.84 7.81934 127.84 10.2738C127.84 12.7282 129.881 14.7281 132.385 14.7281Z",
    fill: "none"
  }), _jsx("path", {
    d: "M0 23.5458C0 20.7278 2.13325 19.3642 5.28675 19.3642C6.58525 19.3642 7.6055 19.546 8.533 19.9097V19.7278C8.533 18.1825 7.6055 17.3643 5.7505 17.3643C4.35925 17.3643 3.339 17.637 2.13325 18.0916L1.20575 15.2735C2.68975 14.6372 4.081 14.2736 6.307 14.2736C8.3475 14.2736 9.8315 14.819 10.759 15.728C11.7792 16.728 12.1502 18.0916 12.1502 19.8188V27.2729H8.44025V25.8185C7.51275 26.8184 6.307 27.4548 4.452 27.4548C2.0405 27.4548 0 26.0912 0 23.5458ZM8.533 22.7277V22.0914C7.88375 21.8187 7.049 21.6369 6.1215 21.6369C4.54475 21.6369 3.5245 22.2732 3.5245 23.4549V23.5458C3.5245 24.5458 4.35925 25.0912 5.565 25.0912C7.32725 24.9094 8.533 24.0004 8.533 22.7277Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.5615 14.3645H18.2715V16.1826C19.199 15.0008 20.4048 14.0917 22.3525 14.0917C25.4133 14.0917 28.2885 16.4553 28.2885 20.7278V20.8187C28.2885 25.0912 25.4133 27.4547 22.3525 27.4547C20.4048 27.4547 19.1063 26.5457 18.2715 25.5457V31H14.5615C14.5615 31 14.5615 14.3645 14.5615 14.3645ZM24.7022 20.8187C24.7022 18.637 23.2182 17.1825 21.4559 17.1825C19.6937 17.1825 18.3024 18.637 18.3024 20.7278V20.8187C18.3024 22.9095 19.7864 24.364 21.4559 24.364C23.2182 24.364 24.7022 23.0004 24.7022 20.8187Z",
    fill: "none"
  }), _jsx("path", {
    d: "M29.4326 23.5458C29.4326 20.7278 31.5659 19.3642 34.7194 19.3642C36.0179 19.3642 37.0381 19.546 37.9656 19.9097V19.7278C37.9656 18.1825 37.0381 17.3643 35.1831 17.3643C33.7919 17.3643 32.7716 17.637 31.5659 18.0916L30.6384 15.2735C32.1224 14.6372 33.5136 14.2736 35.7396 14.2736C37.7801 14.2736 39.2641 14.819 40.1916 15.728C41.2119 16.728 41.5829 18.0916 41.5829 19.8188V27.2729H37.9656V25.8185C37.0381 26.8184 35.8324 27.4548 33.9774 27.4548C31.4731 27.4548 29.4326 26.0912 29.4326 23.5458ZM37.9656 22.7277V22.0914C37.3164 21.8187 36.4816 21.6369 35.5541 21.6369C33.9774 21.6369 32.9571 22.2732 32.9571 23.4549V23.5458C32.9571 24.5458 33.7919 25.0912 34.9976 25.0912C36.7599 24.9094 37.9656 24.0004 37.9656 22.7277Z",
    fill: "none"
  }), _jsx("path", {
    d: "M43.9941 14.3645H47.7041V17.0007C48.4461 15.1826 49.6519 14.0917 51.8779 14.1826V18.0006H51.6924C49.1881 18.0006 47.7041 19.4551 47.7041 22.5459V27.2729H43.9941V14.3645Z",
    fill: "none"
  }), _jsx("path", {
    d: "M62.5752 14.3645H66.2852V16.1826C67.1199 15.0917 68.2329 14.0917 70.1807 14.0917C71.8502 14.0917 73.1487 14.819 73.8907 16.0916C75.0037 14.7281 76.3949 14.0917 78.1572 14.0917C80.9397 14.0917 82.6092 15.728 82.6092 18.8188V27.182H78.8992V20.0005C78.8992 18.2734 78.1572 17.3643 76.7659 17.3643C75.3747 17.3643 74.5399 18.2734 74.5399 20.0005V27.182H70.8299V20.0005C70.8299 18.2734 70.0879 17.3643 68.6967 17.3643C67.3054 17.3643 66.4707 18.2734 66.4707 20.0005V27.182H62.7607V14.3645H62.5752V14.3645Z",
    fill: "none"
  }), _jsx("path", {
    d: "M84.4951 20.9096C84.4951 17.1825 87.1849 14.1827 90.9876 14.1827C95.3469 14.1827 97.3873 17.5462 97.3873 21.1823C97.3873 21.4551 97.3873 21.8187 97.2946 22.1823H88.2051C88.5761 23.8186 89.7819 24.7276 91.4514 24.7276C92.6571 24.7276 93.5846 24.364 94.6048 23.3641L96.7381 25.1822C95.5323 26.6366 93.7701 27.5457 91.3586 27.5457C87.4631 27.5457 84.4951 24.8185 84.4951 20.9096ZM93.7701 19.8188C93.5846 18.1825 92.5644 17.0916 90.9876 17.0916C89.4109 17.0916 88.4834 18.1825 88.1124 19.8188H93.7701Z",
    fill: "none"
  }), _jsx("path", {
    d: "M99.335 14.3645H103.045V16.1826C103.88 15.0917 104.993 14.0917 106.848 14.0917C109.63 14.0917 111.3 15.9098 111.3 18.9097V27.2729H107.59V20.0915C107.59 18.3643 106.755 17.4552 105.364 17.4552C103.972 17.4552 103.045 18.3643 103.045 20.0915V27.2729H99.335V14.3645Z",
    fill: "none"
  }), _jsx("path", {
    d: "M53.6714 23.0913V10.5464H57.3814V14.3644H60.4421V17.4552H57.3814V23.0913C57.3814 23.9094 57.7524 24.364 58.5871 24.364C59.2364 24.2731 59.7929 24.0913 60.0711 23.9095L60.9986 26.5457C60.2566 27.0002 59.0509 27.4547 57.7524 27.4547C55.4336 27.4547 53.6714 26.0912 53.6714 23.0913Z",
    fill: "none"
  }), _jsx("path", {
    d: "M113.464 23.0913V10.5464H117.174V14.3644H120.235V17.4552H117.174V23.0913C117.174 23.9094 117.545 24.364 118.38 24.364C119.029 24.2731 119.586 24.0913 119.864 23.9095L120.792 26.5457C120.05 27.0002 118.844 27.4547 117.545 27.4547C115.319 27.4547 113.464 26.0912 113.464 23.0913Z",
    fill: "none"
  }), _jsx("path", {
    d: "M178.173 23.0913V10.5464H181.883V14.3644H184.944V17.4552H181.883V23.0913C181.883 23.9094 182.254 24.364 183.089 24.364C183.738 24.2731 184.294 24.0913 184.573 23.9095L185.5 26.5457C184.758 27.0002 183.552 27.4547 182.254 27.4547C180.028 27.4547 178.173 26.0912 178.173 23.0913Z",
    fill: "none"
  }), _jsx("path", {
    d: "M158.201 10.5464H154.491V27.2123H158.201V10.5464Z",
    fill: "none"
  }), _jsx("path", {
    d: "M160.89 10.5464H164.786V13.819H160.89V10.5464ZM160.983 15.1826H164.693V27.182H160.983V15.1826Z",
    fill: "none"
  }), _jsx("path", {
    d: "M166.27 25.5457L167.847 23.1822C169.238 24.1822 170.722 24.7276 172.021 24.7276C173.134 24.7276 173.597 24.364 173.597 23.7276V23.6367C173.597 22.8186 172.299 22.5459 170.908 22.1823C169.053 21.6368 167.012 20.8187 167.012 18.2734V18.1825C167.012 15.5462 169.145 14.0917 171.835 14.0917C173.505 14.0917 175.267 14.6372 176.751 15.5462L175.36 18.0915C174.061 17.3643 172.763 16.9098 171.835 16.9098C170.908 16.9098 170.444 17.2734 170.444 17.8188V17.9097C170.444 18.637 171.65 19.0006 173.134 19.4551C174.989 20.0915 177.122 20.9096 177.122 23.2731V23.364C177.122 26.1821 174.896 27.5456 172.113 27.5456C170.104 27.4547 168.032 26.9093 166.27 25.5457Z",
    fill: "none"
  })]
}), 'ApartmentListMonoLogo', 'CompanyMono');
export default ApartmentListMonoLogo;