/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AppsflyerMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 111,
  height: 32,
  viewBox: "0 0 111 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M19.5286 0C19.7375 0.252788 20.006 0.475836 20.1552 0.743493C21.7068 3.58364 23.2434 6.43865 24.795 9.2788C25.2873 10.171 25.2276 10.974 24.5712 11.7918C22.7511 14.0818 20.9459 16.3866 19.1407 18.6766C19.0512 18.7806 18.9766 18.8996 18.8722 18.9888C18.5589 19.2862 18.171 19.2862 17.8726 18.974C17.7533 18.8401 17.6488 18.6766 17.5593 18.5279C15.9928 15.658 14.4264 12.7881 12.8748 9.90333C12.4123 9.05575 12.4571 8.25278 13.0687 7.47954C14.9038 5.18958 16.7089 2.86988 18.5291 0.565055C18.6932 0.356877 18.9169 0.193308 19.1109 0C19.2452 0 19.3943 0 19.5286 0Z",
    fill: "none"
  }), _jsx("path", {
    d: "M0 9.4275C0.193946 9.11524 0.477405 9.01115 0.850378 9.01115C4.02811 9.01115 7.19092 9.01115 10.3686 8.99628C11.2787 8.99628 11.9501 9.41263 12.3827 10.2156C13.9492 13.145 15.5008 16.0743 17.0523 19.0037C17.0971 19.078 17.1418 19.1673 17.1717 19.2565C17.3955 19.8215 17.1269 20.223 16.5152 20.2379C15.6052 20.2528 14.6951 20.2528 13.7851 20.2528C11.5174 20.2528 9.24973 20.2528 6.99697 20.2825C5.9974 20.2825 5.29622 19.8513 4.83373 18.9888C3.23741 15.9554 1.64108 12.9517 0.0447567 9.94795C0.0298378 9.93308 0.0149189 9.91821 0 9.90334C0 9.73977 0 9.5762 0 9.4275Z",
    fill: "none"
  }), _jsx("path", {
    d: "M16.9778 31.9852C16.8436 31.8067 16.6944 31.6283 16.5899 31.435C15.6948 29.7993 14.8146 28.1487 13.9194 26.513C13.6061 25.9331 13.636 25.4275 14.0537 24.9071C15.1279 23.5688 16.1871 22.2157 17.2464 20.8625C17.6044 20.4015 17.9028 20.4164 18.1863 20.9368C19.1261 22.6618 20.066 24.4015 21.0059 26.1264C21.2596 26.6023 21.2446 27.0632 20.9015 27.5093C19.8124 28.8922 18.7233 30.2751 17.6193 31.6729C17.5298 31.7918 17.4254 31.8959 17.321 32C17.2016 31.9852 17.0823 31.9852 16.9778 31.9852Z",
    fill: "none"
  }), _jsx("path", {
    d: "M110.4 14.3045C109.908 14.3789 109.386 14.3789 108.908 14.5276C107.655 14.9439 106.909 16.0889 106.894 17.5164C106.879 18.9588 106.894 20.4012 106.894 21.8435C106.894 21.9774 106.894 22.1112 106.894 22.2599C106.238 22.2599 105.611 22.2599 104.97 22.2599C104.97 18.9885 104.97 15.7469 104.97 12.4606C105.596 12.4606 106.208 12.4606 106.879 12.4606C106.879 12.7432 106.879 13.0406 106.879 13.4421C107.968 12.6242 109.132 12.3417 110.385 12.3714C110.4 13.0257 110.4 13.6651 110.4 14.3045Z",
    fill: "none"
  }), _jsx("path", {
    d: "M39.893 22.26C39.2515 22.26 38.6249 22.26 37.9685 22.26C37.9685 20.1188 37.9685 17.9924 37.9685 15.8214C36.2976 15.8214 34.6565 15.8214 33.0005 15.8214C33.0005 15.1671 33.0005 14.5426 33.0005 13.8883C34.6565 13.8883 36.2826 13.8883 37.9536 13.8883C37.9685 13.0407 38.0431 12.2377 37.6552 11.4645C37.0286 10.2006 35.6262 9.53142 34.2238 9.81395C32.8364 10.0965 31.807 11.2563 31.7921 12.6838C31.7622 15.7173 31.7771 18.7507 31.7771 21.7991C31.7771 21.9478 31.7771 22.0965 31.7771 22.26C31.1207 22.26 30.4941 22.26 29.8228 22.26C29.8228 22.1411 29.8228 22.037 29.8228 21.9329C29.8228 18.944 29.8228 15.9552 29.8228 12.9664C29.8377 10.3493 31.5683 8.31209 34.1045 7.89574C37.1032 7.4199 39.8632 9.68012 39.9079 12.7433C39.9527 15.8511 39.9229 18.9589 39.9229 22.0667C39.9229 22.1262 39.9079 22.1857 39.893 22.26Z",
    fill: "none"
  }), _jsx("path", {
    d: "M54.7079 19.3756C55.8119 20.4463 57.0054 20.7585 58.3631 20.2083C59.7804 19.6284 60.5114 18.0671 60.1235 16.6098C59.7356 15.1377 58.3034 14.1563 56.7817 14.3199C55.2599 14.4834 54.0366 15.7028 54.0067 17.2195C53.962 20.1786 53.9918 23.1526 53.9769 26.1266C53.9769 26.2753 53.9769 26.424 53.9769 26.5875C53.3205 26.5875 52.6939 26.5875 52.0374 26.5875C52.0225 26.4834 52.0076 26.3793 52.0076 26.2901C52.0076 23.3905 51.9927 20.4909 52.0076 17.5913C52.0225 14.989 53.6338 13.0113 56.1103 12.5058C58.8405 11.9556 61.496 13.7697 62.0331 16.5504C62.5403 19.1972 60.6606 21.8589 57.9602 22.305C56.9159 22.4834 55.9313 22.3496 54.9615 21.9333C54.7676 21.844 54.6632 21.7399 54.6781 21.502C54.7228 20.8031 54.7079 20.1191 54.7079 19.3756Z",
    fill: "none"
  }), _jsx("path", {
    d: "M43.7573 19.3753C43.9214 19.524 44.0259 19.6281 44.1452 19.7173C45.2641 20.669 46.8903 20.7136 48.0689 19.8362C49.2027 18.9887 49.6056 17.4571 49.0536 16.1634C48.4717 14.84 47.0395 14.0816 45.6073 14.3493C44.1601 14.6169 43.086 15.8214 43.0711 17.2935C43.0412 20.2377 43.0561 23.182 43.0561 26.1262C43.0561 26.26 43.0561 26.3939 43.0561 26.5574C42.4146 26.5574 41.788 26.5574 41.1465 26.5574C41.1316 26.498 41.1167 26.4236 41.1167 26.3641C41.1167 23.3158 41.0868 20.2675 41.1316 17.2192C41.1614 14.6021 43.265 12.5351 45.9355 12.3864C48.5016 12.2526 50.7991 14.1262 51.1422 16.669C51.4107 18.602 50.7394 20.208 49.1729 21.3678C47.5915 22.5277 45.8609 22.6615 44.0557 21.918C43.8468 21.8288 43.7573 21.7247 43.7722 21.4868C43.7722 20.8028 43.7573 20.1336 43.7573 19.3753Z",
    fill: "none"
  }), _jsx("path", {
    d: "M103.895 18.141C103.597 20.5648 101.359 22.4087 98.8379 22.3641C96.2867 22.3195 94.1384 20.3715 93.8997 17.918C93.661 15.2711 95.3618 12.996 98.0024 12.4756C100.389 11.9998 102.956 13.4868 103.672 15.7767C101.658 16.9366 99.6286 18.0815 97.5847 19.2563C97.2565 18.6912 96.9432 18.1708 96.615 17.5908C98.0621 16.7581 99.4943 15.9552 100.956 15.1076C100.837 15.0183 100.762 14.9291 100.658 14.8697C99.1661 13.8436 97.0924 14.3195 96.2121 15.8957C95.3469 17.4421 95.9734 19.3901 97.5698 20.1485C99.2108 20.9217 101.18 20.1485 101.807 18.4533C101.896 18.2005 102.016 18.1262 102.269 18.141C102.821 18.1559 103.343 18.141 103.895 18.141Z",
    fill: "none"
  }), _jsx("path", {
    d: "M92.945 12.4758C92.945 13.5613 92.945 14.632 92.945 15.7026C92.945 16.5799 92.945 17.4424 92.945 18.3197C92.945 20.3271 91.841 21.829 89.9314 22.4386C89.8717 22.4535 89.7971 22.4832 89.7225 22.5279C89.7225 23.197 89.7225 23.881 89.7225 24.5502C89.7225 25.2193 89.7225 25.9033 89.7225 26.5873C89.0661 26.5873 88.4544 26.5873 87.7681 26.5873C87.7681 25.9182 87.7681 25.249 87.7681 24.5799C87.7681 23.9108 87.7681 23.2267 87.7681 22.5279C87.6935 22.4981 87.6189 22.4684 87.5443 22.4386C85.7392 21.8587 84.5904 20.4163 84.5457 18.513C84.5009 16.5502 84.5307 14.6022 84.5307 12.6394C84.5307 12.5948 84.5457 12.5502 84.5606 12.4907C85.1872 12.4907 85.8138 12.4907 86.4851 12.4907C86.4851 12.6394 86.4851 12.7732 86.4851 12.9071C86.4851 14.7212 86.4851 16.5204 86.4851 18.3346C86.4851 19.5539 87.2161 20.4609 88.3201 20.6543C89.7225 20.907 90.9906 19.881 90.9906 18.4535C91.0055 16.5948 90.9906 14.736 90.9906 12.8922C90.9906 12.7732 90.9906 12.6394 90.9906 12.4907C91.662 12.4758 92.2736 12.4758 92.945 12.4758Z",
    fill: "none"
  }), _jsx("path", {
    d: "M25.5262 26.6168C24.6162 26.6168 23.7061 26.602 22.811 26.6168C22.2142 26.6317 21.7965 26.364 21.5279 25.8585C20.603 24.1484 19.678 22.4384 18.753 20.7284C18.4696 20.2079 18.6188 19.9551 19.2155 19.9403C21.0207 19.9254 22.8259 19.9105 24.6311 19.8808C25.2875 19.8659 25.75 20.1336 26.0484 20.7135C26.9435 22.3938 27.8536 24.0592 28.7636 25.7246C28.7935 25.7693 28.8233 25.8287 28.8382 25.8733C29.0471 26.2897 28.8979 26.5425 28.4354 26.5574C27.4657 26.5722 26.5109 26.5574 25.5411 26.5574C25.5262 26.5871 25.5262 26.602 25.5262 26.6168Z",
    fill: "none"
  }), _jsx("path", {
    d: "M62.8534 22.2901C62.8534 21.6358 62.8534 21.0262 62.8534 20.3719C62.9877 20.3719 63.122 20.3719 63.2413 20.3719C64.9272 20.3719 66.6279 20.3719 68.3138 20.357C68.4928 20.357 68.7315 20.2827 68.8658 20.1637C69.0896 19.9407 68.9553 19.5987 68.5972 19.4351C67.5828 18.9593 66.5832 18.4983 65.5687 18.0373C65.0316 17.7845 64.4796 17.5466 63.9425 17.2938C62.8534 16.7734 62.3014 15.7325 62.5252 14.6024C62.7341 13.5466 63.6591 12.7288 64.8078 12.7139C66.6279 12.6693 68.463 12.699 70.298 12.699C70.3129 12.699 70.3428 12.7139 70.3875 12.7288C70.3875 13.3384 70.3875 13.9481 70.3875 14.6173C70.2682 14.6173 70.1488 14.6173 70.0295 14.6173C68.448 14.6173 66.8517 14.6173 65.2703 14.6173C65.1808 14.6173 65.0913 14.6173 65.0018 14.6173C64.7332 14.6321 64.5244 14.7065 64.4647 15.0039C64.405 15.2864 64.5542 15.4648 64.8078 15.5689C65.4195 15.8515 66.0163 16.1191 66.6279 16.4016C67.5678 16.8329 68.5077 17.2641 69.4327 17.6953C70.5665 18.2306 71.1335 19.2715 70.8798 20.4314C70.6411 21.5466 69.6565 22.305 68.4033 22.305C66.5533 22.2901 64.7183 22.2901 62.8534 22.2901Z",
    fill: "none"
  }), _jsx("path", {
    d: "M79.8164 14.0816C79.8164 14.7508 79.8164 15.3604 79.8164 16.0147C78.041 16.0147 76.2657 16.0147 74.4754 16.0147C74.4754 18.1114 74.4754 20.1634 74.4754 22.2452C73.8189 22.2452 73.1923 22.2452 72.521 22.2452C72.521 22.1411 72.521 22.0221 72.521 21.9032C72.521 18.9143 72.521 15.9255 72.521 12.9218C72.521 10.0073 74.6992 7.83628 77.6084 7.82141C78.3841 7.82141 79.1748 7.82141 79.9805 7.82141C79.9805 8.46082 79.9805 9.08535 79.9805 9.75449C79.1898 9.75449 78.414 9.72475 77.6382 9.76936C77.1906 9.7991 76.7132 9.85858 76.3104 10.037C75.1318 10.5575 74.5201 11.5091 74.4605 12.7879C74.4455 13.2043 74.4605 13.6355 74.4605 14.0816C76.2507 14.0816 78.0112 14.0816 79.8164 14.0816Z",
    fill: "none"
  }), _jsx("path", {
    d: "M80.9648 7.85162C81.6064 7.85162 82.218 7.85162 82.8596 7.85162C82.8596 12.6546 82.8596 17.4427 82.8596 22.2605C82.233 22.2605 81.6064 22.2605 80.9648 22.2605C80.9648 17.4724 80.9648 12.6695 80.9648 7.85162Z",
    fill: "none"
  })]
}), 'AppsflyerMonoLogo', 'CompanyMono');
export default AppsflyerMonoLogo;