/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VmwareMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 148,
  height: 23,
  viewBox: "0 0 148 23",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M1.78606 0.743975C3.39739 0.0280457 5.12311 0.721292 5.82129 2.25176L11.3633 15.3179L15.6835 5.13421C17.146 1.79025 19.5432 0.462929 22.5618 0.462929C24.5133 0.462929 26.8702 1.54077 28.2547 3.33453C28.2547 3.33453 31.2357 0.462929 34.6418 0.462929C37.8694 0.462929 39.7766 1.54077 41.1601 3.33453C41.1601 3.33453 43.7635 0.458984 47.92 0.458984C52.0815 0.458984 55.5398 3.4716 55.5398 7.7721V19.4745C55.5398 21.4112 54.4196 22.9999 52.3556 22.9999C50.2907 22.9999 49.2138 21.4112 49.2138 19.4745V9.19212C49.2138 7.25537 47.92 5.92212 45.9862 5.92212C44.0485 5.92212 42.6334 7.20902 42.6334 9.19212V19.4745C42.6334 21.4112 41.5112 22.9999 39.4462 22.9999C37.3832 22.9999 36.3054 21.4112 36.3054 19.4745V9.19212C36.3054 7.25537 35.0165 5.92212 33.0798 5.92212C31.143 5.92212 29.722 7.20902 29.722 9.19212V19.4745C29.722 21.4112 28.6057 22.9999 26.5408 22.9999C24.4739 22.9999 23.4 21.4112 23.4 19.4745V6.90924C23.4 6.3649 22.973 5.9596 22.4651 5.95664C21.8646 5.95269 21.6752 6.36785 21.6151 6.50394C21.5559 6.63805 15.6549 19.4873 15.6549 19.4873C14.5919 21.6844 13.6117 22.9999 11.3633 22.9999C9.25892 22.9999 8.1377 21.7919 7.07367 19.4873L0.307853 4.78118C-0.420895 3.24972 0.172752 1.46089 1.78606 0.743975Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M88.2417 16.3356V16.2528C88.2417 11.76 91.9535 9.36569 97.3525 9.36569C100.075 9.36569 102.012 9.73647 103.912 10.2759V9.53037C103.912 5.69927 101.555 3.71715 97.5606 3.71715C95.4158 3.71715 94.1673 3.99031 92.5974 4.68947C92.3943 4.77231 92.1862 4.81471 92.0255 4.81471C91.2829 4.81471 90.6202 4.19444 90.6202 3.45386C90.6202 2.79611 90.9072 2.38884 91.488 2.13146C93.5875 1.22028 95.1702 0.91754 97.89 0.91754C100.898 0.91754 103.207 1.69855 104.771 3.26649C106.217 4.7092 106.959 6.7702 106.959 9.4929V21.2554C106.959 22.1597 106.3 22.8214 105.429 22.8214C104.526 22.8214 103.913 22.1607 103.913 21.3373V19.3039C102.426 21.2456 99.95 22.9742 96.2057 22.9742C92.2454 22.9713 88.2417 20.7061 88.2417 16.3356ZM103.953 14.6858V12.6238C102.383 12.1712 100.277 11.7166 97.6819 11.7166C93.6881 11.7166 91.4584 13.4502 91.4584 16.1276V16.2094C91.4584 18.8887 93.9297 20.4557 96.819 20.4557C100.734 20.4557 103.953 18.0633 103.953 14.6858Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M122.693 11.9514C122.693 18.4983 127.393 22.9625 133.149 22.9625C136.712 22.9625 139.065 21.6894 141.048 19.7675C141.376 19.4844 141.538 19.1146 141.538 18.7478C141.538 18.0082 140.928 17.3939 140.16 17.3939C139.794 17.3939 139.511 17.5576 139.267 17.7597C137.725 19.1926 135.826 20.2161 133.232 20.2161C129.499 20.2161 126.3 17.6384 125.859 13.0579H141.007C141.773 13.0579 142.468 12.4415 142.468 11.5797C142.468 5.96958 138.94 0.857498 132.744 0.857498C126.949 0.857498 122.693 5.7694 122.693 11.8666V11.9514ZM132.659 3.52004C136.833 3.52004 139.022 6.87287 139.348 10.7237H125.857C126.257 6.58788 129.012 3.52004 132.659 3.52004Z",
    fill: "none"
  }), _jsx("path", {
    d: "M63.5785 21.3499L57.0987 3.30576L57.0839 3.26166L57.0703 3.22131C56.9904 2.98481 56.8926 2.69534 56.8926 2.43797C56.8926 1.65597 57.5533 0.91342 58.5 0.91342C59.3263 0.91342 59.8648 1.44987 60.1113 2.23187L65.4808 17.7801L70.8887 2.18848C71.1352 1.44888 71.6312 0.912434 72.4527 0.912434H72.6203C73.4891 0.912434 74.0255 1.44888 74.2721 2.18848L79.6356 17.7801L85.0899 2.15002C85.296 1.48636 85.7466 0.912434 86.6164 0.912434C87.5236 0.912434 88.1853 1.61061 88.1853 2.39655C88.1853 2.64301 88.0936 2.91908 88.0164 3.15119L87.9951 3.21555L87.9792 3.26434L81.458 21.3499C81.1247 22.2946 80.4669 22.7906 79.7224 22.7906H79.5991C78.7728 22.7906 78.1525 22.2946 77.8616 21.3499L72.5394 6.01662L67.171 21.3499C66.882 22.2946 66.2608 22.7906 65.4374 22.7906H65.3506C64.5686 22.7906 63.9108 22.2946 63.5785 21.3499Z",
    fill: "none"
  }), _jsx("path", {
    d: "M110.813 2.48633C110.813 1.61558 111.515 0.872044 112.382 0.872044C113.296 0.872044 113.996 1.57515 113.996 2.48633V6.14782C115.566 2.59185 119.048 0.913462 121.356 0.913462C122.311 0.913462 122.932 1.61558 122.932 2.52479C122.932 3.39456 122.356 4.01187 121.522 4.13612C117.385 4.63214 113.997 7.73549 113.997 13.8988V21.1498C113.997 22.0185 113.337 22.7611 112.428 22.7611C111.517 22.7611 110.814 22.055 110.814 21.1498L110.813 2.48633Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M144.635 2.18289C144.477 2.18289 144.351 2.30813 144.351 2.46492L144.352 4.47761C144.352 4.63342 144.478 4.75767 144.636 4.75767C144.794 4.75767 144.915 4.6344 144.915 4.47761V3.92143H145.364L145.911 4.61369C145.977 4.69456 146.062 4.75865 146.187 4.75865C146.322 4.75865 146.454 4.6561 146.454 4.50029C146.454 4.41351 146.417 4.35533 146.363 4.28433L145.974 3.81099C146.281 3.6818 146.484 3.43626 146.484 3.03392V3.02603C146.484 2.78936 146.405 2.58523 146.26 2.44421C146.102 2.27657 145.846 2.18289 145.515 2.18289H144.635ZM145.908 3.06252C145.908 3.28045 145.758 3.42344 145.483 3.42344L144.912 3.42245V2.68779H145.474C145.747 2.68779 145.908 2.81598 145.908 3.05364V3.06252Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M142.725 3.52579V3.50804C142.725 2.06928 143.893 0.857336 145.36 0.857336C146.848 0.857336 148 2.05647 148 3.4972V3.50804C148 4.95173 146.835 6.16368 145.36 6.16368C143.872 6.16368 142.725 4.9685 142.725 3.52579ZM147.493 3.50804V3.50114C147.493 2.31779 146.579 1.34744 145.36 1.34744C144.152 1.34744 143.224 2.33455 143.224 3.50804V3.52185C143.224 4.70224 144.141 5.67259 145.36 5.67259C146.564 5.67259 147.493 4.68745 147.493 3.50804Z",
    fill: "none"
  })]
}), 'VmwareMonoLogo', 'CompanyMono');
export default VmwareMonoLogo;