/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CriteoMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 102,
  height: 33,
  viewBox: "0 0 102 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M20.4846 29.794H17.7412V13.4943H20.4846V15.4878C21.4607 14.0974 23.0933 13.3101 24.9951 13.3101C25.3149 13.3101 25.6515 13.3268 25.9712 13.3771L26.1732 13.4106V16.3254L25.8871 16.2584C25.399 16.1579 24.9109 16.1076 24.4229 16.1076C22.1171 16.1076 20.5014 17.7996 20.5014 20.2286L20.4846 29.794Z",
    fill: "none"
  }), _jsx("path", {
    d: "M52.3783 29.7605C55.0879 29.7605 57.0403 28.9229 58.7233 27.0466L58.8748 26.8791L57.1244 25.0699L56.9561 25.2374C55.7107 26.5441 54.5662 27.3147 52.3951 27.3147C49.3488 27.3147 47.346 25.3547 47.144 22.2053H59.5143L59.5312 21.9875C59.5648 21.5352 59.5648 21.0327 59.5648 21.0159C59.5648 18.7879 58.8748 16.7609 57.6125 15.2867C56.2829 13.7288 54.4652 12.908 52.3446 12.908C47.6489 12.908 44.3839 16.4594 44.3839 21.552V21.6692C44.367 26.5106 47.5816 29.7605 52.3783 29.7605ZM47.2955 19.793C47.5648 17.4142 49.7696 15.5045 52.3278 15.5045C54.7008 15.5045 56.6363 17.3975 56.7542 19.793H47.2955Z",
    fill: "none"
  }), _jsx("path", {
    d: "M31.5925 13.4775H28.8491V29.794H31.5925V13.4775Z",
    fill: "none"
  }), _jsx("path", {
    d: "M41.0177 29.9782C40.1762 29.9782 38.9307 29.8944 37.9546 29.1406C36.8774 28.3365 36.3389 26.9126 36.3389 24.9358V9.69153H39.0822V13.4607H42.6502V16.0405H39.0822V24.9358C39.0822 26.9963 39.8059 27.3984 40.984 27.3984C41.4553 27.4151 41.9265 27.3816 42.381 27.3146L42.6502 27.2644V29.7772L42.4819 29.8274C42.0107 29.9447 41.5058 29.9949 41.0177 29.9782Z",
    fill: "none"
  }), _jsx("path", {
    d: "M69.6964 29.9782C71.918 29.995 74.0555 29.1239 75.6207 27.5659C77.2364 25.941 78.1116 23.7297 78.0947 21.4514C78.1284 19.1732 77.2364 16.9619 75.6207 15.337C72.3388 12.1206 67.054 12.1206 63.7721 15.337C62.1564 16.9619 61.2812 19.1732 61.3149 21.4514C61.2812 23.7465 62.1732 25.941 63.789 27.5827C65.3373 29.1406 67.4748 29.995 69.6964 29.9782ZM69.6964 15.5045C71.228 15.4877 72.6922 16.1076 73.7357 17.1964C74.7623 18.2853 75.3346 19.793 75.3346 21.4514C75.3346 23.1099 74.7623 24.6176 73.7357 25.7065C71.5646 27.9177 67.9965 27.968 65.7581 25.807C65.7244 25.7735 65.6908 25.74 65.6571 25.7065C64.6305 24.6176 64.0582 23.1099 64.0582 21.4514C64.0582 19.793 64.6305 18.2853 65.6571 17.1964C66.7006 16.1076 68.1648 15.4877 69.6964 15.5045Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.51811 29.9783C6.27967 29.9951 4.14222 29.124 2.54334 27.566C0.927626 25.9411 0.0356179 23.7466 0.0692786 21.4516C0.0356179 19.1733 0.927626 16.962 2.54334 15.3371C4.12539 13.7791 6.27967 12.908 8.51811 12.9248C10.908 12.9248 12.995 13.7624 14.5265 15.3371L14.678 15.4879L12.995 17.4813L12.8267 17.2971C11.7159 16.1244 10.2348 15.5046 8.51811 15.5046C6.98655 15.4879 5.50548 16.1077 4.44517 17.1966C3.40168 18.2854 2.82945 19.7931 2.82945 21.4516C2.82945 23.11 3.40168 24.6177 4.44517 25.7066C5.50548 26.8122 6.98655 27.4153 8.51811 27.3985C10.218 27.3985 11.699 26.7787 12.8098 25.6228L12.9781 25.4385L14.6612 27.4153L14.5097 27.566C12.995 29.1575 10.9248 29.9783 8.51811 29.9783Z",
    fill: "none"
  }), _jsx("path", {
    d: "M30.2124 6.72649C29.0511 6.74324 28.0918 5.80513 28.0749 4.63249C28.0581 3.4766 29.0006 2.52173 30.1787 2.50498C31.34 2.48823 32.2993 3.42634 32.3162 4.59898V4.61573C32.3162 5.78837 31.3737 6.72649 30.2124 6.72649Z",
    fill: "none"
  }), _jsx("path", {
    d: "M98.9643 29.9782C97.803 29.9949 96.8437 29.0568 96.8269 27.8842C96.8101 26.7283 97.7525 25.7734 98.9307 25.7567C100.092 25.7399 101.051 26.678 101.068 27.8507V27.8674C101.068 29.0401 100.126 29.9782 98.9643 29.9782Z",
    fill: "none"
  }), _jsx("path", {
    d: "M92.9729 26.8792H87.3852C85.9378 26.8792 84.7765 25.7066 84.7765 24.2659V7.88245C84.7765 5.52042 82.8578 3.62744 80.5016 3.62744H36.2378V5.52041H80.2491C81.6965 5.52041 82.8578 6.69305 82.8578 8.11697V24.5004C82.8578 26.8625 84.7765 28.7722 87.1327 28.7722H92.956V26.8792H92.9729Z",
    fill: "none"
  })]
}), 'CriteoMonoLogo', 'CompanyMono');
export default CriteoMonoLogo;