/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MaderiamaderiaMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 229,
  height: 32,
  viewBox: "0 0 229 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("mask", {
    id: "MaderiamaderiaMonoLogo_svg__mask0_1308_10672",
    style: {
      maskType: 'luminance'
    },
    maskUnits: "userSpaceOnUse",
    x: 0,
    y: 0,
    width: 229,
    height: 32,
    children: _jsx("path", {
      d: "M228.001 0.5H0V31.4998H228.001V0.5Z",
      fill: "none"
    })
  }), _jsxs("g", {
    mask: "url(#MaderiamaderiaMonoLogo_svg__mask0_1308_10672)",
    children: [_jsx("path", {
      d: "M26.522 3.06015L31.464 22.4516C31.9167 24.268 30.8228 26.0852 28.9332 26.5676L27.2737 26.9758L25.693 27.3456L9.24466 31.3873C7.39574 31.869 5.50945 30.7571 5.05677 28.9399L0.114705 9.54847C-0.37618 7.73128 0.755928 5.91491 2.60484 5.47L22.3349 0.612736C24.183 0.130275 26.0693 1.24296 26.522 3.06015Z",
      fill: "none"
    }), _jsx("path", {
      d: "M31.464 22.4516L26.522 3.06015C26.0693 1.24296 24.183 0.130275 22.3349 0.612736L2.60484 5.47C0.755928 5.91491 -0.37618 7.73128 0.114705 9.54847L5.05677 28.9399C5.50945 30.7571 7.39574 31.869 9.24466 31.3873L25.693 27.3456C25.6557 27.3089 25.6174 27.2713 25.5801 27.2346C24.9006 26.5301 23.9205 26.1962 22.6373 26.2337L13.1302 26.1227C10.5653 26.1227 9.8103 24.3055 9.77292 23.3047L9.09349 16.2972H6.52777C6.11247 16.2972 5.77358 15.9633 5.77358 15.5559C5.77358 15.3706 5.84917 15.1478 6.00034 15.0367L15.1295 6.35982C15.3936 6.10022 15.8463 6.06349 16.1478 6.32308L25.7678 14.6286C26.1075 14.8882 26.1449 15.3698 25.8807 15.667C25.7296 15.8523 25.5037 15.9633 25.2769 15.9266H23.2776V22.6002C23.2776 23.0084 22.9379 23.3414 22.5235 23.3414L17.4194 23.2933L15.8454 19.2262L14.2989 21.4883V12.8114V12.7746L19.9951 19.4115L17.3164 18.8556C17.3164 18.8556 17.8447 20.561 18.2592 21.8589H21.805V15.222C21.805 14.8139 22.1447 14.4808 22.5592 14.4808H23.3134L15.6926 7.91823L8.41157 14.8514H9.80781C10.1849 14.8514 10.5246 15.1478 10.562 15.5192L11.3162 23.2312V23.268C11.3162 23.4908 11.4673 24.677 13.1651 24.677L22.6339 24.7513C24.3691 24.677 25.7271 25.1594 26.708 26.2346C26.9348 26.4574 27.1233 26.7162 27.2737 26.9758L28.9332 26.5676C30.8228 26.0852 31.9167 24.268 31.464 22.4516Z",
      fill: "#EA5B0C"
    }), _jsx("path", {
      d: "M57.2302 23.6882L57.2277 14.341C57.2277 12.8577 57.0392 11.8193 56.6239 11.2634C56.2086 10.7075 55.4918 10.4479 54.4735 10.4479C53.417 10.3736 52.3604 10.7817 51.6436 11.5605C50.9642 12.3026 50.6245 13.3402 50.6245 14.6757L50.627 23.6523H48.0239L48.0214 14.3427C48.0214 12.8594 47.8328 11.821 47.4175 11.265C47.0022 10.7091 46.2854 10.4495 45.2671 10.4495C44.2106 10.3752 43.1541 10.7834 42.4373 11.5622C41.7578 12.3042 41.4181 13.3418 41.4181 14.6774L41.4206 23.6539H38.8931L38.8906 8.2168H41.3051V10.7466C42.2861 9.15233 44.0976 8.22496 45.9839 8.29925C47.7199 8.22496 49.3047 9.11478 50.0962 10.6356C50.6619 9.89358 51.4169 9.30091 52.2466 8.856C53.0764 8.48538 54.02 8.26251 54.9253 8.30007C55.7933 8.26333 56.6613 8.44864 57.4537 8.81926C58.1331 9.15315 58.7369 9.67235 59.114 10.3025C59.3408 10.7107 59.4911 11.1924 59.5667 11.6373C59.6797 12.4528 59.7179 13.3059 59.7179 14.1222L59.7204 23.6547L57.2302 23.6882Z",
      fill: "none"
    }), _jsx("path", {
      d: "M73.4878 20.1024V12.8622C73.4878 11.3413 72.9969 10.1829 72.0542 9.4588C71.1114 8.73469 69.6014 8.37305 67.6021 8.37305C65.7158 8.37305 64.244 8.80735 63.1874 9.64003C62.1309 10.4727 61.5271 11.7756 61.6026 13.0793V13.1879H64.0172C63.9798 12.4279 64.2814 11.667 64.9226 11.1968C65.5264 10.7625 66.3944 10.5086 67.5639 10.5086C68.696 10.5086 69.5258 10.6899 70.0923 11.0515C70.6579 11.4131 70.9603 12.0654 70.922 12.7169C70.9594 13.1152 70.922 13.4769 70.7709 13.8393C70.6579 14.0565 70.4312 14.201 70.2052 14.2736C69.4502 14.4549 68.6586 14.5994 67.828 14.6353L66.0547 14.8165C64.3187 15.0337 63.0363 15.5047 62.1683 16.2647C61.3003 17.0615 60.8476 18.1831 60.885 19.3415C60.885 20.6812 61.3003 21.7669 62.1683 22.5277C63.0363 23.2878 64.2431 23.6861 65.7532 23.6861C66.7341 23.6861 67.6777 23.5049 68.5831 23.1065C69.5266 22.6722 70.3938 22.0567 71.0732 21.3326V21.4412C71.0358 22.0208 71.2618 22.8045 71.7144 23.2029C72.2053 23.5645 72.8839 23.7237 73.4878 23.6878C73.4878 23.6878 73.4878 20.2469 73.4878 20.1024ZM70.8847 18.1113C70.922 19.1252 70.432 20.0664 69.564 20.6452C68.5831 21.3334 67.4136 21.6951 66.2059 21.6224C65.4891 21.6583 64.7722 21.4779 64.2058 21.0428C63.7149 20.6085 63.4507 20.0289 63.489 19.3774C63.4516 18.7619 63.6775 18.1831 64.0928 17.748C64.6584 17.3137 65.3379 17.0239 66.0929 16.9513C66.3944 16.9153 66.8097 16.8427 67.4136 16.77C69.0357 16.5888 70.2061 16.299 70.8847 15.9374V18.1113Z",
      fill: "none"
    }), _jsx("path", {
      d: "M88.8835 3.33008V23.689H86.4307V21.4007C85.8269 22.1248 85.0727 22.7045 84.2421 23.1028C83.4123 23.5012 82.4687 23.6824 81.5634 23.6824C79.7145 23.7551 77.9411 22.9943 76.7717 21.6179C75.564 20.2415 74.9609 18.359 74.9609 16.0406C74.9609 13.6871 75.5648 11.8038 76.7717 10.3915C77.9411 9.01513 79.7145 8.21837 81.5634 8.29103C82.507 8.29103 83.4879 8.47226 84.3559 8.8339C85.1856 9.23228 85.9025 9.81188 86.4689 10.5001V3.33008H88.8835ZM86.5445 15.8602C86.5445 14.0855 86.1674 12.7099 85.4124 11.8046C84.6574 10.8993 83.5261 10.465 82.0543 10.465C80.771 10.3923 79.5259 10.9719 78.7335 11.9499C77.9411 12.9279 77.5641 14.3402 77.5641 16.1867C77.5641 17.8162 77.9794 19.1199 78.7717 20.0619C79.5641 21.0032 80.8092 21.5469 82.0543 21.4742C83.3376 21.5469 84.5444 21.0032 85.3368 20.026C86.1292 19.0472 86.5445 17.6709 86.5445 15.8602Z",
      fill: "none"
    }), _jsx("path", {
      d: "M101.258 18.5398H103.748C103.56 20.0248 102.767 21.3644 101.598 22.3065C100.353 23.2477 98.7681 23.7547 97.1833 23.6828C95.1085 23.6828 93.4481 22.9946 92.2404 21.655C91.0327 20.3154 90.4297 18.468 90.4297 16.1504C90.4297 13.6875 91.0336 11.7682 92.2404 10.3927C93.4473 9.01713 95.1076 8.32812 97.1833 8.32812C99.3337 8.32812 101.032 8.97958 102.202 10.2474C103.371 11.5152 103.975 13.3258 103.975 15.6434C103.975 15.9332 103.975 16.1504 103.975 16.2949C103.975 16.4394 103.975 16.5847 103.938 16.6933H93.071C93.1084 18.2141 93.4863 19.3733 94.2405 20.206C95.7871 21.6542 98.1642 21.8722 99.9376 20.677C100.654 20.1333 101.107 19.3725 101.258 18.5398ZM93.0328 14.6646H101.295C101.295 13.325 100.956 12.2744 100.239 11.5503C99.5223 10.8262 98.5413 10.4637 97.2581 10.4637C96.1642 10.4278 95.1076 10.7894 94.3153 11.5503C93.5611 12.2385 93.1458 13.2891 93.0328 14.6646Z",
      fill: "none"
    }), _jsx("path", {
      d: "M105.109 6.14893V3.33008H107.637V6.14812L105.109 6.14893ZM105.109 23.6898V8.20531H107.637V23.6898H105.109Z",
      fill: "none"
    }), _jsx("path", {
      d: "M109.789 23.689V8.55958H112.242V11.2519C112.543 10.3417 113.147 9.54572 113.864 8.97673C114.619 8.44611 115.486 8.18079 116.392 8.21834H116.807C116.883 8.21834 116.959 8.21834 117.072 8.2559V10.9483L116.845 10.9107H116.468C115.11 10.9107 114.091 11.2895 113.374 12.0103C112.657 12.7312 112.317 13.8308 112.317 15.2333V23.6514L109.789 23.689Z",
      fill: "none"
    }), _jsx("path", {
      d: "M131.367 23.6887V8.66953H135.254V10.3381C135.782 9.70792 136.423 9.15117 137.178 8.78055C137.895 8.40993 138.687 8.26135 139.517 8.26135C140.385 8.22462 141.215 8.40993 142.007 8.78055C142.724 9.18872 143.365 9.74465 143.781 10.4492C144.762 9.04015 146.384 8.18706 148.12 8.2238C149.666 8.2238 150.874 8.66871 151.779 9.55853C152.685 10.4483 153.137 11.6353 153.137 13.1929V23.6879H149.1V14.0092C149.137 13.379 148.911 12.7855 148.496 12.3031C148.043 11.8949 147.439 11.6729 146.836 11.7096C146.119 11.6729 145.439 11.9692 144.949 12.4884C144.458 13.0819 144.194 13.8231 144.232 14.5652V23.6511H140.233V14.0092C140.271 13.379 140.082 12.7855 139.667 12.3031C139.252 11.8949 138.648 11.6729 138.045 11.7096C137.329 11.6729 136.649 11.9692 136.159 12.4884C135.668 13.0819 135.404 13.8231 135.48 14.6019V23.6879L131.367 23.6887Z",
      fill: "#285DA3"
    }), _jsx("path", {
      d: "M183.361 3.33008V23.689H179.475V21.1926C178.984 21.9518 178.305 22.6024 177.475 23.0726C176.682 23.4702 175.777 23.6873 174.872 23.6873C173.061 23.7233 171.362 22.9281 170.306 21.5542C169.136 20.1444 168.57 18.2635 168.57 15.9492C168.57 13.6348 169.135 11.7189 170.306 10.2723C171.362 8.86247 173.023 8.06653 174.833 8.10245C175.739 8.10245 176.644 8.28286 177.475 8.68124C178.267 9.0788 178.908 9.69351 179.399 10.4168V3.33008H183.361ZM172.684 15.7328C172.684 17.2153 172.985 18.3362 173.552 19.1321C174.117 19.8913 175.061 20.3615 176.08 20.2889C177.061 20.3248 178.005 19.8913 178.57 19.1321C179.136 18.3729 179.438 17.2153 179.438 15.7328C179.438 14.3589 179.137 13.274 178.533 12.5148C177.929 11.7556 176.985 11.358 176.004 11.394C175.061 11.358 174.156 11.7915 173.59 12.5507C172.985 13.3467 172.684 14.3949 172.684 15.7328Z",
      fill: "#285DA3"
    }), _jsx("path", {
      d: "M194.72 18.7931H198.795C198.455 20.2315 197.55 21.5254 196.305 22.3883C194.984 23.2871 193.362 23.7549 191.739 23.683C189.513 23.683 187.777 22.9638 186.495 21.5622C185.211 20.1597 184.57 18.2184 184.57 15.8094C184.57 13.4362 185.211 11.5309 186.495 10.1643C187.777 8.7977 189.513 8.11523 191.702 8.11523C194.041 8.11523 195.852 8.79852 197.135 10.1284C198.418 11.459 199.059 13.4003 199.059 15.9171C199.059 16.2045 199.059 16.4208 199.059 16.5286C199.059 16.6722 199.059 16.78 199.022 16.9237H188.834C188.872 18.0739 189.174 18.9368 189.702 19.5123C190.306 20.1238 191.136 20.447 191.967 20.3752C192.57 20.4111 193.174 20.2674 193.703 19.9801C194.154 19.6919 194.531 19.2609 194.72 18.7931ZM188.758 14.4069H194.757C194.795 13.5799 194.493 12.7889 193.928 12.1774C193.324 11.6378 192.531 11.3505 191.739 11.4223C190.947 11.3864 190.192 11.6737 189.626 12.2133C189.098 12.7889 188.796 13.5799 188.758 14.4069Z",
      fill: "#285DA3"
    }), _jsx("path", {
      d: "M204.153 8.65234H200.078V23.6209H204.153V8.65234Z",
      fill: "none"
    }), _jsx("path", {
      d: "M200.078 3.33008V7.0012H203.021C203.474 6.66731 203.889 6.2224 204.19 5.73994V3.33008H200.078Z",
      fill: "none"
    }), _jsx("path", {
      d: "M206.078 23.6214V8.65287H209.851V11.1223C210.267 10.2635 210.908 9.51167 211.738 8.93859C212.493 8.43736 213.36 8.22266 214.266 8.22266H214.643C214.719 8.22266 214.794 8.22266 214.87 8.25858V12.1958H213.512C212.38 12.1958 211.55 12.4824 210.983 13.0187C210.417 13.555 210.154 14.3787 210.154 15.4882V23.6206L206.078 23.6214Z",
      fill: "none"
    }), _jsx("path", {
      d: "M227.997 20.4642V12.2314C228.034 11.0142 227.393 9.83288 226.299 9.18878C225.167 8.47285 223.507 8.15039 221.394 8.15039C219.432 8.15039 217.923 8.57979 216.829 9.43859C215.735 10.2974 215.093 11.5864 215.168 12.9465V13.1612H218.979V12.9465C218.942 12.4093 219.206 11.9081 219.658 11.6224C220.224 11.2648 220.866 11.1211 221.545 11.157C222.45 11.157 223.092 11.2999 223.469 11.5505C223.884 11.8371 224.11 12.3383 224.035 12.8036C224.035 13.5555 223.167 14.0208 221.432 14.2355C220.752 14.3073 220.187 14.3784 219.771 14.4502C217.922 14.7008 216.602 15.202 215.809 15.918C215.017 16.6339 214.602 17.6715 214.602 19.0675C214.602 20.5353 215.017 21.6806 215.809 22.4675C216.602 23.2553 217.771 23.6847 219.243 23.6847C220.262 23.7206 221.243 23.5059 222.149 23.0765C222.978 22.6831 223.658 22.0741 224.186 21.3581C224.186 21.6806 224.487 23.4341 224.601 23.6847H227.997C227.997 23.6847 227.997 20.7859 227.997 20.4642ZM224.11 17.3858C224.148 18.2805 223.809 19.1752 223.167 19.8201C222.45 20.4283 221.506 20.7508 220.564 20.7148C220.035 20.7508 219.507 20.572 219.092 20.2495C218.715 19.9271 218.526 19.4618 218.526 18.9613C218.489 18.5319 218.639 18.1025 218.942 17.7442C219.357 17.3866 219.885 17.136 220.451 17.0282L221.809 16.7417C222.601 16.5988 223.355 16.3482 224.11 16.0257V17.3858Z",
      fill: "none"
    }), _jsx("path", {
      d: "M167.535 20.453V12.1924C167.573 10.9712 166.932 9.78587 165.838 9.13932C164.706 8.42093 163.045 8.09766 160.933 8.09766C158.971 8.09766 157.462 8.52869 156.367 9.39075C155.273 10.2528 154.631 11.5459 154.707 12.9108V13.1263H158.518V12.9108C158.48 12.372 158.744 11.8692 159.197 11.5818C159.763 11.2226 160.405 11.0789 161.083 11.1148C161.989 11.1148 162.63 11.2585 163.008 11.51C163.423 11.7973 163.649 12.3002 163.574 12.7671C163.574 13.5214 162.706 13.9884 160.97 14.2039C160.291 14.2757 159.725 14.3476 159.31 14.4194C157.461 14.6709 156.141 15.1737 155.348 15.8921C154.556 16.6105 154.141 17.6522 154.141 19.053C154.141 20.5257 154.556 21.6751 155.348 22.4653C156.141 23.2556 157.31 23.6858 158.782 23.6858C159.801 23.7217 160.782 23.5062 161.687 23.0752C162.517 22.68 163.196 22.0694 163.725 21.351C163.725 21.6743 164.026 23.4343 164.14 23.6858H167.535C167.535 23.6858 167.535 20.7763 167.535 20.453ZM163.649 17.364C163.686 18.262 163.348 19.16 162.706 19.8065C161.989 20.4171 161.045 20.7404 160.103 20.7045C159.574 20.7404 159.046 20.5608 158.631 20.2375C158.254 19.9143 158.065 19.4473 158.065 18.9444C158.028 18.5134 158.178 18.0824 158.48 17.7232C158.896 17.364 159.424 17.1126 159.99 17.0048L161.348 16.7174C162.14 16.5738 162.894 16.3223 163.649 15.9991V17.364Z",
      fill: "none"
    }), _jsx("path", {
      d: "M129.276 20.1004V12.8602C129.276 11.3394 128.786 10.181 127.843 9.45683C126.899 8.73273 125.39 8.37109 123.391 8.37109C121.504 8.37109 120.033 8.80539 118.976 9.63806C117.92 10.4708 117.316 11.7737 117.391 13.0774V13.1859H119.806C119.768 12.4259 120.07 11.6651 120.711 11.1949C121.315 10.7606 122.183 10.5067 123.353 10.5067C124.485 10.5067 125.314 10.6879 125.881 11.0496C126.447 11.4112 126.749 12.0635 126.711 12.7149C126.748 13.1133 126.711 13.4749 126.56 13.8374C126.447 14.0545 126.22 14.199 125.994 14.2717C125.239 14.4529 124.447 14.5974 123.617 14.6333L121.843 14.8146C120.107 15.0317 118.825 15.5027 117.957 16.2628C117.089 17.0595 116.636 18.1812 116.674 19.3396C116.674 20.6792 117.09 21.7649 117.957 22.5258C118.825 23.2858 120.032 23.6842 121.542 23.6842C122.523 23.6842 123.466 23.503 124.372 23.1046C125.315 22.6703 126.182 22.0547 126.862 21.3306V21.4392C126.824 22.0188 127.05 22.8025 127.503 23.2009C127.994 23.5625 128.673 23.7217 129.276 23.6858C129.276 23.6858 129.276 20.2449 129.276 20.1004ZM126.673 18.1093C126.711 19.1232 126.221 20.0645 125.353 20.6433C124.372 21.3315 123.202 21.6931 121.995 21.6205C121.278 21.6564 120.561 21.476 119.994 21.0408C119.504 20.6065 119.239 20.0269 119.278 19.3755C119.24 18.76 119.466 18.1812 119.881 17.7461C120.447 17.3118 121.127 17.022 121.882 16.9493C122.183 16.9134 122.598 16.8407 123.202 16.7681C124.824 16.5868 125.995 16.297 126.673 15.9354V18.1093Z",
      fill: "none"
    })]
  })]
}), 'MaderiamaderiaMonoLogo', 'CompanyMono');
export default MaderiamaderiaMonoLogo;