/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var GithubMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 119,
  height: 32,
  viewBox: "0 0 119 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M22.7886 13.6955H12.848C12.7866 13.6951 12.7257 13.7068 12.6689 13.7301C12.6121 13.7534 12.5605 13.7878 12.5171 13.8312C12.4737 13.8746 12.4394 13.9262 12.4161 13.983C12.3928 14.0398 12.381 14.1006 12.3815 14.162V19.0206C12.3815 19.0819 12.3935 19.1426 12.417 19.1991C12.4404 19.2557 12.4748 19.3072 12.5181 19.3505C12.5614 19.3938 12.6129 19.4282 12.6694 19.4516C12.726 19.475 12.7867 19.4871 12.848 19.4871H16.7244V25.5284C16.7244 25.5284 15.8538 25.824 13.4393 25.824C10.5977 25.824 6.6293 24.786 6.6293 16.0608C6.6293 7.33559 10.7685 6.18253 14.6482 6.18253C18.0088 6.18253 19.4543 6.77384 20.3741 7.05964C20.4417 7.07264 20.5113 7.07063 20.578 7.05376C20.6447 7.03689 20.7069 7.00557 20.7602 6.96201C20.8134 6.91845 20.8565 6.86372 20.8863 6.80168C20.9161 6.73965 20.9319 6.67183 20.9326 6.60302L22.0396 1.90535C22.0418 1.83522 22.0267 1.76563 21.9958 1.70265C21.9649 1.63967 21.919 1.5852 21.8622 1.54399C21.4877 1.27461 19.2079 0 13.4458 0C6.80998 0 0 2.82517 0 16.3991C0 29.9731 7.7955 32 14.3657 32C19.8025 32 23.104 29.6742 23.104 29.6742C23.1563 29.6326 23.1974 29.5788 23.2238 29.5174C23.2502 29.4561 23.2609 29.3892 23.2551 29.3227V14.162C23.2551 14.0383 23.206 13.9196 23.1185 13.8321C23.031 13.7447 22.9123 13.6955 22.7886 13.6955V13.6955Z",
    fill: "none"
  }), _jsx("path", {
    d: "M74.0328 1.64257C74.0355 1.58147 74.026 1.52044 74.0049 1.46302C73.9838 1.4056 73.9516 1.35292 73.9101 1.30803C73.8685 1.26313 73.8185 1.2269 73.7629 1.20144C73.7073 1.17598 73.6472 1.16178 73.5861 1.15967H68.0014C67.9364 1.15545 67.8713 1.16497 67.8103 1.18761C67.7493 1.21025 67.6937 1.2455 67.6472 1.29107C67.6007 1.33663 67.5643 1.39149 67.5404 1.45205C67.5166 1.51262 67.5057 1.57754 67.5086 1.64257V12.4472H58.7835V1.64257C58.7839 1.58147 58.7722 1.52088 58.7492 1.46431C58.7261 1.40773 58.692 1.35628 58.649 1.31292C58.6059 1.26956 58.5547 1.23514 58.4983 1.21166C58.4419 1.18818 58.3814 1.17609 58.3203 1.17609H52.7356C52.6734 1.17432 52.6115 1.18511 52.5536 1.2078C52.4956 1.23049 52.4428 1.26463 52.3984 1.30817C52.3539 1.3517 52.3187 1.40376 52.2948 1.46121C52.2709 1.51867 52.2588 1.58035 52.2593 1.64257V30.9225C52.2589 30.9838 52.2705 31.0445 52.2935 31.1013C52.3166 31.1581 52.3506 31.2097 52.3936 31.2533C52.4366 31.297 52.4878 31.3317 52.5442 31.3555C52.6007 31.3794 52.6612 31.3919 52.7225 31.3923H58.3071C58.3684 31.3919 58.429 31.3794 58.4854 31.3555C58.5418 31.3317 58.593 31.297 58.636 31.2533C58.679 31.2097 58.713 31.1581 58.7361 31.1013C58.7591 31.0445 58.7708 30.9838 58.7703 30.9225V18.3965H67.5086V30.9291C67.5082 30.9904 67.5198 31.0511 67.5429 31.1079C67.5659 31.1646 67.5999 31.2163 67.643 31.2599C67.686 31.3035 67.7371 31.3383 67.7936 31.3621C67.85 31.3859 67.9106 31.3984 67.9718 31.3989H73.5861C73.7095 31.3971 73.8273 31.3469 73.914 31.259C74.0007 31.1711 74.0493 31.0526 74.0492 30.9291L74.0328 1.64257Z",
    fill: "none"
  }), _jsx("path", {
    d: "M33.3535 5.46967C33.3463 4.75655 33.1284 4.06149 32.727 3.47201C32.3256 2.88253 31.7588 2.42499 31.0979 2.15702C30.437 1.88905 29.7116 1.82262 29.013 1.96611C28.3144 2.10959 27.6739 2.45657 27.1722 2.96335C26.6704 3.47013 26.3298 4.11405 26.1933 4.81401C26.0568 5.51398 26.1304 6.23869 26.4049 6.89688C26.6795 7.55508 27.1426 8.11732 27.7361 8.51282C28.3295 8.90831 29.0267 9.11936 29.7399 9.1194C30.2175 9.11942 30.6903 9.02477 31.1311 8.84091C31.5719 8.65706 31.9719 8.38765 32.3079 8.04826C32.644 7.70887 32.9094 7.30623 33.0888 6.86363C33.2683 6.42103 33.3582 5.94725 33.3535 5.46967Z",
    fill: "none"
  }), _jsx("path", {
    d: "M32.9526 24.7432V11.2186C32.9526 11.0954 32.9039 10.9772 32.8172 10.8899C32.7304 10.8025 32.6126 10.7529 32.4894 10.7521H26.9048C26.7743 10.7649 26.6531 10.8252 26.5641 10.9215C26.4751 11.0177 26.4245 11.1433 26.4219 11.2744V30.6564C26.4219 31.228 26.7767 31.3955 27.2366 31.3955H32.2628C32.8146 31.3955 32.9493 31.1262 32.9493 30.6498L32.9526 24.7432Z",
    fill: "none"
  }), _jsx("path", {
    d: "M95.3067 10.7981H89.7516C89.6908 10.7985 89.6306 10.8109 89.5746 10.8346C89.5186 10.8583 89.4678 10.8928 89.425 10.9361C89.3823 10.9794 89.3486 11.0307 89.3257 11.0871C89.3028 11.1434 89.2913 11.2037 89.2917 11.2646V25.6237C88.2764 26.2903 87.0898 26.6486 85.8752 26.6552C83.8713 26.6552 83.3391 25.7485 83.3391 23.784V11.2646C83.3396 11.2037 83.328 11.1434 83.3051 11.0871C83.2823 11.0307 83.2485 10.9794 83.2058 10.9361C83.1631 10.8928 83.1123 10.8583 83.0562 10.8346C83.0002 10.8109 82.94 10.7985 82.8792 10.7981H77.2387C77.1779 10.7985 77.1178 10.8109 77.0617 10.8346C77.0057 10.8583 76.9549 10.8928 76.9122 10.9361C76.8695 10.9794 76.8357 11.0307 76.8128 11.0871C76.7899 11.1434 76.7784 11.2037 76.7788 11.2646V24.7334C76.7788 30.5612 80.0245 31.9869 84.4922 31.9869C88.1551 31.9869 91.1116 29.96 91.1116 29.96C91.158 30.3619 91.226 30.7611 91.3153 31.1558C91.3558 31.2293 91.4146 31.2912 91.4859 31.3355C91.5572 31.3798 91.6388 31.405 91.7227 31.4087H95.31C95.4331 31.4078 95.5509 31.3583 95.6377 31.2709C95.7245 31.1835 95.7732 31.0654 95.7732 30.9422V11.2646C95.7723 11.1411 95.7229 11.023 95.6356 10.9357C95.5483 10.8484 95.4301 10.799 95.3067 10.7981V10.7981Z",
    fill: "none"
  }), _jsx("path", {
    d: "M110.494 10.1377C108.632 10.1261 106.802 10.6127 105.191 11.547V1.64251C105.192 1.5814 105.18 1.52082 105.157 1.46424C105.134 1.40766 105.1 1.35621 105.057 1.31285C105.014 1.26949 104.963 1.23508 104.906 1.2116C104.85 1.18811 104.789 1.17602 104.728 1.17603H99.1141C99.053 1.17602 98.9925 1.18811 98.9361 1.2116C98.8797 1.23508 98.8285 1.26949 98.7854 1.31285C98.7423 1.35621 98.7083 1.40766 98.6852 1.46424C98.6621 1.52082 98.6505 1.5814 98.6509 1.64251V30.9225C98.6505 30.9837 98.6621 31.0445 98.6851 31.1012C98.7082 31.158 98.7422 31.2097 98.7852 31.2533C98.8282 31.2969 98.8794 31.3316 98.9358 31.3555C98.9923 31.3793 99.0528 31.3918 99.1141 31.3922H103.01C103.094 31.3908 103.176 31.367 103.248 31.323C103.32 31.2791 103.378 31.2168 103.418 31.1426C103.529 30.6989 103.608 30.2476 103.654 29.7924C103.654 29.7924 105.954 31.9671 110.297 31.9671C115.398 31.9671 118.322 29.3785 118.322 20.3478C118.322 11.3171 113.664 10.1377 110.494 10.1377ZM108.302 26.6453C107.162 26.6161 106.048 26.2949 105.067 25.7123V16.4254C105.949 15.9205 106.927 15.6029 107.938 15.4924C109.938 15.315 111.88 15.9195 111.88 20.6894C111.867 25.732 110.996 26.7274 108.302 26.6453Z",
    fill: "none"
  }), _jsx("path", {
    d: "M49.0432 10.7489H44.8448V5.20363C44.8448 4.99339 44.7364 4.87512 44.4933 4.87512H38.7641C38.5408 4.87512 38.4356 4.97039 38.4356 5.1872V10.9328C38.4356 10.9328 35.5678 11.626 35.3739 11.6818C35.2783 11.7109 35.1946 11.7701 35.1354 11.8506C35.0762 11.9311 35.0447 12.0286 35.0454 12.1286V15.7422C35.0476 15.8587 35.0933 15.9702 35.1736 16.0547C35.2538 16.1392 35.3628 16.1906 35.4791 16.1988H38.4356V24.8649C38.4356 31.3036 42.9526 31.9344 45.9913 31.9344C47.1222 31.8999 48.2434 31.7154 49.3257 31.3857C49.4072 31.3509 49.4761 31.292 49.5231 31.2168C49.5701 31.1416 49.5929 31.0538 49.5885 30.9653V27.0034C49.5876 26.8806 49.5387 26.7629 49.4521 26.6757C49.3655 26.5885 49.2482 26.5387 49.1253 26.537C48.8789 26.537 48.2547 26.6355 47.6109 26.6355C45.5478 26.6355 44.8481 25.6763 44.8481 24.4345V16.1988H49.0465C49.1077 16.1984 49.1683 16.1859 49.2247 16.162C49.2811 16.1382 49.3323 16.1035 49.3753 16.0599C49.4184 16.0162 49.4524 15.9646 49.4754 15.9078C49.4984 15.851 49.5101 15.7903 49.5096 15.729V11.2186C49.5101 11.1571 49.4983 11.0961 49.4751 11.0391C49.4518 10.9821 49.4175 10.9303 49.3742 10.8866C49.3308 10.843 49.2793 10.8083 49.2224 10.7847C49.1656 10.761 49.1047 10.7489 49.0432 10.7489V10.7489Z",
    fill: "none"
  })]
}), 'GithubMonoLogo', 'CompanyMono');
export default GithubMonoLogo;