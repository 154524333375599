/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LookerMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 121,
  height: 34,
  viewBox: "0 0 121 34",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M10.2126 0.996585C9.70519 0.996806 9.20744 1.13494 8.77252 1.39622C8.3376 1.65749 7.98189 2.03207 7.74343 2.4799C7.50497 2.92773 7.39273 3.43196 7.41871 3.93866C7.4447 4.44535 7.60793 4.93546 7.89095 5.35654L9.08632 4.16286C9.04681 4.04379 9.02688 3.91909 9.02731 3.79363C9.02731 3.56021 9.09652 3.33204 9.22621 3.13796C9.35589 2.94387 9.54021 2.79261 9.75586 2.70328C9.97151 2.61396 10.2088 2.59058 10.4377 2.63612C10.6667 2.68166 10.877 2.79406 11.042 2.95911C11.2071 3.12417 11.3195 3.33446 11.365 3.56339C11.4105 3.79232 11.3872 4.02962 11.2979 4.24527C11.2085 4.46092 11.0573 4.64525 10.8632 4.77493C10.6691 4.90461 10.4409 4.97382 10.2075 4.97382C10.0826 4.9741 9.95848 4.95417 9.83995 4.91481L8.64627 6.10849C9.00855 6.3542 9.42344 6.51144 9.85756 6.56758C10.2917 6.62372 10.7329 6.57719 11.1458 6.43172C11.5586 6.28625 11.9316 6.04591 12.2346 5.73004C12.5377 5.41417 12.7624 5.03157 12.8906 4.61304C13.0188 4.19451 13.0471 3.75172 12.973 3.3203C12.8989 2.88887 12.7246 2.48086 12.4641 2.12907C12.2036 1.77729 11.8642 1.49156 11.4731 1.29486C11.0821 1.09817 10.6503 0.996002 10.2126 0.996585Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.09958 9.14834C9.10141 8.20349 8.79543 7.28376 8.22793 6.52832L6.67682 8.07774C6.9127 8.50916 6.99894 9.00659 6.92207 9.49223C6.84519 9.97787 6.60954 10.4243 6.25195 10.7618L7.09495 12.8221C7.70964 12.4262 8.21522 11.8825 8.56544 11.2407C8.91565 10.5989 9.0993 9.87948 9.09958 9.14834Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.76531 11.3719H4.72485C4.37803 11.372 4.036 11.2909 3.72606 11.1353C3.41611 10.9797 3.14683 10.7538 2.93969 10.4756C2.73255 10.1974 2.59329 9.87471 2.53302 9.53317C2.47275 9.19163 2.49313 8.84072 2.59255 8.50846C2.69197 8.17619 2.86767 7.87176 3.10563 7.61946C3.34359 7.36715 3.63722 7.17395 3.96311 7.05527C4.28899 6.93659 4.6381 6.89572 4.98258 6.93591C5.32706 6.97611 5.65738 7.09626 5.94719 7.28678L7.48312 5.75084C6.93953 5.31015 6.29813 5.00633 5.61279 4.8649C4.92744 4.72348 4.21817 4.74857 3.54453 4.93808C2.8709 5.12759 2.25258 5.47598 1.74149 5.95399C1.23041 6.432 0.841498 7.02568 0.607413 7.68515C0.373329 8.34462 0.300911 9.05063 0.396241 9.74389C0.491571 10.4372 0.751866 11.0974 1.15526 11.6692C1.55866 12.241 2.09338 12.7077 2.71452 13.03C3.33565 13.3523 4.02506 13.5209 4.72485 13.5216C5.02151 13.5217 5.31745 13.4923 5.6083 13.4339L4.76531 11.3719Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.2715 13.1543C9.30068 13.1533 8.33501 13.2947 7.40529 13.5741L8.63437 16.5768C9.16939 16.4442 9.71858 16.3774 10.2698 16.3779C11.8186 16.3783 13.3194 16.9158 14.5165 17.8987C15.7135 18.8816 16.5327 20.2491 16.8345 21.7683C17.1363 23.2874 16.9021 24.8643 16.1717 26.2301C15.4413 27.5959 14.26 28.6663 12.8289 29.2588C11.3979 29.8512 9.80565 29.9292 8.32354 29.4794C6.84143 29.0296 5.56113 28.0799 4.70075 26.792C3.84037 25.5041 3.45315 23.9577 3.60506 22.4163C3.75697 20.8749 4.43861 19.4339 5.53385 18.3387C6.00511 17.8689 6.54311 17.4712 7.13047 17.1585L5.91488 14.1642C3.72612 15.2361 2.00826 17.0759 1.08866 19.3329C0.169063 21.5899 0.112008 24.1063 0.928372 26.4027C1.74474 28.699 3.37745 30.6147 5.51537 31.7848C7.6533 32.9548 10.147 33.2973 12.5212 32.7471C14.8954 32.1969 16.9842 30.7924 18.3895 28.8012C19.7948 26.81 20.4184 24.3714 20.1415 21.95C19.8645 19.5287 18.7063 17.2939 16.8877 15.6714C15.069 14.049 12.717 13.1525 10.2799 13.1526L10.2715 13.1543Z",
    fill: "none"
  }), _jsx("path", {
    d: "M29.8086 5.10034H32.5989V26.1634H42.8716V28.8154H29.8086V5.10034Z",
    fill: "none"
  }), _jsx("path", {
    d: "M46.9164 27.6739C45.6568 26.9261 44.6268 25.8466 43.939 24.5532C43.2205 23.2122 42.8533 21.7111 42.8718 20.1899C42.8545 18.6709 43.2216 17.1723 43.939 15.8333C44.6268 14.5399 45.6568 13.4603 46.9164 12.7125C48.2245 11.9606 49.7069 11.5649 51.2157 11.5649C52.7245 11.5649 54.2069 11.9606 55.515 12.7125C56.7745 13.4611 57.8048 14.5404 58.4941 15.8333C59.2118 17.1745 59.579 18.6755 59.5613 20.1966C59.579 21.7177 59.2118 23.2186 58.4941 24.5599C57.8053 25.8526 56.7756 26.9319 55.5167 27.6807C54.2086 28.4326 52.7262 28.8283 51.2174 28.8283C49.7086 28.8283 48.2262 28.4326 46.9181 27.6807L46.9164 27.6739ZM53.9976 25.6204C54.8736 25.108 55.5917 24.3642 56.073 23.4708C56.6118 22.4627 56.8807 21.3325 56.8537 20.1899C56.8805 19.0472 56.6116 17.917 56.073 16.9089C55.593 16.0161 54.8768 15.2725 54.0026 14.7593C53.1577 14.2642 52.195 14.0057 51.2157 14.0107C50.2149 14.0068 49.2317 14.2742 48.3708 14.7846C47.5098 15.2949 46.8033 16.029 46.3263 16.9089C45.7891 17.9175 45.5208 19.0474 45.5474 20.1899C45.5207 21.3323 45.789 22.4623 46.3263 23.4708C46.682 24.1231 47.1647 24.6977 47.746 25.1604C48.3273 25.6232 48.9955 25.9648 49.7109 26.1652C50.4264 26.3656 51.1748 26.4206 51.9119 26.327C52.649 26.2334 53.3599 25.9932 54.0026 25.6204H53.9976Z",
    fill: "none"
  }), _jsx("path", {
    d: "M65.0541 27.6739C63.7952 26.9252 62.7654 25.8459 62.0766 24.5532C61.3589 23.2119 60.9918 21.711 61.0094 20.1899C60.9929 18.671 61.36 17.1725 62.0766 15.8333C62.7654 14.5406 63.7952 13.4613 65.0541 12.7125C66.3622 11.9606 67.8446 11.5649 69.3533 11.5649C70.8621 11.5649 72.3445 11.9606 73.6526 12.7125C74.9125 13.4606 75.9429 14.54 76.6317 15.8333C77.3495 17.1745 77.7166 18.6755 77.699 20.1966C77.7166 21.7177 77.3495 23.2186 76.6317 24.5599C75.9434 25.853 74.9135 26.9324 73.6543 27.6807C72.3462 28.4326 70.8638 28.8283 69.355 28.8283C67.8462 28.8283 66.3638 28.4326 65.0558 27.6807L65.0541 27.6739ZM72.1352 25.6204C73.009 25.1067 73.7251 24.3632 74.2056 23.4708C74.7436 22.4624 75.0124 21.3324 74.9862 20.1899C75.0122 19.0473 74.7433 17.9173 74.2056 16.9089C73.7251 16.0165 73.009 15.273 72.1352 14.7593C71.2902 14.2642 70.3276 14.0057 69.3483 14.0107C68.3638 14.0072 67.3961 14.2655 66.5445 14.7593C65.6636 15.2689 64.9416 16.0131 64.4589 16.9089C63.9204 17.917 63.6515 19.0472 63.6783 20.1899C63.6512 21.3325 63.9202 22.4627 64.4589 23.4708C64.9416 24.3666 65.6636 25.1108 66.5445 25.6204C67.3961 26.1142 68.3638 26.3725 69.3483 26.369C70.3299 26.3752 71.295 26.1166 72.1419 25.6204H72.1352Z",
    fill: "none"
  }), _jsx("path", {
    d: "M79.7036 5.10034H82.4922V20.0921L90.8885 11.5627H94.429V11.6942L87.3479 18.8445L94.1087 28.6906V28.8154H90.7637L85.3803 20.7733L82.4922 23.6681V28.8154H79.7036V5.10034Z",
    fill: "none"
  }), _jsx("path", {
    d: "M97.7084 27.706C96.4814 26.968 95.4834 25.904 94.8253 24.6324C94.1215 23.2685 93.7664 21.7515 93.7918 20.2169C93.7802 18.724 94.1137 17.2486 94.7663 15.9058C95.3818 14.6154 96.3401 13.519 97.5364 12.7361C98.7642 11.9425 100.2 11.5317 101.662 11.5559C103.254 11.5559 104.634 11.9117 105.803 12.6232C106.966 13.327 107.902 14.3499 108.5 15.5703C109.145 16.9136 109.465 18.3898 109.434 19.8797C109.433 20.1568 109.412 20.4335 109.37 20.7075H96.4978C96.5619 21.9439 96.8592 22.9847 97.3897 23.8299C97.8784 24.6384 98.5777 25.2987 99.4129 25.7401C100.203 26.1582 101.083 26.377 101.977 26.3774C104.099 26.3774 105.702 25.4007 106.786 23.4472L109.079 24.5616C108.425 25.8289 107.447 26.9004 106.245 27.6672C105.034 28.4327 103.58 28.8154 101.881 28.8154C100.414 28.8397 98.9695 28.4556 97.7084 27.706ZM106.568 18.4702C106.517 17.759 106.322 17.0656 105.995 16.4318C105.629 15.725 105.079 15.1306 104.402 14.7121C103.68 14.2445 102.767 14.0107 101.664 14.0107C100.463 13.9834 99.2983 14.4228 98.4148 15.2365C97.5235 16.0547 96.9395 17.1326 96.663 18.4702H106.568Z",
    fill: "none"
  }), _jsx("path", {
    d: "M111.235 11.5659H113.892V14.7693H114.023C114.352 13.8499 114.986 13.0839 115.927 12.4713C116.798 11.8829 117.824 11.565 118.876 11.5575C119.55 11.5403 120.221 11.6638 120.845 11.92V14.9058C120.11 14.5484 119.302 14.3682 118.485 14.3798C117.687 14.376 116.908 14.6173 116.252 15.0711C115.564 15.5432 115.005 16.1809 114.627 16.9257C114.223 17.7028 114.015 18.5669 114.02 19.4428V28.8152H111.235V11.5659Z",
    fill: "none"
  })]
}), 'LookerMonoLogo', 'CompanyMono');
export default LookerMonoLogo;