/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MindbodygreenMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 187,
  height: 32,
  viewBox: "0 0 187 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("mask", {
    id: "MindbodygreenMonoLogo_svg__mask0_1362_10718",
    style: {
      maskType: 'luminance'
    },
    maskUnits: "userSpaceOnUse",
    x: 0,
    y: 0,
    width: 186,
    height: 32,
    children: _jsx("path", {
      d: "M185.923 0H0V31.6327H185.923V0Z",
      fill: "white"
    })
  }), _jsxs("g", {
    mask: "url(#MindbodygreenMonoLogo_svg__mask0_1362_10718)",
    children: [_jsx("path", {
      d: "M9.69697e-06 10.4657C1.72829 10.2783 3.49198 9.93796 4.84203 11.4997C5.26881 11.9933 5.44069 11.4517 5.6546 11.2869C7.17329 10.1174 8.86827 9.8488 10.7005 10.2547C10.9987 10.3207 11.2754 10.4787 11.5686 10.5739C12.3641 10.8323 12.7548 12.052 13.3855 11.93C14.0458 11.8022 14.5916 10.9326 15.348 10.5928C19.0629 8.92429 22.2578 10.9039 22.3227 14.9496C22.3606 17.3076 22.3064 19.6671 22.3513 22.0248C22.366 22.7971 22.1048 23.0109 21.3518 23.0053C19.5367 22.9919 19.5364 23.0286 19.5364 21.2126C19.5364 19.3954 19.5488 17.578 19.5328 15.7609C19.5171 13.9706 18.3851 12.7615 16.7265 12.7258C15.0208 12.689 13.7706 13.917 13.7447 15.7258C13.7148 17.8133 13.6739 19.9042 13.76 21.9885C13.8172 23.3745 12.8838 22.9203 12.2063 22.9846C11.4493 23.0564 10.8689 23.0291 10.9138 21.9423C10.9984 19.8966 10.95 17.8448 10.9345 15.7957C10.9207 13.9728 9.73164 12.7016 8.05543 12.6784C6.33974 12.6547 5.11303 13.9512 5.09974 15.837C5.08529 17.8861 5.0385 19.9377 5.11918 21.9838C5.16374 23.1146 4.4996 23.0193 3.7875 22.9859C3.11003 22.9541 2.2723 23.2814 2.31924 22.0062C2.39599 19.9211 2.34 17.8312 2.33995 15.7433C2.33992 14.1404 1.55993 13.2319 0 13.018L9.69697e-06 10.4657Z",
      fill: "none"
    }), _jsx("path", {
      d: "M112.865 19.1141C113.982 16.4517 115.02 13.9704 116.063 11.4907C116.457 10.5521 118.249 9.91653 119.146 10.4142C119.649 10.6939 119.218 11.036 119.111 11.2884C117.805 14.3722 116.473 17.4453 115.143 20.5191C114.317 22.4267 113.528 24.3517 112.642 26.2314C111.499 28.6588 110.204 29.3236 107.542 29.0696C107.037 29.0214 106.855 28.8431 106.885 28.3619C106.907 28.0156 106.923 27.6629 106.882 27.3201C106.797 26.6098 107.082 26.4014 107.791 26.3904C110.157 26.3537 111.782 23.6981 110.799 21.5395C109.269 18.1754 107.757 14.8028 106.189 11.4564C105.776 10.5771 105.928 10.414 106.895 10.3386C108.333 10.2264 109.122 10.674 109.668 12.0623C110.582 14.3869 111.721 16.6233 112.865 19.1141Z",
      fill: "none"
    }), _jsx("path", {
      d: "M174.362 11.7995C175.748 10.551 177.317 9.92377 179.144 10.0121C181.363 10.1194 182.917 11.4438 183.352 13.6217C183.696 15.3452 183.543 17.0905 183.527 18.8247C183.518 19.8446 183.806 20.3964 184.911 20.2364C185.775 20.1114 185.95 20.5515 185.92 21.317C185.845 23.2757 186.212 23.0158 184.18 23.0152C181.918 23.0144 180.796 21.8527 180.78 19.5644C180.772 18.4051 180.802 17.245 180.771 16.0865C180.716 14.0649 179.32 12.6839 177.412 12.7259C175.555 12.7669 174.286 14.1357 174.265 16.1498C174.245 18.0819 174.182 20.0166 174.258 21.9454C174.311 23.268 173.448 22.9585 172.723 22.9888C172.005 23.0189 171.383 23.1072 171.405 21.9821C171.473 18.4668 171.443 14.9491 171.419 11.4325C171.414 10.7074 171.512 10.1728 172.402 10.3299C173.137 10.4593 174.375 9.76896 174.246 11.4012C174.238 11.4988 174.302 11.6022 174.362 11.7995Z",
      fill: "none"
    }), _jsx("path", {
      d: "M33.5427 11.8344C34.9805 10.6298 36.4643 9.98108 38.2047 10.0066C40.724 10.0434 42.569 11.5542 42.7236 14.0541C42.8928 16.7896 42.7977 19.5411 42.8325 22.2854C42.8397 22.853 42.5747 23.0332 42.0484 22.9936C41.7019 22.9676 41.3474 22.9536 41.0041 22.996C40.2054 23.0943 40.0115 22.7103 40.0279 21.9858C40.0733 19.9763 40.1239 17.9641 40.0664 15.9559C39.9991 13.6069 38.0122 12.1514 35.8671 12.7882C34.4804 13.1998 33.611 14.3798 33.5636 16.0444C33.5075 18.015 33.4353 19.9906 33.5181 21.9578C33.57 23.1928 32.825 22.9932 32.1089 22.9873C31.4041 22.9815 30.6428 23.2207 30.6666 21.9767C30.7347 18.4207 30.7106 14.8625 30.6748 11.3057C30.667 10.528 30.9431 10.3143 31.6828 10.3088C33.4503 10.2957 33.4501 10.2639 33.5427 11.8344Z",
      fill: "none"
    }), _jsx("path", {
      d: "M137.177 12.4363C138.405 10.9374 139.715 10.2718 141.257 10.0298C141.882 9.93194 142.231 10.0947 142.156 10.8037C142.123 11.1093 142.123 11.4242 142.156 11.7296C142.239 12.4934 142.01 12.8419 141.147 12.8202C140.079 12.7935 139.113 13.2183 138.275 13.8948C137.543 14.4861 137.148 15.2166 137.167 16.1947C137.202 18.0095 137.126 19.8275 137.197 21.6401C137.237 22.6534 137.013 23.2042 135.878 22.9948C135.654 22.9534 135.414 22.9707 135.184 22.9922C134.573 23.0491 134.363 22.7567 134.365 22.1762C134.375 18.5067 134.376 14.8372 134.363 11.1676C134.361 10.5911 134.555 10.2886 135.165 10.321C135.473 10.3374 135.788 10.3571 136.092 10.3168C136.975 10.1997 137.305 10.5797 137.182 11.4341C137.151 11.6475 137.177 11.8692 137.177 12.4363Z",
      fill: "none"
    }), _jsx("path", {
      d: "M25.1044 16.6084C25.1044 14.8747 25.1349 13.1402 25.0904 11.4077C25.0709 10.6472 25.2411 10.3008 26.0986 10.3047C27.9024 10.313 27.9026 10.2672 27.9026 12.0906C27.9027 15.3653 27.8509 18.6414 27.9305 21.9142C27.9586 23.0736 27.3575 23.0328 26.5715 22.9955C25.8315 22.9605 25.0029 23.2393 25.0791 21.9236C25.1814 20.1573 25.1044 18.3806 25.1044 16.6084Z",
      fill: "none"
    }), _jsx("path", {
      d: "M26.5138 4.66538C25.5583 4.67741 24.7467 3.89226 24.726 2.93575C24.7051 1.97588 25.4773 1.18971 26.46 1.17033C27.4666 1.15047 28.1962 1.86435 28.2145 2.88695C28.2326 3.89358 27.5065 4.65288 26.5138 4.66538Z",
      fill: "#020202"
    }), _jsx("path", {
      d: "M130.574 12.9132C131.463 14.7841 131.54 16.5708 130.625 18.3462C129.394 20.7352 126.732 21.9196 124.055 21.2714C123.693 21.1838 123.306 20.9069 123.005 21.3326C122.823 21.5895 122.81 21.9142 122.921 22.2243C123.1 22.7207 123.51 22.7749 123.958 22.7732C125.119 22.7689 126.281 22.7557 127.442 22.7762C129.761 22.8171 131.684 24.3984 131.977 26.4821C132.267 28.5487 131.031 30.393 128.777 31.1097C126.538 31.8218 124.264 31.8256 122.055 31.0099C119.027 29.8915 118.219 26.7445 120.293 24.2773C120.601 23.9097 120.727 23.6558 120.515 23.1739C120.052 22.1211 120.136 21.0484 120.754 20.0738C121.018 19.6576 121.021 19.3928 120.746 18.962C119.364 16.7923 119.334 14.561 120.832 12.4628C122.325 10.3743 124.403 9.71054 126.955 10.1657C128.234 10.3937 129.578 10.3037 130.891 10.2711C131.728 10.2503 132.205 10.3835 132.133 11.3881C132.073 12.2246 132.239 13.1039 130.923 12.8486C130.853 12.835 130.772 12.8744 130.574 12.9132ZM128.685 15.7174C128.634 14.0436 127.2 12.6395 125.53 12.6273C123.802 12.6147 122.359 14.0607 122.366 15.7979C122.373 17.5438 123.809 18.9539 125.554 18.9283C127.288 18.9029 128.737 17.4165 128.685 15.7174ZM123.202 25.4926C122.411 25.6988 121.903 26.1972 121.811 27.0033C121.716 27.8307 122.281 28.2961 122.958 28.5473C124.681 29.1856 126.433 29.1962 128.165 28.5727C128.849 28.3266 129.442 27.8897 129.374 27.071C129.299 26.1599 128.721 25.5879 127.817 25.4651C127.095 25.367 126.356 25.3879 125.795 25.3634C124.817 25.4395 124.002 25.2839 123.202 25.4926Z",
      fill: "none"
    }), _jsx("path", {
      d: "M101.187 21.5285C98.2824 24.3155 94.1815 23.4525 92.4293 21.5264C90.022 18.8799 89.9687 14.571 92.3663 11.8913C94.0725 9.98424 98.0051 8.87602 101.119 11.7591C101.466 11.5447 101.292 11.2015 101.294 10.9279C101.308 7.64281 101.364 4.35597 101.273 1.07332C101.236 -0.264471 102.049 0.0701375 102.768 0.021158C103.574 -0.0338093 104.128 -0.0247813 104.119 1.11676C104.064 7.87981 104.104 14.6436 104.07 21.4069C104.067 21.9936 104.596 22.9685 103.252 23.0033C101.391 23.0514 101.392 23.0931 101.187 21.5285ZM97.4232 12.6801C95.174 12.6528 93.3694 14.4477 93.3762 16.7055C93.3835 18.921 95.1236 20.6743 97.3335 20.6918C99.5789 20.7097 101.386 18.9191 101.393 16.6706C101.399 14.4831 99.6322 12.707 97.4232 12.6801Z",
      fill: "none"
    }), _jsx("path", {
      d: "M55.409 21.5293C52.4728 24.328 48.3482 23.463 46.5689 21.4411C44.2057 18.7558 44.2272 14.4211 46.6656 11.8047C48.3792 9.96607 52.2842 8.90847 55.3392 11.765C55.6907 11.5538 55.5128 11.2099 55.5138 10.9388C55.5283 7.653 55.5787 4.36572 55.4939 1.08205C55.4633 -0.108774 56.1043 0.00374983 56.8631 0.0197644C57.6238 0.035808 58.3526 -0.181487 58.3409 1.10775C58.2798 7.87215 58.3249 14.6375 58.29 21.4023C58.2871 21.9886 58.818 22.965 57.475 23.0023C55.6103 23.0542 55.6117 23.0948 55.409 21.5293ZM55.6098 16.6681C55.602 14.4628 53.8478 12.702 51.636 12.6796C49.4294 12.6572 47.6312 14.4006 47.5941 16.5988C47.5557 18.8737 49.3063 20.6776 51.5656 20.6913C53.8391 20.705 55.618 18.9355 55.6098 16.6681Z",
      fill: "none"
    }), _jsx("path", {
      d: "M63.9804 21.6455C63.8044 22.8855 62.7295 23.4365 61.4495 22.9739C61.0403 22.8259 61.0922 22.5207 61.0922 22.2231C61.0893 15.0759 61.0961 7.92878 61.0791 0.781648C61.0777 0.14301 61.339 -0.0321946 61.9121 0.0206878C62.219 0.0490128 62.5351 0.0587922 62.8396 0.0192527C63.6561 -0.0868272 63.9043 0.243505 63.8956 1.05874C63.8563 4.60752 63.8786 8.15699 63.8786 11.9245C65.2537 10.5661 66.761 9.94856 68.5307 10.008C70.3324 10.0685 71.818 10.7531 72.9768 12.1427C75.1703 14.7729 75.1029 18.8634 72.8173 21.3994C70.62 23.8376 67.5145 23.9241 63.9804 21.6455ZM71.7991 16.685C71.7981 14.4959 70.027 12.7135 67.8209 12.6807C65.5931 12.6476 63.7147 14.4862 63.7196 16.6947C63.7244 18.867 65.5276 20.6714 67.7128 20.691C69.9674 20.7112 71.8006 18.914 71.7991 16.685Z",
      fill: "none"
    }), _jsx("path", {
      d: "M150.69 17.4359C149.336 17.4361 147.982 17.4512 146.628 17.4298C145.948 17.419 145.84 17.662 146.073 18.2729C146.653 19.7945 148.36 20.8261 150.508 20.8493C151.599 20.8611 152.687 20.7047 153.659 20.2046C154.622 19.7098 154.691 20.0842 154.73 20.9548C154.774 21.9542 154.338 22.4334 153.478 22.75C151.915 23.3256 150.309 23.4349 148.68 23.2242C144.716 22.7115 142.31 19.0808 143.171 14.9484C143.907 11.4157 147.569 9.26415 151.197 10.2323C153.886 10.9496 155.923 14.0896 155.545 16.8668C155.47 17.4225 155.166 17.4406 154.752 17.4384C153.398 17.4314 152.044 17.4358 150.69 17.4359ZM151.895 15.3866C152.517 15.4081 152.713 15.2481 152.513 14.595C152.115 13.2866 150.873 12.3706 149.36 12.3638C147.806 12.3568 146.305 13.3881 145.964 14.6647C145.808 15.2481 146.019 15.4097 146.582 15.3872C147.428 15.3535 148.276 15.3784 149.123 15.3784C150.047 15.3784 150.972 15.3548 151.895 15.3866Z",
      fill: "none"
    }), _jsx("path", {
      d: "M164.679 17.4361C163.365 17.4362 162.049 17.4765 160.737 17.4227C159.782 17.3837 159.803 17.7781 160.117 18.4593C160.845 20.0412 162.827 21.0243 165.018 20.8383C166.023 20.7531 167.008 20.5527 167.916 20.0948C168.46 19.8204 168.621 19.9349 168.693 20.5574C168.836 21.7962 168.391 22.4853 167.17 22.8528C165.654 23.3091 164.133 23.4283 162.59 23.2159C158.687 22.6783 156.3 19.0721 157.123 15.0103C157.848 11.4272 161.575 9.22914 165.209 10.2411C167.852 10.9772 169.969 14.3248 169.481 16.9359C169.371 17.5185 168.995 17.4323 168.622 17.4339C167.307 17.4394 165.994 17.436 164.679 17.4361ZM165.875 15.389C166.635 15.4272 166.703 15.1249 166.487 14.4979C166.033 13.1858 164.754 12.3206 163.251 12.3642C161.716 12.4086 160.361 13.3815 159.962 14.6638C159.772 15.2758 160.006 15.4005 160.555 15.3852C161.441 15.3605 162.329 15.3786 163.215 15.3786C164.102 15.3786 164.991 15.3445 165.875 15.389Z",
      fill: "none"
    }), _jsx("path", {
      d: "M82.6278 23.2783C78.91 23.3031 75.9728 20.4316 75.9349 16.7348C75.8966 13.0422 78.798 10.056 82.4683 10.0103C86.2002 9.9639 89.2271 12.9261 89.2353 16.6328C89.2436 20.3207 86.3272 23.2537 82.6278 23.2783ZM82.5856 12.7223C80.3829 12.718 78.5774 14.5053 78.5919 16.6756C78.606 18.7927 80.366 20.571 82.5022 20.6263C84.6685 20.6824 86.4969 18.8954 86.5202 16.7C86.5434 14.4836 84.8057 12.7267 82.5856 12.7223Z",
      fill: "none"
    })]
  })]
}), 'MindbodygreenMonoLogo', 'CompanyMono');
export default MindbodygreenMonoLogo;