/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var GapMonoLogo = createCompanyLogosComponent(_jsx("svg", {
  width: 44,
  height: 32,
  viewBox: "0 0 44 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M38.5063 0.25H32.8397V0.75H34.3397V30.1667C34.3397 30.8333 34.0897 31 33.173 31.0833H32.8397V31.5833H39.2563V31H38.5897C37.5063 31 37.0897 30.5 37.0897 29.5833V17.75H38.923C41.673 17.75 43.173 16.4167 43.173 14.6667V3.5C43.2563 1.83333 41.923 0.25 38.5063 0.25ZM40.5063 15.3333C40.5326 15.5805 40.5032 15.8305 40.4204 16.0648C40.3375 16.2992 40.2033 16.5121 40.0275 16.6878C39.8517 16.8636 39.6389 16.9978 39.4045 17.0807C39.1701 17.1635 38.9202 17.1929 38.673 17.1667H37.0897V0.75H38.5063C39.0354 0.754355 39.5416 0.966467 39.9157 1.3406C40.2899 1.71473 40.502 2.22091 40.5063 2.75V15.3333ZM26.3397 29.5833L22.673 0.25H20.2563L16.7563 29.3333C16.5897 30.4167 16.423 31 14.7563 31.0833V31.5833H19.0063V31C17.7563 31 17.2563 30.3333 17.3397 29.5833L18.7563 17.75H22.0897L23.5897 29.9167C23.5897 30.6667 23.5063 30.9167 22.5897 31H22.173V31.5H27.923V31H27.7563C27.57 31.0011 27.3853 30.9652 27.2129 30.8944C27.0405 30.8236 26.8839 30.7193 26.7521 30.5875C26.6204 30.4558 26.5161 30.2992 26.4453 30.1268C26.3745 29.9544 26.3386 29.7697 26.3397 29.5833ZM18.923 17.25L20.423 4.66667L22.0897 17.25H18.923ZM8.42301 0.666667C8.42301 1.16667 8.08968 1.16667 7.83968 1C6.92 0.396568 5.85513 0.0512055 4.75635 0C3.08968 0 0.756348 0.75 0.756348 3.91667V27.1667C0.756348 31.25 2.92301 32 5.00635 32C6.67301 32 7.42301 31.25 8.08968 31C8.42301 30.8333 8.83968 31.1667 8.83968 31.75H9.50635V17.8333H10.8397V17.25H5.00635V17.8333H6.67301V29.6667C6.67966 29.9022 6.63955 30.1368 6.55499 30.3568C6.47043 30.5768 6.3431 30.7778 6.18037 30.9483C6.01764 31.1188 5.82274 31.2553 5.60692 31.35C5.3911 31.4447 5.15865 31.4957 4.92301 31.5C4.46025 31.4741 4.0262 31.2674 3.71442 30.9245C3.40265 30.5815 3.23813 30.1298 3.25635 29.6667V3C3.25635 2.25 3.42301 0.583333 4.67301 0.583333C5.67301 0.583333 6.42301 1.5 6.75635 2.91667C7.11587 5.09334 7.31087 7.29402 7.33968 9.5C7.33968 10 7.50635 10.1667 8.08968 10.1667H9.17301V0.25H8.42301V0.666667Z",
    fill: "none"
  })
}), 'GapMonoLogo', 'CompanyMono');
export default GapMonoLogo;