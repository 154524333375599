/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CircleCiMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 124,
  height: 33,
  viewBox: "0 0 124 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M5.87707 13.3755C7.24307 7.98455 12.0579 4.00073 17.7881 4.00073C24.5804 4.00073 30.0863 9.5972 30.0863 16.5004C30.0863 23.4035 24.5804 29 17.7881 29C12.0579 29 7.24307 25.0162 5.87707 19.6253C5.86492 19.5779 5.85669 19.5281 5.85669 19.4767C5.85669 19.148 6.11891 18.8815 6.44268 18.8815H11.4014C11.5134 18.8816 11.6229 18.9145 11.717 18.9761C11.8111 19.0378 11.8858 19.1257 11.9321 19.2293C11.9321 19.2293 11.9396 19.2432 11.9423 19.2504C12.965 21.4921 15.1972 23.0478 17.7881 23.0478C21.3459 23.0478 24.2296 20.1169 24.2296 16.5004C24.2296 12.8839 21.3451 9.95296 17.7873 9.95296C15.1964 9.95296 12.9638 11.5086 11.9415 13.7507C11.9392 13.7575 11.9317 13.7715 11.9317 13.7715C11.8392 13.9766 11.6374 14.1192 11.401 14.1192H6.44228C6.11891 14.1192 5.85669 13.8527 5.85669 13.5241C5.85669 13.4727 5.86531 13.4229 5.87707 13.3755Z",
    fill: "none"
  }), _jsx("path", {
    d: "M48.2727 19.4305H49.9456C49.9929 19.4304 50.0398 19.4398 50.0836 19.4582C50.1274 19.4766 50.1671 19.5035 50.2007 19.5375C50.2342 19.5715 50.2608 19.6119 50.279 19.6564C50.2971 19.7008 50.3065 19.7484 50.3066 19.7966C50.3062 19.8539 50.2925 19.9102 50.2666 19.9611C49.0205 22.4705 46.4626 24.1923 43.5079 24.1923C39.3284 24.1923 35.9395 20.7479 35.9395 16.5C35.9395 12.2516 39.3284 8.80761 43.5079 8.80761C46.4626 8.80761 49.0205 10.5294 50.267 13.0388C50.2928 13.0897 50.3063 13.1461 50.3066 13.2034C50.3065 13.2515 50.2971 13.2991 50.279 13.3436C50.2608 13.388 50.2342 13.4284 50.2007 13.4624C50.1671 13.4964 50.1274 13.5234 50.0836 13.5417C50.0398 13.5601 49.9929 13.5695 49.9456 13.5695H48.2727C48.2174 13.5694 48.1629 13.5564 48.1134 13.5315C48.0639 13.5065 48.0208 13.4703 47.9873 13.4257C47.0152 11.9664 45.3725 11.0055 43.5076 11.0055C40.522 11.0055 38.102 13.4655 38.102 16.5C38.102 19.534 40.522 21.9941 43.5076 21.9941C45.3721 21.9941 47.0152 21.034 47.9869 19.5743C48.0205 19.5296 48.0637 19.4934 48.1133 19.4685C48.1628 19.4435 48.2174 19.4305 48.2727 19.4305Z",
    fill: "none"
  }), _jsx("path", {
    d: "M54.569 7.28065C54.8394 7.00582 54.9913 6.63308 54.9913 6.24442C54.9913 5.85576 54.8394 5.48302 54.569 5.20819C54.2986 4.93337 53.9319 4.77897 53.5495 4.77897C53.1671 4.77897 52.8003 4.93337 52.5299 5.20819C52.2595 5.48302 52.1076 5.85576 52.1076 6.24442C52.1076 6.63308 52.2595 7.00582 52.5299 7.28065C52.8003 7.55547 53.1671 7.70987 53.5495 7.70987C53.9319 7.70987 54.2986 7.55547 54.569 7.28065Z",
    fill: "none"
  }), _jsx("path", {
    d: "M54.6311 9.17412V23.4597C54.6311 23.5078 54.6218 23.5553 54.6037 23.5997C54.5856 23.6441 54.559 23.6845 54.5256 23.7184C54.4921 23.7524 54.4524 23.7793 54.4087 23.7977C54.365 23.816 54.3182 23.8255 54.2709 23.8254H52.8288C52.7333 23.8253 52.6418 23.7867 52.5743 23.7181C52.5067 23.6494 52.4687 23.5564 52.4686 23.4593V9.17412H54.6311Z",
    fill: "none"
  }), _jsx("path", {
    d: "M58.9552 11.5983C60.0837 9.98084 61.8256 8.92155 64.0065 8.81717C64.101 8.81874 64.1911 8.85793 64.2574 8.92633C64.3237 8.99472 64.361 9.08684 64.3612 9.18289V10.6513C64.3613 10.6995 64.352 10.7473 64.3338 10.7919C64.3157 10.8364 64.2891 10.8769 64.2556 10.911C64.2221 10.9451 64.1823 10.9722 64.1385 10.9907C64.0946 11.0091 64.0477 11.0186 64.0002 11.0186C61.184 11.2075 58.9552 13.589 58.9552 16.5004V23.4593C58.9553 23.5074 58.946 23.555 58.9279 23.5995C58.9098 23.6439 58.8833 23.6843 58.8499 23.7183C58.8164 23.7523 58.7767 23.7793 58.7329 23.7977C58.6892 23.816 58.6423 23.8255 58.595 23.8254H57.1534C56.9539 23.8254 56.7932 23.6617 56.7932 23.4593V9.54023C56.7933 9.44317 56.8313 9.35011 56.8988 9.28147C56.9663 9.21283 57.0579 9.17423 57.1534 9.17412H58.595C58.6905 9.17423 58.7821 9.21283 58.8496 9.28147C58.9172 9.35011 58.9551 9.44317 58.9552 9.54023V11.5983Z",
    fill: "none"
  }), _jsx("path", {
    d: "M76.694 19.4305H78.3669C78.4142 19.4304 78.4611 19.4398 78.5049 19.4582C78.5487 19.4766 78.5884 19.5035 78.622 19.5375C78.6555 19.5715 78.6821 19.6119 78.7003 19.6564C78.7184 19.7008 78.7278 19.7484 78.7279 19.7966C78.7275 19.8539 78.7138 19.9102 78.6879 19.9611C77.4418 22.4705 74.8839 24.1923 71.9296 24.1923C67.7497 24.1923 64.3612 20.7479 64.3612 16.5C64.3612 12.2516 67.7497 8.80761 71.9296 8.80761C74.8839 8.80761 77.4418 10.5294 78.6879 13.0388C78.714 13.0896 78.7277 13.146 78.7279 13.2034C78.7278 13.2515 78.7184 13.2991 78.7003 13.3436C78.6821 13.388 78.6555 13.4284 78.622 13.4624C78.5884 13.4964 78.5487 13.5234 78.5049 13.5417C78.4611 13.5601 78.4142 13.5695 78.3669 13.5695H76.694C76.6387 13.5695 76.5841 13.5565 76.5345 13.5315C76.485 13.5066 76.4417 13.4704 76.4082 13.4257C75.4365 11.9664 73.7934 11.0055 71.9292 11.0055C68.9433 11.0055 66.5233 13.4655 66.5233 16.5C66.5233 19.534 68.9433 21.9941 71.9292 21.9941C73.7934 21.9941 75.4365 21.034 76.4082 19.5743C76.4417 19.5296 76.485 19.4933 76.5345 19.4684C76.5841 19.4434 76.6386 19.4304 76.694 19.4305Z",
    fill: "none"
  }), _jsx("path", {
    d: "M81.2498 4.41266H82.6914C82.787 4.41266 82.8786 4.45122 82.9463 4.51987C83.0139 4.58852 83.0519 4.68164 83.052 4.77877V23.4597C83.0519 23.5568 83.0139 23.65 82.9463 23.7186C82.8786 23.7873 82.787 23.8258 82.6914 23.8258H81.2498C81.0506 23.8258 80.8895 23.6621 80.8895 23.4597V4.77877C80.8896 4.68171 80.9276 4.58864 80.9952 4.52001C81.0627 4.45137 81.1543 4.41277 81.2498 4.41266Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M85.2145 16.5004C85.2145 12.252 88.603 8.80801 92.7829 8.80801C96.9624 8.80801 100.351 12.252 100.351 16.5008C100.351 16.6294 100.347 16.7581 100.34 16.8856C100.336 16.9793 100.296 17.0676 100.229 17.1323C100.162 17.197 100.073 17.2332 99.9809 17.2334H87.4259C87.7787 19.9209 90.0419 21.9949 92.7829 21.9949C94.6443 21.9949 96.2851 21.0371 97.2572 19.5819C97.2903 19.5351 97.334 19.497 97.3845 19.4708C97.435 19.4445 97.4909 19.4308 97.5476 19.4309H99.2201C99.2818 19.4303 99.3425 19.446 99.3965 19.4763C99.4504 19.5067 99.4958 19.5507 99.528 19.6041C99.5603 19.6575 99.5785 19.7184 99.5808 19.781C99.5831 19.8437 99.5694 19.9058 99.5411 19.9615C98.2947 22.4709 95.7375 24.1927 92.7829 24.1927C88.603 24.1927 85.2145 20.7483 85.2145 16.5004ZM92.7829 11.0059C90.2967 11.0059 88.204 12.7125 87.5729 15.0351H97.9929C97.3614 12.7125 95.2691 11.0059 92.7829 11.0059Z",
    fill: "none"
  }), _jsx("path", {
    d: "M119.754 4.69029C120.16 4.27811 120.71 4.04655 121.283 4.04655C121.857 4.04655 122.407 4.27811 122.812 4.69029C123.218 5.10247 123.446 5.66151 123.446 6.24442C123.446 6.82733 123.218 7.38637 122.812 7.79855C122.407 8.21073 121.857 8.44229 121.283 8.44229C120.71 8.44229 120.16 8.21073 119.754 7.79855C119.349 7.38637 119.121 6.82733 119.121 6.24442C119.121 5.66151 119.349 5.10247 119.754 4.69029Z",
    fill: "none"
  }), _jsx("path", {
    d: "M123.086 23.4593V9.17412H119.482V23.4593C119.482 23.5074 119.491 23.555 119.509 23.5995C119.527 23.6439 119.554 23.6843 119.587 23.7183C119.621 23.7523 119.66 23.7793 119.704 23.7977C119.748 23.816 119.795 23.8255 119.842 23.8254H122.725C122.821 23.8253 122.912 23.7867 122.98 23.7181C123.048 23.6494 123.086 23.5564 123.086 23.4593Z",
    fill: "none"
  }), _jsx("path", {
    d: "M117.413 18.4066C116.578 21.7323 113.614 24.1927 110.081 24.1927C105.902 24.1927 102.513 20.7487 102.513 16.5004C102.513 12.2524 105.902 8.80801 110.081 8.80801C113.614 8.80801 116.579 11.2688 117.413 14.5953C117.418 14.6194 117.421 14.6439 117.422 14.6686C117.422 14.7657 117.384 14.8589 117.316 14.9276C117.249 14.9964 117.157 15.035 117.062 15.0351H114.011C113.942 15.0349 113.875 15.0146 113.817 14.9766C113.759 14.9386 113.713 14.8845 113.684 14.8208L113.684 14.8216C113.367 14.1203 112.86 13.5261 112.221 13.1095C111.583 12.6929 110.84 12.4715 110.081 12.4715C107.892 12.4715 106.117 14.275 106.117 16.5004C106.117 18.7257 107.892 20.5292 110.081 20.5292C111.681 20.5292 113.057 19.5655 113.684 18.1791C113.712 18.1154 113.759 18.0618 113.817 18.0239C113.875 17.986 113.942 17.9658 114.011 17.9656H117.062C117.157 17.9657 117.249 18.0044 117.316 18.0731C117.384 18.1418 117.422 18.235 117.422 18.3321C117.422 18.3572 117.419 18.3822 117.413 18.4066Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.85673 16.5004C5.85673 18.1441 4.54565 19.4767 2.92836 19.4767C1.31107 19.4767 0 18.1441 0 16.5004C0 14.8566 1.31107 13.524 2.92836 13.524C4.54565 13.524 5.85673 14.8566 5.85673 16.5004Z",
    fill: "none"
  })]
}), 'CircleCiMonoLogo', 'CompanyMono');
export default CircleCiMonoLogo;