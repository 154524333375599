/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ClassPassMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 214,
  height: 32,
  viewBox: "0 0 214 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M25.4026 1.40961C25.0292 1.39152 23.3532 1.38165 22.1541 1.37671C21.7649 1.37575 21.3874 1.50959 21.0858 1.75547C20.7841 2.00135 20.5769 2.3441 20.4994 2.72547L19.8612 5.77003C19.8071 6.0358 19.6631 6.2748 19.4534 6.44677C19.2436 6.61875 18.9811 6.71318 18.7098 6.71416L12.7408 6.74377C6.09735 6.74377 0 11.721 0 18.6967C0 24.9651 5.22889 30.6035 12.7424 30.6035C13.1306 30.6233 15.3116 30.6183 16.7657 30.6233C17.1544 30.6238 17.5314 30.4903 17.8332 30.2452C18.1349 30.0001 18.3429 29.6584 18.422 29.2778L19.0553 26.2201C19.1093 25.9542 19.2533 25.715 19.463 25.5427C19.6727 25.3705 19.9353 25.2757 20.2066 25.2743L25.401 25.2891C32.7369 25.2891 38.1418 19.649 38.1418 13.3214C38.1418 6.41316 32.1727 1.41619 25.3977 1.41619L25.4026 1.40961ZM25.3566 20.7067L20.0635 20.7165C19.6791 20.7176 19.3068 20.851 19.0091 21.0942C18.7113 21.3374 18.5064 21.6756 18.4286 22.0521L17.7953 25.1427C17.741 25.4077 17.5966 25.6457 17.3868 25.8163C17.1769 25.9869 16.9144 26.0796 16.6439 26.0786C15.3034 26.0786 14.5649 26.0342 12.7934 26.0342C8.78006 26.0342 4.53806 23.3581 4.53806 18.7394C4.53806 14.4629 8.32115 11.3279 12.7803 11.3279L18.8217 11.3164C19.2059 11.3152 19.578 11.1818 19.8755 10.9385C20.1729 10.6953 20.3775 10.3571 20.455 9.98078L21.0998 6.90167C21.1541 6.63687 21.2986 6.39908 21.5085 6.22875C21.7184 6.05842 21.9808 5.96607 22.2511 5.96741C23.3746 5.96741 25.1197 5.97892 25.3582 5.99866C29.5755 5.99866 33.6004 8.88203 33.6004 13.4004C33.6004 17.5338 29.9966 20.7116 25.3599 20.7116",
    fill: "none"
  }), _jsx("path", {
    d: "M146.695 7.79651C144.626 7.79651 142.734 8.82617 141.779 9.82622L141.394 8.30969H138.504V31.9951H141.723V23.7184L141.826 23.6707C142.78 24.6724 144.623 25.503 146.445 25.503C150.771 25.503 154.779 22.692 154.779 16.5239C154.779 10.8954 150.911 7.79651 146.695 7.79651V7.79651ZM146.279 22.3236C144.085 22.3236 141.724 20.5883 141.724 16.7772V16.6128C141.724 12.9711 144.141 11.0648 146.529 11.0648C149.758 11.0648 151.144 13.9202 151.144 16.5716C151.144 20.8235 148.562 22.3285 146.279 22.3285",
    fill: "none"
  }), _jsx("path", {
    d: "M72.5496 0H69.1514V25.0737H72.5496V0Z",
    fill: "none"
  }), _jsx("path", {
    d: "M57.8551 22.205C54.4915 22.205 52.572 19.5848 52.572 16.6538C52.572 13.2835 54.7596 11.0202 57.8551 11.0202C59.4227 11.0202 60.4984 11.6535 61.5231 12.2785L63.112 9.39187C62.0001 8.66321 60.0247 7.79968 58.069 7.79968C52.7776 7.79968 49.0225 11.5236 49.0225 16.6538C49.0225 21.7017 52.8648 25.5078 58.069 25.5078C60.2878 25.5078 61.88 24.5752 63.112 23.8729L61.5165 21.0306C60.4951 21.6491 59.0822 22.2017 57.8486 22.2017",
    fill: "none"
  }), _jsx("path", {
    d: "M92.1362 8.30969L91.7514 9.82622C90.7941 8.82617 88.9042 7.79651 86.835 7.79651C82.6193 7.79651 78.749 11.119 78.749 16.5239C78.749 22.1509 82.6193 25.503 86.835 25.503C88.9042 25.503 90.7957 24.4733 91.7514 23.4733L92.1362 24.9898H95.0262V8.30969H92.1362ZM91.8073 16.7772C91.8073 20.5883 89.447 22.3236 87.2528 22.3236C84.9697 22.3236 82.3874 20.8169 82.3874 16.5667C82.3874 13.9152 83.774 11.0598 87.0028 11.0598C89.391 11.0598 91.8073 12.9662 91.8073 16.6078V16.7772Z",
    fill: "none"
  }), _jsx("path", {
    d: "M107.125 18.1374C109.482 18.7525 110.469 19.2065 110.469 20.4138C110.469 21.3399 109.635 22.2725 107.775 22.2725C106.035 22.2725 104.625 21.6441 103.319 20.9138L101.76 23.7528C102.965 24.5078 105.543 25.4947 107.775 25.4947C111.51 25.4947 113.714 22.9386 113.714 20.4155C113.714 16.032 109.411 15.3264 108.263 14.9612C105.834 14.3263 105.258 13.8411 105.258 12.823C105.258 11.8048 106.186 11.0202 107.9 11.0202C109.443 11.0202 110.569 11.494 111.634 12.0219L113.221 9.13363C111.667 8.44939 109.895 7.79968 107.9 7.79968C104.235 7.79968 102.008 10.387 102.008 12.8197C102.008 16.6488 105.283 17.6406 107.12 18.1407",
    fill: "none"
  }), _jsx("path", {
    d: "M125.309 18.1374C127.665 18.7525 128.652 19.2065 128.652 20.4138C128.652 21.3399 127.819 22.2725 125.959 22.2725C124.217 22.2725 122.808 21.6441 121.503 20.9138L119.942 23.7528C121.155 24.5045 123.725 25.493 125.959 25.493C129.695 25.493 131.899 22.937 131.899 20.4138C131.899 16.0304 127.596 15.3247 126.448 14.9596C124.017 14.3247 123.443 13.8395 123.443 12.8213C123.443 11.8032 124.37 11.0202 126.089 11.0202C127.632 11.0202 128.757 11.494 129.821 12.0219L131.41 9.13363C129.854 8.44939 128.083 7.79968 126.089 7.79968C122.423 7.79968 120.196 10.387 120.196 12.8197C120.196 16.6488 123.472 17.6406 125.309 18.1407",
    fill: "none"
  }), _jsx("path", {
    d: "M173.741 8.30969L173.354 9.82622C172.4 8.82617 170.509 7.79651 168.439 7.79651C164.224 7.79651 160.354 11.119 160.354 16.5239C160.354 22.1509 164.224 25.503 168.439 25.503C170.509 25.503 172.4 24.4733 173.354 23.4733L173.741 24.9898H176.631V8.30969H173.741ZM173.412 16.7772C173.412 20.5883 171.051 22.3236 168.857 22.3236C166.574 22.3236 163.992 20.8169 163.992 16.5667C163.992 13.9152 165.378 11.0598 168.607 11.0598C170.995 11.0598 173.412 12.9662 173.412 16.6078V16.7772Z",
    fill: "none"
  }), _jsx("path", {
    d: "M188.73 18.1374C191.087 18.7525 192.074 19.2065 192.074 20.4138C192.074 21.3399 191.24 22.2725 189.38 22.2725C187.638 22.2725 186.23 21.6441 184.924 20.9138L183.363 23.7528C184.576 24.5045 187.146 25.493 189.38 25.493C193.115 25.493 195.319 22.937 195.319 20.4138C195.319 16.0304 191.017 15.3247 189.869 14.9596C187.439 14.3247 186.863 13.8395 186.863 12.8213C186.863 11.8032 187.791 11.0202 189.51 11.0202C191.053 11.0202 192.18 11.494 193.244 12.0219L194.831 9.13363C193.277 8.44939 191.503 7.79968 189.51 7.79968C185.844 7.79968 183.617 10.387 183.617 12.8197C183.617 16.6488 186.893 17.6406 188.73 18.1407",
    fill: "none"
  }), _jsx("path", {
    d: "M206.919 18.1374C209.274 18.7525 210.261 19.2065 210.261 20.4138C210.261 21.3399 209.429 22.2725 207.569 22.2725C205.827 22.2725 204.417 21.6441 203.113 20.9138L201.552 23.7528C202.762 24.5045 205.335 25.493 207.569 25.493C211.304 25.493 213.508 22.937 213.508 20.4138C213.508 16.0304 209.205 15.3247 208.057 14.9596C205.626 14.3247 205.05 13.8395 205.05 12.8213C205.05 11.8032 205.975 11.0202 207.694 11.0202C209.236 11.0202 210.361 11.494 211.426 12.0219L213.015 9.13363C211.459 8.44939 209.687 7.79968 207.694 7.79968C204.027 7.79968 201.8 10.387 201.8 12.8197C201.8 16.6488 205.077 17.6406 206.914 18.1407",
    fill: "none"
  })]
}), 'ClassPassMonoLogo', 'CompanyMono');
export default ClassPassMonoLogo;