/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var McKinseyCompanyMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 104,
  height: 32,
  viewBox: "0 0 104 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M1.62429 1.49971C1.62429 1.2496 0.932795 0.591207 0.701071 0.591207H0.108887V-0.000976562H3.60314L7.19302 9.04362H7.27762L11.0661 -0.000976562H14.1962V0.591207H13.6041C13.3723 0.591207 12.6808 1.2496 12.6808 1.49971V10.4928C12.6808 10.7429 13.3723 11.4013 13.6041 11.4013H14.1962V11.9935H9.55073V11.4013H10.1429C10.3746 11.4013 11.0661 10.7429 11.0661 10.4928V1.76086L6.70015 12.2068L2.33417 1.58063V10.4928C2.33417 10.7429 3.02567 11.4013 3.25739 11.4013H3.84958V11.9935H0.108887V11.4013H0.701071C0.932795 11.4013 1.62429 10.7429 1.62429 10.4928V1.49971Z",
    fill: "none"
  }), _jsx("path", {
    d: "M19.235 6.86964C19.452 5.66688 19.235 4.57815 17.9182 4.57815C16.3697 4.57815 15.612 5.81401 15.612 7.64205C15.612 9.37447 16.7155 10.809 18.4957 10.809C19.8456 10.809 20.4231 10.3308 20.9674 9.42228H21.4419C20.9307 11.1216 19.8934 12.1919 17.9477 12.1919C15.7886 12.1919 14.3872 10.3529 14.3872 8.14596C14.3872 5.64113 16.0534 3.99332 18.0948 3.99332C18.6055 3.9705 19.1153 4.05716 19.5898 4.24747C20.0643 4.43779 20.4926 4.72736 20.8461 5.09677L19.7095 6.85861L19.235 6.86964Z",
    fill: "none"
  }), _jsx("path", {
    d: "M34.9298 2.37142C35.0634 2.37771 35.1968 2.35605 35.3214 2.30781C35.4461 2.25958 35.5593 2.18583 35.6539 2.0913C35.7484 1.99678 35.8221 1.88355 35.8704 1.75887C35.9186 1.63419 35.9403 1.50081 35.934 1.36728C35.9415 1.23361 35.9206 1.09987 35.8728 0.974846C35.8249 0.849818 35.7511 0.736331 35.6563 0.641841C35.5615 0.547351 35.4477 0.473996 35.3225 0.426597C35.1973 0.379197 35.0635 0.358825 34.9298 0.366819C34.7961 0.358016 34.662 0.377318 34.5362 0.423481C34.4103 0.469643 34.2956 0.541644 34.1992 0.634848C34.1029 0.728052 34.0272 0.840393 33.9769 0.964639C33.9266 1.08889 33.9029 1.22228 33.9073 1.35624C33.9009 1.49188 33.9231 1.62734 33.9723 1.75388C34.0216 1.88043 34.0968 1.99524 34.1931 2.09091C34.2895 2.18657 34.4049 2.26096 34.5317 2.30928C34.6586 2.3576 34.7943 2.37877 34.9298 2.37142Z",
    fill: "none"
  }), _jsx("path", {
    d: "M45.9682 11.4013C45.7365 11.4013 45.1112 10.8386 45.1112 10.5774V6.2519C45.1253 5.96153 45.0797 5.67134 44.9771 5.39934C44.8744 5.12734 44.717 4.87933 44.5146 4.67068C44.3122 4.46204 44.069 4.2972 43.8002 4.18641C43.5315 4.07562 43.2428 4.02123 42.9521 4.02661C41.5507 4.02661 40.8261 4.8358 39.8882 5.78845L39.5903 4.19213H37.1369V4.78063H37.6261C37.8579 4.78063 38.4831 5.3581 38.4831 5.60454V10.5774C38.4831 10.8386 37.8579 11.4013 37.6261 11.4013H36.6698C36.4454 11.4013 35.7871 10.8386 35.7871 10.5774V4.18477H33.0174V4.78063H33.4956C33.7273 4.78063 34.3526 5.3581 34.3526 5.60454V10.5774C34.3526 10.8386 33.7273 11.4013 33.4956 11.4013H32.6238C32.3737 11.4013 31.4027 10.6657 31.2041 10.3972L27.0036 4.81006L29.9204 1.67994C30.0859 1.49971 31.2225 0.576495 31.4542 0.576495H32.028V-0.000976562H27.6951V0.591207H28.2726C28.5006 0.591207 29.1774 1.41512 29.0082 1.62845L24.7231 6.41006V1.49971C24.7231 1.2496 25.4146 0.591207 25.6464 0.591207H26.2238V-0.000976562H21.5342V0.591207H22.1264C22.3581 0.591207 23.0496 1.2496 23.0496 1.49971V10.4928C23.0496 10.7429 22.3581 11.4013 22.1264 11.4013H21.5342V11.9935H26.2238V11.4013H25.6206C25.3889 11.4013 24.6974 10.7429 24.6974 10.4928V7.31489L25.9149 5.9981L30.0675 11.9935H41.205V11.4013H40.7599C40.5319 11.4013 39.9029 10.8386 39.9029 10.5774V6.61971C39.9029 6.09374 41.3374 5.005 42.4408 5.005C43.2831 5.005 43.6767 5.58247 43.6767 6.34017V10.5774C43.6767 10.8386 43.0514 11.4013 42.8197 11.4013H42.3746V11.9935H46.3618V11.4013H45.9682Z",
    fill: "none"
  }), _jsx("path", {
    d: "M47.4135 9.2569C47.4135 9.99254 48.4691 11.5484 50.15 11.5484C51.1211 11.5484 51.6654 11.2027 51.6654 10.4781C51.6654 9.60633 50.8893 9.3084 50.1942 9.00679C49.6498 8.77874 49.2084 8.59484 48.5795 8.3153C47.6415 7.88863 46.9316 7.25966 46.9316 6.18932C46.9316 4.79162 47.7739 3.98242 49.271 3.98242C50.128 3.98242 50.6061 4.39438 51.202 4.39438C51.43 4.39438 51.5698 4.34656 51.6287 4.18104H52.0553V6.58656H51.6029C51.6029 5.94288 50.6944 4.57461 49.2452 4.57461C48.3404 4.57461 47.91 4.97185 47.91 5.61553C47.91 6.2592 48.3735 6.53507 49.179 6.88081C49.7712 7.13093 50.2162 7.32587 50.8599 7.57599C51.6985 7.90334 52.6549 8.58012 52.6549 9.8307C52.6549 11.302 51.636 12.1884 50.1684 12.1884C49.179 12.1884 48.6015 11.725 47.9284 11.725C47.8152 11.7183 47.702 11.7388 47.5983 11.7849C47.4946 11.831 47.4035 11.9012 47.3326 11.9898H46.9647V9.2569H47.4135Z",
    fill: "none"
  }), _jsx("path", {
    d: "M53.1187 8.0912C53.1187 5.78499 54.6819 3.99005 56.6938 4.00844C58.4557 4.00844 59.4451 5.22591 59.526 6.74131L59.5591 7.3004L54.3692 7.72338C54.3692 9.29028 55.4727 10.8388 57.2198 10.8388C58.555 10.8388 59.1656 10.3275 59.6437 9.43741H60.1219C59.6106 11.1367 58.6506 12.1887 56.7269 12.1887C54.5458 12.174 53.1187 10.2981 53.1187 8.0912ZM58.2239 6.82223C58.3564 5.71879 58.1761 4.59695 56.6607 4.59695C55.046 4.59695 54.4023 5.93212 54.3545 7.14959L58.2239 6.82223Z",
    fill: "none"
  }), _jsx("path", {
    d: "M60.8941 5.66687C60.7764 5.40204 60.0849 4.77675 59.8863 4.77675H59.5405V4.18457H63.6785V4.78043H63.2003C62.9833 4.78043 62.5088 5.3248 62.5088 5.62273L64.3185 9.79009L66.0987 5.63744C66.0699 5.44815 65.9924 5.26961 65.8737 5.11935C65.7551 4.96909 65.5994 4.85229 65.4219 4.78043H64.9474V4.18457H68.0591V4.78043H67.6913C67.5074 4.78043 66.9557 5.37262 66.7828 5.63744L64.1309 11.6181L62.1704 15.9363L61.4605 15.6053L63.5387 11.3533L60.8941 5.66687Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.2815 18.1874C23.2815 19.1437 23.9399 19.9161 24.9771 20.3133C26.5256 21.9759 28.3868 23.7377 30.0199 25.1906C30.4654 24.4149 30.6798 23.5279 30.6378 22.6343C30.6378 21.5308 29.9022 20.8724 28.7435 20.8393V20.2471H32.9992V20.8393H32.5394C32.3077 20.8393 31.4838 21.7772 31.4691 22.1414C31.4409 23.3458 31.0695 24.5172 30.3987 25.5179C31.1794 26.2089 31.9959 26.8583 32.8447 27.4637H33.5803V28.0559H32.2599C31.7061 28.0733 31.1548 27.975 30.6411 27.7672C30.1275 27.5595 29.6628 27.2469 29.2769 26.8494C28.3205 27.769 27.1031 28.2802 25.3376 28.2802C23.3955 28.2802 21.5343 26.8494 21.5343 24.7382C21.5281 24.0189 21.701 23.3095 22.0375 22.6737C22.374 22.038 22.8634 21.4961 23.4617 21.0968C22.8444 20.4076 22.4992 19.5172 22.4907 18.592C22.4907 16.8816 23.5941 15.9253 25.3707 15.9253C26.4741 15.9253 27.0516 16.2931 27.5776 16.2931C27.8424 16.2931 28.0079 16.2931 28.1366 16.0798H28.6479V18.9156H28.1219C28.1219 18.1064 26.9376 16.4917 25.3376 16.4917C24.0539 16.4733 23.2815 17.0986 23.2815 18.1874ZM22.7702 23.9547C22.7702 25.6687 24.6093 27.3129 26.3123 27.3129C27.2447 27.3425 28.1603 27.0589 28.9127 26.5074C27.0722 24.9283 25.3395 23.2277 23.7265 21.4168C23.0976 22.0678 22.7702 22.7189 22.7702 23.9547Z",
    fill: "none"
  }), _jsx("path", {
    d: "M45.8726 20.3134C45.8726 19.0775 44.4896 16.5727 42.2349 16.5727C40.1568 16.5727 38.9246 17.724 38.7076 20.4274C38.6377 21.4431 38.6487 22.4627 38.7407 23.4766C38.9393 25.5695 40.3885 27.2982 42.8271 27.2982C44.9016 27.2982 45.9057 26.4265 46.6818 24.8449H47.3071C46.5347 26.9856 44.8538 28.2693 42.463 28.2693C39.0202 28.2693 36.7949 25.4371 36.7949 22.0164C36.7949 18.5957 38.8069 15.8555 42.1687 15.8555C43.4524 15.8555 44.2616 16.3814 45.1995 16.3814C45.3385 16.3953 45.4788 16.374 45.6074 16.3193C45.736 16.2647 45.8487 16.1785 45.9352 16.0688H46.3802V20.3134H45.8726Z",
    fill: "none"
  }), _jsx("path", {
    d: "M48.1162 24.15C48.1162 21.6304 49.9295 20.0488 51.9893 20.0488C54.0491 20.0488 55.8587 21.6304 55.8587 24.15C55.8587 26.6695 54.0491 28.2695 51.9893 28.2695C49.9295 28.2695 48.1162 26.6732 48.1162 24.15ZM54.2955 25.452C54.3948 24.5709 54.3948 23.6813 54.2955 22.8001C54.1447 21.5495 53.4532 20.7072 52.004 20.7072C50.5548 20.7072 49.8964 21.5495 49.7162 22.7854C49.5985 23.6654 49.5985 24.5573 49.7162 25.4373C49.8964 26.6401 50.5695 27.5302 52.004 27.5302C53.4385 27.5302 54.1447 26.6732 54.2955 25.452Z",
    fill: "none"
  }), _jsx("path", {
    d: "M68.4932 26.6397C68.4932 26.9009 69.0854 27.4636 69.3172 27.4636H69.685V28.0558H65.7935V27.4636H66.2385C66.4666 27.4636 67.0588 26.9009 67.0588 26.6397V22.3068C67.0588 21.5491 66.6909 21.0195 65.8413 21.0195C64.8188 21.0195 63.5498 22.0089 63.5498 22.5349V26.6397C63.5498 26.9009 64.1457 27.4636 64.3737 27.4636H64.8188V28.0558H60.85V27.4636H61.2951C61.5231 27.4636 62.119 26.9009 62.119 26.6397V22.3068C62.119 21.5491 61.7217 21.0195 60.8831 21.0195C59.8606 21.0195 58.5916 22.0604 58.5916 22.5864V26.6323C58.5916 26.8935 59.1838 27.4563 59.4155 27.4563H59.8606V28.0484H55.8882V27.4636H56.3332C56.565 27.4636 57.1572 26.9009 57.1572 26.6397V21.6632C57.087 21.4748 56.977 21.3037 56.8349 21.1615C56.6927 21.0193 56.5216 20.9094 56.3332 20.8392H55.8882V20.2471H58.279L58.5916 21.7441C59.4817 20.8061 60.0629 20.0815 61.4275 20.0815C62.4647 20.0815 63.3365 20.6406 63.4836 21.7294C64.4399 20.7914 65.0505 20.0999 66.3673 20.0999C66.6563 20.0893 66.9443 20.1398 67.2124 20.248C67.4806 20.3563 67.7229 20.5199 67.9236 20.7282C68.1242 20.9364 68.2786 21.1847 68.3768 21.4567C68.475 21.7288 68.5146 22.0184 68.4932 22.3068V26.6397Z",
    fill: "none"
  }), _jsx("path", {
    d: "M71.9839 20.2474L72.2487 21.8438C72.9218 20.5932 73.7788 20.0488 75.0662 20.0488C76.9604 20.0488 78.1117 21.6488 78.1117 24.036C78.1117 26.4231 76.7949 28.2217 74.9007 28.2217C73.5324 28.2217 72.8078 27.692 72.2487 26.6879V30.4139C72.2487 30.675 72.8556 31.2378 73.0726 31.2378H73.5839V31.83H69.4644V31.2378H69.994C70.2221 31.2378 70.8179 30.675 70.8179 30.4139V21.6635C70.8179 21.4171 70.2221 20.8396 69.994 20.8396H69.4827V20.2474H71.9839ZM72.2303 23.098V25.1394C72.2269 25.4369 72.2829 25.7322 72.3952 26.0077C72.5075 26.2833 72.6737 26.5336 72.8841 26.744C73.0945 26.9544 73.3448 27.1206 73.6204 27.2329C73.896 27.3452 74.1912 27.4012 74.4887 27.3978C75.8239 27.3978 76.4492 26.5555 76.6147 25.3196C76.714 24.5492 76.714 23.7692 76.6147 22.9987C76.4492 21.7629 75.7761 20.972 74.6542 20.972C73.3522 20.9647 72.6092 21.8769 72.2303 23.098Z",
    fill: "none"
  }), _jsx("path", {
    d: "M95.0275 27.4637C94.7957 27.4637 94.1704 26.9009 94.1704 26.6398V22.3069C94.182 22.018 94.1345 21.7298 94.0307 21.46C93.9269 21.1902 93.7691 20.9444 93.5669 20.7378C93.3647 20.5311 93.1225 20.368 92.855 20.2583C92.5875 20.1486 92.3004 20.0948 92.0114 20.1C90.6137 20.1 89.8854 20.9092 88.9475 21.8618L88.6532 20.2655H86.1962V20.8577H86.6744C86.9061 20.8577 87.5314 21.4352 87.5314 21.6816V26.6398C87.5314 26.9009 86.9061 27.4637 86.6744 27.4637H85.7364C85.5194 27.4637 84.8794 26.9009 84.8794 26.6398V22.3878C84.8794 20.8577 83.6436 20.0669 81.9626 20.0669C80.381 20.0669 79.4725 20.6076 78.8325 21.2697L79.8698 22.8329H80.4288C79.855 21.4977 80.2817 20.626 81.6169 20.626C83.0183 20.626 83.456 21.2549 83.456 22.3584V23.3294L81.4477 23.7414C80.3442 23.9731 78.9281 24.5138 78.9281 26.066C78.9127 26.3364 78.9522 26.6071 79.0441 26.8619C79.136 27.1166 79.2786 27.3502 79.4631 27.5484C79.6476 27.7467 79.8703 27.9056 80.1178 28.0155C80.3653 28.1255 80.6325 28.1842 80.9033 28.1883C82.0583 28.1883 82.8822 27.2834 83.5075 26.4743L83.7061 28.0411H90.2937V27.4637H89.8523C89.6206 27.4637 88.9953 26.9009 88.9953 26.6398V22.6674C88.9953 22.1414 90.4261 21.0526 91.5295 21.0526C92.3718 21.0526 92.7654 21.6301 92.7654 22.3878V26.6398C92.7654 26.9009 92.1401 27.4637 91.9084 27.4637H91.467V28.0559H95.4541V27.4637H95.0275ZM83.4449 25.7644C83.1139 26.3418 82.29 27.1326 81.5323 27.1326C81.3665 27.1383 81.2014 27.1098 81.0471 27.0489C80.8928 26.9881 80.7527 26.8962 80.6355 26.7789C80.5182 26.6616 80.4263 26.5215 80.3654 26.3672C80.3046 26.213 80.2761 26.0478 80.2817 25.8821C80.2817 25.106 80.6127 24.58 81.5838 24.3336L83.4449 23.8554V25.7644Z",
    fill: "none"
  }), _jsx("path", {
    d: "M95.9949 21.7294C95.8809 21.4645 95.1894 20.8393 94.9908 20.8393H94.6377V20.2471H98.7719V20.8393H98.3159C98.1025 20.8393 97.6244 21.3836 97.6244 21.6816L99.4193 25.8489L101.203 21.6999C101.172 21.5106 101.093 21.3323 100.974 21.1817C100.855 21.0312 100.7 20.9133 100.523 20.8393H100.045V20.2471H103.16V20.8393H102.792C102.612 20.8393 102.057 21.4314 101.887 21.6963L99.2317 27.677L97.2786 31.9988L96.5724 31.6678L98.6469 27.4158L95.9949 21.7294Z",
    fill: "none"
  })]
}), 'McKinseyCompanyMonoLogo', 'CompanyMono');
export default McKinseyCompanyMonoLogo;