/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StaplesMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 172,
  height: 32,
  viewBox: "0 0 172 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M64.4495 10.2838C61.8331 9.73831 59.1466 9.16273 59.1466 7.08527C59.1466 4.79528 61.5907 4.31531 63.6792 4.31531C65.8607 4.32686 68.0129 4.81052 69.9841 5.73217L70.1727 5.8225C70.1985 5.8351 70.2282 5.83734 70.2557 5.82875C70.2831 5.82017 70.3062 5.80142 70.3199 5.77645L72.2037 2.48934C72.2115 2.47633 72.2164 2.46189 72.2182 2.44691C72.2201 2.43193 72.2187 2.41674 72.2143 2.40228C72.21 2.38782 72.2026 2.37441 72.1928 2.36288C72.1829 2.35135 72.1707 2.34195 72.157 2.33526L71.9559 2.23608C69.1131 0.96446 66.4536 0.376465 63.5912 0.376465C58.0351 0.376465 54.4435 3.03307 54.4435 7.14902C54.4435 11.8901 58.4696 13.3655 62.8154 14.251C65.5521 14.8089 68.2027 15.3615 68.2027 17.5239C68.2027 19.86 65.5737 20.3577 63.3326 20.3577C60.964 20.3577 58.5882 19.674 56.0669 18.2678L55.9879 18.2218C55.9751 18.2142 55.9609 18.2092 55.9461 18.2072C55.9313 18.2052 55.9163 18.2061 55.9019 18.2099C55.8875 18.2137 55.8741 18.2202 55.8623 18.2293C55.8505 18.2384 55.8407 18.2496 55.8335 18.2624L53.8832 21.6789C53.8692 21.704 53.8655 21.7333 53.8729 21.761C53.8802 21.7888 53.8981 21.8126 53.9227 21.8277L54.0036 21.8755C56.8526 23.4667 60.0674 24.3119 63.3416 24.3301C69.4256 24.3301 72.9148 21.8383 72.9148 17.4921C72.9112 12.0655 68.0447 11.0347 64.4495 10.2838Z",
    fill: "none"
  }), _jsx("path", {
    d: "M87.0642 20.0035C87.0591 19.9892 87.0516 19.976 87.0414 19.9648C87.0313 19.9536 87.0187 19.9445 87.0052 19.9381C86.9918 19.9316 86.9766 19.928 86.9614 19.9273C86.9463 19.9267 86.9311 19.929 86.9168 19.9344L86.6684 20.0282C85.6932 20.4002 85.0221 20.5595 83.6159 20.5595C81.9836 20.5595 80.9223 19.69 80.9223 18.3457V10.1546H86.7459C86.761 10.1548 86.7753 10.1521 86.7888 10.1467C86.8031 10.1413 86.8149 10.1333 86.8259 10.123C86.836 10.1128 86.8444 10.1006 86.8503 10.0872C86.8562 10.0737 86.8587 10.0593 86.8587 10.0448V6.73108C86.8587 6.70196 86.8478 6.67402 86.8267 6.65343C86.8057 6.63284 86.777 6.62127 86.7476 6.62127H80.9294V2.30341C80.9294 2.27429 80.9177 2.24635 80.8969 2.22576C80.876 2.20517 80.8477 2.1936 80.8181 2.1936H76.555C76.5402 2.1936 76.5256 2.1965 76.5119 2.20213C76.4983 2.20775 76.486 2.216 76.4756 2.22639C76.4653 2.23677 76.4571 2.24909 76.4516 2.26261C76.4461 2.27614 76.4434 2.29061 76.4436 2.30518V6.6195H72.9635C72.9486 6.6195 72.9341 6.6224 72.9204 6.62802C72.9068 6.63365 72.8944 6.6419 72.8841 6.65228C72.8737 6.66266 72.8655 6.67498 72.8601 6.68851C72.8546 6.70204 72.8519 6.71651 72.8521 6.73108V10.0518C72.8519 10.0666 72.8546 10.0811 72.8602 10.0948C72.8658 10.1084 72.8741 10.1209 72.8847 10.1312C72.8953 10.1417 72.9078 10.1498 72.9216 10.1554C72.9355 10.161 72.9503 10.1637 72.9652 10.1634H76.4454V18.7956C76.4454 22.2616 78.7961 24.3301 82.7306 24.3301C85.017 24.3301 86.3526 23.8608 87.7446 23.2941L88.0082 23.1825C88.0334 23.1712 88.0545 23.151 88.0654 23.1258C88.0764 23.1007 88.0781 23.0723 88.0697 23.0462L87.0642 20.0035Z",
    fill: "none"
  }), _jsx("path", {
    d: "M38.8148 0.86377H5.95225C2.83838 0.86377 0.458984 3.35921 0.458984 6.43555V23.769C0.458984 23.8004 0.471661 23.8306 0.494225 23.8529C0.516788 23.8751 0.547391 23.8876 0.579301 23.8876H4.8281C4.86001 23.8876 4.89061 23.8751 4.91317 23.8529C4.93574 23.8306 4.94841 23.8004 4.94841 23.769V6.17697H39.8205V23.769C39.8205 23.8004 39.8332 23.8306 39.8558 23.8529C39.8783 23.8751 39.9089 23.8876 39.9408 23.8876H44.195C44.2269 23.8876 44.2575 23.8751 44.2801 23.8529C44.3027 23.8306 44.3153 23.8004 44.3153 23.769V6.43555C44.3153 3.35921 41.9413 0.86377 38.8148 0.86377Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M106.979 20.6551H106.304C105.468 20.6551 105.025 20.2053 105.025 19.3569V12.4994C105.025 8.24881 102.69 6.17666 97.8633 6.17666C95.2014 6.13387 92.5631 6.67846 90.142 7.77062C90.1167 7.78157 90.0957 7.80143 90.0847 7.82631C90.0738 7.85118 90.0721 7.87931 90.0805 7.90522L91.1458 11.1144C91.15 11.1286 91.1576 11.1418 91.1677 11.153C91.1778 11.1642 91.1904 11.1733 91.2039 11.1798C91.2182 11.1863 91.2325 11.1899 91.2477 11.1905C91.2637 11.1912 91.2788 11.1887 91.2923 11.1834C93.6401 10.2678 95.7336 9.7666 97.1955 9.7666C98.8544 9.7666 100.539 10.0694 100.539 12.3081V13.2627C100.539 13.2627 100.275 13.2821 94.9471 13.7055C89.6191 14.1287 88.8418 17.809 88.8418 19.3002C88.8418 22.2615 91.2266 24.3318 94.6422 24.3318C97.0203 24.3651 99.3285 23.5378 101.13 22.0064C101.529 22.83 102.657 24.2114 105.733 24.2114H106.989C107.004 24.2117 107.019 24.209 107.033 24.2036C107.046 24.1982 107.059 24.1901 107.069 24.1799C107.08 24.1697 107.089 24.1575 107.094 24.144C107.099 24.1306 107.103 24.1162 107.103 24.1016V20.7649C107.103 20.7494 107.099 20.734 107.094 20.7199C107.087 20.7056 107.078 20.6929 107.066 20.6826C107.054 20.6722 107.041 20.6644 107.025 20.6597C107.01 20.6549 106.994 20.6533 106.979 20.6551ZM100.537 19.0364C98.5622 20.416 97.1576 20.9845 95.7159 20.9845C94.2144 20.9845 93.2014 20.2195 93.2014 19.0806C93.2014 18.5972 93.2595 17.0776 95.3908 16.8084C97.5222 16.5391 100.534 16.1637 100.534 16.1637L100.537 19.0364Z",
    fill: "none"
  }), _jsx("path", {
    d: "M164.708 13.4239C161.835 12.9262 160.416 12.6641 160.416 11.3305C160.416 10.5388 160.796 9.49206 163.903 9.49206C165.818 9.49299 167.702 9.97063 169.38 10.8806L169.5 10.9426C169.527 10.9556 169.556 10.9584 169.584 10.9505C169.612 10.9426 169.635 10.9246 169.649 10.9001L171.316 8.04512C171.324 8.032 171.329 8.01745 171.33 8.0024C171.332 7.98733 171.33 7.97209 171.326 7.95761C171.321 7.94314 171.313 7.92976 171.304 7.9183C171.294 7.90684 171.281 7.89755 171.267 7.89104L171.127 7.82374C169.116 6.91163 167.187 6.15894 163.927 6.15894C158.94 6.15894 156.192 8.05752 156.192 11.504C156.192 15.4252 159.888 16.3603 163.35 16.927C166.413 17.4282 167.645 17.8533 167.645 19.1869C167.645 20.4019 166.459 20.9916 163.923 20.9916C161.588 20.9916 159.212 20.014 157.622 19.1922L157.521 19.1444C157.495 19.1322 157.465 19.1302 157.438 19.1388C157.411 19.1473 157.388 19.1658 157.373 19.1904L155.719 22.0737C155.713 22.0865 155.708 22.1007 155.706 22.1152C155.704 22.1298 155.705 22.1446 155.709 22.1588L155.712 22.1666C155.713 22.1694 155.714 22.1722 155.715 22.175C155.717 22.1792 155.72 22.1834 155.722 22.1874C155.724 22.1909 155.727 22.1944 155.729 22.1977C155.734 22.2037 155.74 22.2093 155.745 22.2142L155.747 22.2154C155.749 22.2169 155.752 22.2196 155.752 22.2196L155.757 22.2228C155.759 22.2239 155.761 22.225 155.763 22.226L155.862 22.2792C158.465 23.6093 161.249 24.3124 163.923 24.3124C169.143 24.3124 171.79 22.4935 171.79 18.9035C171.792 14.9913 168.289 14.0437 164.708 13.4239Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M118.899 6.177C116.872 6.177 115.158 6.83762 113.512 8.25624V6.73135C113.512 6.70223 113.5 6.6743 113.479 6.6537C113.458 6.63311 113.43 6.62154 113.401 6.62154H109.134C109.119 6.62154 109.104 6.62444 109.091 6.63007C109.077 6.6357 109.065 6.64394 109.055 6.65433C109.044 6.66472 109.036 6.67703 109.03 6.69055C109.025 6.70408 109.022 6.71855 109.023 6.73312V31.0747C109.022 31.0894 109.025 31.104 109.03 31.1177C109.036 31.1313 109.044 31.1437 109.055 31.1541C109.066 31.1645 109.078 31.1727 109.092 31.1783C109.106 31.1838 109.12 31.1865 109.135 31.1863H113.395C113.41 31.1865 113.424 31.1838 113.438 31.1784C113.452 31.173 113.465 31.1649 113.475 31.1547C113.486 31.1445 113.494 31.1323 113.499 31.1189C113.505 31.1054 113.508 31.091 113.508 31.0765V22.3097C115.076 23.5955 117.039 24.3322 118.895 24.3322C123.658 24.3322 126.976 20.6006 126.976 15.2555C126.98 10.1636 123.429 6.177 118.899 6.177ZM117.776 20.5669C116.218 20.5669 114.903 19.8425 113.506 18.2185V12.1385C114.943 10.6171 116.266 9.94056 117.776 9.94056C120.641 9.94056 122.49 12.0269 122.49 15.2537C122.49 18.431 120.596 20.5669 117.776 20.5669Z",
    fill: "none"
  }), _jsx("path", {
    d: "M133.781 0.86377H129.517C129.455 0.86377 129.404 0.913723 129.404 0.975349V23.7761C129.404 23.8377 129.455 23.8876 129.517 23.8876H133.781C133.843 23.8876 133.894 23.8377 133.894 23.7761V0.975349C133.894 0.913723 133.843 0.86377 133.781 0.86377Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M152.202 19.2156C152.187 19.1907 152.163 19.1723 152.135 19.1644C152.106 19.1565 152.077 19.1596 152.051 19.1731L151.905 19.251C151.841 19.2842 151.777 19.3173 151.714 19.3505L151.685 19.3658C150.315 20.079 149.001 20.7636 146.389 20.7636C143.402 20.7636 141.375 19.1252 140.969 16.3819H153.616C153.631 16.3822 153.646 16.3795 153.66 16.3741C153.674 16.3686 153.686 16.3606 153.696 16.3503C153.707 16.3401 153.716 16.3279 153.721 16.3145C153.727 16.3011 153.73 16.2867 153.73 16.2721V15.066C153.73 9.57568 150.406 6.177 145.055 6.177C139.927 6.177 136.345 9.96355 136.345 15.3865C136.345 20.7369 140.218 24.3322 145.983 24.3322C148.719 24.3678 151.41 23.6483 153.753 22.2548L153.829 22.2069C153.853 22.1913 153.87 22.1673 153.877 22.1396C153.883 22.1119 153.879 22.0828 153.865 22.0581L152.202 19.2156ZM145.096 9.76338C147.43 9.76338 148.867 10.9607 149.251 13.2276H140.991C141.386 10.9908 142.844 9.76338 145.096 9.76338Z",
    fill: "none"
  })]
}), 'StaplesMonoLogo', 'CompanyMono');
export default StaplesMonoLogo;