/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SmartcarMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 102,
  height: 32,
  viewBox: "0 0 102 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M15.929 0.000244141C7.14 0.000244141 0 7.15499 0 16.0002C0 24.8455 7.123 32.0002 15.929 32.0002C24.718 32.0002 31.858 24.8455 31.858 16.0002C31.858 7.17207 24.718 0.000244141 15.929 0.000244141ZM15.929 3.99598C21.114 3.99598 25.483 7.34283 27.149 11.9874H4.709C6.358 7.3599 10.744 3.99598 15.929 3.99598ZM15.929 17.9981C14.824 17.9981 13.94 17.1102 13.94 16.0002C13.94 15.4709 14.144 14.9586 14.518 14.583C15.3 13.7975 16.558 13.7975 17.34 14.583C18.122 15.3684 18.122 16.632 17.34 17.4175C17.153 17.6054 16.932 17.742 16.694 17.8444C16.439 17.9469 16.184 17.9981 15.929 17.9981ZM3.978 16.0002C9.418 16.0002 13.821 21.2596 13.923 27.7996C8.296 26.8263 3.978 21.9255 3.978 16.0002ZM17.935 27.7996C18.02 21.2767 22.44 16.0002 27.88 16.0002C27.863 21.9255 23.562 26.8263 17.935 27.7996Z",
    fill: "none"
  }), _jsx("path", {
    d: "M44.9819 14.9928C43.6219 14.822 42.6529 14.7196 42.1089 14.7196C41.5649 14.7196 41.2079 14.7708 41.0549 14.8732C40.9019 14.9757 40.8169 15.1294 40.8169 15.3514C40.8169 15.5733 40.9189 15.71 41.1399 15.7953C41.3609 15.8807 41.9049 16.0002 42.7889 16.1539C43.6729 16.3076 44.3019 16.5808 44.6759 16.9394C45.0499 17.298 45.2369 17.8957 45.2369 18.7153C45.2369 20.5082 44.1319 21.4133 41.9049 21.4133C41.1739 21.4133 40.2899 21.3108 39.2529 21.1059L38.7259 21.0034L38.7939 19.1422C40.1539 19.3129 41.1059 19.4154 41.6499 19.4154C42.1769 19.4154 42.5509 19.3642 42.7379 19.2617C42.9249 19.1763 43.0269 18.9885 43.0269 18.7836C43.0269 18.5787 42.9249 18.425 42.7209 18.3225C42.5169 18.2201 41.9899 18.1006 41.1399 17.964C40.2899 17.8274 39.6439 17.5712 39.2359 17.2297C38.8109 16.8882 38.6069 16.2735 38.6069 15.4026C38.6069 14.5146 38.9129 13.8658 39.5079 13.4047C40.1029 12.9608 40.8679 12.7388 41.8029 12.7388C42.4489 12.7388 43.3329 12.8412 44.4719 13.0461L45.0159 13.1486L44.9819 14.9928Z",
    fill: "none"
  }), _jsx("path", {
    d: "M46.8521 21.2083H49.0621V14.9927L49.266 14.9244C49.674 14.7708 50.099 14.6854 50.541 14.6854C51.0171 14.6854 51.34 14.8561 51.4761 15.1977C51.6291 15.5392 51.6971 16.1197 51.6971 16.9394V21.2083H53.9071V16.9052C53.9071 16.4783 53.8901 15.8465 53.8391 15.0098L54.0941 14.9244C54.6041 14.7708 55.0291 14.6854 55.3861 14.6854C55.8451 14.6854 56.1511 14.8561 56.3041 15.1977C56.4571 15.5392 56.5421 16.0685 56.5421 16.7686V21.2083H58.7521V16.7003C58.7521 15.3172 58.5481 14.2926 58.1231 13.6608C57.6981 13.029 56.9331 12.7046 55.8451 12.7046C55.0121 12.7046 54.0941 12.9778 53.0741 13.4901C52.5811 12.9607 51.9011 12.7046 51.0001 12.7046C50.4561 12.7046 49.8101 12.9266 49.0451 13.3705V12.9095H46.8521V21.2083Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M67.0481 15.5051V18.9544C67.0651 19.2276 67.1161 19.3983 67.1841 19.5179C67.2521 19.6203 67.3881 19.7057 67.5921 19.7398L67.5241 21.4133C66.9631 21.4133 66.5211 21.3791 66.1811 21.2937C65.8241 21.2084 65.4671 21.0376 65.1611 20.8327C64.4131 21.2084 63.5801 21.4133 62.7301 21.4133C61.0131 21.4133 60.1631 20.5083 60.1631 18.6982C60.1631 17.8103 60.4011 17.1785 60.8771 16.8199C61.3531 16.4442 62.0841 16.2222 63.0531 16.171L64.8211 16.0344V15.5221C64.8211 15.1806 64.7361 14.9416 64.5831 14.822C64.4301 14.7025 64.1751 14.6342 63.8351 14.6342L60.7241 14.7708L60.6561 13.234C61.7441 12.9266 62.8831 12.7559 64.0221 12.7559C65.0931 12.7559 65.8581 12.9778 66.3171 13.4218C66.8101 13.8146 67.0481 14.5317 67.0481 15.5051ZM63.3251 17.6908C62.7131 17.742 62.4071 18.0835 62.4071 18.6812C62.4071 19.2959 62.6791 19.5862 63.2231 19.5862C63.6821 19.5862 64.1411 19.5179 64.6001 19.3813L64.8551 19.313V17.5371L63.3251 17.6908Z",
    fill: "none"
  }), _jsx("path", {
    d: "M69.1729 12.9095V21.2083L71.3999 21.2254V15.6587L71.7229 15.5392C72.3519 15.3343 73.1169 15.1464 74.0179 14.9586V12.7217C73.1679 12.8924 72.2839 13.251 71.3659 13.7975V12.9095H69.1729Z",
    fill: "none"
  }), _jsx("path", {
    d: "M79.8999 14.8049H77.8599V18.203C77.8599 18.442 77.8599 18.6811 77.8769 18.9031C77.8939 19.0397 77.9619 19.1592 78.0639 19.2617C78.1659 19.3641 78.3359 19.4154 78.5569 19.4154L79.8149 19.3812L79.9169 21.1571C79.1859 21.3278 78.6079 21.4132 78.2339 21.4132C77.2479 21.4132 76.5679 21.1912 76.1939 20.7473C75.8369 20.3033 75.6499 19.4666 75.6499 18.2713V14.822H74.6809V12.9266H75.6499V10.6213H77.8599V12.9266H79.8999V14.8049Z",
    fill: "none"
  }), _jsx("path", {
    d: "M86.632 12.9778C85.748 12.79 85.017 12.7046 84.439 12.7046C83.215 12.7046 82.314 13.0461 81.77 13.7121C81.209 14.3951 80.937 15.505 80.937 17.0248C80.937 18.5616 81.209 19.6715 81.736 20.3545C82.263 21.0546 83.147 21.3962 84.371 21.3962C85 21.3962 85.901 21.2937 87.074 21.0546L87.006 19.2788L85.085 19.4154C84.32 19.4154 83.81 19.2446 83.555 18.9031C83.3 18.5787 83.181 17.9469 83.181 17.0418C83.181 16.1368 83.3 15.5221 83.555 15.1977C83.81 14.8732 84.303 14.7025 85.068 14.7025C85.493 14.7025 86.139 14.7537 87.006 14.8391L87.074 13.0803L86.632 12.9778Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M95.0471 15.5051V18.9544C95.0641 19.2276 95.1151 19.3983 95.1831 19.5179C95.2511 19.6203 95.3871 19.7057 95.5911 19.7398L95.5231 21.4133C94.9621 21.4133 94.5201 21.3791 94.1801 21.2937C93.8231 21.2084 93.4661 21.0376 93.1601 20.8327C92.4121 21.2084 91.5791 21.4133 90.7291 21.4133C89.0121 21.4133 88.1621 20.5083 88.1621 18.6982C88.1621 17.8103 88.4001 17.1785 88.8761 16.8199C89.3521 16.4442 90.0831 16.2222 91.0521 16.171L92.8201 16.0344V15.5221C92.8201 15.1806 92.7351 14.9416 92.5821 14.822C92.4291 14.7025 92.1741 14.6342 91.8341 14.6342L88.7231 14.7708L88.6551 13.234C89.7431 12.9266 90.8821 12.7559 92.0211 12.7559C93.0921 12.7559 93.8571 12.9778 94.3161 13.4218C94.8091 13.8146 95.0471 14.5317 95.0471 15.5051ZM91.3241 17.6908C90.7121 17.742 90.4061 18.0835 90.4061 18.6812C90.4061 19.2959 90.6781 19.5862 91.2221 19.5862C91.6811 19.5862 92.1401 19.5179 92.5991 19.3813L92.8541 19.313V17.5371L91.3241 17.6908Z",
    fill: "none"
  }), _jsx("path", {
    d: "M97.155 12.9095V21.2083L99.382 21.2254V15.6587L99.705 15.5392C100.334 15.3343 101.116 15.1464 102 14.9586V12.7217C101.15 12.8924 100.266 13.251 99.348 13.7975V12.9095H97.155Z",
    fill: "none"
  })]
}), 'SmartcarMonoLogo', 'CompanyMono');
export default SmartcarMonoLogo;