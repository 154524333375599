/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FuboTvMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 74,
  height: 32,
  viewBox: "0 0 74 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M8.24357 9.87817C8.39203 8.93367 8.92337 8.40038 9.91865 8.36326L11.9288 8.3994L12.7463 4.14674C7.39382 3.4474 4.25462 5.22993 3.49863 9.87817L1.00505 9.82347L0.111347 14.0947L2.6606 14.0761L0 27.6009L4.6707 27.5823L7.36842 14.1123L10.029 14.0761L10.8475 9.85961L8.24259 9.87817H8.24357Z",
    fill: "white"
  }), _jsx("path", {
    d: "M23.8586 9.69532L21.9832 18.7427C21.5818 20.6796 21.0993 22.6086 19.1146 23.5209C18.4563 23.8237 17.7609 23.9184 17.085 23.8227C14.6471 23.4027 15.0377 21.1758 15.4099 19.1686L17.2891 9.67773H12.3771L10.4022 19.9588C9.89528 23.0618 10.3563 25.5124 12.3791 26.9111C14.4625 28.2609 17.9543 28.509 20.8249 26.6523L20.6383 27.5284L24.9554 27.4737L28.5654 9.67773L23.8576 9.69629L23.8586 9.69532Z",
    fill: "white"
  }), _jsx("path", {
    d: "M46.6505 11.0119C47.7034 12.2914 48.1781 13.7545 48.5111 15.3017C50.2477 13.0503 53.6038 13.3179 55.1724 14.6815C56.5183 15.8447 56.8944 18.5639 55.8844 20.7039C55.1011 22.3634 53.6731 23.261 51.8672 23.5355C48.6889 24.018 46.9904 21.5078 46.836 18.898C46.7999 16.293 46.586 14.4168 45.6054 12.9009C44.9314 11.8577 43.8404 10.9074 42.723 10.3438C40.3047 9.12485 36.9037 9.03597 34.5547 10.4649L35.802 4.18555L31.1684 4.16699L26.5533 27.5303L31.1684 27.5117L31.4477 26.0701C34.2822 29.0755 40.7637 28.0841 43.0219 26.1062C41.7776 24.856 41.2511 23.3714 41.0489 21.7803C39.4422 23.8363 36.2913 24.0863 34.666 22.8566C33.1404 21.793 32.7448 19.6725 33.1414 17.8353C33.4998 16.1758 34.6113 14.7059 36.2337 14.0671C38.1539 13.3111 40.457 13.7946 41.5871 15.2646C43.4898 18.3012 41.9602 20.827 43.2905 23.5462C44.0827 25.1647 45.6454 26.5389 47.3078 27.2421C49.3081 28.087 51.7519 28.2501 54.014 27.6055C56.6707 26.8476 58.8547 24.9722 60.059 22.5402C61.4918 19.6471 61.5924 16.0762 60.0883 13.3922C57.5986 8.94807 50.8299 7.86292 46.6475 11.0119H46.6505Z",
    fill: "white"
  }), _jsx("path", {
    d: "M71.3195 4.01856L68.6765 8.87386L68.2301 4.01856L60.3968 4L59.968 5.55104L62.0338 5.5149L60.6566 11.1545L62.4616 11.1369L63.8945 5.49634L66.5394 5.47876L67.356 11.1545L68.993 11.1369L73.2544 4.01856H71.3195Z",
    fill: "white"
  })]
}), 'FuboTvMonoLogo', 'CompanyMono');
export default FuboTvMonoLogo;