/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DemandCurveMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 164,
  height: 48,
  viewBox: "0 0 164 48",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M71.6777 21.1359V6.30884H77.5211C82.1594 6.30884 85.3766 9.26969 85.3766 13.711C85.3766 18.1978 82.1594 21.1359 77.5438 21.1359H71.6777ZM82.1594 13.711C82.1594 11.1031 80.5564 9.08749 77.5438 9.08749H74.8381V18.3572H77.5211C80.4541 18.3572 82.1594 16.2505 82.1594 13.711Z",
    fill: "none"
  }), _jsx("path", {
    d: "M87.4004 21.1359V6.30884H97.882V9.08749H90.5494V12.1964H97.7229V14.975H90.5494V18.3572H97.882V21.1359H87.4004Z",
    fill: "none"
  }), _jsx("path", {
    d: "M112.99 21.1359V10.4426L108.818 21.1359H107.442L103.27 10.4426V21.1359H100.121V6.30884H104.543L108.136 15.5786L111.728 6.30884H116.173V21.1473H112.99V21.1359Z",
    fill: "none"
  }), _jsx("path", {
    d: "M129.11 21.1359L128.178 18.6192H121.823L120.891 21.1359H117.321L123.028 6.29749H126.984L132.691 21.1359H129.11ZM125.006 9.46332L122.699 15.8519H127.314L125.006 9.46332Z",
    fill: "none"
  }), _jsx("path", {
    d: "M144.049 21.1359L136.989 11.4562V21.1359H133.84V6.30884H137.08L143.935 15.6241V6.30884H147.084V21.1473H144.049V21.1359Z",
    fill: "none"
  }), _jsx("path", {
    d: "M149.801 21.1359V6.30884H155.644C160.282 6.30884 163.5 9.26969 163.5 13.711C163.5 18.1978 160.282 21.1359 155.667 21.1359H149.801ZM160.271 13.711C160.271 11.1031 158.668 9.08749 155.655 9.08749H152.95V18.3572H155.633C158.566 18.3572 160.271 16.2505 160.271 13.711Z",
    fill: "none"
  }), _jsx("path", {
    d: "M70.9727 34.0157C70.9727 29.4377 74.4173 26.3402 78.8737 26.3402C82.1137 26.3402 84.0008 28.0939 85.0467 29.9616L82.341 31.2939C81.7158 30.0868 80.3857 29.1416 78.8737 29.1416C76.168 29.1416 74.2126 31.2142 74.2126 34.0157C74.2126 36.8171 76.168 38.8897 78.8737 38.8897C80.3857 38.8897 81.7158 37.9331 82.341 36.7374L85.0467 38.047C84.0008 39.8918 82.1137 41.6911 78.8737 41.6911C74.4059 41.6911 70.9727 38.5822 70.9727 34.0157Z",
    fill: "none"
  }), _jsx("path", {
    d: "M86.7061 35.4846V26.5907H89.9006V35.3935C89.9006 37.4661 91.0943 38.8896 93.3907 38.8896C95.6871 38.8896 96.858 37.4661 96.858 35.3935V26.5907H100.075V35.4846C100.075 39.1516 97.9039 41.6911 93.3907 41.6911C88.8774 41.6911 86.7061 39.1516 86.7061 35.4846Z",
    fill: "none"
  }), _jsx("path", {
    d: "M111.148 41.4291L108.238 36.1565H105.93V41.4291H102.781V26.5907H109.705C112.785 26.5907 114.695 28.6177 114.695 31.3736C114.695 33.9814 113.036 35.4049 111.433 35.7807L114.786 41.4291H111.148ZM111.455 31.3508C111.455 30.1323 110.5 29.3693 109.261 29.3693H105.93V33.3779H109.261C110.5 33.3779 111.455 32.6149 111.455 31.3508Z",
    fill: "none"
  }), _jsx("path", {
    d: "M120.766 41.4291L115.06 26.5907H118.629L122.733 37.9786L126.837 26.5907H130.407L124.7 41.4291H120.766Z",
    fill: "none"
  }), _jsx("path", {
    d: "M131.566 41.4291V26.5907H142.048V29.3693H134.727V32.4782H141.9V35.2569H134.727V38.6391H142.048V41.4177H131.566V41.4291Z",
    fill: "none"
  }), _jsx("path", {
    d: "M35.8784 32.558C34.8098 36.0199 33.1045 39.1971 30.8877 41.9644C35.003 45.4035 40.2893 47.4761 46.0417 47.4761H54.9091V37.227H46.0417C41.9832 37.227 38.3453 35.4163 35.8784 32.558Z",
    fill: "none"
  }), _jsx("path", {
    d: "M19.2808 15.1915C20.4403 11.7751 22.2252 8.64342 24.5102 5.95587C20.338 2.26619 14.8698 0 8.86733 0H0V10.2491H8.86733C13.0623 10.2491 16.8138 12.1737 19.2808 15.1915Z",
    fill: "none"
  }), _jsx("path", {
    d: "M46.0419 0.512451C32.9683 0.512451 22.3389 11.1715 22.3389 24.2562C22.3389 31.6925 16.3023 37.7509 8.86733 37.7509H0V48H8.86733C21.9296 48 32.5704 37.3523 32.5704 24.2562C32.5704 16.8199 38.607 10.7616 46.0419 10.7616H54.9093V0.512451H46.0419Z",
    fill: "none"
  })]
}), 'DemandCurveMonoLogo', 'CompanyMono');
export default DemandCurveMonoLogo;