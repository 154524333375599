/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TillsterMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 100,
  height: 32,
  viewBox: "0 0 100 32",
  fill: "none",
  children: [_jsx("path", {
    d: "M22.6603 12.6469C22.599 12.6469 19.2919 12.6469 16.0153 12.6469C14.3311 12.6469 12.6775 12.6469 11.422 12.6469V12.6163V9.30913C11.422 8.48233 11.3914 7.86989 11.2995 7.41056C11.2077 6.95123 10.9933 6.58377 10.6565 6.27755C10.1665 5.81822 9.58469 5.57324 8.97225 5.57324C8.54354 5.57324 8.14545 5.66511 7.77799 5.87946C7.41053 6.09382 7.13493 6.36941 6.9512 6.67563C6.76746 7.01248 6.64498 7.37994 6.61435 7.83927C6.58373 8.2986 6.55311 8.91104 6.55311 9.67659V12.5857V12.6163H3.30718H1.77608C0.826794 12.5551 0 13.2594 0 14.3005C0 15.2804 0.734928 15.8622 1.50048 15.9541H6.55311V16.1378V25.4775C6.55311 26.978 6.6756 28.2029 6.92057 29.0909C7.16555 29.979 7.68612 30.7139 8.45167 31.2039C9.24784 31.7244 10.3809 31.9694 11.912 31.9694C13.5043 31.9694 14.7598 31.7551 15.5866 31.357C16.444 30.9283 16.8727 30.3464 16.8727 29.5503C16.8727 29.0909 16.7196 28.6929 16.4134 28.3866C16.1072 28.0804 15.7397 27.9273 15.3416 27.9273C15.066 27.9273 14.6986 27.9886 14.2086 28.0804C13.7187 28.1723 13.3206 28.2335 13.045 28.2335C12.5244 28.2335 12.1569 28.111 11.912 27.8354C11.667 27.5905 11.5445 27.2536 11.4833 26.8249C11.422 26.4268 11.3914 25.8144 11.3914 25.0795V16.1378V15.9847C11.5445 15.9847 11.6976 15.9847 11.8507 15.9847C12.2794 16.0153 12.6163 16.046 12.6775 16.1072C12.8919 16.3216 13.0756 16.4441 13.1062 16.4747C13.5655 16.9646 14.1167 17.3627 14.8211 17.6383C15.4947 17.9139 16.2909 18.067 17.2096 18.067C18.0976 18.067 20.1493 17.7302 21.4048 16.3216C21.8947 15.801 22.4153 15.0967 22.9665 13.5043C23.1502 13.1981 23.1196 12.6163 22.6603 12.6469Z",
    fill: "none"
  }), _jsx("path", {
    d: "M22.9972 21.6498V28.9991C22.9972 29.979 22.7522 30.7139 22.2929 31.2038C21.8335 31.6938 21.2517 31.9388 20.5168 31.9388C19.8125 31.9388 19.2307 31.6938 18.7713 31.1732C18.312 30.6526 18.0977 29.9177 18.0977 28.9684V21.8029C18.0977 20.8536 18.312 20.1187 18.7713 19.6287C19.2307 19.1388 19.7819 18.8938 20.5168 18.8938C21.2211 18.8938 21.8029 19.1388 22.2929 19.6287C22.7828 20.1187 22.9972 20.7924 22.9972 21.6498Z",
    fill: "none"
  }), _jsx("path", {
    d: "M25.2939 29.0297V10.6565C25.2939 9.6766 25.5083 8.94168 25.937 8.45173C26.3657 7.96177 26.9475 7.7168 27.6825 7.7168C28.4174 7.7168 28.9992 7.96177 29.4585 8.45173C29.9179 8.94168 30.1322 9.6766 30.1322 10.6565V29.0297C30.1322 30.0096 29.9179 30.7445 29.4585 31.2345C28.9992 31.7244 28.4174 31.9694 27.6825 31.9694C26.9782 31.9694 26.3963 31.7244 25.937 31.2039C25.5083 30.7139 25.2939 29.979 25.2939 29.0297Z",
    fill: "none"
  }), _jsx("path", {
    d: "M32.4897 29.0297V2.93971C32.4897 1.95981 32.7041 1.22488 33.1328 0.734928C33.5615 0.244976 34.1433 0 34.8783 0C35.6132 0 36.195 0.244976 36.6543 0.734928C37.1137 1.22488 37.328 1.95981 37.328 2.93971V29.0297C37.328 30.0096 37.1137 30.7445 36.6543 31.2344C36.195 31.7244 35.6132 31.9694 34.8783 31.9694C34.174 31.9694 33.5921 31.7244 33.1328 31.2038C32.7041 30.7139 32.4897 29.9789 32.4897 29.0297Z",
    fill: "none"
  }), _jsx("path", {
    d: "M55.1197 25.6306C55.1197 26.9779 54.7828 28.1109 54.1398 29.0909C53.4967 30.0401 52.5168 30.7751 51.2307 31.265C49.9445 31.755 48.4134 31.9999 46.5761 31.9999C44.8307 31.9999 43.3302 31.7243 42.0747 31.2038C40.8192 30.6832 39.9005 30.0095 39.3187 29.2133C38.7369 28.4172 38.4307 27.621 38.4307 26.7942C38.4307 26.2736 38.6144 25.8143 39.0125 25.4162C39.3799 25.0487 39.8699 24.8344 40.4517 24.8344C40.9723 24.8344 41.3398 24.9569 41.6154 25.2019C41.8909 25.4468 42.1665 25.8143 42.4115 26.243C42.9015 27.1004 43.5139 27.7741 44.2182 28.1722C44.9225 28.6009 45.8718 28.8153 47.0661 28.8153C48.046 28.8153 48.8421 28.6009 49.4546 28.1722C50.067 27.7435 50.4039 27.2535 50.4039 26.6717C50.4039 25.8143 50.067 25.2019 49.424 24.8038C48.7809 24.4057 47.7091 24.0382 46.2393 23.6708C44.5551 23.242 43.2077 22.8133 42.1359 22.354C41.0948 21.8947 40.2374 21.2822 39.6249 20.5473C38.9819 19.8124 38.6756 18.8631 38.6756 17.7913C38.6756 16.8114 38.9512 15.8928 39.5637 15.0353C40.1455 14.1779 41.0029 13.4736 42.1359 12.9531C43.2689 12.4325 44.6469 12.1875 46.2393 12.1875C47.4948 12.1875 48.6278 12.31 49.6383 12.5856C50.6488 12.8612 51.4756 13.198 52.1493 13.6267C52.823 14.0554 53.3436 14.5454 53.6804 15.0966C54.0173 15.6172 54.201 16.1684 54.201 16.6583C54.201 17.2095 54.0173 17.6688 53.6498 18.0363C53.2823 18.4038 52.7311 18.5569 52.0575 18.5569C51.5675 18.5569 51.1388 18.4038 50.7713 18.1282C50.4345 17.8526 50.0058 17.4239 49.5771 16.842C49.2096 16.3521 48.7809 15.9846 48.2909 15.709C47.801 15.4334 47.1273 15.2803 46.2699 15.2803C45.3819 15.2803 44.6469 15.4641 44.0651 15.8315C43.4833 16.199 43.1771 16.6583 43.1771 17.2401C43.1771 17.7607 43.3914 18.1588 43.8201 18.4956C44.2488 18.8325 44.8307 19.1081 45.535 19.2918C46.2699 19.5062 47.2498 19.7818 48.5359 20.088C50.0364 20.4554 51.2919 20.8841 52.2412 21.4047C53.1905 21.9253 53.9254 22.5071 54.4153 23.2114C54.8747 23.9464 55.1197 24.7119 55.1197 25.6306Z",
    fill: "none"
  }), _jsx("path", {
    d: "M56.5894 12.6163H57.11V9.70723C57.11 8.91106 57.1406 8.29862 57.1712 7.86991C57.2018 7.41058 57.3243 7.04312 57.5081 6.70627C57.6918 6.36943 57.9674 6.09383 58.3349 5.9101C58.7023 5.69575 59.1004 5.60388 59.5291 5.60388C60.1416 5.60388 60.6928 5.84886 61.2133 6.30819C61.5502 6.61441 61.7645 6.98187 61.8564 7.4412C61.9483 7.90053 61.9789 8.51297 61.9789 9.33977V12.6469H63.755C64.4286 12.6469 64.9798 12.8001 65.3473 13.1369C65.7148 13.4737 65.8985 13.8718 65.8985 14.3924C65.8985 15.0355 65.6535 15.4948 65.1329 15.7704C64.6124 16.046 63.8774 16.1685 62.9282 16.1685H62.0095V25.1101C62.0095 25.8757 62.0401 26.4575 62.1014 26.8556C62.1626 27.2536 62.2851 27.5905 62.5301 27.8661C62.7751 28.1111 63.1425 28.2642 63.6631 28.2642C63.9387 28.2642 64.3368 28.2029 64.8267 28.1111C65.3167 28.0192 65.6841 27.9579 65.9597 27.9579C66.3578 27.9579 66.6947 28.1111 67.0315 28.4173C67.3377 28.7235 67.4908 29.1216 67.4908 29.5809C67.4908 30.3771 67.0621 30.9589 66.2047 31.3876C65.3473 31.8163 64.1224 32.0001 62.4995 32.0001C60.9684 32.0001 59.8353 31.7551 59.0392 31.2345C58.243 30.7139 57.753 30.0096 57.5081 29.1216C57.2631 28.2335 57.1406 27.0087 57.1406 25.5082V16.1379H56.4975C55.7932 16.1379 55.2727 15.9847 54.9052 15.6479C54.5377 15.3111 54.354 14.8823 54.354 14.3924C54.354 13.8718 54.5377 13.4737 54.9358 13.1369C55.3033 12.7694 55.8545 12.6163 56.5894 12.6163Z",
    fill: "none"
  }), _jsx("path", {
    d: "M80.9647 23.2727H71.4412C71.4412 24.3751 71.6862 25.355 72.1149 26.1818C72.5436 27.0392 73.1254 27.6517 73.8298 28.0804C74.5341 28.5091 75.3302 28.7234 76.2183 28.7234C76.8001 28.7234 77.3207 28.6622 77.8106 28.5091C78.3006 28.3866 78.7599 28.1722 79.2192 27.866C79.6786 27.5598 80.0766 27.2536 80.4747 26.9167C80.8422 26.5799 81.3628 26.1206 81.9446 25.5694C82.1896 25.355 82.557 25.2325 83.0164 25.2325C83.5063 25.2325 83.9044 25.355 84.2412 25.6306C84.5475 25.9062 84.7006 26.3043 84.7006 26.7943C84.7006 27.223 84.5168 27.7435 84.18 28.3253C83.8431 28.9072 83.3226 29.489 82.6183 30.0096C81.914 30.5608 81.0565 30.9895 79.9848 31.3569C78.9436 31.7244 77.7187 31.8775 76.3714 31.8775C73.2479 31.8775 70.8288 30.9895 69.0833 29.2134C67.3379 27.4373 66.4805 25.0182 66.4805 21.956C66.4805 20.5167 66.6948 19.1694 67.1235 17.9445C67.5522 16.7196 68.1647 15.6478 68.9915 14.7904C69.8183 13.9024 70.8288 13.2287 72.023 12.7694C73.2173 12.31 74.5647 12.0651 76.0039 12.0651C77.9025 12.0651 79.5254 12.4631 80.9034 13.2593C82.2508 14.0555 83.292 15.0966 83.9656 16.3828C84.6393 17.6689 84.9762 18.955 84.9762 20.2718C84.9762 21.4966 84.6393 22.2928 83.935 22.6603C83.2307 23.089 82.2508 23.2727 80.9647 23.2727ZM71.4412 20.5167H80.2604C80.1379 18.8632 79.6786 17.6076 78.913 16.7809C78.1475 15.9541 77.1063 15.556 75.8508 15.556C74.6259 15.556 73.646 15.9847 72.8498 16.8115C72.0843 17.6383 71.625 18.8632 71.4412 20.5167Z",
    fill: "none"
  }), _jsx("path", {
    d: "M91.0086 24.9569V29.0296C91.0086 30.0095 90.7636 30.7444 90.3042 31.2344C89.8449 31.7243 89.2631 31.9693 88.5282 31.9693C87.8239 31.9693 87.242 31.7243 86.8133 31.2344C86.354 30.7444 86.1396 30.0095 86.1396 29.0296V15.4641C86.1396 13.2899 86.9358 12.1875 88.4975 12.1875C89.2937 12.1875 89.8755 12.4325 90.243 12.9531C90.6105 13.4736 90.7942 14.2086 90.8248 15.2191C91.4066 14.2086 92.0191 13.4736 92.6315 12.9531C93.244 12.4325 94.0708 12.1875 95.0813 12.1875C96.0918 12.1875 97.1023 12.4325 98.0516 12.9531C99.0009 13.4736 99.4908 14.1473 99.4908 14.9741C99.4908 15.5559 99.2765 16.0459 98.8784 16.444C98.4803 16.842 98.021 17.0258 97.5617 17.0258C97.3779 17.0258 96.9492 16.9033 96.2755 16.6889C95.6019 16.4746 94.9894 16.3521 94.4688 16.3521C93.7645 16.3521 93.1827 16.5358 92.7234 16.9033C92.2641 17.2708 91.9272 17.822 91.6822 18.5569C91.4373 19.2918 91.2535 20.1798 91.1617 21.1904C91.0392 22.2621 91.0086 23.487 91.0086 24.9569Z",
    fill: "none"
  })]
}), 'TillsterMonoLogo', 'CompanyMono');
export default TillsterMonoLogo;