/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var IntuitMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 83,
  height: 32,
  viewBox: "0 0 83 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M31.9047 4C33.2788 4 34.3918 5.11397 34.3918 6.48792C34.3918 7.86188 33.2788 8.97462 31.9047 8.97462C30.5315 8.97462 29.4167 7.86188 29.4167 6.48792C29.4167 5.11397 30.5313 4 31.9047 4Z",
    fill: "none"
  }), _jsx("path", {
    d: "M22.3039 27.5925H19.8622C19.252 27.5925 19.0488 27.2877 19.0488 26.7104V17.4848C19.0488 15.6204 18.5409 14.2972 16.4708 14.2972C15.1476 14.2972 13.5204 15.0108 12.4692 15.9252V26.7445C12.4692 27.3226 12.2316 27.5923 11.6561 27.5923H9.17926C8.67037 27.5923 8.36475 27.3226 8.36475 26.7786V11.9678C8.36475 11.4248 8.60378 11.12 9.21337 11.12H11.4168C11.8914 11.12 12.1985 11.255 12.2314 11.7637L12.2649 12.669C13.8263 11.4151 15.7934 10.7034 17.7607 10.7034C21.5593 10.7034 23.119 13.077 23.119 16.7739V26.7104C23.1188 27.2877 22.916 27.5925 22.3039 27.5925Z",
    fill: "none"
  }), _jsx("path", {
    d: "M0 11.7933C0 11.3873 0.203885 11.1158 0.610215 11.1158H3.4794C3.88614 11.1158 4.12352 11.3534 4.12352 11.7933V26.7447C4.12352 27.3553 3.85099 27.5925 3.27572 27.5925H0.882129C0.273559 27.5925 0 27.3553 0 26.7447V11.7933Z",
    fill: "none"
  }), _jsx("path", {
    d: "M55.1954 27.5925H53.0244C52.5834 27.5925 52.2453 27.3553 52.2122 26.9151L52.1777 26.0662C50.6165 27.3228 48.7511 28.0001 46.7838 28.0001C42.9856 28.0001 41.4263 25.5937 41.4263 21.929V11.8973C41.4263 11.3204 41.7652 11.1167 42.3061 11.1167H44.6814C45.2246 11.1167 45.5303 11.2854 45.5303 11.8973V21.2162C45.5303 23.0824 46.0048 24.3704 48.0406 24.3704C49.3274 24.3704 50.9566 23.6591 51.9742 22.7433V11.8973C51.9742 11.3204 52.28 11.1167 52.821 11.1167H55.2292C55.7726 11.1167 56.0784 11.2854 56.0784 11.8973V26.6436C56.078 27.3553 55.8731 27.5925 55.1954 27.5925Z",
    fill: "none"
  }), _jsx("path", {
    d: "M60.9324 11.1158C60.5264 11.1158 60.3215 11.3873 60.3215 11.7933V26.7447C60.3215 27.3553 60.5949 27.5925 61.2037 27.5925H63.5973C64.1746 27.5925 64.4463 27.3553 64.4463 26.7447V11.7933C64.4463 11.3534 64.2089 11.1158 63.8013 11.1158H60.9324Z",
    fill: "none"
  }), _jsx("path", {
    d: "M37.9315 11.1158C38.3383 11.1158 38.5759 11.2509 38.5759 11.6924V14.0808C38.5759 14.4875 38.305 14.7251 37.8972 14.7251H33.9665V26.7445C33.9665 27.3551 33.6954 27.5923 33.0843 27.5923H30.7243C30.1136 27.5923 29.843 27.3551 29.843 26.7445V14.7251H25.9112C25.5045 14.7251 25.2332 14.4875 25.2332 14.0808V11.6924C25.2332 11.2509 25.4703 11.1158 25.8777 11.1158H37.9315Z",
    fill: "none"
  }), _jsx("path", {
    d: "M73.9922 4C75.3661 4 76.4801 5.11397 76.4801 6.48792C76.4801 7.86188 75.3661 8.97462 73.9922 8.97462C72.6188 8.97462 71.5049 7.86188 71.5049 6.48792C71.5049 5.11397 72.6188 4 73.9922 4Z",
    fill: "none"
  }), _jsx("path", {
    d: "M80.0198 11.1158C80.4257 11.1158 80.6629 11.2509 80.6629 11.6924V14.0808C80.6629 14.4875 80.3926 14.7251 79.9844 14.7251H76.0537V26.7445C76.0537 27.3551 75.7834 27.5923 75.1724 27.5923H72.8117C72.2008 27.5923 71.9304 27.3551 71.9304 26.7445L71.9295 14.7251H67.9998C67.5921 14.7251 67.3206 14.4875 67.3206 14.0808V11.6924C67.3206 11.2509 67.5588 11.1158 67.9645 11.1158H80.0198Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M81.1615 25.957H80.6658L80.6656 27.2411H80.8651V26.6881H81.077L81.4262 27.2411H81.6412L81.2735 26.6739C81.4655 26.6539 81.6116 26.5555 81.6116 26.3247C81.6116 26.0774 81.4673 25.957 81.1615 25.957ZM81.0741 26.5267H80.8653V26.1192H81.13C81.2655 26.1192 81.4116 26.1451 81.4116 26.3158C81.4116 26.5185 81.2505 26.5267 81.0741 26.5267Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M82.1955 26.5943C82.1955 25.9745 81.694 25.502 81.0945 25.502C80.4925 25.502 79.9875 25.9745 79.9873 26.5943C79.9873 27.2251 80.4923 27.6989 81.0945 27.6989C81.694 27.6989 82.1955 27.2251 82.1955 26.5943ZM81.9963 26.5943C81.9963 27.1326 81.6032 27.5377 81.0945 27.5377C80.5803 27.5377 80.1865 27.1328 80.1865 26.5943C80.1865 26.0692 80.5803 25.6631 81.0945 25.6631C81.6032 25.6631 81.9963 26.069 81.9963 26.5943Z",
    fill: "none"
  })]
}), 'IntuitMonoLogo', 'CompanyMono');
export default IntuitMonoLogo;