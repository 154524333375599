/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CanduMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 132,
  height: 32,
  viewBox: "0 0 132 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M18.0723 13.3104V19.6304C18.0723 20.1604 18.4923 20.5804 19.0223 20.5804H21.4523C22.0123 20.5804 22.4523 20.1004 22.3923 19.5404C22.0723 16.3704 20.3123 13.8404 18.0723 13.3104Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.0528 20.5904H15.0328C15.5528 20.5904 15.9828 20.1604 15.9828 19.6404V9.11035C12.3028 9.98035 9.44281 14.2704 9.11281 19.5804C9.08281 20.1304 9.51281 20.5904 10.0528 20.5904Z",
    fill: "none"
  }), _jsx("path", {
    d: "M28.5224 18.8804C27.1124 18.2204 25.4224 18.9004 24.7524 20.3204C24.5124 20.8304 24.2324 21.3204 23.9124 21.7704C23.4924 22.3704 22.8124 22.7304 22.0824 22.7304H9.26241C8.53241 22.7304 7.85241 22.3804 7.43241 21.7804C6.50241 20.4504 5.91241 18.9104 5.70241 17.2504C5.37241 14.5704 6.10241 11.9204 7.74241 9.8004C9.39241 7.6704 11.7624 6.3204 14.4324 5.9804C16.6124 5.7104 18.7624 6.1404 20.6624 7.2404C22.4824 8.2904 23.9524 9.9104 24.8124 11.8204C25.4524 13.2404 27.1324 13.9504 28.5524 13.3104C29.9624 12.6704 30.6024 11.0104 29.9724 9.5804C28.6324 6.5604 26.3224 3.9904 23.4624 2.3404C20.5024 0.630398 17.1424 -0.0496019 13.7424 0.370398C9.59241 0.890398 5.89241 3.0004 3.32241 6.3204C0.752406 9.6404 -0.377594 13.7604 0.132406 17.9404C0.652406 22.1204 2.75241 25.8404 6.05241 28.4204C8.83241 30.6004 12.1824 31.7504 15.6424 31.7504C16.2924 31.7504 16.9524 31.7104 17.6024 31.6304C22.9924 30.9604 27.5824 27.5904 29.8824 22.6304C30.5324 21.2204 29.9224 19.5404 28.5224 18.8804Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M112.492 27.3703V2.96033H109.432V13.9303C108.392 12.1703 106.262 11.2203 103.872 11.2203C101.652 11.2203 99.7624 11.9703 98.2524 13.4903C96.7324 15.0103 95.9624 16.9803 95.9624 19.4503C95.9624 21.8903 96.7324 23.8903 98.2524 25.4103C99.8024 26.9303 101.682 27.7103 103.912 27.7103C106.202 27.7103 108.392 26.6903 109.432 24.9303V27.3703H112.492ZM104.172 14.2303C105.552 14.2303 106.762 14.7003 107.812 15.6203C108.852 16.5303 109.392 17.8203 109.392 19.4103C109.392 21.0003 108.892 22.2903 107.842 23.2703C106.832 24.2203 105.622 24.6903 104.172 24.6903C102.722 24.6903 101.512 24.1803 100.532 23.2003C99.5524 22.2203 99.0824 20.9303 99.0824 19.4103C99.0824 17.8903 99.5524 16.6303 100.532 15.6803C101.512 14.7103 102.722 14.2303 104.172 14.2303Z",
    fill: "none"
  }), _jsx("path", {
    d: "M85.5422 14.2303C83.0822 14.2303 81.0922 15.7203 81.0922 18.8403V27.3703H78.0322V11.5503H81.0922V13.9203C82.0022 12.1603 84.0922 11.2103 86.0122 11.2103C89.9122 11.2103 92.0722 13.7503 92.0722 18.2203V27.3703H89.0122V18.4303C89.0122 15.8203 87.7022 14.2303 85.5422 14.2303Z",
    fill: "none"
  }), _jsx("path", {
    d: "M47.8422 11.2203C45.5222 11.2203 43.5622 11.9703 41.9522 13.4903C40.3322 15.0103 39.5322 16.9803 39.5322 19.4203C39.5322 21.8603 40.3422 23.8603 41.9522 25.4203C43.5722 26.9403 45.5222 27.7203 47.8422 27.7203C50.3322 27.7203 52.3522 26.8703 53.9422 25.1503L51.5822 23.1903C50.6022 24.2403 49.3622 24.7503 47.8422 24.7503C46.3922 24.7503 45.1522 24.2403 44.1022 23.2603C43.0622 22.2803 42.5522 20.9903 42.5522 19.4303C42.5522 17.9103 43.0622 16.6503 44.1022 15.7003C45.1422 14.7203 46.3922 14.2403 47.8422 14.2403C49.3622 14.2403 50.6022 14.7503 51.5822 15.7303L53.9022 13.7703C52.3522 12.0703 50.3322 11.2203 47.8422 11.2203Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M72.8923 27.3703V11.5603H69.8623V13.9303C68.8823 12.2003 66.6323 11.2203 64.3423 11.2203C62.1223 11.2203 60.2323 11.9703 58.6823 13.4903C57.1323 15.0103 56.3623 16.9803 56.3623 19.4503C56.3623 21.8903 57.1323 23.8903 58.6823 25.4103C60.2323 26.9303 62.1123 27.7103 64.3423 27.7103C66.6323 27.7103 68.8223 26.6903 69.8623 24.9303V27.3703H72.8923ZM69.8323 19.4203C69.8323 21.0503 69.3223 22.3303 68.3123 23.2803C67.3023 24.2303 66.0523 24.7003 64.6123 24.7003C63.1623 24.7003 61.9523 24.1903 60.9723 23.2103C59.9923 22.2303 59.4923 20.9403 59.4923 19.4203C59.4923 17.9003 59.9623 16.6403 60.9423 15.6903C61.9223 14.7103 63.1623 14.2303 64.6123 14.2303C65.9923 14.2303 67.2023 14.7003 68.2523 15.6203C69.2923 16.5403 69.8323 17.8203 69.8323 19.4203Z",
    fill: "none"
  }), _jsx("path", {
    d: "M123.952 24.7004C126.472 24.7004 128.392 23.2404 128.392 20.0904V11.5604H131.452V27.3804H128.392V25.0104C127.482 26.7704 125.432 27.7204 123.512 27.7204C119.602 27.7204 117.422 25.1804 117.452 20.7104V11.5604H120.512V20.5004C120.512 23.1104 121.792 24.7004 123.952 24.7004Z",
    fill: "none"
  })]
}), 'CanduMonoLogo', 'CompanyMono');
export default CanduMonoLogo;