/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FrasersGroupMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 253,
  height: 13,
  viewBox: "0 0 253 13",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#FrasersGroupMonoLogo_svg__a)",
    children: [_jsx("mask", {
      id: "FrasersGroupMonoLogo_svg__b",
      style: {
        maskType: 'luminance'
      },
      maskUnits: "userSpaceOnUse",
      x: 0,
      y: 0,
      width: 253,
      height: 13,
      children: _jsx("path", {
        d: "M253 0H0v13h253V0Z",
        fill: "#fff"
      })
    }), _jsxs("g", {
      mask: "url(#FrasersGroupMonoLogo_svg__b)",
      children: [_jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__d",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 176,
        y: 0,
        width: 11,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__c",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__c)",
          children: _jsx("path", {
            d: "m182.644 7.586.416-.205a3.658 3.658 0 0 0 1.512-1.369c.365-.586.549-1.29.549-2.088 0-.588-.104-1.13-.312-1.614a3.554 3.554 0 0 0-.865-1.234A3.905 3.905 0 0 0 182.6.283 5.18 5.18 0 0 0 180.849 0h-4.505v12.6h1.879V7.868h2.509l3.361 4.732h2.037l-3.485-5.014h-.001Zm-1.814-1.334h-2.608V2.454l-.808-.835h.808v-.004h2.608c.732 0 1.313.218 1.728.646.415.428.625.987.625 1.663 0 .675-.21 1.255-.624 1.683-.413.429-.995.645-1.729.645Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__d)",
        children: _jsx("path", {
          d: "M187.153-1h-11.825v14.6h11.825V-1Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__f",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 152,
        y: 0,
        width: 13,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__e",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__e)",
          children: _jsx("path", {
            d: "M161.967.7c-.897-.465-1.93-.7-3.072-.7a7.42 7.42 0 0 0-2.7.488 6.696 6.696 0 0 0-2.157 1.348 6.181 6.181 0 0 0-1.952 4.566 6.178 6.178 0 0 0 1.951 4.562 6.703 6.703 0 0 0 2.159 1.35c1.417.552 3.173.638 4.643.234a7.653 7.653 0 0 0 1.707-.696c.517-.293.986-.65 1.391-1.057.388-.387.721-.81.992-1.261v-3.38h-5.672v1.544h3.719V9.29l-.079.103c-.417.55-.96.99-1.613 1.31-.654.317-1.423.478-2.285.478-.734 0-1.405-.121-1.994-.359a4.62 4.62 0 0 1-1.543-1 4.38 4.38 0 0 1-.996-1.524 5.1 5.1 0 0 1-.345-1.896c0-.68.111-1.293.332-1.867.225-.577.55-1.084.967-1.504a4.42 4.42 0 0 1 1.505-.984 5.201 5.201 0 0 1 1.93-.346c.898 0 1.69.187 2.356.556a5.494 5.494 0 0 1 1.464 1.205l1.351-1.08A6.43 6.43 0 0 0 161.967.7Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__f)",
        children: _jsx("path", {
          d: "M165.945-1h-14.883v14.8h14.883V-1Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__h",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 221,
        y: 0,
        width: 11,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__g",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__g)",
          children: _jsx("path", {
            d: "M230.276 11.4a4.896 4.896 0 0 0 1.112-1.662c.271-.654.408-1.403.408-2.223V.2h-1.947v7.516c0 .54-.085 1.024-.252 1.44a3.038 3.038 0 0 1-.713 1.072c-.304.29-.669.51-1.084.656a4.135 4.135 0 0 1-2.612-.001 2.937 2.937 0 0 1-1.083-.657 3.02 3.02 0 0 1-.713-1.071c-.166-.417-.25-.9-.25-1.438V.2h-1.947v7.316c0 .824.134 1.574.399 2.226.263.65.633 1.207 1.099 1.659a4.8 4.8 0 0 0 1.674 1.037c1.295.483 2.962.482 4.246 0a4.827 4.827 0 0 0 1.663-1.039Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__h)",
        children: _jsx("path", {
          d: "M232.82-.8h-12.64v14.6h12.64V-.8Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__j",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 196,
        y: 0,
        width: 15,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__i",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__i)",
          children: _jsx("path", {
            d: "M209.657 8.934a6.041 6.041 0 0 0 .533-2.532c0-.906-.18-1.76-.533-2.536a6.383 6.383 0 0 0-1.46-2.029 6.788 6.788 0 0 0-2.172-1.35A7.353 7.353 0 0 0 203.349 0c-.945 0-1.839.164-2.666.486a6.851 6.851 0 0 0-2.161 1.351 6.385 6.385 0 0 0-1.458 2.03 6.033 6.033 0 0 0-.533 2.535c0 .912.179 1.762.533 2.532a6.41 6.41 0 0 0 1.45 2.032 6.704 6.704 0 0 0 2.16 1.349c1.663.647 3.69.646 5.352 0a6.802 6.802 0 0 0 2.171-1.35 6.369 6.369 0 0 0 1.46-2.031Zm-1.863-.64a4.408 4.408 0 0 1-1.006 1.492c-.433.413-.946.74-1.53.97a5.116 5.116 0 0 1-1.887.346c-.67 0-1.347-.117-1.921-.348a4.669 4.669 0 0 1-1.515-.968 4.252 4.252 0 0 1-1-1.495 5.017 5.017 0 0 1-.348-1.889c0-.682.117-1.317.349-1.883a4.369 4.369 0 0 1 .997-1.491c.427-.42.939-.75 1.517-.98 1.12-.452 2.624-.47 3.809 0 .584.23 1.098.56 1.53.98.432.424.769.925 1.005 1.49a4.83 4.83 0 0 1 .361 1.884c0 .683-.122 1.317-.361 1.893Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__j)",
        children: _jsx("path", {
          d: "M211.206-1h-15.698v14.8h15.698V-1Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__m",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 244,
        y: 0,
        width: 9,
        height: 13,
        children: [_jsxs("mask", {
          id: "FrasersGroupMonoLogo_svg__l",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 244,
          y: 0,
          width: 9,
          height: 13,
          children: [_jsx("mask", {
            id: "FrasersGroupMonoLogo_svg__k",
            style: {
              maskType: 'luminance'
            },
            maskUnits: "userSpaceOnUse",
            x: 0,
            y: 0,
            width: 253,
            height: 13,
            children: _jsx("path", {
              d: "M253 0H0v13h253V0Z",
              fill: "#fff"
            })
          }), _jsx("g", {
            mask: "url(#FrasersGroupMonoLogo_svg__k)",
            children: _jsx("path", {
              d: "M252.691 2.31a3.6 3.6 0 0 0-.866-1.234 3.902 3.902 0 0 0-1.342-.793A5.172 5.172 0 0 0 248.734 0h-4.5v12.6h1.876V8.63l-.739-.763h3.212v.001h.151c.635 0 1.222-.097 1.746-.29a3.971 3.971 0 0 0 1.342-.813c.37-.347.663-.763.869-1.238A4.007 4.007 0 0 0 253 3.923c0-.591-.104-1.132-.309-1.613Zm-2.25 3.305c-.411.437-.993.659-1.728.659h-2.603v-4.66h2.603c.732 0 1.313.219 1.727.647.414.425.624.985.624 1.663 0 .677-.209 1.26-.623 1.691Z",
              fill: "#fff"
            })
          })]
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__l)",
          children: _jsx("path", {
            d: "M252.998-18.68H0V13h252.998v-31.68Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__m)",
        children: _jsx("path", {
          d: "M254.016-1h-10.805v14.6h10.805V-1Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__o",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 122,
        y: 0,
        width: 9,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__n",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__n)",
          children: _jsx("path", {
            d: "M123.846 12.263c.801.488 1.754.736 2.83.736.607 0 1.176-.085 1.693-.25a3.94 3.94 0 0 0 1.323-.704c.369-.302.662-.676.871-1.109.213-.436.321-.932.321-1.478 0-.842-.241-1.496-.735-2-.526-.537-1.293-1.034-2.279-1.475l-1.491-.655c-.515-.235-.922-.463-1.213-.68-.251-.19-.553-.548-.553-1.187 0-.716.357-1.097.657-1.29.826-.535 2.139-.477 3.006.089.346.226.664.523.95.884l1.238-.952a4.483 4.483 0 0 0-1.379-1.348c-.67-.428-1.5-.645-2.468-.645-.566 0-1.102.081-1.591.24-.48.154-.898.38-1.242.67a3.254 3.254 0 0 0-.816 1.045c-.199.402-.3.862-.3 1.367 0 .407.07.777.207 1.097.142.328.34.632.587.905.25.271.562.526.924.756.382.235.799.459 1.239.66l1.394.636c.561.251.987.485 1.301.713.275.2.599.58.599 1.27 0 .588-.214 1.052-.639 1.38-.395.305-.935.46-1.604.46-.44 0-.845-.06-1.2-.178a3.815 3.815 0 0 1-.965-.478 4.84 4.84 0 0 1-.769-.659 6.69 6.69 0 0 1-.373-.43l-1.252.939a5.358 5.358 0 0 0 1.729 1.67Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__o)",
        children: _jsx("path", {
          d: "M131.906-.8h-10.804V14h10.804V-.8Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__q",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 61,
        y: 0,
        width: 9,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__p",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__p)",
          children: _jsx("path", {
            d: "M62.89 12.263c.802.489 1.755.736 2.833.736.605 0 1.174-.084 1.692-.25.509-.163.954-.4 1.324-.704.367-.3.66-.673.872-1.11.212-.433.319-.93.319-1.477 0-.842-.24-1.496-.734-1.999-.528-.538-1.294-1.034-2.28-1.476l-1.492-.655c-.518-.235-.924-.463-1.212-.68-.253-.188-.555-.547-.555-1.186 0-.717.357-1.097.657-1.291.83-.537 2.141-.478 3.007.09.346.227.665.523.95.883l1.24-.952A4.508 4.508 0 0 0 68.13.844C67.46.416 66.63.199 65.663.199c-.568 0-1.103.08-1.592.24-.48.154-.898.38-1.243.67a3.223 3.223 0 0 0-.816 1.045c-.198.4-.299.86-.299 1.366 0 .408.07.777.208 1.098.14.328.339.632.587.904.25.273.561.528.924.756.375.234.793.456 1.239.66l1.394.636c.561.252.988.486 1.302.714.274.2.6.58.6 1.27 0 .588-.216 1.052-.64 1.38-.395.305-.935.46-1.604.46-.44 0-.845-.06-1.2-.178a3.79 3.79 0 0 1-.965-.478 4.746 4.746 0 0 1-.77-.659 6.237 6.237 0 0 1-.373-.43l-1.25.94a5.337 5.337 0 0 0 1.725 1.67Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__q)",
        children: _jsx("path", {
          d: "M70.946-.8H60.14V14h10.805V-.8Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__s",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 8,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__r",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__r)",
          children: _jsx("path", {
            d: "m1.927 2.796-.715-.72h6.739V.4H0V13h1.927V7.905h5.942V6.227H1.927v-3.43Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__s)",
        children: _jsx("path", {
          d: "M8.974-.6h-9.99V14h9.99V-.6Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__u",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 18,
        y: 0,
        width: 11,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__t",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__t)",
          children: _jsx("path", {
            d: "m25.262 7.986.416-.205a3.659 3.659 0 0 0 1.513-1.368c.363-.587.548-1.29.548-2.09 0-.587-.104-1.131-.311-1.613a3.558 3.558 0 0 0-.866-1.234 3.908 3.908 0 0 0-1.344-.793A5.195 5.195 0 0 0 23.468.4h-4.507V13h1.879V8.27h2.51L26.71 13h2.036l-3.485-5.014Zm-1.814-1.333H20.84V2.701l-.664-.686h3.273c.731 0 1.313.218 1.729.647.413.427.623.986.623 1.662 0 .675-.21 1.256-.623 1.684-.414.428-.996.645-1.73.645Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__u)",
        children: _jsx("path", {
          d: "M29.762-.6H17.937V14h11.825V-.6Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__w",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 81,
        y: 0,
        width: 10,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__v",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__v)",
          children: _jsx("path", {
            d: "M90.109 2.076V.4H81.75V13h8.341v-1.676h-6.366V8.302l-.799-.785h3.381v.003h3.802V5.843h-6.384V2.076h6.384Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__w)",
        children: _jsx("path", {
          d: "M91.132-.6H80.734V14h10.398V-.6Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__y",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 101,
        y: 0,
        width: 11,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__x",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__x)",
          children: _jsx("path", {
            d: "m108.029 7.986.415-.205a3.67 3.67 0 0 0 1.513-1.368c.363-.587.548-1.29.548-2.09 0-.586-.104-1.13-.311-1.613a3.575 3.575 0 0 0-.866-1.234 3.916 3.916 0 0 0-1.343-.793A5.204 5.204 0 0 0 106.233.4h-4.506V13h1.879V8.27h2.51L109.478 13h2.036l-3.485-5.014Zm-1.814-1.333h-2.609V2.855l-.808-.835h.808v-.005h2.609c.731 0 1.312.217 1.728.647.414.426.624.986.624 1.661 0 .676-.21 1.255-.624 1.685-.413.427-.995.645-1.728.645Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__y)",
        children: _jsx("path", {
          d: "M112.535-.6h-11.824V14h11.824V-.6Z",
          fill: "none"
        })
      }), _jsxs("mask", {
        id: "FrasersGroupMonoLogo_svg__A",
        style: {
          maskType: 'luminance'
        },
        maskUnits: "userSpaceOnUse",
        x: 39,
        y: 0,
        width: 12,
        height: 13,
        children: [_jsx("mask", {
          id: "FrasersGroupMonoLogo_svg__z",
          style: {
            maskType: 'luminance'
          },
          maskUnits: "userSpaceOnUse",
          x: 0,
          y: 0,
          width: 253,
          height: 13,
          children: _jsx("path", {
            d: "M253 0H0v13h253V0Z",
            fill: "#fff"
          })
        }), _jsx("g", {
          mask: "url(#FrasersGroupMonoLogo_svg__z)",
          children: _jsx("path", {
            d: "M45.24.2h-.376l-5.723 12.6h2.099l1.462-3.47h5.973l-.903.872 1.095 2.597h2.099L45.242.2h-.001Zm-1.844 7.487 1.658-3.935 1.658 3.935h-3.316Z",
            fill: "#fff"
          })
        })]
      }), _jsx("g", {
        mask: "url(#FrasersGroupMonoLogo_svg__A)",
        children: _jsx("path", {
          d: "M51.988-.8H38.125v14.6h13.863V-.8Z",
          fill: "none"
        })
      })]
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "FrasersGroupMonoLogo_svg__a",
      children: _jsx("path", {
        fill: "#fff",
        d: "M0 0h253v13H0z"
      })
    })
  })]
}), 'FrasersGroupMonoLogo', 'CompanyMono');
export default FrasersGroupMonoLogo;