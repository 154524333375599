/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SplitMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 79,
  height: 33,
  viewBox: "0 0 79 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#SplitMonoLogo_svg__clip0_12270_185909)",
    children: [_jsx("path", {
      d: "M44.8717 10.4805H47.6617V12.1605C47.7501 12.0967 47.8337 12.0265 47.9117 11.9505C48.5478 11.1901 49.3913 10.6312 50.3394 10.3418C51.2876 10.0523 52.2995 10.045 53.2517 10.3205C54.1192 10.5055 54.9218 10.9179 55.5772 11.5154C56.2327 12.1128 56.7174 12.8739 56.9817 13.7205C57.4215 14.8454 57.6186 16.0505 57.56 17.2569C57.5014 18.4632 57.1885 19.6436 56.6417 20.7205C56.2319 21.4903 55.6398 22.1481 54.9171 22.6362C54.1944 23.1244 53.3631 23.4281 52.4959 23.5208C51.6288 23.6135 50.7521 23.4925 49.9425 23.1682C49.1329 22.8439 48.4151 22.3263 47.8517 21.6605L47.6817 21.5205V28.1505H45.0517C44.9917 28.1505 44.9117 28.0305 44.8717 27.9605C44.8618 27.8909 44.8618 27.8202 44.8717 27.7505V10.4805ZM51.2817 21.1305C51.933 21.1524 52.5766 20.9845 53.1341 20.6471C53.6916 20.3098 54.139 19.8176 54.4217 19.2305C55.0103 18.0567 55.1422 16.7061 54.7917 15.4405C54.691 14.9338 54.4814 14.455 54.1775 14.0372C53.8737 13.6194 53.4827 13.2725 53.0317 13.0205C52.2255 12.6327 51.3126 12.5264 50.4388 12.7186C49.565 12.9107 48.7809 13.3903 48.2117 14.0805C48.0893 14.2301 48.0222 14.4173 48.0217 14.6105C48.0217 16.1205 48.0217 17.6105 48.0217 19.1205C48.023 19.3028 48.0865 19.4792 48.2017 19.6205C48.5816 20.0729 49.0518 20.4409 49.5822 20.7009C50.1126 20.9609 50.6915 21.1073 51.2817 21.1305Z",
      fill: "none"
    }), _jsx("path", {
      d: "M33.4219 19.6007C34.6606 20.7581 36.2967 21.3953 37.9919 21.3807C38.6185 21.4068 39.2364 21.2278 39.7519 20.8707C39.9539 20.7453 40.1169 20.5662 40.2228 20.3534C40.3287 20.1406 40.3732 19.9025 40.3514 19.6658C40.3296 19.4291 40.2423 19.2031 40.0994 19.0133C39.9564 18.8234 39.7634 18.677 39.5419 18.5907C38.7319 18.2907 37.8819 18.1007 37.0519 17.8807C36.0013 17.6704 34.9894 17.2994 34.0519 16.7807C33.3889 16.3369 32.909 15.6679 32.7013 14.8975C32.4935 14.1272 32.5719 13.3077 32.9219 12.5907C33.238 11.9627 33.7049 11.423 34.2809 11.0199C34.8568 10.6167 35.5237 10.3627 36.2219 10.2807C37.8543 9.95026 39.55 10.1609 41.0519 10.8807C41.6168 11.1831 42.1643 11.517 42.6919 11.8807L41.5319 13.8107C41.0788 13.4851 40.6081 13.1846 40.1219 12.9107C39.0913 12.3769 37.9083 12.2145 36.7719 12.4507C36.4616 12.4829 36.1659 12.5985 35.9159 12.7851C35.6659 12.9717 35.4711 13.2224 35.3519 13.5107C35.2899 13.6285 35.2543 13.7583 35.2474 13.8913C35.2405 14.0242 35.2625 14.1571 35.3119 14.2807C35.3614 14.4043 35.437 14.5157 35.5337 14.6072C35.6304 14.6987 35.7458 14.7681 35.8719 14.8107C36.5217 15.0735 37.1902 15.2874 37.8719 15.4507C38.9662 15.6633 40.0329 15.9987 41.0519 16.4507C41.6641 16.7184 42.182 17.1636 42.5385 17.7287C42.895 18.2938 43.0739 18.9529 43.0519 19.6207C43.0699 20.2257 42.9288 20.8248 42.6428 21.3583C42.3567 21.8917 41.9358 22.3408 41.4219 22.6607C40.1804 23.3853 38.7433 23.7035 37.3119 23.5707C36.0368 23.5283 34.7897 23.1857 33.6719 22.5707C33.1719 22.2907 32.6719 21.9307 32.2119 21.5707L33.4219 19.6007Z",
      fill: "none"
    }), _jsx("path", {
      d: "M78.1517 20.7505C78.3517 21.3605 78.5617 21.9705 78.7317 22.5805C78.7345 22.6497 78.7205 22.7186 78.6909 22.7813C78.6613 22.844 78.617 22.8986 78.5617 22.9405C77.9916 23.3365 77.3158 23.5525 76.6217 23.5605C75.7911 23.6664 74.9481 23.509 74.2117 23.1105C73.7669 22.8334 73.4061 22.4402 73.1682 21.9732C72.9303 21.5062 72.8243 20.9832 72.8617 20.4605C72.8617 18.0905 72.8617 15.7205 72.8617 13.3405V12.9005H70.7417V10.4705H72.8517V6.98047H75.6317V10.4505H78.2417V12.8805H75.6717C75.6717 13.0405 75.6717 13.1505 75.6717 13.2705V19.4305C75.6714 19.6518 75.6914 19.8728 75.7317 20.0905C75.7493 20.272 75.8084 20.447 75.9044 20.602C76.0005 20.757 76.1308 20.8879 76.2854 20.9845C76.44 21.0812 76.6148 21.141 76.7962 21.1593C76.9776 21.1777 77.1608 21.1541 77.3317 21.0905C77.6105 20.9908 77.8842 20.8774 78.1517 20.7505Z",
      fill: "none"
    }), _jsx("path", {
      d: "M60.1621 5.58057H62.8921V23.2306H60.1621V5.58057Z",
      fill: "none"
    }), _jsx("path", {
      d: "M66.082 10.4805H68.812V23.2305H66.082V10.4805Z",
      fill: "none"
    }), _jsx("path", {
      d: "M67.4217 5.46057C67.6566 5.44257 67.8927 5.47329 68.1151 5.55081C68.3376 5.62833 68.5416 5.75098 68.7144 5.91107C68.8873 6.07115 69.0251 6.26521 69.1194 6.48109C69.2137 6.69696 69.2624 6.93 69.2624 7.16557C69.2624 7.40114 69.2137 7.63417 69.1194 7.85004C69.0251 8.06592 68.8873 8.25998 68.7144 8.42007C68.5416 8.58015 68.3376 8.7028 68.1151 8.78032C67.8927 8.85784 67.6566 8.88857 67.4217 8.87057C67.1868 8.88857 66.9508 8.85784 66.7283 8.78032C66.5059 8.7028 66.3018 8.58015 66.129 8.42007C65.9562 8.25998 65.8183 8.06592 65.724 7.85004C65.6297 7.63417 65.5811 7.40114 65.5811 7.16557C65.5811 6.93 65.6297 6.69696 65.724 6.48109C65.8183 6.26521 65.9562 6.07115 66.129 5.91107C66.3018 5.75098 66.5059 5.62833 66.7283 5.55081C66.9508 5.47329 67.1868 5.44257 67.4217 5.46057Z",
      fill: "none"
    }), _jsx("path", {
      d: "M13.7719 9.44043L8.48193 12.4704L20.9719 19.6104L16.3819 22.2404L18.2819 21.1504V27.2004L12.9919 24.1804L8.48193 26.7604V32.8104L26.3519 22.6004V19.6104V16.6304L13.7719 9.44043Z",
      fill: "none"
    }), _jsx("path", {
      d: "M18.2819 0.810547L0.421875 11.0205V14.0105V16.9905L12.9919 24.1805L18.2819 21.1505L5.79187 14.0105L8.48188 12.4705V6.42055L13.7719 9.44055L18.2819 6.86055V0.810547Z",
      fill: "none"
    }), _jsxs("g", {
      opacity: 0.3,
      children: [_jsx("path", {
        d: "M18.2817 21.1504L12.9917 24.1804L18.2817 27.2004V21.1504Z",
        fill: "none"
      }), _jsx("path", {
        d: "M8.48193 12.4704L13.7719 9.44041L8.48193 6.42041V12.4704Z",
        fill: "none"
      })]
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "SplitMonoLogo_svg__clip0_12270_185909",
      children: _jsx("rect", {
        width: 78.3103,
        height: 32,
        fill: "none",
        transform: "translate(0.421875 0.810547)"
      })
    })
  })]
}), 'SplitMonoLogo', 'CompanyMono');
export default SplitMonoLogo;