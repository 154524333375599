/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ParabolMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 121,
  height: 32,
  viewBox: "0 0 121 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M36.5522 28.0002V9.37039H39.3405V10.8995C39.6807 10.3802 40.1574 9.96493 40.7184 9.69943C41.4021 9.33226 42.1677 9.1444 42.9438 9.1534C43.7658 9.09784 44.5899 9.23116 45.3525 9.54316C46.1151 9.85516 46.7964 10.3376 47.3439 10.9535C48.4272 12.4043 48.9603 14.1926 48.8478 15.9997C48.948 17.7938 48.4089 19.5654 47.3268 20.9999C46.7781 21.6257 46.0929 22.1168 45.324 22.4354C44.5551 22.7539 43.7232 22.8913 42.8925 22.837C42.1641 22.8457 41.4444 22.6804 40.7925 22.355C40.2171 22.0821 39.7188 21.6696 39.3435 21.1549V28.0002H36.5522ZM40.1274 12.7746C39.4548 13.7107 39.1218 14.8485 39.1836 15.9997C39.1215 17.1509 39.4545 18.2887 40.1274 19.2248C40.4166 19.5959 40.7862 19.8963 41.2086 20.1033C41.6307 20.3103 42.0948 20.4186 42.5649 20.4199C43.0353 20.4211 43.4997 20.3153 43.923 20.1105C44.3466 19.9058 44.7177 19.6073 45.0087 19.2378C45.6831 18.2973 46.0173 17.1554 45.9567 15.9997C46.0185 14.8488 45.6864 13.7112 45.0147 12.7746C44.7249 12.4032 44.3541 12.1028 43.9305 11.8962C43.5072 11.6896 43.0422 11.5822 42.5712 11.5822C42.0999 11.5822 41.6352 11.6896 41.2116 11.8962C40.7883 12.1028 40.4175 12.4032 40.1274 12.7746Z",
    fill: "white"
  }), _jsx("path", {
    d: "M50.5072 18.8143C50.4847 18.1937 50.6176 17.5773 50.8933 17.0209C51.1693 16.4646 51.5797 15.9859 52.0873 15.6282C53.3152 14.8063 54.7747 14.401 56.2507 14.4721H58.5376V13.8941C58.5637 13.566 58.5115 13.2365 58.3855 12.9326C58.2592 12.6286 58.0624 12.359 57.8116 12.146C57.2746 11.7524 56.6197 11.5531 55.9546 11.581C55.4053 11.5542 54.8632 11.7134 54.4153 12.0327C53.9677 12.3521 53.641 12.813 53.4874 13.3411L50.9824 12.8141C51.2323 11.7405 51.859 10.7921 52.7485 10.1409C53.6887 9.46755 54.8236 9.12021 55.9795 9.15189C59.5432 9.15189 61.3249 10.8993 61.3249 14.3941V22.6295H59.0116L58.7116 20.8954C58.249 21.5349 57.6328 22.0478 56.92 22.3868C56.2072 22.7259 55.4206 22.8801 54.6325 22.8355H54.5944C53.5267 22.873 52.4836 22.5087 51.6715 21.8145C51.2689 21.4306 50.956 20.9628 50.7553 20.4442C50.5543 19.9256 50.4703 19.3691 50.5093 18.8143H50.5072ZM53.4004 18.8143C53.3854 19.0335 53.4184 19.2534 53.497 19.4586C53.5756 19.6637 53.6983 19.8492 53.8561 20.0022C54.0139 20.1551 54.2029 20.2718 54.4105 20.344C54.6181 20.4162 54.8389 20.4423 55.0576 20.4204C55.9513 20.4347 56.8195 20.1217 57.4987 19.5404C57.8494 19.2321 58.1254 18.8481 58.3057 18.4173C58.4863 17.9866 58.5661 17.5205 58.5397 17.0542V16.7542H56.1397C55.4305 16.7158 54.7285 16.9096 54.1396 17.3063C53.9026 17.4765 53.7115 17.7023 53.5825 17.9639C53.4535 18.2255 53.3911 18.5148 53.4004 18.8063",
    fill: "white"
  }), _jsx("path", {
    d: "M64.4258 22.6296V9.36999H67.2179V11.5801C67.6262 10.8388 68.2253 10.2201 68.9531 9.78801C69.6101 9.38199 70.3649 9.16215 71.1371 9.15198C71.7137 9.14766 72.2855 9.25674 72.8201 9.47301L72.3443 11.9781C71.8793 11.6999 71.3444 11.561 70.8032 11.5781C70.2818 11.6046 69.7763 11.7651 69.335 12.0442C68.894 12.3233 68.5322 12.7115 68.285 13.1712C67.4936 14.5472 67.1225 16.1249 67.2179 17.7094V22.6296H64.4258Z",
    fill: "white"
  }), _jsx("path", {
    d: "M73.5277 18.8143C73.5052 18.1937 73.6381 17.5773 73.9138 17.0209C74.1898 16.4646 74.6002 15.9859 75.1078 15.6282C76.3357 14.8063 77.7952 14.401 79.2712 14.4721H81.558V13.8941C81.5841 13.566 81.5319 13.2365 81.4056 12.9326C81.2793 12.6287 81.0828 12.3591 80.832 12.146C80.2951 11.7524 79.6402 11.5532 78.9751 11.581C78.4258 11.5542 77.8837 11.7134 77.4361 12.0327C76.9882 12.3521 76.6615 12.813 76.5079 13.3411L74.0029 12.8141C74.2531 11.7405 74.8795 10.7921 75.769 10.1409C76.7092 9.46755 77.8441 9.12021 79 9.15189C82.5636 9.15189 84.3453 10.8993 84.3453 14.3941V22.6295H82.032L81.732 20.8954C81.2694 21.5349 80.6533 22.0478 79.9405 22.3868C79.2277 22.7259 78.4411 22.8801 77.653 22.8355H77.6149C76.5472 22.8729 75.5041 22.5086 74.692 21.8145C74.2894 21.4306 73.9765 20.9628 73.7758 20.4442C73.5748 19.9256 73.4908 19.3691 73.5298 18.8143H73.5277ZM76.4209 18.8143C76.4059 19.0335 76.4389 19.2534 76.5175 19.4586C76.5961 19.6637 76.7188 19.8492 76.8766 20.0022C77.0344 20.1551 77.2234 20.2718 77.431 20.344C77.6386 20.4162 77.8594 20.4423 78.0781 20.4204C78.9718 20.4347 79.84 20.1217 80.5192 19.5404C80.8699 19.2321 81.1458 18.8481 81.3264 18.4173C81.5067 17.9866 81.5865 17.5205 81.5601 17.0542V16.7542H79.1602C78.451 16.7159 77.749 16.9097 77.1601 17.3063C76.9231 17.4765 76.732 17.7023 76.603 17.9639C76.474 18.2255 76.4116 18.5148 76.4209 18.8063",
    fill: "white"
  }), _jsx("path", {
    d: "M87.4468 22.6308V4H90.235V10.8353C90.6415 10.3278 91.165 9.92662 91.7608 9.66625C92.3884 9.34189 93.0819 9.16603 93.7878 9.15223C94.6191 9.09868 95.4516 9.23758 96.2205 9.5581C96.9894 9.87862 97.674 10.3722 98.2212 11.0003C99.3012 12.4359 99.8397 14.2067 99.7422 16.0005C99.8532 17.805 99.3186 19.5903 98.2341 21.0367C97.686 21.6519 97.0047 22.1338 96.2424 22.4458C95.4798 22.7577 94.656 22.8915 93.834 22.8368C93.061 22.8443 92.2981 22.6586 91.615 22.2968C91.0507 22.0358 90.5707 21.6218 90.2299 21.1017V22.6308H87.4468ZM91.0219 12.7764C90.3496 13.7124 90.0166 14.8497 90.0778 16.0005C90.016 17.1517 90.349 18.2895 91.0219 19.2257C91.3108 19.5967 91.6807 19.8971 92.1028 20.1042C92.5252 20.3112 92.989 20.4194 93.4593 20.4207C93.9297 20.4219 94.3941 20.3162 94.8174 20.1114C95.2407 19.9066 95.6121 19.6082 95.9031 19.2387C96.5772 18.2981 96.9117 17.1562 96.8511 16.0005C96.9126 14.8495 96.5799 13.7119 95.9079 12.7754C95.6181 12.404 95.2473 12.1036 94.824 11.897C94.4004 11.6904 93.9354 11.583 93.4644 11.583C92.9934 11.583 92.5284 11.6904 92.1049 11.897C91.6816 12.1036 91.3108 12.404 91.021 12.7754",
    fill: "white"
  }), _jsx("path", {
    d: "M101.592 15.9745C101.542 14.1924 102.176 12.4588 103.365 11.1303C103.988 10.5032 104.729 10.0055 105.545 9.66587C106.361 9.32624 107.236 9.15137 108.119 9.15137C109.003 9.15137 109.878 9.32624 110.694 9.66587C111.51 10.0055 112.251 10.5032 112.874 11.1303C114.063 12.4684 114.697 14.2099 114.647 15.9995C114.698 17.7875 114.063 19.5273 112.874 20.8627C112.274 21.5193 111.537 22.0362 110.716 22.377C109.894 22.7177 109.008 22.874 108.119 22.8348C108.118 22.8345 108.116 22.8345 108.114 22.8351C108.112 22.8356 108.111 22.8365 108.109 22.8378C108.108 22.8391 108.107 22.8407 108.107 22.8424C108.106 22.8442 108.106 22.846 108.106 22.8478C107.22 22.8843 106.336 22.7262 105.517 22.3844C104.698 22.0427 103.963 21.5256 103.365 20.8698C102.175 19.5201 101.541 17.7686 101.592 15.9695V15.9745ZM105.446 19.1047C105.745 19.5289 106.146 19.8713 106.612 20.1004C107.078 20.3294 107.594 20.4379 108.112 20.4157C108.632 20.4375 109.148 20.3301 109.616 20.1032C110.083 19.8763 110.487 19.537 110.791 19.1156C111.419 18.1979 111.755 17.1119 111.755 16C111.755 14.8881 111.419 13.8021 110.791 12.8844C110.473 12.4778 110.066 12.1491 109.602 11.9232C109.137 11.6974 108.628 11.5803 108.111 11.5809C107.595 11.5814 107.085 11.6997 106.621 11.9266C106.157 12.1535 105.751 12.4831 105.433 12.8904C104.784 13.7848 104.45 14.869 104.482 15.9735C104.453 17.0957 104.792 18.1968 105.446 19.1087",
    fill: "white"
  }), _jsx("path", {
    d: "M120.069 4H117.281V22.6308H120.069V4Z",
    fill: "white"
  }), _jsx("path", {
    d: "M13.292 23.7872C12.41 23.7872 11.5389 23.7452 10.6919 23.6652C13.9241 27.2073 17.5852 28.8714 19.9413 27.5103C21.4414 26.6433 22.1414 24.7022 22.0824 22.2251C19.278 23.2952 16.2964 23.8252 13.295 23.7872",
    fill: "white"
  }), _jsx("path", {
    d: "M13.2918 8.21003C14.1748 8.21003 15.0449 8.25203 15.8919 8.33204C12.6638 4.79088 9.00258 3.12681 6.64649 4.48687C5.1464 5.3539 4.44638 7.295 4.50539 9.7721C7.30983 8.70194 10.2913 8.17196 13.2928 8.21003",
    fill: "white"
  }), _jsx("path", {
    d: "M23.0781 11.502C22.6025 14.4656 21.5706 17.3124 20.037 19.8924C19.5939 20.6604 19.1219 21.3925 18.6289 22.0865C23.3121 21.0584 26.5843 18.7204 26.5843 15.9982C26.5843 14.2642 25.2542 12.6851 23.0781 11.498",
    fill: "white"
  }), _jsx("path", {
    d: "M6.54729 12.1057C6.9903 11.3377 7.46232 10.6056 7.95534 9.91162C3.27114 10.9397 0 13.2788 0 15.9999C0 17.7339 1.32906 19.313 3.50616 20.5001C3.98163 17.5362 5.01348 14.689 6.54729 12.1087",
    fill: "white"
  }), _jsx("path", {
    d: "M20.0358 12.1041C20.4769 12.8681 20.8759 13.6432 21.2359 14.4202C22.684 9.84801 22.2939 5.84783 19.9378 4.48778C18.4378 3.61974 16.4017 3.98275 14.2876 5.27681C16.6175 7.16962 18.568 9.48654 20.0358 12.1051",
    fill: "white"
  }), _jsx("path", {
    d: "M6.54711 19.8932C6.10611 19.1292 5.70708 18.3542 5.34705 17.5771C3.89499 22.1474 4.28502 26.1495 6.64212 27.5106C8.14218 28.3786 10.1783 28.0156 12.2923 26.7215C9.96239 24.8287 8.01195 22.5118 6.54411 19.8932",
    fill: "white"
  })]
}), 'ParabolMonoLogo', 'CompanyMono');
export default ParabolMonoLogo;