/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VeronicaBeardMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 265,
  height: 32,
  viewBox: "0 0 265 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M7.14678 18.6297C7.05274 19.0058 6.86467 19.382 6.77063 19.8521C6.60412 19.5237 6.47783 19.1764 6.39449 18.8177L2.72706 8.28564H0L5.6422 23.7076H7.711L13.4472 8.28564H10.8142L7.14678 18.6297ZM12.5069 8.9439L7.24082 23.0494H6.11238L0.846329 9.03794H2.16284L5.73623 18.8177L5.83027 19.0058C6.09284 19.7448 6.31262 20.4984 6.48853 21.2627H7.14678C7.24137 20.4191 7.46331 19.5948 7.80504 18.8177L11.2844 8.9439H12.5069Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.9102 23.7076H30.2794V21.2627H24.5432V16.937H30.2794V14.5861H24.5432V10.6366H30.2794V8.28564H21.9102V23.7076ZM22.5684 9.03794H29.6212V10.0723H23.8849V15.3384H29.6212V16.3728H23.8849V22.015H29.6212V23.0494H22.5684V9.03794Z",
    fill: "none"
  }), _jsx("path", {
    d: "M50.3095 13.0815C50.3095 10.0723 48.0526 8.28564 44.3852 8.28564H40.7178V23.7076H43.2568V18.9118L48.0526 23.7076H51.3439L45.7958 17.9714C46.4134 17.9472 47.0201 17.8015 47.5814 17.5425C48.1426 17.2836 48.6473 16.9165 49.0665 16.4623C49.4857 16.0082 49.8113 15.4758 50.0246 14.8957C50.2379 14.3156 50.3347 13.6991 50.3095 13.0815ZM49.7453 23.0494H48.2407L42.5985 17.3132V23.0494H41.282V8.9439H44.2912C47.6765 8.9439 49.5572 10.4485 49.5572 13.0815C49.5777 13.6348 49.4854 14.1864 49.2859 14.7029C49.0864 15.2193 48.7839 15.6898 48.3969 16.0856C48.0098 16.4815 47.5463 16.7944 47.0344 17.0055C46.5226 17.2165 45.9732 17.3212 45.4196 17.3132H44.1971L49.7453 23.0494Z",
    fill: "none"
  }), _jsx("path", {
    d: "M48.3349 13.176C48.3349 12.0475 47.7706 10.0728 44.1973 10.0728H42.5986V16.5613H44.2913C46.8303 16.5613 48.2408 15.3388 48.3349 13.176ZM43.1629 15.903V10.731H44.1032C46.4541 10.731 47.5826 11.5773 47.5826 13.176C47.5826 15.4328 45.7018 15.903 44.1973 15.903H43.1629Z",
    fill: "none"
  }), _jsx("path", {
    d: "M242.52 13.0815C242.52 10.0723 240.264 8.28564 236.596 8.28564H232.929V23.7076H235.468V18.9118L240.264 23.7076H243.555L238.007 17.9714C238.624 17.9472 239.231 17.8015 239.792 17.5425C240.354 17.2836 240.858 16.9165 241.277 16.4623C241.697 16.0082 242.022 15.4758 242.236 14.8957C242.449 14.3156 242.546 13.6991 242.52 13.0815ZM241.956 23.0494H240.452L234.715 17.3132V23.0494H233.493V8.9439H236.502C239.887 8.9439 241.768 10.4485 241.768 13.0815C241.789 13.6348 241.696 14.1864 241.497 14.7029C241.297 15.2193 240.995 15.6898 240.608 16.0856C240.221 16.4815 239.757 16.7944 239.245 17.0055C238.734 17.2165 238.184 17.3212 237.631 17.3132H236.408L241.956 23.0494Z",
    fill: "none"
  }), _jsx("path", {
    d: "M240.546 13.176C240.546 12.0475 239.982 10.0728 236.408 10.0728H234.81V16.5613H236.502C239.041 16.5613 240.452 15.3388 240.546 13.176ZM235.468 10.731H236.408C238.759 10.731 239.888 11.5773 239.888 13.176C239.888 15.4328 238.007 15.903 236.502 15.903H235.468V10.731Z",
    fill: "none"
  }), _jsx("path", {
    d: "M66.5781 9.69648C65.8086 9.67505 65.0431 9.81454 64.3306 10.106C63.6181 10.3975 62.9743 10.8345 62.4405 11.3891C61.2807 12.6421 60.6419 14.2897 60.6538 15.9969C60.6305 16.8452 60.7765 17.6895 61.0832 18.4807C61.39 19.2719 61.8514 19.9939 62.4405 20.6047C62.9688 21.1665 63.6116 21.6084 64.3255 21.9004C65.0393 22.1924 65.8075 22.3278 66.5781 22.2974C67.3616 22.3188 68.1412 22.1797 68.8689 21.8886C69.5966 21.5975 70.2571 21.1606 70.8097 20.6047C71.9695 19.3518 72.6083 17.7042 72.5964 15.9969C72.624 15.1482 72.48 14.3026 72.173 13.5108C71.866 12.7191 71.4023 11.9974 70.8097 11.3891C70.2571 10.8333 69.5966 10.3964 68.8689 10.1053C68.1412 9.81419 67.3616 9.67507 66.5781 9.69648ZM70.3395 20.2286C69.8565 20.7336 69.2708 21.1292 68.622 21.3887C67.9731 21.6483 67.2762 21.7657 66.5781 21.7332C65.8941 21.7645 65.2115 21.6461 64.5781 21.3863C63.9446 21.1264 63.3755 20.7313 62.9107 20.2286C62.3785 19.6827 61.9624 19.0346 61.6877 18.3235C61.4129 17.6123 61.2851 16.8529 61.312 16.091C61.2851 15.3291 61.4129 14.5696 61.6877 13.8585C61.9624 13.1473 62.3785 12.4993 62.9107 11.9534C63.3755 11.4507 63.9446 11.0556 64.5781 10.7957C65.2115 10.5358 65.8941 10.4174 66.5781 10.4488C67.2751 10.4251 67.9694 10.5467 68.617 10.8057C69.2645 11.0647 69.8511 11.4555 70.3395 11.9534C71.3968 13.0681 71.9713 14.555 71.9382 16.091C71.9651 16.8529 71.8373 17.6123 71.5625 18.3235C71.2878 19.0346 70.8717 19.6827 70.3395 20.2286Z",
    fill: "none"
  }), _jsx("path", {
    d: "M66.5783 8.00376C65.5555 7.97156 64.5367 8.14656 63.5832 8.51825C62.6297 8.88994 61.7613 9.45063 61.0301 10.1666C60.2654 10.9297 59.663 11.8396 59.259 12.8415C58.855 13.8435 58.6578 14.9168 58.6792 15.9969C58.6449 17.0786 58.8362 18.1557 59.2409 19.1595C59.6457 20.1633 60.255 21.0718 61.0301 21.8271C61.7613 22.5431 62.6297 23.1038 63.5832 23.4755C64.5367 23.8472 65.5555 24.0222 66.5783 23.99C67.6036 24.044 68.6287 23.8791 69.5853 23.5062C70.542 23.1332 71.4082 22.5609 72.1264 21.8271C72.8911 21.064 73.4936 20.1541 73.8976 19.1522C74.3016 18.1503 74.4988 17.077 74.4774 15.9969C74.5117 14.9151 74.3204 13.838 73.9156 12.8343C73.5109 11.8305 72.9016 10.922 72.1264 10.1666C71.3953 9.45063 70.5268 8.88994 69.5734 8.51825C68.6199 8.14656 67.6011 7.97156 66.5783 8.00376ZM71.6563 21.357C70.2694 22.6257 68.4579 23.3293 66.5783 23.3293C64.6987 23.3293 62.8871 22.6257 61.5003 21.357C60.7938 20.6579 60.2375 19.8219 59.8656 18.9002C59.4937 17.9785 59.3139 16.9905 59.3375 15.9969C59.3231 15.0045 59.5071 14.0192 59.8785 13.0988C60.2498 12.1783 60.8012 11.3413 61.5003 10.6368C62.8871 9.36804 64.6987 8.6644 66.5783 8.6644C68.4579 8.6644 70.2694 9.36804 71.6563 10.6368C72.3697 11.3307 72.9305 12.1658 73.3029 13.0887C73.6753 14.0117 73.8511 15.0021 73.8191 15.9969C73.8334 16.9893 73.6495 17.9746 73.2781 18.895C72.9067 19.8154 72.3553 20.6524 71.6563 21.357Z",
    fill: "none"
  }), _jsx("path", {
    d: "M106.167 23.7076H108.706V8.28564H106.167V23.7076ZM106.731 8.9439H107.954V23.0494H106.731V8.9439Z",
    fill: "none"
  }), _jsx("path", {
    d: "M129.112 20.1343C128.627 20.6364 128.041 21.0301 127.393 21.2894C126.744 21.5487 126.048 21.6678 125.351 21.6389C124.655 21.6499 123.965 21.5226 123.319 21.2643C122.673 21.006 122.085 20.6219 121.589 20.1343C120.532 19.0196 119.958 17.5327 119.991 15.9967C119.958 14.4608 120.532 12.9738 121.589 11.8591C122.078 11.3613 122.664 10.9705 123.312 10.7115C123.959 10.4525 124.654 10.3309 125.351 10.3545C126.709 10.3349 128.023 10.8402 129.018 11.7651L129.3 12.0472L130.993 10.1665L130.711 9.97839C129.183 8.67207 127.231 7.96999 125.221 8.00464C123.212 8.03929 121.284 8.80826 119.803 10.1665C119.038 10.9296 118.435 11.8395 118.031 12.8414C117.627 13.8433 117.43 14.9166 117.452 15.9967C117.417 17.0785 117.609 18.1556 118.013 19.1593C118.418 20.1631 119.027 21.0716 119.803 21.827C120.534 22.543 121.402 23.1037 122.356 23.4753C123.309 23.847 124.328 24.022 125.351 23.9898C127.425 24.0381 129.441 23.299 130.993 21.921L131.181 21.733L129.394 19.8522L129.112 20.1343ZM125.351 23.3316C124.414 23.3659 123.481 23.2084 122.608 22.8688C121.735 22.5292 120.94 22.0147 120.273 21.3568C119.559 20.6629 118.999 19.8278 118.626 18.9048C118.254 17.9819 118.078 16.9914 118.11 15.9967C118.096 15.0043 118.28 14.019 118.651 13.0986C119.022 12.1782 119.574 11.3412 120.273 10.6366C120.94 9.97879 121.735 9.46427 122.608 9.12468C123.481 8.78508 124.414 8.62759 125.351 8.66187C127.042 8.62608 128.696 9.15557 130.053 10.1665L129.3 11.1068C128.197 10.1753 126.794 9.67433 125.351 9.69628C124.567 9.67486 123.788 9.81398 123.06 10.1051C122.332 10.3962 121.672 10.8331 121.119 11.3889C119.959 12.6418 119.32 14.2895 119.332 15.9967C119.32 17.704 119.959 19.3516 121.119 20.6045C121.672 21.1604 122.332 21.5973 123.06 21.8884C123.788 22.1795 124.567 22.3186 125.351 22.2972C126.092 22.3385 126.834 22.2262 127.53 21.9673C128.226 21.7083 128.86 21.3084 129.394 20.7926L130.241 21.6389C128.877 22.7901 127.134 23.3934 125.351 23.3316Z",
    fill: "none"
  }), _jsx("path", {
    d: "M145.099 8.28564L138.798 23.7076H141.525L142.842 20.3223H149.33L150.647 23.7076H153.562L147.168 8.28564H145.099ZM151.023 23.0494L149.801 19.6641H142.372L141.055 23.0494H139.739L145.475 8.9439H146.603L152.528 23.0494H151.023Z",
    fill: "none"
  }), _jsx("path", {
    d: "M145.757 10.8247C145.475 11.483 142.748 18.5357 142.748 18.5357H149.236C149.236 18.5357 146.603 11.671 146.321 10.8247H145.757ZM143.688 17.9715L145.851 12.5174C145.847 12.3857 145.879 12.2555 145.945 12.1412C146.039 12.3293 146.133 12.4233 146.133 12.5174L148.296 17.9715H143.688Z",
    fill: "none"
  }), _jsx("path", {
    d: "M216.754 8.28564L210.454 23.7076H213.181L214.497 20.3223H220.986L222.302 23.7076H225.217L218.823 8.28564H216.754ZM222.772 23.0494L221.55 19.6641H214.121L212.805 23.0494H211.488L217.224 8.9439H218.353L224.277 23.0494H222.772Z",
    fill: "none"
  }), _jsx("path", {
    d: "M217.506 10.8247C217.224 11.483 214.497 18.5357 214.497 18.5357H220.986C220.986 18.5357 218.353 11.671 218.07 10.8247H217.506ZM215.437 17.9715L217.6 12.5174C217.596 12.3857 217.629 12.2555 217.694 12.1412C217.788 12.3293 217.882 12.4233 217.882 12.5174L220.045 17.9715H215.437Z",
    fill: "none"
  }), _jsx("path", {
    d: "M179.61 16.373H177.259V22.0152H179.516C182.713 22.0152 183.183 20.2285 183.183 19.1941C183.183 17.8776 182.619 16.373 179.61 16.373ZM179.422 21.357H177.823V17.0313H179.516C181.49 17.0313 182.431 17.7836 182.431 19.1941C182.431 20.6047 181.396 21.357 179.422 21.357Z",
    fill: "none"
  }), _jsx("path", {
    d: "M182.243 12.7056C182.243 11.4831 181.679 9.97852 179.045 9.97852H177.259V15.3386H179.14C181.02 15.3386 182.243 14.3042 182.243 12.7056ZM177.823 14.6803V10.6368H178.951C181.208 10.6368 181.49 11.8592 181.49 12.7056C181.49 13.9281 180.644 14.6803 179.045 14.6803H177.823Z",
    fill: "none"
  }), _jsx("path", {
    d: "M182.619 15.4326C183.06 15.1072 183.418 14.6819 183.663 14.1915C183.909 13.7012 184.034 13.1597 184.03 12.6115C184.04 11.8719 183.853 11.1428 183.49 10.4986C183.127 9.85435 182.599 9.31796 181.961 8.94404C180.754 8.39568 179.426 8.16891 178.105 8.28578H175.284V23.7078H179.986C182.901 23.7078 185.158 21.827 185.064 19.2881C185.098 18.4729 184.881 17.6669 184.444 16.9779C184.008 16.2889 183.371 15.7498 182.619 15.4326ZM179.986 23.0495H175.942V9.03807H178.105C179.293 8.95568 180.484 9.14877 181.585 9.60229C182.133 9.91215 182.588 10.3636 182.902 10.9095C183.217 11.4554 183.379 12.0756 183.371 12.7055C183.364 13.2348 183.211 13.7519 182.929 14.1998C182.647 14.6478 182.247 15.0093 181.773 15.2445L181.114 15.6206L181.867 15.8087C182.624 16.0102 183.29 16.4657 183.752 17.099C184.214 17.7323 184.445 18.505 184.406 19.2881C184.406 21.4509 182.525 23.0495 179.986 23.0495Z",
    fill: "none"
  }), _jsx("path", {
    d: "M193.998 23.7076H202.367V21.2627H196.631V16.937H202.367V14.5861H196.631V10.6366H202.367V8.28564H193.998V23.7076ZM194.656 9.03794H201.709V10.0723H195.972V15.3384H201.709V16.3728H195.972V22.015H201.709V23.0494H194.656V9.03794Z",
    fill: "none"
  }), _jsx("path", {
    d: "M255.967 8.28564H252.582V23.7076H255.967C261.704 23.7076 264.713 21.0746 264.807 15.9966C264.901 10.9187 261.704 8.28564 255.967 8.28564ZM255.967 23.0494H253.24V8.9439H255.967C261.327 8.9439 264.149 11.3889 264.149 15.9966C264.149 20.6044 261.327 23.0494 255.967 23.0494Z",
    fill: "none"
  }), _jsx("path", {
    d: "M256.25 10.0728H254.557V22.0154H256.25C260.575 22.0154 262.832 19.9466 262.832 15.9971C262.832 12.0475 260.575 10.0728 256.25 10.0728ZM256.25 21.3571H255.121V10.825H256.25C260.199 10.825 262.174 12.6117 262.174 16.0911C262.174 19.5705 260.199 21.3571 256.25 21.3571Z",
    fill: "none"
  }), _jsx("path", {
    d: "M93.0964 18.7237L89.2409 13.3636L85.7616 8.28564H83.4106V23.7076H85.9496V13.0815L89.9932 18.7237L93.5666 23.7076H95.6354V8.28564H93.0964V18.7237ZM93.7547 9.03794H94.9771V23.0494H93.9427L90.5292 18.2723L85.2914 11.0127V23.0494H84.0689V9.03794H85.3854L88.7707 13.7398L93.7547 20.6985V9.03794Z",
    fill: "none"
  })]
}), 'VeronicaBeardMonoLogo', 'CompanyMono');
export default VeronicaBeardMonoLogo;