/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MicrosoftMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 132,
  height: 32,
  viewBox: "0 0 132 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M0.108887 1.99902H13.4284V15.3186H0.108887V1.99902Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.7886 1.99902H28.1094C28.1094 6.4393 28.1094 10.8791 28.1094 15.3186H14.7898C14.7898 10.8783 14.7898 6.43845 14.7898 1.99902H14.7886Z",
    fill: "none"
  }), _jsx("path", {
    d: "M0.108887 16.6782C4.54916 16.6782 8.98901 16.6782 13.4284 16.6782C13.4284 21.1185 13.4284 25.5583 13.4284 29.9978H0.108887V16.6782Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.7898 16.6792H28.1094V29.9988H14.7886C14.7886 25.5585 14.7886 21.1186 14.7886 16.6792H14.7898Z",
    fill: "none"
  }), _jsx("path", {
    d: "M128.377 12.3604C128.355 11.1587 128.377 9.95954 128.367 8.75407C127.417 9.04091 126.471 9.34544 125.516 9.61711C125.493 10.5294 125.516 11.4417 125.505 12.3541C124.1 12.3541 122.694 12.3541 121.291 12.3541C121.308 11.6351 121.24 10.911 121.344 10.1971C121.43 9.63354 121.756 9.07124 122.304 8.85264C122.996 8.56959 123.77 8.75787 124.428 9.04723C124.428 8.22463 124.428 7.40456 124.422 6.57816C123.038 6.19909 121.452 6.27237 120.224 7.07097C119.433 7.59041 118.856 8.37783 118.599 9.28858C118.293 10.2856 118.449 11.3369 118.401 12.3591C117.734 12.3591 117.069 12.3477 116.404 12.3591C116.404 13.1286 116.404 13.8994 116.404 14.6677C117.073 14.6816 117.741 14.6677 118.408 14.6778C118.403 17.916 118.403 21.1542 118.408 24.3923H121.288C121.288 21.1533 121.288 17.9143 121.288 14.6753C122.692 14.6753 124.097 14.6753 125.503 14.6753C125.503 16.74 125.503 18.8034 125.503 20.8669C125.516 21.8285 125.68 22.8558 126.317 23.6152C126.868 24.2875 127.743 24.5945 128.592 24.6577C129.489 24.7355 130.392 24.5968 131.225 24.2533V21.898C130.593 22.32 129.721 22.4881 129.035 22.104C128.625 21.8765 128.465 21.3926 128.412 20.9541C128.325 20.2124 128.381 19.463 128.365 18.7188C128.365 17.3731 128.365 16.0286 128.365 14.6841C129.32 14.6841 130.274 14.6576 131.228 14.6968V12.3604C130.277 12.3604 129.327 12.3503 128.377 12.3604Z",
    fill: "none"
  }), _jsx("path", {
    d: "M58.545 7.24166C57.7451 7.3339 57.0628 8.06047 57.0843 8.87675C57.0514 9.65134 57.6743 10.3463 58.4224 10.4815C59.1426 10.6395 59.9703 10.3337 60.3405 9.67787C60.5017 9.38351 60.5688 9.04685 60.5329 8.71318C60.497 8.37952 60.3597 8.06486 60.1396 7.81154C59.9427 7.59079 59.6935 7.42295 59.4149 7.3234C59.1363 7.22384 58.8372 7.19574 58.545 7.24166Z",
    fill: "none"
  }), _jsx("path", {
    d: "M50.6197 7.60811C48.9556 11.7514 47.2535 15.8783 45.5767 20.0166C45.2267 19.1953 44.9146 18.3575 44.581 17.5286C43.2762 14.218 41.9683 10.9082 40.6576 7.59926H36.4688C36.4688 13.1987 36.4688 18.8006 36.4688 24.4051C37.3823 24.4051 38.2959 24.4051 39.2108 24.4051C39.2108 21.8981 39.2108 19.3911 39.2108 16.8842C39.2209 15.0734 39.1905 13.2614 39.2259 11.4507C39.279 11.5341 39.3257 11.6188 39.3712 11.7034C41.0796 15.9365 42.7754 20.1758 44.4938 24.4051C45.1838 24.3937 45.8737 24.4164 46.5636 24.3924C48.2189 20.1581 49.9159 15.9415 51.5801 11.711C51.6244 11.6183 51.6751 11.5288 51.7317 11.4431C51.7444 11.6289 51.7507 11.8133 51.7494 11.9991C51.7494 16.1323 51.7494 20.2643 51.7494 24.3962C52.7186 24.4101 53.6878 24.3962 54.6557 24.3962C54.6557 18.7977 54.6557 13.1987 54.6557 7.59926C53.3062 7.60432 51.9629 7.58662 50.6197 7.60811Z",
    fill: "none"
  }), _jsx("path", {
    d: "M70.7524 12.3606C69.3547 11.9623 67.874 11.9579 66.4739 12.348C65.1786 12.7071 64.0485 13.5053 63.277 14.606C62.3836 15.9025 62.0677 17.5224 62.1398 19.0753C62.2093 20.5689 62.7981 22.06 63.8823 23.1062C64.8805 24.0754 66.2566 24.5973 67.6351 24.6705C68.9834 24.7375 70.3948 24.5556 71.5725 23.8555C71.5624 22.9862 71.5725 22.1156 71.5725 21.2462C70.8171 21.817 69.9269 22.1826 68.9884 22.3076C68.0382 22.3796 67.0198 22.1509 66.2932 21.5052C65.5932 20.9088 65.2217 20.0104 65.1294 19.112C65.0031 17.9621 65.1711 16.7112 65.8876 15.7761C66.1715 15.4004 66.5306 15.088 66.942 14.8589C67.3534 14.6298 67.8081 14.489 68.277 14.4455C69.4585 14.2977 70.6362 14.7475 71.5624 15.4564C71.5712 14.5378 71.5624 13.6191 71.5712 12.7018C71.3138 12.554 71.0387 12.4393 70.7524 12.3606Z",
    fill: "none"
  }), _jsx("path", {
    d: "M90.1152 12.6258C88.8327 12.0421 87.3732 11.9637 85.9909 12.1394C84.539 12.3226 83.1263 13.0062 82.2001 14.1611C81.3585 15.1947 80.9491 16.5228 80.8708 17.8407C80.7545 19.3937 81.0136 21.0414 81.9259 22.3366C82.7229 23.4529 83.9113 24.228 85.2542 24.5074C86.3665 24.7387 87.5144 24.7387 88.6267 24.5074C89.939 24.2471 91.114 23.5237 91.9373 22.4692C92.722 21.4735 93.115 20.2175 93.2009 18.964C93.2907 17.6802 93.1618 16.3509 92.6273 15.1732C92.1254 14.0484 91.233 13.1434 90.1152 12.6258ZM89.8486 20.7002C89.5766 21.3302 89.0692 21.829 88.4347 22.0902C87.6815 22.3662 86.8612 22.402 86.0869 22.1925C85.6978 22.0847 85.3357 21.8961 85.0244 21.6389C84.713 21.3818 84.4594 21.062 84.28 20.7002C83.8162 19.774 83.7467 18.7037 83.8428 17.6878C83.9299 16.7628 84.2509 15.8126 84.956 15.1758C85.4813 14.7131 86.1517 14.4485 86.8514 14.4277C87.7169 14.3709 88.6394 14.6147 89.261 15.2453C89.927 15.8998 90.1847 16.8488 90.2555 17.7548C90.306 18.7442 90.2656 19.7778 89.8486 20.7002Z",
    fill: "none"
  }), _jsx("path", {
    d: "M100.582 17.8359C99.7355 17.3469 98.7613 17.1144 97.945 16.5723C97.4674 16.2703 97.3322 15.6056 97.5267 15.0989C97.7731 14.5935 98.3645 14.3597 98.9028 14.3408C99.9808 14.3097 101.041 14.6226 101.929 15.2341C101.929 14.3496 101.929 13.4651 101.929 12.5806C100.661 12.0889 99.2846 11.9454 97.9425 12.1649C96.8166 12.3658 95.7122 12.9508 95.0754 13.9263C94.5194 14.7628 94.4436 15.8419 94.6773 16.7997C94.8419 17.4501 95.2212 18.0258 95.7539 18.4336C96.4994 19.006 97.3966 19.3257 98.2394 19.7073C98.6918 19.9195 99.1707 20.1369 99.503 20.5299C99.8518 21.0353 99.7456 21.8743 99.158 22.1725C98.5452 22.467 97.8287 22.4632 97.1704 22.3558C96.2247 22.1838 95.3309 21.7979 94.5573 21.2274C94.5459 22.1548 94.5573 23.0836 94.5573 24.0073C95.4866 24.4017 96.4781 24.6292 97.4863 24.6795C98.8965 24.7313 100.43 24.4571 101.494 23.4614C102.61 22.4758 102.884 20.7295 102.244 19.4078C101.895 18.7002 101.249 18.2099 100.582 17.8359Z",
    fill: "none"
  }), _jsx("path", {
    d: "M113.227 12.7168C111.915 12.0547 110.389 11.9587 108.951 12.1356C107.792 12.2847 106.653 12.7244 105.768 13.4977C104.688 14.4277 104.055 15.7962 103.873 17.195C103.691 18.5938 103.755 20.0672 104.326 21.3775C104.888 22.6738 105.94 23.6947 107.253 24.2168C108.535 24.7223 109.961 24.7779 111.31 24.5593C112.708 24.3388 113.971 23.5998 114.848 22.4895C115.761 21.3358 116.154 19.8435 116.151 18.3891C116.186 17.4126 116.033 16.4385 115.701 15.5195C115.258 14.3073 114.375 13.3065 113.227 12.7168ZM113.101 19.5984C112.991 20.2302 112.771 20.862 112.356 21.3674C111.925 21.8642 111.326 22.186 110.673 22.2721C109.979 22.389 109.265 22.3111 108.612 22.0472C107.919 21.735 107.375 21.1647 107.096 20.4576C106.755 19.6325 106.687 18.7177 106.755 17.8344C106.821 16.9802 107.063 16.102 107.628 15.4336C107.891 15.1308 108.214 14.885 108.575 14.7112C108.937 14.5374 109.33 14.4392 109.731 14.4227C110.543 14.3658 111.413 14.5491 112.038 15.1025C112.616 15.5814 112.934 16.2979 113.081 17.0194C113.24 17.8709 113.248 18.7441 113.103 19.5984H113.101Z",
    fill: "none"
  }), _jsx("path", {
    d: "M78.0155 12.5424C77.2927 12.9518 76.8353 13.6797 76.527 14.429C76.527 13.7391 76.527 13.0504 76.527 12.3617C75.578 12.3617 74.6316 12.3529 73.6826 12.3617C73.6877 16.3741 73.6877 20.3856 73.6826 24.3962H76.5219C76.5219 22.4772 76.5219 20.5583 76.5219 18.6393C76.4992 17.8053 76.5687 16.9423 76.9326 16.1803C77.2687 15.4601 77.9169 14.8321 78.7345 14.7398C79.4164 14.67 80.1021 14.8311 80.6817 15.1972C80.6817 14.2369 80.6817 13.2753 80.6817 12.315C79.8212 12.0256 78.8103 12.0648 78.0155 12.5424Z",
    fill: "none"
  }), _jsx("path", {
    d: "M57.3496 12.3603C57.3496 16.3735 57.3496 20.3858 57.3496 24.3973H60.2091C60.2091 20.3867 60.2028 16.376 60.2091 12.3654C59.2576 12.3477 58.3011 12.3603 57.3496 12.3603Z",
    fill: "none"
  })]
}), 'MicrosoftMonoLogo', 'CompanyMono');
export default MicrosoftMonoLogo;