/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VolleyballWorldMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 173,
  height: 32,
  viewBox: "0 0 173 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M19.1715 5.6836C17.776 12.4832 14.2733 18.9838 8.68446 24.0503C9.59279 25.0845 10.5541 26.0705 11.5671 27C18.2514 20.8605 21.7294 13.3821 23.1331 6.73783C21.8412 6.31661 20.5198 5.96246 19.1715 5.6836ZM18.242 5.50476C16.9042 5.26356 15.5417 5.0953 14.1603 5.00118C12.7802 11.2713 9.56926 16.2824 5.58295 20.011C6.35127 21.1641 7.18077 22.2701 8.06557 23.329C12.8755 18.9862 16.75 12.8079 18.242 5.50476ZM7.9385 16.0447C6.05476 12.8385 4.71462 9.34635 3.96278 5.68478C2.6144 5.96363 1.29308 6.31779 0 6.73783C0.95422 11.236 2.68852 15.4411 5.05701 19.1992C5.94534 18.4085 7.37255 16.7883 7.9385 16.0447ZM11.04 11.0995C10.1281 9.15574 9.43395 7.11199 8.97272 5C7.59022 5.0953 6.22773 5.26238 4.89111 5.50358C5.59707 8.92983 6.8325 12.2031 8.55974 15.2223C9.50807 13.9162 10.3364 12.5373 11.04 11.0995Z",
    fill: "white"
  }), _jsx("path", {
    d: "M28.6333 10.0293H31.4677L36.1012 20.008L40.7346 10.0293H43.569L37.4601 22.9266H34.7616L28.6333 10.0293Z",
    fill: "white"
  }), _jsx("path", {
    d: "M41.6855 18.4641C41.6855 15.6977 43.7725 13.7466 47.0146 13.7466C50.2228 13.7466 52.2758 15.6977 52.2758 18.4641C52.2758 21.1966 50.2228 23.1995 47.0146 23.1995C43.7725 23.1979 41.6855 21.195 41.6855 18.4641ZM49.8135 18.4641C49.8135 16.7169 48.7279 15.7316 46.9791 15.7316C45.2302 15.7316 44.1624 16.7153 44.1624 18.4641C44.1624 20.2292 45.2318 21.2128 46.9791 21.2128C48.7263 21.2128 49.8135 20.2276 49.8135 18.4641Z",
    fill: "white"
  }), _jsx("path", {
    d: "M53.4639 10.0293H55.9747V22.9266H53.4639V10.0293Z",
    fill: "white"
  }), _jsx("path", {
    d: "M57.6729 10.0293H60.1837V22.9266H57.6729V10.0293Z",
    fill: "white"
  }), _jsx("path", {
    d: "M61.3394 18.4641C61.3394 15.6815 63.2403 13.7466 66.4306 13.7466C69.299 13.7466 71.4378 15.3256 71.4378 18.0743V19.1436H63.8696C64.0395 20.4169 64.9552 21.2144 66.5682 21.2144C67.8915 21.2144 68.7409 20.6207 68.9787 19.993H71.4572C71.2711 21.5202 69.5223 23.2011 66.5018 23.2011C63.1724 23.1979 61.3394 21.2128 61.3394 18.4641ZM68.9755 17.597C68.9593 16.3917 67.8381 15.73 66.4646 15.73C65.0393 15.73 63.8858 16.3755 63.8858 17.597H68.9755Z",
    fill: "white"
  }), _jsx("path", {
    d: "M75.1176 22.9102L71.0439 14.0171H73.7085L75.9654 19.5161L76.3391 20.3816H76.3731L76.7128 19.5161L78.9697 14.0171H81.6342L77.8663 22.3165L75.8634 27.0001H73.3688L75.1176 22.9102Z",
    fill: "white"
  }), _jsx("path", {
    d: "M93.278 18.4468C93.278 21.2974 91.3949 23.1984 88.5605 23.1984C87.2533 23.1984 85.828 22.6887 85.1162 21.9089V22.9266H82.6895V10.0293H85.2003V15.1206C85.9979 14.2728 87.2711 13.7454 88.5265 13.7454C91.3771 13.7454 93.278 15.6302 93.278 18.4468ZM90.7995 18.4808C90.7995 16.8338 89.6962 15.7321 87.9991 15.7321C86.3198 15.7321 85.1987 16.6996 85.1987 18.1588V18.8723C85.1987 20.2814 86.336 21.1971 88.0331 21.1971C89.6962 21.1955 90.7995 20.1099 90.7995 18.4808Z",
    fill: "white"
  }), _jsx("path", {
    d: "M94.0601 20.4655C94.0601 19.6178 94.45 18.9205 95.2815 18.4125C96.1972 17.8528 97.6743 17.547 100.593 17.547H101.204V17.0892C101.204 16.1735 100.679 15.6121 99.1853 15.6121C97.54 15.6121 97.0643 16.2576 97.0126 16.7996H94.5859C94.6376 15.2723 95.6035 13.7451 99.2193 13.7451C102.002 13.7451 103.751 14.7288 103.751 17.6651V20.7034V21.8569V22.9263H101.408C101.408 22.1804 101.408 22.2468 101.392 21.6708H101.358C100.679 22.4846 99.4231 23.1123 97.6921 23.1123C95.0437 23.1139 94.0601 21.8407 94.0601 20.4655ZM101.238 20.0077V18.9383H100.509C98.3877 18.9383 96.5709 19.2441 96.5709 20.3296C96.5709 21.0593 97.2666 21.4152 98.488 21.4152C100.357 21.4168 101.238 20.5335 101.238 20.0077Z",
    fill: "white"
  }), _jsx("path", {
    d: "M105.209 10.0293H107.72V22.9266H105.209V10.0293Z",
    fill: "white"
  }), _jsx("path", {
    d: "M109.419 10.0293H111.93V22.9266H109.419V10.0293Z",
    fill: "white"
  }), _jsx("path", {
    d: "M117.413 9.89307H120.247L123.132 19.8718L126.78 9.89307H129.988L133.654 19.8718L136.522 9.89307H139.357L135.131 22.7903H132.314L128.394 12.5058L124.457 22.7903H121.655L117.413 9.89307Z",
    fill: "white"
  }), _jsx("path", {
    d: "M138.61 18.3284C138.61 15.5619 140.697 13.6108 143.939 13.6108C147.147 13.6108 149.2 15.5619 149.2 18.3284C149.2 21.0609 147.147 23.0638 143.939 23.0638C140.698 23.0621 138.61 21.0593 138.61 18.3284ZM146.739 18.3284C146.739 16.5812 145.654 15.5959 143.905 15.5959C142.158 15.5959 141.088 16.5795 141.088 18.3284C141.088 20.0934 142.158 21.0771 143.905 21.0771C145.654 21.0771 146.739 20.0934 146.739 18.3284Z",
    fill: "white"
  }), _jsx("path", {
    d: "M150.423 13.8808H152.833V15.5601C153.257 14.8645 153.919 14.2367 154.7 13.9472C155.294 13.7271 155.888 13.6592 156.703 13.6592V16.1878H156.33C154.259 16.1878 152.935 17.4271 152.935 19.2763V22.7885H150.424V13.8808H150.423Z",
    fill: "white"
  }), _jsx("path", {
    d: "M157.687 9.89307H160.199V22.7903H157.687V9.89307Z",
    fill: "white"
  }), _jsx("path", {
    d: "M172.451 9.89307V22.7903H170.007V21.7727C169.293 22.5541 167.886 23.0621 166.578 23.0621C163.762 23.0621 161.861 21.1612 161.861 18.3106C161.861 15.494 163.762 13.6092 166.63 13.6092C167.886 13.6092 169.141 14.135 169.94 14.9843V9.89307H172.451ZM169.939 17.9207C169.939 16.5294 168.835 15.6121 167.138 15.6121C165.459 15.6121 164.338 16.6814 164.338 18.3106C164.338 19.9737 165.457 21.0593 167.172 21.0593C168.835 21.0593 169.939 20.108 169.939 18.6325V17.9207Z",
    fill: "white"
  })]
}), 'VolleyballWorldMonoLogo', 'CompanyMono');
export default VolleyballWorldMonoLogo;