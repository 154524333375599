/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PeerspaceMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 110,
  height: 32,
  viewBox: "0 0 110 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M28.6747 14.7623C28.6747 15.1384 28.748 15.4924 28.8928 15.8258C29.0375 16.1582 29.236 16.4482 29.4865 16.6952C29.7376 16.9407 30.0297 17.1342 30.3709 17.2815C30.7042 17.4248 31.0733 17.4972 31.468 17.4972C31.8745 17.4972 32.2396 17.4248 32.5687 17.2815C32.8951 17.1342 33.1748 16.9407 33.4061 16.6952C33.6399 16.4482 33.814 16.1582 33.9367 15.8258C34.0578 15.4924 34.1155 15.1384 34.1155 14.7623C34.1155 14.3873 34.0578 14.0309 33.9367 13.6989C33.814 13.3665 33.6399 13.0785 33.4061 12.8305C33.1748 12.5843 32.8951 12.3884 32.5687 12.2445C32.2396 12.0989 31.8745 12.0283 31.468 12.0283C31.0733 12.0283 30.7042 12.0989 30.3709 12.2445C30.0297 12.3884 29.7376 12.5843 29.4865 12.8305C29.236 13.0785 29.0375 13.3665 28.8928 13.6989C28.748 14.0309 28.6747 14.3873 28.6747 14.7623ZM27.8955 11.4208H28.7624V12.5919H28.7905C28.9281 12.3605 29.0983 12.1607 29.2988 11.9921C29.4992 11.8224 29.7212 11.6817 29.9586 11.5715C30.1941 11.4603 30.4425 11.3792 30.7 11.324C30.9628 11.2723 31.2178 11.2466 31.468 11.2466C31.9788 11.2466 32.449 11.3348 32.8812 11.5066C33.3064 11.68 33.6784 11.9242 33.9936 12.2382C34.3063 12.553 34.5498 12.9231 34.7247 13.3523C34.8974 13.7808 34.9849 14.251 34.9849 14.7623C34.9849 15.2742 34.8974 15.7445 34.7247 16.1712C34.5498 16.6035 34.3063 16.9741 33.9936 17.288C33.6784 17.601 33.3064 17.844 32.8812 18.0181C32.449 18.1933 31.9788 18.2776 31.468 18.2776C31.2178 18.2776 30.9628 18.2517 30.7 18.1977C30.4425 18.1461 30.1941 18.0638 29.9586 17.9512C29.7212 17.8425 29.4992 17.7036 29.2988 17.535C29.0983 17.3654 28.9281 17.1649 28.7905 16.9323H28.7624V21.4031H27.8955V11.4208Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M43.8747 14.1987C43.8747 13.899 43.8129 13.6193 43.6939 13.3584C43.5718 13.0982 43.4091 12.8689 43.203 12.6712C42.9941 12.4732 42.7478 12.3168 42.463 12.2018C42.1777 12.0867 41.8775 12.0281 41.5578 12.0281C41.0851 12.0281 40.6923 12.1263 40.3784 12.3168C40.0659 12.5095 39.8168 12.7325 39.6365 12.9815C39.4497 13.2331 39.3219 13.4771 39.2444 13.7143C39.1658 13.9493 39.1291 14.1113 39.1291 14.1987H43.8747ZM44.5384 16.8469C44.2 17.3593 43.7816 17.7238 43.2907 17.9461C42.7923 18.1687 42.2452 18.2774 41.6449 18.2774C41.1346 18.2774 40.6744 18.1931 40.2567 18.0181C39.8417 17.8438 39.4855 17.6009 39.1875 17.2878C38.8878 16.9739 38.657 16.6033 38.4984 16.171C38.3368 15.7444 38.2588 15.2696 38.2588 14.7478C38.2588 14.247 38.3406 13.7841 38.5063 13.3584C38.6723 12.9345 38.9005 12.566 39.2 12.2528C39.4976 11.9392 39.85 11.6937 40.2567 11.5134C40.6623 11.3363 41.1119 11.2466 41.601 11.2466C42.1154 11.2466 42.5652 11.3363 42.9536 11.5134C43.3476 11.6937 43.6749 11.9274 43.938 12.2222C44.2027 12.5178 44.406 12.8522 44.5384 13.2272C44.6766 13.6044 44.7417 13.9871 44.7417 14.3723V14.9789H39.1291C39.1291 15.1635 39.1624 15.3966 39.2383 15.6829C39.3107 15.9662 39.4416 16.244 39.6365 16.513C39.8273 16.7829 40.0853 17.0161 40.4077 17.2089C40.7323 17.4009 41.1479 17.4972 41.6594 17.4972C42.1022 17.4972 42.5319 17.3914 42.9481 17.1783C43.3646 16.9665 43.6805 16.6783 43.9015 16.3105L44.5384 16.8469Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M53.735 14.1987C53.735 13.899 53.6716 13.6193 53.5542 13.3584C53.4306 13.0982 53.2674 12.8689 53.062 12.6712C52.8522 12.4732 52.6061 12.3168 52.3225 12.2018C52.0382 12.0867 51.7366 12.0281 51.4222 12.0281C50.9463 12.0281 50.5542 12.1263 50.2377 12.3168C49.9247 12.5095 49.6776 12.7325 49.4939 12.9815C49.3085 13.2331 49.1821 13.4771 49.1054 13.7143C49.0255 13.9493 48.9857 14.1113 48.9857 14.1987H53.735ZM54.3993 16.8469C54.0625 17.3593 53.6448 17.7238 53.1477 17.9461C52.6489 18.1687 52.103 18.2774 51.503 18.2774C50.994 18.2774 50.5313 18.1931 50.117 18.0181C49.7005 17.8438 49.3461 17.6009 49.0442 17.2878C48.7447 16.9739 48.5165 16.6033 48.359 16.171C48.1985 15.7444 48.1196 15.2696 48.1196 14.7478C48.1196 14.247 48.2001 13.7841 48.3653 13.3584C48.5297 12.9345 48.76 12.566 49.0596 12.2528C49.3568 11.9392 49.711 11.6937 50.117 11.5134C50.5217 11.3363 50.9673 11.2466 51.4613 11.2466C51.9723 11.2466 52.4242 11.3363 52.8147 11.5134C53.2054 11.6937 53.5332 11.9274 53.798 12.2222C54.0628 12.5178 54.2628 12.8522 54.3993 13.2272C54.5332 13.6044 54.6011 13.9871 54.6011 14.3723V14.9789H48.9857C48.9857 15.1635 49.0254 15.3966 49.0959 15.6829C49.1682 15.9662 49.2992 16.244 49.4939 16.513C49.6861 16.7829 49.943 17.0161 50.2687 17.2089C50.5899 17.4009 51.0088 17.4972 51.5184 17.4972C51.9634 17.4972 52.3929 17.3914 52.8072 17.1783C53.2241 16.9665 53.5402 16.6783 53.7608 16.3105L54.3993 16.8469Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M58.863 13.301C58.863 13.1761 58.8586 13.0252 58.8502 12.8462C58.8376 12.6668 58.8293 12.4856 58.8273 12.3041C58.8229 12.1206 58.8149 11.9479 58.8065 11.7882C58.796 11.6309 58.7896 11.5065 58.7896 11.4208H59.6591C59.6701 11.6722 59.6789 11.9132 59.681 12.1443C59.6854 12.375 59.6974 12.5244 59.7182 12.5919C59.9365 12.1956 60.2236 11.8746 60.5719 11.6241C60.9176 11.3734 61.3413 11.2466 61.8441 11.2466C61.93 11.2466 62.0143 11.2544 62.0957 11.2689C62.178 11.2837 62.2628 11.2996 62.3507 11.3199L62.2469 12.1715C62.1333 12.1345 62.0217 12.1135 61.9171 12.1135C61.5382 12.1135 61.2133 12.1748 60.939 12.2951C60.6632 12.4165 60.4398 12.5835 60.2573 12.7956C60.0797 13.0074 59.9483 13.257 59.8611 13.5391C59.7727 13.8249 59.7316 14.1303 59.7316 14.4584V18.1049H58.863V13.301Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M66.1701 16.5765C66.3547 16.9327 66.6254 17.205 66.9817 17.3939C67.3423 17.5824 67.7158 17.6751 68.1096 17.6751C68.2942 17.6751 68.4747 17.6469 68.6607 17.5893C68.8453 17.5309 69.009 17.4494 69.1522 17.3433C69.2962 17.2371 69.4137 17.1083 69.5075 16.9579C69.5977 16.8091 69.6439 16.6379 69.6439 16.4451C69.6439 16.1366 69.5476 15.9055 69.3486 15.7502C69.1507 15.597 68.9083 15.4759 68.615 15.3901C68.3274 15.3032 68.0084 15.2279 67.6582 15.1639C67.3145 15.1019 66.9961 15.0047 66.7048 14.8747C66.4172 14.7457 66.1727 14.5654 65.9758 14.3336C65.7779 14.1004 65.6771 13.775 65.6771 13.3501C65.6771 13.0211 65.7465 12.737 65.8805 12.4949C66.0188 12.2549 66.193 12.0543 66.4087 11.8941C66.627 11.735 66.8706 11.616 67.1418 11.5404C67.4137 11.4637 67.685 11.4248 67.9667 11.4248C68.5139 11.4248 68.9911 11.5262 69.3913 11.7289C69.7902 11.9317 70.1023 12.2497 70.3249 12.6823L69.5437 13.1457C69.3779 12.8372 69.1731 12.6056 68.9281 12.4439C68.6821 12.2866 68.3611 12.2058 67.9667 12.2058C67.8115 12.2058 67.6479 12.2295 67.4822 12.2766C67.3125 12.3255 67.1627 12.3946 67.0243 12.4823C66.8889 12.5666 66.7753 12.6794 66.6881 12.8127C66.5933 12.9493 66.5491 13.1034 66.5491 13.2757C66.5491 13.5766 66.6464 13.7975 66.8452 13.9426C67.0421 14.0883 67.2832 14.1997 67.5749 14.2826C67.8636 14.3649 68.185 14.4338 68.5263 14.491C68.8756 14.5508 69.1954 14.6416 69.4846 14.7755C69.7763 14.9035 70.0184 15.093 70.2162 15.3395C70.4146 15.5841 70.51 15.9327 70.51 16.3873C70.51 16.7351 70.446 17.0373 70.3169 17.2937C70.1871 17.5487 70.0105 17.76 69.7802 17.9366C69.5561 18.1103 69.2962 18.2396 69.009 18.327C68.7168 18.4123 68.4132 18.4555 68.0957 18.4555C67.5179 18.4555 66.9886 18.3468 66.5118 18.1245C66.0337 17.9024 65.6666 17.5586 65.4072 17.0973L66.1701 16.5765Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M75.1733 14.7623C75.1733 15.1384 75.2468 15.4924 75.389 15.8258C75.5339 16.1582 75.7308 16.4482 75.9853 16.6952C76.2328 16.9407 76.529 17.1342 76.8678 17.2815C77.2025 17.4248 77.5721 17.4972 77.9664 17.4972C78.3741 17.4972 78.7378 17.4248 79.0651 17.2815C79.3936 17.1342 79.6723 16.9407 79.9059 16.6952C80.1365 16.4482 80.3112 16.1582 80.4336 15.8258C80.5547 15.4924 80.6107 15.1384 80.6107 14.7623C80.6107 14.3873 80.5547 14.0309 80.4336 13.6989C80.3112 13.3665 80.1365 13.0785 79.9059 12.8305C79.6723 12.5843 79.3936 12.3884 79.0651 12.2445C78.7378 12.0989 78.3741 12.0283 77.9664 12.0283C77.5721 12.0283 77.2025 12.0989 76.8678 12.2445C76.529 12.3884 76.2328 12.5843 75.9853 12.8305C75.7308 13.0785 75.5339 13.3665 75.389 13.6989C75.2468 14.0309 75.1733 14.3873 75.1733 14.7623ZM74.3921 11.4208H75.2596V12.5919H75.2894C75.4252 12.3605 75.5949 12.1607 75.7943 11.9921C75.9997 11.8224 76.2179 11.6817 76.4555 11.5715C76.6897 11.4603 76.9406 11.3792 77.1986 11.324C77.461 11.2723 77.7164 11.2466 77.9664 11.2466C78.4759 11.2466 78.9451 11.3348 79.3756 11.5066C79.8047 11.68 80.1758 11.9242 80.4927 12.2382C80.8038 12.553 81.0482 12.9231 81.2225 13.3523C81.394 13.7808 81.4824 14.251 81.4824 14.7623C81.4824 15.2742 81.394 15.7445 81.2225 16.1712C81.0482 16.6035 80.8038 16.9741 80.4927 17.288C80.1758 17.601 79.8047 17.844 79.3756 18.0181C78.9451 18.1933 78.4759 18.2776 77.9664 18.2776C77.7164 18.2776 77.461 18.2517 77.1986 18.1977C76.9406 18.1461 76.6897 18.0638 76.4555 17.9512C76.2179 17.8425 75.9997 17.7036 75.7943 17.535C75.5949 17.3654 75.4252 17.1649 75.2894 16.9323H75.2596V21.4031H74.3921V11.4208Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M88.3996 14.748C88.0321 14.748 87.6799 14.7732 87.3386 14.8214C86.9944 14.869 86.6859 14.9508 86.4101 15.068C86.1348 15.1829 85.9165 15.3358 85.7528 15.5302C85.5891 15.7232 85.5073 15.9646 85.5073 16.2521C85.5073 16.4546 85.553 16.6371 85.6432 16.797C85.736 16.9561 85.8556 17.0836 85.9959 17.1862C86.1447 17.288 86.3044 17.3654 86.4825 17.4184C86.663 17.472 86.8421 17.4972 87.0257 17.4972C87.4874 17.4972 87.8709 17.4294 88.168 17.2959C88.4662 17.162 88.7063 16.9839 88.8837 16.7646C89.0633 16.5499 89.1849 16.3041 89.2513 16.0291C89.3212 15.7533 89.3525 15.4762 89.3525 15.1955V14.748H88.3996ZM90.1368 15.2981C90.1368 15.6269 90.1368 15.9211 90.1447 16.1797C90.1491 16.4418 90.1566 16.6818 90.1661 16.9045C90.1749 17.1253 90.1874 17.333 90.2023 17.526C90.2147 17.7206 90.2374 17.9119 90.2661 18.1049H89.4556C89.3967 17.7785 89.3718 17.417 89.3718 17.0201H89.3407C89.0881 17.4546 88.7752 17.7705 88.4081 17.9754C88.037 18.1774 87.5668 18.2776 86.9984 18.2776C86.7067 18.2776 86.4224 18.2419 86.1447 18.1704C85.8639 18.097 85.6119 17.9828 85.3903 17.8237C85.1705 17.6625 84.988 17.46 84.849 17.2091C84.7086 16.9566 84.6377 16.6541 84.6377 16.2967C84.6377 15.7852 84.7692 15.379 85.0276 15.0808C85.2901 14.7822 85.6099 14.5577 85.9908 14.4081C86.3718 14.2593 86.7805 14.1628 87.2057 14.1191C87.6357 14.0758 88.0148 14.0529 88.3421 14.0529H89.3525V13.6462C89.3525 13.0696 89.179 12.6553 88.8202 12.4047C88.4636 12.1532 88.0148 12.0281 87.4745 12.0281C86.7028 12.0281 86.0237 12.2787 85.4335 12.7797L84.9279 12.1866C85.2454 11.8791 85.6432 11.6455 86.1155 11.4848C86.5877 11.324 87.0401 11.2466 87.4745 11.2466C88.2837 11.2466 88.9304 11.436 89.4126 11.819C89.8937 12.1993 90.1368 12.8139 90.1368 13.6618V15.2981Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M99.7129 17.1197C99.3836 17.5181 99.0125 17.8081 98.5983 17.9982C98.1842 18.1847 97.7357 18.2774 97.2537 18.2774C96.7417 18.2774 96.2674 18.1931 95.8335 18.0181C95.4004 17.8438 95.0269 17.6009 94.7204 17.2878C94.4123 16.9739 94.1693 16.6033 93.9976 16.171C93.8256 15.7444 93.7393 15.2742 93.7393 14.7622C93.7393 14.251 93.8256 13.7807 93.9976 13.3521C94.1693 12.9229 94.4123 12.5528 94.7204 12.2381C95.0269 11.9242 95.4004 11.6799 95.8335 11.5065C96.2674 11.3346 96.7417 11.2466 97.2537 11.2466C97.7467 11.2466 98.201 11.3482 98.6187 11.5508C99.0398 11.7535 99.4129 12.0363 99.7422 12.4045L99.0462 12.925C98.7948 12.6468 98.522 12.4274 98.2223 12.2662C97.9213 12.1084 97.6013 12.0281 97.2537 12.0281C96.8488 12.0281 96.4797 12.0987 96.153 12.2443C95.8255 12.3884 95.5468 12.5841 95.3132 12.8303C95.0834 13.0784 94.9058 13.3664 94.7858 13.6987C94.6662 14.0308 94.6068 14.3872 94.6068 14.7622C94.6068 15.1382 94.6662 15.4924 94.7858 15.8256C94.9058 16.158 95.0834 16.448 95.3132 16.695C95.5468 16.9406 95.8255 17.1341 96.153 17.2814C96.4797 17.4248 96.8488 17.4972 97.2537 17.4972C97.6211 17.4972 97.956 17.4143 98.2621 17.2433C98.5626 17.0755 98.8261 16.8503 99.0462 16.5701L99.7129 17.1197Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M108.84 14.1987C108.84 13.899 108.777 13.6193 108.658 13.3584C108.536 13.0982 108.37 12.8689 108.163 12.6712C107.958 12.4732 107.71 12.3168 107.427 12.2018C107.142 12.0867 106.84 12.0281 106.524 12.0281C106.049 12.0281 105.657 12.1263 105.345 12.3168C105.029 12.5095 104.783 12.7325 104.597 12.9815C104.415 13.2331 104.284 13.4771 104.207 13.7143C104.13 13.9493 104.093 14.1113 104.093 14.1987H108.84ZM109.502 16.8469C109.167 17.3593 108.75 17.7238 108.251 17.9461C107.753 18.1687 107.206 18.2774 106.611 18.2774C106.1 18.2774 105.636 18.1931 105.219 18.0181C104.806 17.8438 104.451 17.6009 104.147 17.2878C103.852 16.9739 103.621 16.6033 103.465 16.171C103.303 15.7444 103.225 15.2696 103.225 14.7478C103.225 14.247 103.307 13.7841 103.469 13.3584C103.634 12.9345 103.864 12.566 104.163 12.2528C104.464 11.9392 104.818 11.6937 105.219 11.5134C105.626 11.3363 106.074 11.2466 106.565 11.2466C107.079 11.2466 107.527 11.3363 107.917 11.5134C108.307 11.6937 108.636 11.9274 108.903 12.2222C109.167 12.5178 109.369 12.8522 109.502 13.2272C109.641 13.6044 109.707 13.9871 109.707 14.3723V14.9789H104.093C104.093 15.1635 104.129 15.3966 104.2 15.6829C104.274 15.9662 104.408 16.244 104.597 16.513C104.792 16.7829 105.05 17.0161 105.374 17.2089C105.696 17.4009 106.111 17.4972 106.625 17.4972C107.068 17.4972 107.498 17.3914 107.914 17.1783C108.324 16.9665 108.645 16.6783 108.867 16.3105L109.502 16.8469Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.75,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0.0126953 21.4745V0.298584L10.6023 10.8858L0.0126953 21.4745Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.85,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.1928 21.4682H0.0175781L10.6052 10.8801L21.1928 21.4682Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.1846 0.291504V21.4684L10.5977 10.8803L21.1846 0.291504Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.85,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0.00732422 0.291504H21.1847L10.5978 10.8803L0.00732422 0.291504Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.7,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.5776 21.4714L0.0136719 32.0374V21.4714H10.5776Z",
    fill: "none"
  })]
}), 'PeerspaceMonoLogo', 'CompanyMono');
export default PeerspaceMonoLogo;