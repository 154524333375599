/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var HalpMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 79,
  height: 32,
  viewBox: "0 0 79 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M2.88352 20.8177C1.50623 19.4394 0.568485 17.6838 0.188844 15.7727C-0.190799 13.8616 0.00470481 11.8809 0.750645 10.0808C1.49658 8.28084 2.75946 6.74241 4.37963 5.66005C5.99979 4.5777 7.90449 4 9.85293 4C11.8014 4 13.7061 4.5777 15.3263 5.66005C16.9464 6.74241 18.2092 8.28084 18.9552 10.0808C19.7011 11.8809 19.8966 13.8616 19.517 15.7727C19.1373 17.6838 18.1997 19.4394 16.8224 20.8177C15.9073 21.7333 14.8209 22.4596 13.625 22.9552C12.4291 23.4508 11.1474 23.7058 9.85293 23.7058C8.55847 23.7058 7.27669 23.4508 6.08086 22.9552C4.88501 22.4596 3.79854 21.7333 2.88352 20.8177ZM15.1535 8.53538L15.1813 8.56308L15.1627 8.54461L15.1535 8.53538ZM7.21574 18.0127L5.36831 19.8601C6.66634 20.8301 8.24329 21.3542 9.8637 21.3542C11.4841 21.3542 13.061 20.8301 14.3591 19.8601L12.5117 18.0127L14.0266 16.4978L15.874 18.3452C16.844 17.0472 17.3682 15.4702 17.3682 13.8498C17.3682 12.2294 16.844 10.6524 15.874 9.3544L14.0266 11.2018L12.5117 9.68077L14.3591 7.83335C13.061 6.86335 11.4841 6.33924 9.8637 6.33924C8.24329 6.33924 6.66634 6.86335 5.36831 7.83335L7.21574 9.68077L5.70084 11.2018L3.85342 9.3544C2.88343 10.6524 2.35931 12.2294 2.35931 13.8498C2.35931 15.4702 2.88343 17.0472 3.85342 18.3452L5.70084 16.4978L7.21574 18.0127Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.85444 18.7821C10.8288 18.7821 11.7812 18.4932 12.5915 17.9519C13.4016 17.4105 14.0331 16.6411 14.4059 15.741C14.7788 14.8408 14.8763 13.8502 14.6863 12.8946C14.4962 11.9389 14.027 11.0611 13.338 10.3721C12.649 9.68315 11.7711 9.21395 10.8155 9.02387C9.85991 8.83377 8.86936 8.93133 7.96916 9.3042C7.06898 9.67708 6.29956 10.3085 5.75824 11.1187C5.21691 11.9288 4.92798 12.8813 4.92798 13.8557C4.92798 15.1623 5.44701 16.4153 6.37091 17.3392C7.2948 18.2631 8.54787 18.7821 9.85444 18.7821ZM9.85444 11.0845C10.4025 11.0845 10.9383 11.2471 11.394  11.5515C11.8497 11.856 12.2049 12.2888 12.4147 12.7952C12.6244 13.3015 12.6792 13.8587 12.5724 14.3962C12.4654 14.9339 12.2015 15.4276 11.8139 15.8151C11.4264 16.2027 10.9327 16.4666 10.3951 16.5736C9.85752 16.6805 9.30033 16.6256 8.79397 16.4159C8.28761 16.2061 7.85483 15.851 7.55032 15.3952C7.24583 14.9395 7.0833 14.4037 7.0833 13.8557C7.0829 13.4915 7.15428 13.1308 7.29336 12.7942C7.43245 12.4577 7.63649 12.1518 7.89386 11.8942C8.15123 11.6365 8.45685 11.4321 8.79326 11.2927C9.12968 11.1532 9.49027 11.0814 9.85444 11.0814V11.0845Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M63.9427 23.0209C61.8467 23.0209 60.5152 22.0068 60.5152 19.622V4.5498H62.7159V19.3627C62.7159 20.5418 63.4834 20.9456 64.4311 20.9456C64.6447 20.9495 64.8582 20.9406 65.0707 20.9191V22.9148C64.7005 22.9971 64.3216 23.0328 63.9427 23.0209ZM42.6336 22.9412V5.89977H40.3312V13.3431H31.479V5.89977H29.1765V22.9412H31.479V15.5716H40.3312V22.9412H42.6336ZM50.666 23.201C52.637 23.201 54.172 22.3196 54.9889 20.6069L54.9918 22.9415H57.1925V9.97111H54.9918V12.2557C54.2214 10.5695 52.7911 9.7117 50.9218 9.7117C47.3402 9.7117 45.5494 12.801 45.5494 16.4563C45.5494 20.2709 47.2646 23.201 50.666 23.201ZM54.9889 16.9752C54.9889 19.8552 53.2242 21.1257 51.2561 21.1257C48.977 21.1257 47.7385 19.5692 47.7385 16.4563C47.7385 13.4495 49.0176 11.787 51.5004 11.787C53.3784 11.787 54.9889 13.0605 54.9889 15.9375V16.9752ZM69.3939 28V20.6599C70.1614 22.3461 71.5917 23.201 73.461 23.201C77.0426 23.201 78.8334 20.1146 78.8334 16.4563C78.8334 12.6596 77.1182 9.7117 73.7168 9.7117C71.7458 9.7117 70.2108 10.596 69.3939 12.3058V9.97111H67.1932V28H69.3939ZM69.3939 15.9375C69.3939 13.0605 71.1585 11.787 73.1267 11.787C75.4058 11.787 76.6327 13.3434 76.6443 16.4563C76.6443 19.4661 75.3652 21.1257 72.8824 21.1257C71.0044 21.1257 69.3939 19.8552 69.3939 16.9752V15.9375Z",
    fill: "none"
  })]
}), 'HalpMonoLogo', 'CompanyMono');
export default HalpMonoLogo;