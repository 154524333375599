/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MeUndiesMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 119,
  height: 33,
  viewBox: "0 0 119 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M80.9708 16.1806C80.4737 15.6854 79.8821 15.2954 79.2312 15.0336C78.5803 14.7718 77.8834 14.6437 77.1819 14.6568C73.4755 14.698 71.4575 18.0338 71.4575 21.2461C71.4575 25.5703 74.3815 27.8765 77.0996 27.8765C77.8356 27.8989 78.5675 27.7593 79.2436 27.4675C79.9197 27.1758 80.5234 26.7389 81.0119 26.188V27.5882H84.8419V8.80884H80.9708V16.1806ZM78.2527 24.2936C77.8528 24.2993 77.4559 24.2241 77.0859 24.0724C76.7158 23.9207 76.3803 23.6958 76.0994 23.4111C75.8186 23.1264 75.5982 22.7878 75.4516 22.4158C75.305 22.0437 75.2352 21.6458 75.2463 21.2461C75.2463 20.4596 75.5587 19.7054 76.1148 19.1494C76.6709 18.5933 77.4251 18.2809 78.2115 18.2809C78.9979 18.2809 79.7521 18.5933 80.3082 19.1494C80.8643 19.7054 81.1767 20.4596 81.1767 21.2461C81.1933 21.6403 81.13 22.0339 80.9906 22.403C80.8511 22.7721 80.6384 23.1092 80.3652 23.394C80.092 23.6787 79.764 23.9052 79.4009 24.0598C79.0379 24.2144 78.6473 24.2939 78.2527 24.2936V24.2936Z",
    fill: "none"
  }), _jsx("path", {
    d: "M39.994 20.999C39.7984 20.9065 39.5853 20.8568 39.3689 20.853C39.1525 20.8493 38.9379 20.8917 38.7391 20.9774C38.5404 21.0631 38.3623 21.1902 38.2165 21.3502C38.0707 21.5101 37.9607 21.6993 37.8937 21.9051C37.0289 23.9642 34.1049 25.3232 32.2517 23.923C33.9813 22.8934 35.711 21.4932 36.6582 19.3517C37.6054 17.2102 36.823 12.4742 32.4576 13.2155C30.1102 13.6273 26.3625 16.6336 27.3509 22.111C24.221 22.7699 24.7564 18.281 26.1154 12.0624C26.5273 10.2503 26.692 8.89128 25.4153 7.86171C24.1386 6.83214 22.9855 7.61461 22.0383 8.43827C19.938 10.2503 17.3435 13.6273 15.9844 15.4393C16.561 11.98 17.467 7.57343 16.7669 6.42031C16.0668 5.26719 14.7901 5.26719 13.5958 6.04966C10.919 8.23235 6.18293 14.7804 6.18293 14.7804C6.32086 14.2534 6.41725 13.7164 6.47121 13.1743C6.5446 12.7547 6.45263 12.3231 6.2146 11.9698C5.97656 11.6166 5.61097 11.3693 5.19454 11.2799C4.74697 11.1659 4.27256 11.2312 3.8725 11.4621C3.47244 11.6929 3.17838 12.0708 3.05304 12.5154C1.98228 17.6632 1.11744 20.3813 0.0466886 25.5703C-0.0568 26.0174 0.0131729 26.487 0.242482 26.8845C0.471791 27.282 0.843358 27.5776 1.28217 27.7118C1.72672 27.8216 2.19618 27.7629 2.60002 27.5471C2.93766 27.3141 3.19625 26.9836 3.34132 26.5999C5.27691 21.658 10.0541 14.904 13.2664 11.2799C13.2858 11.2602 13.3113 11.2475 13.3388 11.2441C13.3663 11.2406 13.3942 11.2464 13.4179 11.2607C13.4417 11.2749 13.4599 11.2968 13.4698 11.3226C13.4797 11.3485 13.4805 11.377 13.4723 11.4034C13.1016 13.5449 12.0721 18.7752 12.0309 19.5164C11.9897 20.2577 12.0721 21.2049 12.9781 21.5344C13.8841 21.8639 14.6666 21.6991 15.7374 20.7931C16.8081 19.8871 20.5969 15.1923 21.8324 13.6685C21.956 13.5449 22.1619 13.5449 22.0795 13.8332C19.5262 22.2757 22.6149 27.0117 28.7511 25.3232C32.993 30.1004 39.1704 26.9294 40.653 23.1817C40.9824 22.3169 40.8177 21.3697 39.994 20.999ZM33.2812 16.4689C34.3932 17.0455 33.6519 19.8459 30.4808 21.2461C30.3573 18.3633 32.1281 15.8924 33.2812 16.4689V16.4689Z",
    fill: "none"
  }), _jsx("path", {
    d: "M91.2254 15.3982H87.1895V27.646H91.2254V15.3982Z",
    fill: "none"
  }), _jsx("path", {
    d: "M65.6095 14.698C63.7151 14.698 62.8503 15.3157 61.8619 16.4277V14.9863H57.9907V27.6706H61.9031V20.6695C61.9031 18.981 62.6856 17.9926 64.0034 17.9926C65.3213 17.9926 66.0214 18.8575 66.0214 20.4636V27.6706H70.0161V19.4752C70.0161 16.5512 68.3276 14.698 65.6095 14.698Z",
    fill: "none"
  }), _jsx("path", {
    d: "M51.8545 21.2462C51.8545 22.9759 50.825 24.2114 49.0953 24.2114C47.3656 24.2114 46.4596 23.1406 46.4596 21.2462V8.76782H42.3413V21.0815C42.3413 25.7351 45.2241 27.9178 49.0953 27.9178C54.2431 27.9178 55.9316 24.4173 55.9316 21.0815V8.76782H51.8545V21.2462Z",
    fill: "none"
  }), _jsx("path", {
    d: "M89.2075 13.545C90.5266 13.545 91.5961 12.4756 91.5961 11.1564C91.5961 9.83724 90.5266 8.76782 89.2075 8.76782C87.8883 8.76782 86.8188 9.83724 86.8188 11.1564C86.8188 12.4756 87.8883 13.545 89.2075 13.545Z",
    fill: "none"
  }), _jsx("path", {
    d: "M99.6679 14.6157C98.7872 14.5989 97.9121 14.76 97.0951 15.0893C96.2781 15.4187 95.5359 15.9095 94.913 16.5324C94.2901 17.1553 93.7993 17.8974 93.47 18.7145C93.1406 19.5315 92.9795 20.4066 92.9963 21.2873C92.9963 25.6115 96.2086 28.0001 99.6679 28.0001C103.127 28.0001 105.228 25.7762 106.01 23.9642H101.892C101.616 24.2652 101.278 24.5022 100.901 24.6587C100.524 24.8152 100.117 24.8874 99.7091 24.8702C98.9825 24.9016 98.2691 24.6686 97.7011 24.2142C97.1332 23.7599 96.7492 23.1151 96.6204 22.3992H106.381V21.3285C106.381 17.4161 103.498 14.6157 99.6679 14.6157ZM96.7439 19.7635C96.962 19.1459 97.3707 18.6135 97.9111 18.2434C98.4515 17.8733 99.0956 17.6845 99.7503 17.7044C101.398 17.7044 102.345 18.5692 102.715 19.7635H96.7439Z",
    fill: "none"
  }), _jsx("path", {
    d: "M111.693 19.0635C111.585 19.022 111.491 18.9486 111.425 18.8531C111.359 18.7576 111.323 18.6443 111.323 18.5281C111.323 18.0751 111.652 17.5809 112.641 17.5809C113.629 17.5809 114 18.2398 114.041 18.5693H117.953C117.931 18.0199 117.791 17.4818 117.542 16.9915C117.293 16.5011 116.942 16.0701 116.512 15.7277C115.565 14.9864 114.247 14.6157 112.641 14.6157C111.034 14.6157 108.728 15.3158 107.822 17.0455C107.57 17.5279 107.442 18.066 107.451 18.6105C107.423 19.1962 107.551 19.7791 107.822 20.299C108.319 21.161 109.131 21.7961 110.087 22.0698C112.188 22.8111 114.247 22.6052 114.247 23.6348C114.247 24.6643 113.588 24.7055 112.805 24.7055C112.023 24.7055 111.611 24.582 111.405 23.9642H107.369C107.657 26.4352 109.099 27.1353 110.293 27.5471C111.103 27.8127 111.953 27.938 112.805 27.9178C113.604 27.9357 114.399 27.8102 115.153 27.5471C116.471 27.0529 118.118 25.941 118.118 23.5936C118.128 23.0138 118.016 22.4384 117.788 21.9051C117.389 21.1018 116.696 20.4826 115.853 20.1754C114.782 19.7636 112.641 19.5165 111.693 19.0635Z",
    fill: "none"
  })]
}), 'MeUndiesMonoLogo', 'CompanyMono');
export default MeUndiesMonoLogo;