/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AaaMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 54,
  height: 32,
  viewBox: "0 0 54 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#AaaMonoLogo_svg__clip0_12854_184900)",
    children: [_jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M52.8903 1.24734C51.3347 -1.403 43.1535 0.325465 32.9555 4.9922C33.4742 5.39568 33.9927 5.8565 34.3959 6.31744C42.2317 2.97581 48.281 1.93887 49.5488 4.12803C50.9892 6.60561 45.2849 12.7703 36.1241 18.8776L36.2396 18.9927C47.417 11.7333 54.6189 4.24345 52.8903 1.24734Z",
      fill: "white"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.60916 30.0549C3.74487 28.4991 5.58863 25.5608 9.2759 22.0464C8.98784 21.9886 8.69991 21.8733 8.41173 21.7581C8.35414 21.7581 8.35414 21.7003 8.35414 21.7003C8.06621 21.5852 7.77803 21.47 7.48985 21.355C3.05358 25.445 0.806592 28.9022 1.84377 30.7463C3.11129 32.8779 8.58449 32.1865 15.9016 29.4786L15.8441 29.3635C9.96719 31.3799 5.64621 31.7831 4.60916 30.0549Z",
      fill: "white"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 12.1368C0 5.8568 8.29655 0.729004 18.4943 0.729004C28.7497 0.729004 37.0463 5.8568 37.0463 12.1368C37.0463 18.4744 28.7497 23.6021 18.4943 23.6021C8.29655 23.602 0 18.4744 0 12.1368ZM10.4858 3.26417L14.0006 15.1904L17.6877 1.70835C15.095 1.82389 12.6176 2.34205 10.4858 3.26417ZM17.5149 8.21909L15.6135 15.1904H19.4738L17.5149 8.21909ZM22.9883 15.1904L26.3299 3.14888C24.1406 2.28458 21.6632 1.76594 19.0704 1.70835L22.9883 15.1904ZM10.4284 15.1904L8.52678 8.21909L6.62579 15.1904H10.4284ZM32.3795 16.5731C33.3012 15.2479 33.82 13.75 33.82 12.1367C33.82 9.08314 31.9187 6.37521 28.8073 4.41616L32.3795 16.5731ZM28.4619 15.1904L26.5029 8.21909L24.6017 15.1904H28.4619ZM28.8652 16.5731H24.1982L23.7951 18.0711L24.8896 21.6427C26.6757 21.1243 28.2887 20.3177 29.6141 19.3389L28.8652 16.5731ZM12.2144 21.6428L10.8315 16.5732H6.22244L5.81897 18.0133C7.43226 19.6264 9.62154 20.8942 12.2143 21.7007V21.6428H12.2144ZM19.8773 16.5731H15.2103L13.7123 22.1039C15.2103 22.4499 16.8237 22.6224 18.4943 22.6224C19.5315 22.6224 20.5108 22.5651 21.4903 22.3921L19.8773 16.5731ZM7.89319 4.64687C5.0125 6.54797 3.22646 9.19855 3.22646 12.1368C3.22646 13.7501 3.74486 15.1904 4.60915 16.5155L7.89319 4.64687Z",
      fill: "white"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "AaaMonoLogo_svg__clip0_12854_184900",
      children: _jsx("rect", {
        width: 53.1459,
        height: 32,
        fill: "white",
        transform: "translate(0 0.000976562)"
      })
    })
  })]
}), 'AaaMonoLogo', 'CompanyMono');
export default AaaMonoLogo;