/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TaxfixMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 87,
  height: 32,
  viewBox: "0 0 87 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M11.2625 15.7844H8.15412V21.7243C8.15412 23.034 8.86082 23.385 9.92089 23.385C10.3526 23.3783 10.7823 23.3246 11.2024 23.2249V27.2496C11.0104 27.345 10.2095 27.6972 8.67119 27.6972C5.37321 27.6972 3.35085 25.749 3.35085 22.5865V15.7844H0.526367V11.5382H1.3273C2.99396 11.5382 3.80079 10.4205 3.80079 8.94697V6.90576H8.15883V11.5382H11.2625V15.7844Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21.5878 23.7175C23.543 23.7175 25.2097 22.1863 25.2097 19.5008C25.2097 16.7552 23.5748 15.3171 21.5878 15.3171C19.6337 15.3171 18.0625 16.7552 18.0625 19.5008C18.0625 22.1203 19.6973 23.7175 21.5878 23.7175ZM25.2391 25.41C24.5324 26.9412 22.9317 27.9012 20.9447 27.9012C16.3946 27.9012 13.1567 24.3559 13.1567 19.4701C13.1567 14.7423 16.2015 11.1074 20.7845 11.1074C23.6372 11.1074 24.8869 12.6716 25.2073 13.4066V11.5809H29.9187L29.9505 27.4536H25.2097L25.2415 25.569L25.2391 25.41Z",
    fill: "none"
  }), _jsx("path", {
    d: "M35.6038 27.7444L32.2705 24.8386L43.8711 11.4253L47.301 14.331L35.6038 27.7444Z",
    fill: "none"
  }), _jsx("path", {
    d: "M35.1232 11.648C35.6863 11.6447 36.2376 11.8087 36.7074 12.1191C37.1771 12.4296 37.5441 12.8725 37.7618 13.3918C37.9796 13.911 38.0382 14.4832 37.9304 15.0359C37.8226 15.5885 37.5531 16.0967 37.1561 16.496C36.7591 16.8953 36.2525 17.1677 35.7005 17.2788C35.1485 17.3898 34.576 17.3345 34.0555 17.1198C33.5349 16.905 33.0899 16.5406 32.7767 16.0727C32.4636 15.6048 32.2964 15.0544 32.2964 14.4913C32.2958 13.7399 32.5929 13.0188 33.1227 12.4859C33.6525 11.953 34.3718 11.6517 35.1232 11.648Z",
    fill: "none"
  }), _jsx("path", {
    d: "M51.3222 27.7007V15.9222H48.791V11.828H51.3222V10.4546C51.3222 6.8139 53.5295 4.32275 57.4164 4.32275C58.4105 4.32275 59.2751 4.45114 59.725 4.67611V8.63486C59.2514 8.51717 58.7641 8.46369 58.2762 8.47585C57.4105 8.47585 56.1938 8.85865 56.1938 10.6148V11.828H59.6543V15.9163H56.1938V27.7007H51.3222Z",
    fill: "none"
  }), _jsx("path", {
    d: "M62.4884 27.5701H67.3588V11.6974H62.4884V27.5701ZM64.8912 4.00015C65.2678 3.99627 65.6415 4.06678 65.9907 4.20764C66.34 4.3485 66.6581 4.55694 66.9266 4.821C67.1952 5.08505 67.4089 5.39954 67.5556 5.7464C67.7024 6.09327 67.7792 6.46569 67.7816 6.8423C67.7837 7.40535 67.619 7.95642 67.3085 8.42608C66.9979 8.89575 66.5553 9.26302 66.0365 9.48162C65.5176 9.70022 64.9456 9.76039 64.3926 9.65453C63.8396 9.54868 63.3303 9.28154 62.9288 8.88677C62.5273 8.492 62.2516 7.98725 62.1365 7.43609C62.0213 6.88494 62.0718 6.31203 62.2817 5.78954C62.4915 5.26704 62.8512 4.81832 63.3156 4.4999C63.78 4.18148 64.3282 4.0076 64.8912 4.00015Z",
    fill: "none"
  }), _jsx("path", {
    d: "M77.8849 22.8257L72.6941 28L69.521 24.8375L74.7129 19.6643L69.6494 14.6502L72.8213 11.5207L77.8531 16.5348L82.9803 11.4253L86.1534 14.5866L81.0262 19.6961L86.2182 24.8375L83.0451 28L77.8849 22.8257Z",
    fill: "none"
  }), _jsx("path", {
    d: "M47.3327 24.6468C47.3176 25.3932 47.0104 26.104 46.4772 26.6265C45.9439 27.149 45.2271 27.4416 44.4806 27.4416C43.734 27.4416 43.0172 27.149 42.484 26.6265C41.9507 26.104 41.6436 25.3932 41.6284 24.6468C41.6436 23.9004 41.9507 23.1897 42.484 22.6672C43.0172 22.1447 43.734 21.8521 44.4806 21.8521C45.2271 21.8521 45.9439 22.1447 46.4772 22.6672C47.0104 23.1897 47.3176 23.9004 47.3327 24.6468Z",
    fill: "none"
  })]
}), 'TaxfixMonoLogo', 'CompanyMono');
export default TaxfixMonoLogo;