/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ImaxMonoLogo = createCompanyLogosComponent(_jsx("svg", {
  width: 169,
  height: 32,
  viewBox: "0 0 169 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M46.788 31.973 58.076 8.925h1.836L59.911 32h9.013V.098H52.688l-9.62 19.075L33.354.123l-16.162.05L17.169 32h8.994l.012-23.075h1.804l11.283 23.048h7.526ZM0 31.998h10.094V.099H0v31.9ZM86.912.098 70.698 32h10.784l2.821-5.426h20.149L107.201 32h10.809L101.787.098H86.912Zm7.954 7.252h-.993l-6.138 12.082h13.2L94.865 7.35ZM131.745 32l10.16-11.528L151.952 32h13.311l-15.012-16.944L162.822.098h-12.304l-8.697 10.365L133.374.098h-12.528L133.49 15.07 118.392 32h13.353ZM168.906 2.429a2.43 2.43 0 0 1-2.428 2.43 2.43 2.43 0 0 1 0-4.859 2.43 2.43 0 0 1 2.428 2.429Zm-.417 0a2.011 2.011 0 0 0-4.023 0 2.012 2.012 0 0 0 4.023 0Zm-1.827-.914h-.71v.891h.723a.73.73 0 0 0 .204-.022.255.255 0 0 0 .128-.079c.052-.06.074-.179.074-.358 0-.166-.019-.28-.06-.338a.207.207 0 0 0-.135-.077 1.187 1.187 0 0 0-.224-.017Zm.089-.432c.342 0 .571.067.692.2.108.118.16.313.16.582 0 .293-.041.484-.116.576a.506.506 0 0 1-.327.172v.01c.155.016.254.062.327.159.07.094.095.25.095.446v.468h-.523v-.365c0-.091-.004-.168-.013-.228a.292.292 0 0 0-.067-.15.255.255 0 0 0-.128-.08.897.897 0 0 0-.196-.016h-.702v.84h-.506l.005-2.614h1.299Z",
    fill: "none"
  })
}), 'ImaxMonoLogo', 'CompanyMono');
export default ImaxMonoLogo;