/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var NikeMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 61,
  height: 32,
  viewBox: "0 0 61 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M14.386 16.6341H18.5832L24.7678 0H20.8699L18.0925 7.46026L18.5712 0H14.424L8.23975 16.6341H12.1374L14.8631 9.27484L14.386 16.6341Z",
    fill: "none"
  }), _jsx("path", {
    d: "M25.2736 0L19.0897 16.6341H22.9873L29.1713 0H25.2736Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.7903 10.9004C2.34378 17.3526 -2.79401 25.9376 1.74417 29.891C6.103 33.6885 11.5417 31.4932 15.2868 29.9815L15.386 29.9415C19.1338 28.4292 60.5582 10.2828 60.5582 10.2828C60.9579 10.0811 60.8832 9.82903 60.3833 9.95492C60.2984 9.97633 52.272 12.1696 43.0143 14.6998L43.4739 13.4082H38.9269L40.4209 9.37582H44.1684L45.3214 6.27579H41.574L42.8586 2.77252H46.8057L47.8531 0H35.9604L31.8769 4.99034L33.7621 0H29.8145L23.6799 16.6341H27.5776L30.1967 9.67808L30.3761 16.6341L35.9824 16.6218C25.3582 19.5258 15.2862 22.2793 15.2862 22.2793C9.58913 23.8926 3.09321 20.3765 7.7903 10.9004ZM34.243 15.5251L33.9931 6.85539L39.8862 0.328481L34.243 15.5251Z",
    fill: "none"
  })]
}), 'NikeMonoLogo', 'CompanyMono');
export default NikeMonoLogo;