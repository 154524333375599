/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ZenjobMonoLogo = createCompanyLogosComponent(_jsx("svg", {
  width: 171,
  height: 32,
  viewBox: "0 0 171 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M15.5127 6.92071H2.54111V0.792715H26.2109L11.4618 25.0793H25.5128V31.2073H0.727295L15.5127 6.92071ZM31.7135 0.792715H50.4248V6.92071H37.9593V12.9134H47.8313V18.9149H37.9593V25.0793H50.4248V31.2073H31.7135V0.792715ZM62.8626 14.0742V31.2087H56.6168V0.446533L76.4873 17.9244V0.791261H82.7418V31.552L62.864 14.0742H62.8626ZM86.9949 22.2502H93.2495C93.2495 24.6894 94.5004 25.7222 96.3506 25.7222C98.1993 25.7222 99.4502 24.6982 99.4502 22.2502V0.792715H105.705V22.64C105.705 28.8945 101.436 31.552 96.3506 31.552C91.2189 31.552 86.9949 28.6429 86.9949 22.2604M110.746 15.9985C110.746 7.06762 118.262 -0.0741941 127.372 0.478533C135.178 0.949806 141.533 7.25962 142.013 15.0094C142.575 24.0669 135.386 31.5462 126.393 31.5462C117.727 31.5462 110.746 24.6036 110.746 15.9985ZM135.75 15.9985C135.75 10.7316 131.743 6.44217 126.393 6.44217C121.044 6.44217 117.039 10.7316 117.039 15.9985C117.039 21.2654 121.044 25.5534 126.393 25.5534C131.743 25.5534 135.748 21.2654 135.748 15.9971L135.75 15.9985ZM158.25 0.792715C163.425 0.792715 167.306 4.78108 167.306 9.57817C167.306 11.9345 166.4 13.7294 164.931 15.0618C167.606 16.3854 169.455 18.8334 169.455 22.2604C169.455 27.3993 165.312 31.216 159.881 31.216H146.991V0.792715H158.25ZM157.606 13.2582C159.763 13.2582 161.232 11.7614 161.232 9.87635C161.232 8.03635 159.763 6.49453 157.606 6.49453H153.254V13.2669H157.606V13.2582ZM159.501 25.5054C161.83 25.5054 163.29 23.9636 163.29 22.0334C163.29 20.0654 161.821 18.5251 159.501 18.5251H153.245V25.5054H159.501Z",
    fill: "none"
  })
}), 'ZenjobMonoLogo', 'CompanyMono');
export default ZenjobMonoLogo;