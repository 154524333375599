/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BettermentMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 244,
  height: 32,
  viewBox: "0 0 244 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#BettermentMonoLogo_svg__clip0_7744_54384)",
    children: [_jsx("path", {
      d: "M228.419 22.7047C228.419 28.5968 231.517 31.8476 237.409 31.8476C239.949 31.8476 241.981 31.3396 243.759 30.4761L241.981 24.8888C241.03 25.4099 239.966 25.689 238.882 25.7015C236.648 25.7015 235.53 24.3809 235.53 21.9428V13.8666H242.489V8.17773H235.479V0.203125L228.419 2.53963V22.7047Z",
      fill: "none"
    }), _jsx("path", {
      d: "M181.841 17.0667C182.135 15.9758 182.784 15.014 183.685 14.3333C184.587 13.6525 185.689 13.2917 186.819 13.308C189.105 13.308 190.882 14.6286 191.543 17.0667H181.841ZM198.654 19.6064C198.552 12.4953 193.676 7.41589 186.819 7.41589C179.86 7.41589 174.628 12.6984 174.628 19.6064C174.628 26.2095 179.454 31.7968 186.768 31.7968C192.051 31.7968 196.825 28.4953 198.247 23.9238H191.39C190.374 25.3461 188.851 26.0572 186.768 26.0572C184.228 26.0572 182.247 24.3302 181.689 21.4857H198.654V19.6572V19.6064Z",
      fill: "none"
    }), _jsx("path", {
      d: "M217.702 31.1365H224.711V17.0159C224.711 14.1714 223.848 11.7841 222.273 10.0571C221.469 9.20078 220.491 8.52653 219.404 8.0797C218.318 7.63288 217.149 7.42384 215.975 7.46665C214.635 7.44957 213.306 7.71173 212.073 8.23643C210.84 8.76114 209.73 9.53688 208.813 10.5143V8.12696H201.702V31.1365H208.813V20.4698C208.813 16.2539 210.54 13.9682 213.638 13.9682C216.076 13.9682 217.702 15.7968 217.702 18.5397V31.1365Z",
      fill: "none"
    }), _jsx("path", {
      d: "M164.673 31.1365H171.683V17.2698C171.683 11.2762 168.229 7.51746 163.098 7.51746C159.695 7.51746 156.902 8.88888 154.971 11.4794C153.448 8.93968 150.857 7.51746 147.606 7.51746C144.762 7.51746 142.527 8.48254 140.851 10.4127V8.12698H133.74V31.1365H140.851V19.8603C140.851 16.1016 142.375 13.9682 145.219 13.9682C147.86 13.9682 149.181 15.4921 149.181 18.2857V31.1365H156.292V19.8603C156.292 16.1016 157.918 13.9682 160.864 13.9682C163.149 13.9682 164.673 15.8476 164.673 18.4381V31.1365V31.1365Z",
      fill: "none"
    }), _jsx("path", {
      d: "M114.59 31.1366H121.651V23.8731C121.651 17.4731 124.546 14.8826 129.168 14.8826C130.336 14.8826 131.098 15.035 131.098 15.035V8.02544C130.59 7.87306 130.133 7.82227 129.473 7.82227C126.07 7.82227 123.53 9.49846 121.651 12.7492V8.17782H114.54V31.1366H114.59Z",
      fill: "none"
    }), _jsx("path", {
      d: "M94.6795 17.0667C94.9731 15.9758 95.622 15.014 96.5235 14.3333C97.425 13.6525 98.5277 13.2917 99.6573 13.308C101.994 13.308 103.772 14.6286 104.381 17.0667H94.6795V17.0667ZM111.543 19.6064C111.391 12.4953 106.464 7.41589 99.6573 7.41589C92.6985 7.41589 87.4668 12.6984 87.4668 19.6064C87.4668 26.2095 92.2922 31.7968 99.6573 31.7968C104.889 31.7968 109.714 28.4953 111.086 23.9238H104.229C103.213 25.3461 101.689 26.0572 99.6573 26.0572C97.1176 26.0572 95.0858 24.3302 94.5779 21.4857H111.543V19.6572V19.6064Z",
      fill: "none"
    }), _jsx("path", {
      d: "M61.6126 13.8666H71.4665V22.7047C71.4665 28.5968 74.5141 31.8476 80.457 31.8476C82.9967 31.8476 85.0284 31.3396 86.8062 30.4761L84.9776 24.8888C84.0423 25.4019 82.9966 25.6808 81.93 25.7015C79.6443 25.7015 78.5268 24.3809 78.5268 21.9428V13.8666H85.4856V8.17773H78.5268V0.203125L71.4665 2.53963V8.12694H61.6126V0.304712L54.5522 2.53963V22.7047C54.5522 28.5968 57.5999 31.8476 63.5427 31.8476C66.0824 31.8476 68.1142 31.3396 69.8919 30.4761L68.0634 24.8888C67.128 25.4019 66.0823 25.6808 65.0157 25.7015C62.73 25.7015 61.6126 24.3809 61.6126 21.9428V13.8666Z",
      fill: "none"
    }), _jsx("path", {
      d: "M34.7938 17.0667C35.0874 15.9758 35.7362 15.014 36.6377 14.3333C37.5393 13.6525 38.642 13.2917 39.7715 13.308C42.0573 13.308 43.8858 14.6286 44.4953 17.0667H34.7938ZM51.6065 19.6064C51.5049 12.4953 46.6287 7.41589 39.7715 7.41589C32.8128 7.41589 27.5811 12.6984 27.5811 19.6064C27.5811 26.2095 32.4065 31.7968 39.7207 31.7968C45.0033 31.7968 49.7779 28.4953 51.2001 23.9238H44.343C43.3271 25.3461 41.8033 26.0572 39.7207 26.0572C37.1811 26.0572 35.2001 24.3302 34.6414 21.4857H51.6065V19.6572V19.6064Z",
      fill: "none"
    }), _jsx("path", {
      d: "M14.3746 25.1429H7.11111V18.9461H14.3746C16.0508 18.9461 17.727 20.0127 17.727 22.0953C17.727 23.873 16.3556 25.1429 14.3746 25.1429V25.1429ZM7.11111 6.90797H13.4603C15.7968 6.90797 16.8127 8.43178 16.8127 9.95559C16.8127 12.1397 15.0857 13.1556 13.4603 13.1556H7.11111V6.90797ZM20.673 15.2381C20.673 15.2381 24.381 13.308 24.381 8.63495C24.381 4.97781 21.181 0.863525 14.8317 0.863525H0V31.1873H14.8317C22.9587 31.1873 25.1937 26.108 25.1937 22.654C25.1937 17.1175 20.673 15.2381 20.673 15.2381V15.2381Z",
      fill: "none"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "BettermentMonoLogo_svg__clip0_7744_54384",
      children: _jsx("rect", {
        width: 243.81,
        height: 32,
        fill: "none"
      })
    })
  })]
}), 'BettermentMonoLogo', 'CompanyMono');
export default BettermentMonoLogo;