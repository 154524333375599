/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TelusDigitalMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 198,
  height: 32,
  viewBox: "0 0 198 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M81.6406 10.2023V7.40186H71.6572V21.8064H81.6406V18.8847H75.7035V15.6929H80.6429V12.8925H75.7035V10.2023H81.6406Z",
    fill: "none"
  }), _jsx("path", {
    d: "M99.2591 18.9619H93.0188V7.40186H88.9229V21.8064H99.2591V18.9619Z",
    fill: "none"
  }), _jsx("path", {
    d: "M132.225 12.8925C129.463 12.2695 128.498 12.1979 128.498 11.1009C128.498 10.081 129.866 9.76129 130.654 9.76129C131.933 9.76129 133.432 10.059 134.959 10.8473L136.073 8.19569C134.507 7.33572 132.49 6.98291 130.549 6.98291C126.74 6.98291 124.182 8.52645 124.077 11.5419C123.967 14.2872 126.2 15.318 128.256 15.7425C130.235 16.1505 132.242 16.5033 132.286 17.6775C132.324 18.7635 131.349 19.2265 129.507 19.2265C127.804 19.2265 126.216 18.6808 124.596 18.0027L123.653 20.8803C125.511 21.6741 127.401 22.1096 129.353 22.1096C132.06 22.1096 134.033 21.6907 135.489 20.3235C136.497 19.3864 136.828 18.2563 136.828 17.1758C136.823 14.8936 135.406 13.6091 132.225 12.8925Z",
    fill: "none"
  }), _jsx("path", {
    d: "M65.8475 10.2464V7.40186H53.2842V10.2464H57.5289V21.8064H61.6028V10.2464H65.8475Z",
    fill: "none"
  }), _jsx("path", {
    d: "M113.222 7.40186V16.5529C113.222 17.2695 113.222 17.468 113.178 17.7491C113.051 18.6201 112.202 19.1273 110.852 19.1438C109.854 19.1273 109.121 18.8351 108.779 18.3114C108.608 18.0413 108.526 17.5617 108.526 16.7899V7.40186H104.281V17.3301C104.281 18.7083 104.463 19.3257 105.086 20.1085C106.111 21.393 108.107 22.0655 110.863 22.0655C110.89 22.0655 110.924 22.0655 110.951 22.0655C110.968 22.0655 110.99 22.0655 111.012 22.0655C114.694 22.0655 116.596 20.6267 117.147 19.2816C117.423 18.6311 117.445 18.3224 117.445 17.0049V7.40186H113.222Z",
    fill: "none"
  }), _jsx("path", {
    d: "M55.2016 3.45439L31.3979 8.19527V9.85458L31.4751 9.83253C47.2027 6.01226 55.2016 3.71348 55.2677 3.69695C55.3559 3.6749 55.389 3.60323 55.378 3.5481C55.3615 3.48747 55.3008 3.43785 55.2016 3.45439Z",
    fill: "none"
  }), _jsx("path", {
    d: "M35.2239 4.78296C35.5932 4.54592 35.6979 4.69476 35.5877 4.95937C35.4168 5.38384 34.612 7.96376 28.6142 12.8811C27.0486 14.16 25.9405 14.8821 23.6859 16.2162C27.3738 11.0508 32.7597 6.38163 35.2239 4.78296ZM35.8468 3.00237C34.6891 3.06852 32.5447 4.78296 31.3705 5.8028C26.6848 9.8546 23.2063 13.5812 20.6704 17.4786C15.3893 20.3066 8.66388 23.2118 1.97703 25.483L1.81716 25.5381L0.532715 29.0001L1.2659 28.7134C5.51616 27.0927 12.6109 24.0497 19.0442 20.6704C18.6804 21.6351 18.4985 22.5227 18.4985 23.333C18.4985 23.9394 18.5977 24.4962 18.8017 25.0089C19.2041 26.0232 20.0034 26.795 21.1059 27.236C22.7542 27.892 24.9979 27.9086 27.5613 27.1313C34.1048 25.1467 41.8721 19.0552 43.5645 17.8424L43.6251 17.7983C43.6913 17.7487 43.6968 17.6771 43.6692 17.6274C43.6307 17.5723 43.5535 17.5558 43.4928 17.5944L43.4101 17.644C41.5965 18.73 32.7818 23.9615 27.0486 25.4995C24.1324 26.2823 21.9053 25.8523 21.0674 24.6781C20.7752 24.2757 20.6374 23.7685 20.6374 23.1787C20.6374 22.0265 21.1776 20.5547 22.2305 18.9229C23.1952 18.3717 24.0938 17.8369 24.9042 17.3298C30.494 14.1876 36.9383 8.07953 37.2084 4.35297C37.2084 4.32541 37.2084 4.29785 37.2084 4.27028C37.2084 3.95055 37.0706 3.61979 36.8225 3.37172C36.5689 3.11263 36.2216 2.98032 35.8468 3.00237Z",
    fill: "none"
  }), _jsx("path", {
    d: "M31.3213 8.21199L6.27736 13.5979L5.30713 16.1998L31.3985 9.85476L31.4371 9.84374V8.18994L31.3213 8.21199Z",
    fill: "none"
  }), _jsx("path", {
    d: "M147.42 7.4458H152.392C156.72 7.54503 158.991 9.92098 158.991 14.6288C158.991 19.3366 156.72 21.7126 152.392 21.8118H147.42V7.4458ZM148.787 20.6431H151.703C155.827 20.6651 157.618 18.9121 157.618 14.6288C157.618 10.3455 155.827 8.59243 151.703 8.61448H148.787V20.6431Z",
    fill: "none"
  }), _jsx("path", {
    d: "M161.13 7.4458H162.398V9.47997H161.13V7.4458ZM161.13 11.4259H162.398V21.8118H161.13V11.4259Z",
    fill: "none"
  }), _jsx("path", {
    d: "M173.611 20.963C173.589 24.0611 172.403 25.9519 169.002 25.9519C166.929 25.9519 164.879 25.0258 164.697 22.8152H165.965C166.246 24.3423 167.596 24.888 169.002 24.888C171.334 24.888 172.343 23.4988 172.343 20.963V19.5573H172.304C171.72 20.8252 170.491 21.6907 169.002 21.6907C165.684 21.6907 164.316 19.3147 164.316 16.3599C164.316 13.5044 166.009 11.1284 169.002 11.1284C170.513 11.1284 171.797 12.0711 172.304 13.2232H172.343V11.4316H173.611V20.963ZM172.343 16.4977C172.343 14.4636 171.4 12.1924 169.002 12.1924C166.588 12.1924 165.579 14.3478 165.579 16.4977C165.579 18.5926 166.665 20.6212 169.002 20.6212C171.196 20.6212 172.343 18.6091 172.343 16.4977Z",
    fill: "none"
  }), _jsx("path", {
    d: "M176.047 7.4458H177.315V9.47997H176.047V7.4458ZM176.047 11.4259H177.315V21.8118H176.047V11.4259Z",
    fill: "none"
  }), _jsx("path", {
    d: "M181.791 11.4262H183.903V12.4901H181.791V19.4967C181.791 20.3236 181.913 20.8032 182.817 20.8638C183.181 20.8638 183.539 20.8418 183.903 20.8032V21.8892C183.522 21.8892 183.159 21.9278 182.778 21.9278C181.086 21.9278 180.507 21.3655 180.523 19.5959V12.4956H178.715V11.4317H180.523V8.31152H181.791V11.4262Z",
    fill: "none"
  }), _jsx("path", {
    d: "M185.562 14.6069C185.683 12.1703 187.392 11.1284 189.768 11.1284C191.598 11.1284 193.589 11.6907 193.589 14.4691V19.9817C193.589 20.4669 193.831 20.748 194.333 20.748C194.471 20.748 194.636 20.7094 194.735 20.6653V21.7293C194.454 21.7899 194.25 21.8119 193.908 21.8119C192.618 21.8119 192.42 21.0898 192.42 19.9983H192.381C191.494 21.3489 190.59 22.1096 188.6 22.1096C186.687 22.1096 185.121 21.1615 185.121 19.0722C185.121 16.156 187.96 16.0567 190.694 15.7315C191.742 15.6102 192.326 15.4724 192.326 14.3202C192.326 12.6113 191.097 12.1868 189.608 12.1868C188.037 12.1868 186.874 12.909 186.83 14.6014H185.562V14.6069ZM192.326 16.1339H192.288C192.128 16.4371 191.565 16.5363 191.224 16.597C189.068 16.9773 186.395 16.9608 186.395 18.9895C186.395 20.2574 187.519 21.0402 188.71 21.0402C190.639 21.0402 192.354 19.8109 192.332 17.7822V16.1339H192.326Z",
    fill: "none"
  }), _jsx("path", {
    d: "M196.218 7.4458H197.486V21.8118H196.218V7.4458Z",
    fill: "none"
  })]
}), 'TelusDigitalMonoLogo', 'CompanyMono');
export default TelusDigitalMonoLogo;