/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AmericanAutomobileAssociationMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 54,
  height: 32,
  viewBox: "0 0 54 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#AmericanAutomobileAssociationMonoLogo_svg__clip0_12854_184900)",
    children: [_jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M52.8903 1.24637C51.3347 -1.40398 43.1535 0.324488 32.9555 4.99123C33.4742 5.3947 33.9927 5.85552 34.3959 6.31646C42.2317 2.97483 48.281 1.9379 49.5488 4.12706C50.9892 6.60464 45.2849 12.7694 36.1241 18.8766L36.2396 18.9917C47.417 11.7323 54.6189 4.24247 52.8903 1.24637Z",
      fill: "white"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.60916 30.0539C3.74487 28.4981 5.58863 25.5598 9.2759 22.0454C8.98784 21.9876 8.69991 21.8723 8.41173 21.7571C8.35414 21.7571 8.35414 21.6993 8.35414 21.6993C8.06621 21.5842 7.77803 21.4691 7.48985 21.354C3.05358 25.444 0.806592 28.9012 1.84377 30.7453C3.11129 32.8769 8.58449 32.1855 15.9016 29.4777L15.8441 29.3625C9.96719 31.3789 5.64621 31.7821 4.60916 30.0539Z",
      fill: "white"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 12.1359C0 5.85583 8.29655 0.728027 18.4943 0.728027C28.7497 0.728027 37.0463 5.85583 37.0463 12.1359C37.0463 18.4735 28.7497 23.6012 18.4943 23.6012C8.29655 23.601 0 18.4735 0 12.1359ZM10.4858 3.26319L14.0006 15.1894L17.6877 1.70737C15.095 1.82291 12.6176 2.34107 10.4858 3.26319ZM17.5149 8.21811L15.6135 15.1894H19.4738L17.5149 8.21811ZM22.9883 15.1894L26.3299 3.1479C24.1406 2.28361 21.6632 1.76496 19.0704 1.70737L22.9883 15.1894ZM10.4284 15.1894L8.52678 8.21811L6.62579 15.1894H10.4284ZM32.3795 16.5721C33.3012 15.2469 33.82 13.749 33.82 12.1357C33.82 9.08216 31.9187 6.37423 28.8073 4.41518L32.3795 16.5721ZM28.4619 15.1894L26.5029 8.21811L24.6017 15.1894H28.4619ZM28.8652 16.5721H24.1982L23.7951 18.0701L24.8896 21.6417C26.6757 21.1233 28.2887 20.3167 29.6141 19.3379L28.8652 16.5721ZM12.2144 21.6419L10.8315 16.5722H6.22244L5.81897 18.0123C7.43226 19.6255 9.62154 20.8932 12.2143 21.6997V21.6419H12.2144ZM19.8773 16.5721H15.2103L13.7123 22.1029C15.2103 22.4489 16.8237 22.6214 18.4943 22.6214C19.5315 22.6214 20.5108 22.5641 21.4903 22.3911L19.8773 16.5721ZM7.89319 4.64589C5.0125 6.547 3.22646 9.19758 3.22646 12.1359C3.22646 13.7491 3.74486 15.1894 4.60915 16.5145L7.89319 4.64589Z",
      fill: "white"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "AmericanAutomobileAssociationMonoLogo_svg__clip0_12854_184900",
      children: _jsx("rect", {
        width: 53.1459,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'AmericanAutomobileAssociationMonoLogo', 'CompanyMono');
export default AmericanAutomobileAssociationMonoLogo;