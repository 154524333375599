/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RugsComMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 104,
  height: 33,
  viewBox: "0 0 104 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M103.996 13.2024C103.664 10.3702 100.596 9.1968 97.9007 10.019C97.1076 10.2589 96.4228 10.8489 95.6277 11.3151C93.5887 9.18713 91.2924 9.4038 89.1412 10.7928C88.6459 10.5142 88.3403 10.2124 88.0056 10.1795C87.2703 10.0954 86.5278 10.0954 85.7925 10.1795C85.5391 10.2105 85.1328 10.6051 85.129 10.8373C85.0877 14.3852 85.0877 17.9319 85.129 21.4772C85.129 21.7094 85.5429 22.104 85.8002 22.1369C86.5296 22.2278 87.2782 22.1369 88.0153 22.1775C88.704 22.2162 88.911 21.9183 88.9032 21.2702C88.8781 19.3202 88.8878 17.3702 88.9032 15.4183C88.9032 14.0138 89.6442 13.1877 90.859 13.1916C92.0372 13.1916 92.7355 13.979 92.7588 15.3738C92.782 16.7686 92.7588 18.1227 92.7588 19.4982C92.7588 22.2239 92.7588 22.2065 95.4923 22.1833C96.2003 22.1833 96.4595 21.9686 96.4421 21.2489C96.4034 19.3318 96.4189 17.4128 96.4421 15.4937C96.4421 14.0447 97.2159 13.1723 98.4482 13.1916C99.6805 13.211 100.313 13.9848 100.329 15.484C100.344 16.8904 100.329 18.2968 100.329 19.7052C100.329 22.1833 100.329 22.1988 102.871 22.1543C103.248 22.1543 103.715 22.1222 103.996 21.8437V13.5785C103.996 13.5749 104.004 13.2778 103.996 13.2024Z"
  }), _jsx("path", {
    d: "M13.2264 9.09231C12.7408 6.83084 10.9436 5.24646 8.41135 5.11491C5.84616 4.98143 3.26936 5.06075 0.698366 5.0298C0.125745 5.0298 0 5.30837 0 5.80361C0.0116072 11.0165 0.0116072 16.2288 0 21.4404C0 22.0401 0.251489 22.2142 0.816372 22.193C1.55343 22.1601 2.29629 22.1562 3.03335 22.193C3.66787 22.2259 3.88841 21.9686 3.87874 21.3534C3.85165 19.916 3.83811 18.4748 3.89228 17.0394C3.90775 16.6409 4.17085 15.9193 4.33335 15.9154C5.06074 15.9019 5.96997 15.4511 6.44586 16.4803C6.96432 17.6043 7.65494 18.6837 8.03605 19.858C8.65316 21.7596 9.76358 22.5025 11.8006 22.2239C13.2748 22.0305 13.3947 22.0189 12.7176 20.6628C11.8297 18.9004 10.9495 17.1361 10.048 15.3292C10.2627 15.2035 10.3904 15.1242 10.5239 15.0526C12.8492 13.7719 13.7893 11.6962 13.2264 9.09231ZM9.40183 10.6399C9.33412 11.6672 8.56224 12.381 7.40345 12.4391C6.92562 12.4661 6.43619 12.4391 5.96223 12.4391C3.86906 12.4584 3.86906 12.4584 3.86906 10.4097C3.88261 8.02639 3.61177 8.42877 5.9158 8.39782C6.49136 8.36736 7.06845 8.38353 7.6414 8.44618C8.89885 8.6377 9.47921 9.41925 9.40183 10.6399Z"
  }), _jsx("path", {
    d: "M37.4911 10.1484C36.8953 10.1484 36.2995 10.0846 36.3652 10.9783C33.4441 9.40363 30.7977 9.71703 28.9695 11.7986C27.1414 13.8801 27.004 17.2752 28.7026 19.5232C30.4011 21.7711 32.9992 22.2025 36.1911 20.7767C36.431 22.1445 35.8313 23.2916 34.659 23.7018C33.2216 24.2067 31.8017 23.7656 31.0743 22.6416C30.9041 22.3785 30.5191 22.0187 30.3005 22.0613C29.368 22.2393 28.4607 22.5507 27.5573 22.8099C27.6416 23.4448 27.8633 24.0536 28.2071 24.5939C28.551 25.1342 29.0085 25.5929 29.548 25.9381C31.6218 27.3329 33.8852 27.4431 36.1853 26.6887C38.6751 25.8723 40.0099 23.6902 40.0351 20.5601C40.0583 17.9388 40.0351 15.3175 40.0351 12.6981C40.0447 10.1446 40.0447 10.1504 37.4911 10.1484ZM33.773 18.4089C32.2544 18.3973 31.1865 17.2927 31.2271 15.774C31.227 15.4291 31.296 15.0877 31.4302 14.7699C31.5644 14.4522 31.7609 14.1646 32.0082 13.9241C32.2555 13.6837 32.5485 13.4953 32.8699 13.3701C33.1913 13.2449 33.5346 13.1855 33.8794 13.1953C35.2858 13.2282 36.3672 14.3715 36.3711 15.8321C36.3691 17.3758 35.3206 18.4205 33.773 18.4089Z"
  }), _jsx("path", {
    d: "M77.4334 9.77114C73.781 9.74019 70.9934 12.4601 70.9701 16.0738C70.9469 19.7746 73.6533 22.5294 77.3541 22.5681C80.9852 22.6048 83.7651 19.8926 83.8116 16.2499C83.8638 12.5627 81.1458 9.8021 77.4334 9.77114ZM77.3812 19.113C75.8103 19.113 74.6574 17.8401 74.6728 16.1319C74.6844 14.4721 75.8162 13.2301 77.3464 13.203C78.9249 13.174 80.0644 14.4005 80.0741 16.1435C80.0837 17.8865 78.9733 19.1169 77.3812 19.113Z"
  }), _jsx("path", {
    d: "M26.1742 12.8784C26.1645 10.1023 26.1742 10.1236 23.4078 10.1391C22.7075 10.1391 22.4405 10.3325 22.456 11.0618C22.5005 12.948 22.4792 14.8342 22.4676 16.7203C22.4676 18.2157 21.7693 18.9837 20.4654 18.9857C19.2137 18.9857 18.5811 18.2699 18.5521 16.7532C18.527 15.4745 18.5521 14.1938 18.5521 12.9151C18.5521 10.0133 18.5521 10.0365 15.6213 10.141C14.9694 10.1642 14.803 10.4176 14.8088 11.0135C14.8301 13.3136 14.7837 15.6177 14.8282 17.9178C14.8726 20.1735 16.1398 21.7985 18.173 22.2937C18.863 22.4782 19.5849 22.51 20.2885 22.3871C20.9921 22.2641 21.6605 21.9893 22.2471 21.5818C22.6959 21.8236 23.0209 22.1215 23.3672 22.1622C24.0984 22.2434 24.8452 22.1622 25.5842 22.1912C26.1645 22.2067 26.2554 21.932 26.2458 21.4174C26.1993 18.5717 26.1839 15.7221 26.1742 12.8784Z"
  }), _jsx("path", {
    d: "M69.3955 18.0589C68.831 17.9373 68.2773 17.7704 67.7396 17.5598C67.2211 17.3528 66.9542 17.4882 66.6427 17.9467C65.9134 19.0223 64.8281 19.3492 63.6055 18.9739C62.4235 18.6122 61.7271 17.6197 61.7019 16.281C61.6768 14.8301 62.3074 13.7874 63.4565 13.3792C64.5225 13.0039 65.7586 13.1684 66.2539 14.0215C66.8342 15.012 67.4726 14.923 68.3083 14.5632C68.6064 14.4445 68.9155 14.3558 69.2311 14.2981C70.0397 14.126 70.1887 13.7023 69.827 12.9846C68.6894 10.7289 66.7414 9.86614 64.4818 9.76941C60.3574 9.79262 57.6317 12.9401 58.036 17.0587C58.2933 19.7129 60.4599 21.9744 63.178 22.4193C66.1804 22.9107 68.7591 21.7035 69.9063 19.3105C70.2235 18.6528 70.2235 18.2407 69.3955 18.0589Z"
  }), _jsx("path", {
    d: "M48.6204 14.9425C47.8292 14.6891 46.9993 14.5556 46.1848 14.3757C45.6451 14.2596 45.0473 14.0372 45.262 13.4259C45.4013 13.0273 45.9972 12.5514 46.3976 12.5418C46.8909 12.5282 47.5835 12.8436 47.8466 13.2401C48.2335 13.8205 48.6204 13.9153 49.2008 13.7779C49.5451 13.6986 49.8953 13.6464 50.2415 13.5845C51.2185 13.4065 51.3674 13.1395 50.9341 12.2303C50.0423 10.3538 48.3534 9.89146 46.6452 9.77539C44.0626 9.76185 42.0391 11.2611 41.8089 13.2343C41.5342 15.4765 42.6736 16.8306 45.3723 17.5097C45.9952 17.6664 46.6452 17.7592 47.2237 18.0126C47.51 18.1364 47.8408 18.5659 47.8272 18.8406C47.8137 19.1153 47.4674 19.5196 47.1676 19.6628C46.3938 20.0342 45.2795 19.7034 44.91 18.9219C44.5966 18.2525 44.1864 18.2196 43.5983 18.3608C43.225 18.4518 42.84 18.4963 42.4608 18.5659C41.54 18.7361 41.3872 19.0031 41.7818 19.8737C41.94 20.2584 42.1632 20.613 42.4415 20.9222C44.1632 22.6633 46.2699 22.8702 48.5043 22.2551C49.3364 22.0447 50.0753 21.5646 50.6055 20.8898C51.1358 20.215 51.4275 19.3835 51.4351 18.5253C51.4584 16.8906 50.4098 15.5132 48.6204 14.9425Z"
  }), _jsx("path", {
    d: "M55.0159 17.4109C54.6883 17.3995 54.3617 17.4528 54.0547 17.5677C53.7476 17.6826 53.4663 17.8568 53.2266 18.0805C52.987 18.3041 52.7937 18.5728 52.658 18.8712C52.5222 19.1695 52.4465 19.4917 52.4353 19.8193C52.4066 20.16 52.4478 20.503 52.5562 20.8272C52.6646 21.1514 52.838 21.4502 53.0657 21.7052C53.2935 21.9601 53.5708 22.166 53.8808 22.3102C54.1908 22.4543 54.5269 22.5338 54.8687 22.5437C55.2104 22.5535 55.5506 22.4936 55.8684 22.3676C56.1862 22.2416 56.4749 22.0521 56.717 21.8107C56.9591 21.5693 57.1495 21.2811 57.2764 20.9636C57.4034 20.6462 57.4642 20.3062 57.4554 19.9644C57.4659 19.6358 57.4109 19.3084 57.2935 19.0013C57.176 18.6941 56.9986 18.4135 56.7715 18.1758C56.5443 17.938 56.2721 17.7479 55.9707 17.6166C55.6693 17.4853 55.3447 17.4153 55.0159 17.4109Z"
  })]
}), 'RugsComMonoLogo', 'CompanyMono');
export default RugsComMonoLogo;