/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var AutodeskMonoLogo = createCompanyLogosComponent(_jsx("svg", {
  width: 312,
  height: 32,
  viewBox: "0 0 312 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "m.236 31.238 27.197-16.933h14.115a.83.83 0 0 1 .833.834c0 .399-.181.562-.398.689L28.63 23.842c-.87.525-1.178 1.559-1.178 2.338l-.018 5.058h16.978V1.885c0-.562-.435-1.051-1.087-1.051H27.125L0 17.695v13.543h.236ZM223.249 31.24h24.462v-6.582h-16.707v-5.512h13.445v-6.6h-13.445v-5.13h16.707V.834h-24.462v30.405ZM69.542.834 57.89 31.239H65.9l2.029-5.693h13.01l1.993 5.693h8.28L79.418.834h-9.875Zm.688 18.24 4.258-11.912 4.186 11.912H70.23ZM124.443 7.416h9.803v23.823h7.737V7.416h9.767V.834h-27.307v6.582ZM113.174 19.526c0 3.953-2.428 5.549-6.578 5.549-4.022 0-6.65-1.668-6.65-5.549V.725h-7.755v19.69c0 8.774 7.828 11.512 14.387 11.512 6.523 0 14.315-2.738 14.315-11.512V.725h-7.756v18.801h.037ZM311.654.834h-9.223l-11.052 12.347V.834h-7.756l.036 30.405h7.72V19.69l10.473 11.55h9.802l-14.133-15.013L311.654.834ZM205.021.834h-14.206v30.405h14.206c10.891 0 13.898-4.061 13.898-15.429 0-10.679-2.881-14.976-13.898-14.976Zm-.308 23.823h-6.16V7.416h6.16c4.857 0 6.306 1.323 6.306 8.394 0 6.455-1.631 8.847-6.306 8.847ZM271.139 13.344l-7.066-.798c-4.132-.453-5.164-1.486-5.164-2.883 0-1.559 2.12-2.955 6.269-2.955 3.986 0 6.07 1.487 6.451 3.717h7.592C278.84 3.988 273.459 0 265.595 0c-9.35 0-14.042 3.717-14.042 10.299 0 5.348 3.116 7.868 9.566 8.557l7.175.761c2.737.308 3.987 1.36 3.987 2.738 0 1.559-1.83 2.955-6.233 2.955-5.092 0-7.139-1.287-7.484-3.88h-7.447c.344 6.998 4.893 10.57 14.659 10.57 8.914 0 13.897-3.916 13.897-9.808 0-5.131-2.953-8.213-8.534-8.848ZM169.779 0c-11.053 0-16.778 6.799-16.778 15.955 0 9.192 5.725 16.027 16.778 16.027 11.09 0 16.816-6.835 16.816-16.027C186.612 6.799 180.869 0 169.779 0Zm0 25.147c-5.726 0-8.878-3.5-8.878-9.192 0-5.585 3.152-9.12 8.878-9.12 5.78 0 8.879 3.535 8.879 9.12.018 5.711-3.099 9.192-8.879 9.192Z",
    fill: "none"
  })
}), 'AutodeskMonoLogo', 'CompanyMono');
export default AutodeskMonoLogo;