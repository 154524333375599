/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LandbotMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 140,
  height: 32,
  viewBox: "0 0 140 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#LandbotMonoLogo_svg__clip0_11066_127574)",
    children: [_jsx("path", {
      d: "M16.3648 13.9304C16.37 14.3962 16.2366 14.8531 15.9814 15.243C15.7262 15.6328 15.3608 15.9381 14.9315 16.12C14.5024 16.3019 14.0287 16.3522 13.5708 16.2645C13.113 16.1768 12.6915 15.9551 12.36 15.6276C12.0285 15.3 11.8019 14.8814 11.709 14.4249C11.6161 13.9684 11.6611 13.4946 11.8383 13.0637C12.0155 12.6328 12.3169 12.2643 12.7041 12.0048C13.0913 11.7455 13.5471 11.607 14.0133 11.607C14.3203 11.6052 14.6248 11.664 14.9091 11.7797C15.1935 11.8956 15.4523 12.0663 15.6706 12.282C15.889 12.4978 16.0626 12.7544 16.1818 13.0372C16.3009 13.32 16.3631 13.6236 16.3648 13.9304Z",
      fill: "white"
    }), _jsx("path", {
      d: "M16.5149 22.298C16.3645 22.1085 16.1453 21.9862 15.9051 21.9576C15.6648 21.929 15.423 21.9965 15.2324 22.1454C14.8888 22.4092 14.4659 22.5489 14.0327 22.5418C13.5993 22.5346 13.1813 22.3809 12.8466 22.1059C12.6719 21.9573 12.4488 21.8776 12.2194 21.882C11.9899 21.8864 11.77 21.9744 11.601 22.1296C11.4756 22.2482 11.3866 22.4 11.3441 22.5672C11.3016 22.7345 11.3077 22.9103 11.3614 23.0743C11.4231 23.2505 11.5348 23.4049 11.6827 23.519C12.3409 24.0553 13.1616 24.3531 14.011 24.3636C14.8603 24.374 15.6881 24.0967 16.3595 23.5768C16.4543 23.5038 16.5336 23.4126 16.5928 23.3086C16.652 23.2047 16.6899 23.0899 16.7044 22.9712C16.7188 22.8525 16.7095 22.732 16.677 22.6169C16.6444 22.5018 16.5893 22.3942 16.5149 22.3006V22.298Z",
      fill: "white"
    }), _jsx("path", {
      d: "M14.0134 7.99131C12.8402 8.00481 11.6973 8.36478 10.7285 9.02594C9.75964 9.68706 9.00817 10.6198 8.56872 11.7068C8.12921 12.7937 8.02137 13.9863 8.25865 15.1343C8.496 16.2824 9.06791 17.3347 9.9024 18.1587C10.7369 18.9827 11.7967 19.5416 12.9484 19.7651C14.1 19.9885 15.2921 19.8665 16.3746 19.4145C17.4571 18.9625 18.3815 18.2006 19.0316 17.2247C19.6816 16.2488 20.0283 15.1025 20.0278 13.9302C20.0181 12.3458 19.379 10.8301 18.2511 9.71639C17.1233 8.60273 15.5989 7.98221 14.0134 7.99131ZM14.0134 18.0746C13.1958 18.0652 12.3992 17.8145 11.7239 17.3538C11.0486 16.8932 10.5247 16.2433 10.2182 15.4858C9.91168 14.7285 9.83613 13.8974 10.0012 13.0971C10.1662 12.297 10.5644 11.5634 11.1457 10.9888C11.7269 10.4142 12.4653 10.0242 13.2677 9.8679C14.0703 9.71162 14.9012 9.79598 15.6558 10.1104C16.4105 10.4248 17.0552 10.9552 17.509 11.6349C17.9626 12.3146 18.205 13.1132 18.2056 13.9302C18.1993 15.0352 17.7542 16.0925 16.9681 16.8696C16.1819 17.6468 15.1192 18.0801 14.0134 18.0746Z",
      fill: "white"
    }), _jsx("path", {
      d: "M26.906 27.1633C26.3312 26.292 25.9215 25.3225 25.6973 24.3031C25.669 24.1799 25.6653 24.0523 25.6865 23.9276C25.7078 23.803 25.7535 23.6838 25.8211 23.5769C27.2653 21.2852 28.0279 18.6305 28.0199 15.9223C28.0032 12.2228 26.5202 8.68049 23.8955 6.07112C21.2708 3.46175 17.7183 1.99788 14.0159 2H13.929C11.2509 2.01434 8.63311 2.79571 6.38595 4.25147C4.13878 5.70723 2.35647 7.77634 1.25033 10.2135C0.144186 12.6507 -0.239398 15.3536 0.145053 18.002C0.529503 20.6504 1.66586 23.133 3.41942 25.1557C4.73137 26.6792 6.35772 27.901 8.1868 28.7372C10.016 29.5734 12.0044 30.0041 14.0159 30H14.0897C16.0446 29.9892 17.9763 29.5763 19.7644 28.7869C19.9564 28.7023 20.1725 28.6901 20.3728 28.7527C21.3921 29.057 22.4504 29.2112 23.5143 29.2106H23.5696C24.4888 29.2079 25.4035 29.0831 26.2898 28.8395C26.5679 28.7619 26.8044 28.5784 26.9486 28.3286C27.0929 28.0786 27.1334 27.7822 27.0614 27.5028C27.0302 27.3813 26.9776 27.2663 26.906 27.1633ZM24.5834 27.3607C23.2847 27.5225 21.9661 27.3856 20.7282 26.9608C20.2395 26.79 19.7031 26.8184 19.2351 27.0397C17.6227 27.8005 15.8624 28.1976 14.0791 28.2028H14.0159C11.6035 28.2081 9.24371 27.4989 7.23444 26.1649C5.22518 24.8309 3.65658 22.9318 2.72676 20.7075C1.79692 18.4833 1.54757 16.0336 2.01018 13.6677C2.47279 11.302 3.62661 9.1262 5.32594 7.41527C6.45056 6.27086 7.79115 5.36068 9.27008 4.73738C10.7491 4.11409 12.3371 3.79006 13.9422 3.78404H14.0159C16.2313 3.78351 18.4051 4.38484 20.3048 5.52368C22.2045 6.66252 23.7586 8.29604 24.8009 10.2494C25.843 12.2029 26.3342 14.4027 26.2217 16.6135C26.1092 18.8243 25.3973 20.9629 24.1621 22.8006C23.8671 23.2322 23.7545 23.7627 23.8487 24.2768C24.0242 25.2303 24.3336 26.1543 24.7678 27.0213C24.7857 27.055 24.7951 27.0928 24.7949 27.131C24.7947 27.1693 24.7851 27.2069 24.7669 27.2405C24.7486 27.2741 24.7223 27.3027 24.6903 27.3237C24.6583 27.3447 24.6216 27.3574 24.5834 27.3607Z",
      fill: "white"
    }), _jsx("path", {
      d: "M38.6638 22.7218H48.2044V26.4715H34.7271V5.51562H38.677L38.6638 22.7218Z",
      fill: "white"
    }), _jsx("path", {
      d: "M60.4651 12.0306H63.7858V26.4713H60.3414L60.0965 24.2583C59.2643 25.8582 57.5738 26.8107 55.3302 26.8107C51.978 26.8107 48.6257 24.2294 48.6257 19.2509C48.6257 14.2724 51.978 11.7358 55.3302 11.7358C57.6053 11.7358 59.296 12.6884 60.1254 14.3171L60.4651 12.0306ZM60.0332 19.2509C60.0332 16.6196 58.1268 15.1329 56.3466 15.1329C54.1636 15.1329 52.473 16.7301 52.473 19.2509C52.473 21.8323 54.1952 23.3979 56.3466 23.3979C58.1899 23.3979 60.0332 22.048 60.0332 19.2509Z",
      fill: "white"
    }), _jsx("path", {
      d: "M65.292 26.4712V18.2667C65.292 14.4565 67.7224 11.7227 72.057 11.7227C76.3916 11.7227 78.7933 14.4565 78.7933 18.2667V26.4712H74.9169V18.5772C74.9169 16.4564 73.9636 15.1354 72.057 15.1354C70.1504 15.1354 69.1971 16.4511 69.1971 18.5772V26.4712H65.292Z",
      fill: "white"
    }), _jsx("path", {
      d: "M91.2641 5.51562H95.1379V26.4715H91.6012L91.3563 24.2586C90.5268 25.8584 88.8651 26.8109 86.6167 26.8109C83.3249 26.8109 80.0042 24.2296 80.0042 19.2511C80.0042 14.2727 83.3303 11.7361 86.6221 11.7361C88.7729 11.7361 90.403 12.6281 91.2641 14.1043V5.51562ZM91.2931 19.2511C91.2931 16.4541 89.4497 15.1331 87.6359 15.1331C85.5134 15.1331 83.8544 16.6698 83.8544 19.2511C83.8544 21.8325 85.5134 23.3981 87.6359 23.3981C89.4497 23.3981 91.2931 22.0483 91.2931 19.2511Z",
      fill: "white"
    }), _jsx("path", {
      d: "M111.804 19.2511C111.804 24.2296 108.484 26.8109 105.192 26.8109C102.949 26.8109 101.287 25.8584 100.452 24.2586L100.213 26.4715H96.676V5.51562H100.55V14.0885C101.411 12.615 103.041 11.7203 105.192 11.7203C108.484 11.7361 111.804 14.2727 111.804 19.2511ZM107.96 19.2511C107.96 16.6698 106.33 15.1331 104.179 15.1331C102.364 15.1331 100.518 16.4488 100.518 19.2511C100.518 22.0535 102.362 23.3981 104.179 23.3981C106.33 23.3981 107.96 21.8325 107.96 19.2511Z",
      fill: "white"
    }), _jsx("path", {
      d: "M112.72 19.2509C112.72 14.9803 115.946 11.7358 120.434 11.7358C124.921 11.7358 128.152 14.9803 128.152 19.2509C128.152 23.5216 124.924 26.8107 120.434 26.8107C115.944 26.8107 112.72 23.5216 112.72 19.2509ZM124.307 19.2509C124.307 16.8222 122.677 15.1039 120.431 15.1039C118.185 15.1039 116.557 16.8222 116.557 19.2509C116.557 21.6796 118.187 23.3979 120.431 23.3979C122.675 23.3979 124.307 21.7086 124.307 19.2509Z",
      fill: "white"
    }), _jsx("path", {
      d: "M139.007 15.1327H134.793V20.5112C134.793 22.3847 135.467 23.2451 137.405 23.2451C137.59 23.2451 138.051 23.2451 138.635 23.2136V26.3712C137.896 26.5864 137.13 26.6997 136.36 26.7079C133.1 26.7079 130.888 24.7423 130.888 21.0243V15.1327H128.273V12.0304H130.89V7.14404H134.796V12.0304H139.009L139.007 15.1327Z",
      fill: "white"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "LandbotMonoLogo_svg__clip0_11066_127574",
      children: _jsx("rect", {
        width: 139.009,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'LandbotMonoLogo', 'CompanyMono');
export default LandbotMonoLogo;