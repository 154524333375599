/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PagerDutyMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 118,
  height: 32,
  viewBox: "0 0 118 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M114.765 9.02811L112.125 16.5161C111.645 17.8601 111.405 18.6281 110.961 19.9241H110.913C110.565 18.8081 110.253 17.9081 109.797 16.5881L107.229 9.01611H104.157L109.473 22.9121C109.353 23.2121 109.233 23.5241 109.101 23.8481C108.657 24.9281 107.601 25.6361 106.437 25.6361H105.117V28.0001H106.473C108.669 28.0001 110.649 26.6681 111.465 24.6161C113.157 20.3801 116.037 13.1681 117.681 9.02811H114.765Z",
    fill: "none"
  }), _jsx("path", {
    d: "M41.049 20.2482C41.049 25.2762 38.745 28.0002 34.089 28.0002C30.621 28.0002 28.533 26.1762 27.789 23.4282H30.597C31.077 24.7002 32.073 25.8402 34.137 25.8402C37.317 25.8402 38.445 23.8602 38.445 20.0922C38.421 20.0922 38.421 20.0682 38.397 20.0682C37.713 21.2562 36.177 22.3122 33.741 22.3122C29.829 22.3122 27.333 19.4802 27.333 15.5082C27.333 11.3802 30.033 8.59619 33.765 8.59619C36.177 8.59619 37.629 9.62819 38.421 10.7682C38.397 10.4802 38.397 10.1802 38.397 9.89219V9.01619H41.049V20.2482ZM30.249 15.4842C30.249 18.1842 31.761 20.1162 34.353 20.1162C36.681 20.1162 38.481 18.4722 38.481 15.4002C38.481 12.6762 36.897 10.8522 34.353 10.8522C31.809 10.8522 30.249 12.6762 30.249 15.4842Z",
    fill: "none"
  }), _jsx("path", {
    d: "M45.429 16.6718C45.537 19.1078 47.097 21.0398 49.797 21.0398C51.705 21.0398 52.629 20.1398 53.337 18.9518H56.013C55.221 21.4598 53.025 23.2598 49.689 23.2598C45.405 23.2598 42.729 20.3798 42.729 16.0358C42.729 11.6918 45.513 8.63184 49.689 8.63184C54.213 8.63184 56.301 12.0758 56.301 15.8078V16.6838H45.429V16.6718ZM53.517 14.5598C53.277 12.3638 51.897 10.8038 49.629 10.8038C47.457 10.8038 45.789 12.2318 45.525 14.5598H53.517Z",
    fill: "none"
  }), _jsx("path", {
    d: "M60.5851 9.02789V10.8039C61.0291 9.50789 62.3851 8.69189 63.9451 8.69189C64.2931 8.69189 64.4491 8.71589 64.6291 8.73989V11.1759C64.3891 11.1279 63.9931 11.0919 63.7051 11.0919C61.1971 11.0919 60.7171 12.9399 60.7171 15.7239V22.9239H58.0411V11.5239C58.0411 10.6479 58.0411 9.87989 58.0171 9.03989H60.5851V9.02789Z",
    fill: "none"
  }), _jsx("path", {
    d: "M92.1089 20.9318C91.3889 22.1198 90.0449 23.2598 87.6929 23.2598C84.4649 23.2598 82.8809 21.2798 82.8809 17.6798V9.02783H85.5449V16.6718C85.5449 19.3958 86.4449 21.0398 88.6169 21.0398C91.4729 21.0398 91.9769 18.4478 91.9769 15.3998V9.02783H94.6529V20.8238C94.6529 21.5438 94.6529 22.2278 94.6769 22.9118H92.1089V20.9318Z",
    fill: "none"
  }), _jsx("path", {
    d: "M100.665 9.02797H103.257V11.092H100.665V19.432C100.665 20.752 101.325 21.148 102.357 21.148C102.537 21.148 102.861 21.124 103.017 21.1V23.08C102.621 23.128 102.333 23.188 101.985 23.188C99.525 23.188 97.965 22.372 97.965 19.588V11.092H95.877V9.02797H97.965V5.27197H100.665V9.02797Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3.47708 17.8721H0.705078V22.9121H3.47708V17.8721Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.1451 4.924C9.65708 4.132 8.62508 4 6.18908 4H0.705078V15.472H3.47708H3.83708H6.16508C8.33708 15.472 9.95708 15.34 11.3851 14.392C12.9451 13.36 13.7491 11.644 13.7491 9.664C13.7491 7.516 12.7531 5.8 11.1451 4.924ZM6.80108 13.072H3.47708V6.46L6.60908 6.436C9.46508 6.4 10.8931 7.408 10.8931 9.688C10.8931 12.136 9.12908 13.072 6.80108 13.072Z",
    fill: "none"
  }), _jsx("path", {
    d: "M66.2612 4H70.8932C77.8292 4.048 81.1652 7.432 81.1892 13.504C81.2132 18.676 78.5372 22.852 71.3492 22.924H66.2732V4H66.2612ZM69.0692 20.56H70.6892C75.7172 20.56 78.3452 18.64 78.3452 13.492C78.3332 8.968 75.7292 6.376 70.9532 6.376C70.0292 6.376 69.0692 6.4 69.0692 6.4V20.56Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.4689 22.924C23.3849 22.372 23.3849 22.024 23.3369 21.016C22.2809 22.528 20.8769 23.188 18.6569 23.188C15.7409 23.188 13.6289 21.76 13.6289 19.168C13.6289 16.18 16.4849 15.196 20.1929 14.692C21.1169 14.56 22.1489 14.452 23.0489 14.38C23.0489 11.632 21.5249 10.804 19.9289 10.804C18.3329 10.804 17.1689 11.92 17.1689 13.384H14.5769C14.5769 10.564 16.7969 8.66797 19.9889 8.66797C23.1809 8.66797 25.6409 9.98797 25.6409 15.076V17.776C25.6409 20.02 25.7489 21.664 25.9889 22.936H23.4689V22.924ZM16.5089 19.132C16.5089 20.428 17.5649 21.244 19.2569 21.244C21.7409 21.244 23.0729 19.732 23.0729 17.032C23.0729 16.684 23.0729 16.444 23.0969 16.288C18.5729 16.756 16.5089 17.2 16.5089 19.132Z",
    fill: "none"
  })]
}), 'PagerDutyMonoLogo', 'CompanyMono');
export default PagerDutyMonoLogo;