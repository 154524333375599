/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BoltMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 49,
  height: 32,
  viewBox: "0 0 49 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("g", {
    clipPath: "url(#BoltMonoLogo_svg__clip0_11058_126732)",
    children: _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M38.6834 2V23.0518H33.6882V3.0528L38.6834 2ZM24.4615 25.0468C25.8405 25.0468 26.9591 26.1556 26.9591 27.5234C26.9591 28.8912 25.8405 30 24.4615 30C23.0825 30 21.9639 28.8912 21.9639 27.5234C21.9639 26.1556 23.0818 25.0468 24.4615 25.0468ZM24.4615 7.9787C28.714 7.9787 32.1685 11.3975 32.1685 15.6213C32.1685 19.8458 28.714 23.2653 24.4615 23.2653C20.202 23.2653 16.7545 19.8458 16.7545 15.6213C16.7545 11.3975 20.209 7.9787 24.4615 7.9787ZM24.4615 18.0979C25.8419 18.0979 26.9591 16.9905 26.9591 15.6213C26.9591 14.2528 25.8419 13.1447 24.4615 13.1447C23.0811 13.1447 21.9639 14.2528 21.9639 15.6213C21.9639 16.9905 23.0811 18.0979 24.4615 18.0979ZM8.7479 18.0979C9.6089 18.0979 10.3089 17.4035 10.3089 16.5509C10.3067 16.1387 10.1411 15.7443 9.84848 15.454C9.55585 15.1638 9.16006 15.0014 8.7479 15.0025H5.0022V18.0979H8.7479ZM5.0022 6.9539V10.0493H7.777C8.6373 10.0493 9.338 9.3542 9.338 8.5009C9.33541 8.08898 9.16967 7.69488 8.87708 7.40492C8.5845 7.11496 8.18892 6.95278 7.777 6.9539H5.0022ZM13.3476 11.9204C14.5544 13.1034 15.3041 14.7414 15.2971 16.5502C15.2971 20.1412 12.3627 23.0518 8.7409 23.0518H0V2H7.77C11.3911 2 14.3255 4.9099 14.3255 8.5009C14.3255 9.7539 13.972 10.9299 13.3476 11.9204ZM48.118 13.214H45.6274V17.1011C45.6274 18.2771 46.0089 19.1437 47.0078 19.1437C47.6532 19.1437 48.125 18.9995 48.125 18.9995V22.6458C48.125 22.6458 47.0911 23.2646 45.6897 23.2646H45.6274C45.5644 23.2646 45.5098 23.2576 45.4468 23.2576H45.3985C45.3705 23.2576 45.3355 23.2506 45.3082 23.2506C42.5194 23.1064 40.6252 21.3662 40.6252 18.3457V5.5287L45.6204 4.4766V8.2601H48.118V13.214Z",
      fill: "white"
    })
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "BoltMonoLogo_svg__clip0_11058_126732",
      children: _jsx("rect", {
        width: 48.3,
        height: 28,
        fill: "white",
        transform: "translate(0 2)"
      })
    })
  })]
}), 'BoltMonoLogo', 'CompanyMono');
export default BoltMonoLogo;