/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BoxMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 61,
  height: 32,
  viewBox: "0 0 61 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M31.1898 9.45293C26.8763 9.45293 23.1222 11.8795 21.2313 15.4419C19.3405 11.8795 15.5877 9.45293 11.2716 9.45293C8.7373 9.45293 6.39475 10.2907 4.50521 11.7075V2.20993C4.48289 0.982191 3.48231 0 2.25064 0C1.01896 0 0.0275749 0.982191 0 2.20993V20.9123H0.00262618C0.102421 27.0536 5.10661 32 11.2716 32C15.589 32 19.3405 29.5708 21.2313 26.0123C23.1222 29.5721 26.8763 32 31.1898 32C37.4139 32 42.4627 26.9525 42.4627 20.7245C42.4627 14.4965 37.4139 9.45293 31.1898 9.45293ZM11.2716 27.4856C7.53714 27.4856 4.50521 24.4602 4.50521 20.7245C4.50521 16.9888 7.53582 13.9647 11.2716 13.9647C15.0073 13.9647 18.0314 16.9901 18.0314 20.7232C18.0314 24.4563 15.006 27.4856 11.2716 27.4856ZM31.1898 27.4856C27.4541 27.4856 24.4248 24.4589 24.4248 20.7232C24.4248 16.9874 27.4541 13.9647 31.1898 13.9647C34.9256 13.9647 37.9509 16.9901 37.9509 20.7232C37.9509 24.4563 34.9229 27.4856 31.1898 27.4856Z",
    fill: "white"
  }), _jsx("path", {
    d: "M59.9557 28.2249L53.8275 20.7088L59.9623 13.1782C60.7383 12.1842 60.5151 10.7976 59.4449 10.0609C58.3734 9.31772 56.8686 9.50811 56.0387 10.4719L50.7601 16.9389L45.4868 10.4706C44.6661 9.50811 43.1521 9.31772 42.0832 10.0609C41.0144 10.7989 40.7911 12.1855 41.5711 13.1782H41.5685L47.6927 20.7088L41.5685 28.2249H41.5711C40.7911 29.2215 41.0144 30.6042 42.0832 31.3448C43.1521 32.0854 44.6661 31.8963 45.4868 30.9312L50.7601 24.4708L56.0309 30.9312C56.8607 31.8963 58.3655 32.0854 59.437 31.3448C60.5085 30.6042 60.7317 29.2215 59.9544 28.2249",
    fill: "white"
  })]
}), 'BoxMonoLogo', 'CompanyMono');
export default BoxMonoLogo;