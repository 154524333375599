/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TeradataMonoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 153,
  height: 30,
  viewBox: "0 0 153 30",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M0.901367 3.7135H6.12314V8.53486H11.947V13.1544H6.12314V21.2291C6.12314 23.3185 7.20631 24.0016 8.73393 24.0016H11.947V28.6211H8.73393C3.39103 28.6211 0.901367 26.293 0.901367 21.2291V3.7135Z",
    fill: "none"
  }), _jsx("path", {
    d: "M24.4393 29.0987C18.0131 29.0987 13.7131 24.8795 13.7131 18.6148C13.7131 12.3503 17.9324 8.05029 24.1566 8.05029C30.3808 8.05029 34.4384 12.1484 34.4384 18.413C34.4384 19.0152 34.3975 19.6579 34.277 20.4217H19.1773C19.6583 23.2345 21.3879 24.5601 24.5202 24.5601C26.4884 24.5601 27.9353 23.7156 28.4971 22.5919H33.8804C32.6759 26.488 29.3011 29.0987 24.4393 29.0987ZM19.258 16.1658H28.978C28.4569 13.6357 26.929 12.4311 24.1566 12.4311C21.5458 12.4311 19.8603 13.7165 19.258 16.1658Z",
    fill: "none"
  }), _jsx("path", {
    d: "M37.3323 8.5312H42.352V10.9401C44.1991 9.09307 46.4097 8.16769 48.4991 8.5312V13.3528C45.8883 13.0333 44.2028 14.2377 42.5539 16.364V28.6139H37.3323V8.5312Z",
    fill: "none"
  }), _jsx("path", {
    d: "M55.0021 28.8563C51.308 28.8563 48.374 26.6861 48.374 22.1878C48.374 18.4937 50.7021 15.9635 55.9237 15.9635H60.4625V15.3613C60.4625 13.5142 59.2984 12.7505 56.4048 12.7505C54.477 12.7505 52.9494 12.9527 50.8601 13.5546V8.85426C52.4685 8.4135 54.4771 8.13098 56.8456 8.13098C62.8715 8.13098 65.6805 10.4186 65.6805 15.3613V28.6175H60.5801V27.4534L59.416 27.9345C57.9289 28.5367 56.4085 28.8563 55.0021 28.8563ZM57.8553 24.156L60.4661 23.0323V20.0616H56.4893C54.6826 20.0616 53.5957 20.9063 53.5957 22.2319C53.5957 23.7999 54.679 24.5599 56.045 24.5599C56.6071 24.5599 57.2531 24.3944 57.8553 24.156Z",
    fill: "none"
  }), _jsx("path", {
    d: "M84.1625 28.6175V27.2919L83.8832 27.4133C82.0362 28.2983 80.0679 28.7389 78.1805 28.7389C73.1607 28.7389 68.7395 25.4853 68.7395 18.5781C68.7395 11.671 73.157 8.37714 78.2208 8.37714C80.0678 8.37714 82.0362 8.81783 83.8832 9.70269L84.0446 9.78377V0.901123H89.2663V28.6179L84.1625 28.6175ZM79.1427 23.9173C80.2663 23.9173 81.5111 23.6345 82.7964 23.0323L84.0413 22.43V14.7186L82.7964 14.1164C81.5111 13.5142 80.2663 13.2313 79.1427 13.2313C76.2491 13.2313 74.0826 15.0381 74.0826 18.5743C74.0826 22.1106 76.2528 23.9173 79.1427 23.9173Z",
    fill: "none"
  }), _jsx("path", {
    d: "M98.7032 28.8563C95.0091 28.8563 92.0752 26.6861 92.0752 22.1878C92.0752 18.4937 94.4033 15.9635 99.625 15.9635H104.163V15.3613C104.163 13.5142 103 12.7505 100.106 12.7505C98.1782 12.7505 96.6505 12.9527 94.5611 13.5546V8.85426C96.1695 8.4135 98.1782 8.13098 100.547 8.13098C106.572 8.13098 109.382 10.4186 109.382 15.3613V28.6175H104.282V27.4534L103.117 27.9345C101.63 28.5367 100.11 28.8563 98.7032 28.8563ZM101.556 24.156L104.167 23.0323V20.0616H100.19C98.3837 20.0616 97.2969 20.9063 97.2969 22.2319C97.2969 23.7999 98.38 24.5599 99.7461 24.5599C100.308 24.5599 100.954 24.3944 101.556 24.156Z",
    fill: "none"
  }), _jsx("path", {
    d: "M113.242 3.7135H118.463V8.53486H124.288V13.1544H118.463V21.2291C118.463 23.3185 119.547 24.0016 121.074 24.0016H124.288V28.6211H121.074C115.731 28.6211 113.242 26.293 113.242 21.2291V3.7135Z",
    fill: "none"
  }), _jsx("path", {
    d: "M132.721 28.8563C129.028 28.8563 126.094 26.6861 126.094 22.1878C126.094 18.4937 128.421 15.9635 133.643 15.9635H138.182V15.3613C138.182 13.5142 137.019 12.7505 134.125 12.7505C132.197 12.7505 130.669 12.9527 128.579 13.5546V8.85426C130.188 8.4135 132.197 8.13098 134.565 8.13098C140.591 8.13098 143.4 10.4186 143.4 15.3613V28.6175H138.299V27.4534L137.136 27.9345C135.649 28.5367 134.128 28.8563 132.721 28.8563ZM135.575 24.156L138.186 23.0323V20.0616H134.209C132.402 20.0616 131.315 20.9063 131.315 22.2319C131.315 23.7999 132.399 24.5599 133.764 24.5599C134.327 24.5599 134.973 24.3944 135.575 24.156Z",
    fill: "none"
  }), _jsx("path", {
    d: "M146.419 26.3263C146.419 24.7988 147.623 23.5941 149.191 23.5941C150.759 23.5941 151.963 24.7988 151.963 26.3263C151.963 27.8539 150.718 29.0986 149.191 29.0986C147.663 29.0986 146.419 27.8539 146.419 26.3263Z",
    fill: "none"
  })]
}), 'TeradataMonoLogo', 'CompanyMono');
export default TeradataMonoLogo;